import {useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {AccountContext} from '../../../../shared/context/AccountContext';
import {useUnitService} from '../../../../shared/services/useUnitService';
import {ValidationUtils} from '../../../../utils/validation-utils';
import {removeMask} from '../../../../shared/utils/remove-mask';
import Routes from "../../../../constants/Routes";
import {useNavigate} from "react-router-dom";


export const useProfileData = () => {
    const {ids} = useContext(AccountContext);
    const [currentName, setCurrentName] = useState("");
    const [currentCnpj, setCurrentCnpj] = useState("");
    const [currentEmail, setCurrentEmail] = useState("");
    const [currentPhone, setCurrentPhone] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [newName, setNewName] = useState(null);
    const [newCnpj, setNewCnpj] = useState(null);
    const [newEmail, setNewEmail] = useState(null);
    const [newPhone, setNewPhone] = useState(null);
    const [initialPhone, setInitialPhone] = useState("");
    const {solicitarAlterarDadosUnidade, findUnityById} = useUnitService();
    const navigate = useNavigate();

    useEffect(() => {
        const getUnity = () => {
            findUnityById(ids.unitId)
                .then((unityResponse) => {
                    setCurrentName(unityResponse.nome);
                    setCurrentCnpj(unityResponse.cpfCnpj);
                    setCurrentEmail(unityResponse.email);
                    setCurrentPhone(unityResponse.telefone);
                    setNewName(unityResponse.nome);
                    setNewCnpj(unityResponse.cpfCnpj);
                    setNewEmail(unityResponse.email);
                    setNewPhone(unityResponse.telefone);
                    setInitialPhone(unityResponse.telefone);
                })
                .catch(() => {
                    console.error("Erro ao buscar dados da unidade");
                    navigate(Routes.Login)
                });
        }
        getUnity()
    }, []);

    const hasChanges = () => {
        return (
            currentName !== newName ||
            currentCnpj !== newCnpj ||
            currentEmail !== newEmail ||
            initialPhone !== newPhone
        );
    };

    const isValid = () => {
        const validName = ValidationUtils.validateBussinessName(newName);
        const validCnpj = ValidationUtils.validateCnpj(newCnpj);
        const validEmail = ValidationUtils.validateEmail(newEmail);
        const validPhone = ValidationUtils.validateDDIPhone(newPhone, false);
        return validName && validCnpj && validEmail && validPhone;
    };

    const send = () => {
        if (!isValid()) {
            toast.error("Os dados inseridos não são válidos. Por favor, verifique e tente novamente.");
            return;
        }
        if (!hasChanges()) {
            toast.error("Nenhum dado foi alterado.");
            return;
        }
        solicitarAlterarDadosUnidade({
            name: newName,
            email: removeMask(newEmail),
            phone: removeMask(newPhone),
            document: removeMask(newCnpj),
        }).then(() => {
            closeModal();
            toast.success("Solicitação enviada com sucesso, em breve você receberá a confirmação via e-mail.");
        });
    };

    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    return {
        modalVisible,
        newName,
        newCnpj,
        newEmail,
        newPhone,
        setNewCnpj,
        setNewEmail,
        setNewName,
        setNewPhone,
        send,
        openModal,
        closeModal,
    };
};