import styled from "styled-components";
import { Palette } from "../../../../shared/theme/palette";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Container = styled.button<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${Palette.lightGrey12};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05); 
  border: none;
  border-bottom-left-radius: ${props => props.isSelected ? '0' : '8px'};
  border-bottom-right-radius: ${props => props.isSelected ? '0' : '8px'};
`;
export const StyledButton = styled.div`
  margin-top: 20px; 
  display: flex;
  justify-content: flex-end;
`;
export const ContainerText = styled.div`
  padding: 15px 20px;
  background-color: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative; // Add this to allow the top property to work
`;

export const Chevron = styled(ExpandMoreIcon)<{ primaryColor: string }>`
  position: absolute;
  right: 15px;
  color: ${(props) => props.primaryColor};
`;
