import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "../../../components/Typography";
import { StyledImage, StyledVideo } from "../styles";
import useInquiryService from "../../../shared/services/useInquiryService";
import { TextExtraSmall } from "../../../theme/GlobalStyles";
import { TitleMidia } from "../../../shared/hooks/useInquirySpecifics";
import { Download as DownloadIcon } from '@mui/icons-material';
import { Palette } from "../../../shared/theme/palette";

interface ModalMidia {
    onClose: () => void;
    title: TitleMidia;
    urls: string[];
    inquiryId: number;
    extraImageUrl?: string;
}

const ModalMidia: React.FC<ModalMidia> = ({
    onClose,
    urls,
    title,
    extraImageUrl,
    inquiryId,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageError, setImageError] = useState(false);
    const [videoUrl, setVideoUrl] = useState(urls[currentIndex]);
    const [videoGenerationMessage, setVideoGenerationMessage] =
        useState<string>("Aguarde...");
    const isClosed = useRef(false);
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        const currentImageUrl = currentIndex === urls.length ? extraImageUrl : urls[currentIndex];
        if (currentImageUrl) {
            fetch(currentImageUrl)
                .then(response => {
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    setDownloadUrl(url);
                })
                .catch(error => {
                    setDownloadUrl(''); 
                });
        }
    }, [currentIndex, urls, extraImageUrl]);

    const { generateWatermarkedVideoAsync, getWatermarkedVideoUrlAsync } =
        useInquiryService();
    const isVideo = title === TitleMidia.Deepfake;

    useEffect(() => {
        if (!isVideo) return;

        if (videoUrl) return;

        generateWatermarkedVideo();

        return () => {
            isClosed.current = true;
        };
    }, []);

    const generateWatermarkedVideo = async () => {
        const response = await generateWatermarkedVideoAsync(inquiryId);

        if (!response.videoExists)
            setVideoGenerationMessage("Mídia indisponível para visualização.");
        else if (!response.generationInProgress)
            setVideoGenerationMessage("Ocorreu um erro com a geração do vídeo.");
        else {
            setVideoGenerationMessage(
                "Estamos preparando o seu vídeo. O processo pode levar alguns segundos..."
            );

            setTimeout(getWatermarkedVideoUrl, 3000);
        }
    };

    const getWatermarkedVideoUrl = async () => {
        const url = await getWatermarkedVideoUrlAsync(inquiryId);

        if (url) {
            setVideoUrl(url);
            return;
        }

        if (!isClosed.current) {
            setTimeout(getWatermarkedVideoUrl, 3000);
        }
    };

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % totalUrls;
        setCurrentIndex(newIndex);
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - 1 + urls.length) % urls.length);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    const totalUrls = extraImageUrl ? urls.length + 1 : urls.length;
    const currentUrl = currentIndex === urls.length ? extraImageUrl : urls[currentIndex];
    const isCurrentUrlVideo = currentUrl?.includes('video') || currentUrl?.includes('mp4');

    return (
        <Modal title={title} onClose={onClose}>
            {isCurrentUrlVideo ? (
                <>
                    {videoUrl ? (
                        <StyledVideo width="270" controls controlsList="">
                            <source src={videoUrl} type="video/mp4" />
                        </StyledVideo>
                    ) : (
                        <Typography variant="caption">{videoGenerationMessage}</Typography>
                    )}
                </>
            ) : (
                <>
                    {totalUrls > 0 && !imageError ? (
                        <>
                            <StyledImage
                                src={
                                    currentIndex === urls.length
                                        ? extraImageUrl
                                        : urls[currentIndex]
                                }
                                style={{ maxWidth: "270px", height: "auto" }}
                                onError={handleImageError}
                            />
                            <div><TextExtraSmall>{`${currentIndex + 1}/${totalUrls}`}</TextExtraSmall></div>
                            {totalUrls > 1 && currentIndex < totalUrls - 1 &&
                                <div><Button variant={'outlined'} onClick={handleNext}>Próximo</Button></div>}
                            {totalUrls > 1 && currentIndex > 0 &&
                                <div><Button variant={'outlined'} onClick={handlePrev}>Anterior</Button></div>}
                            <div>
                                <a
                                    href={downloadUrl}
                                    download
                                    style={{ marginTop: '10px' }}
                                >
                                    <DownloadIcon sx={{ color: Palette.green }} />
                                </a>
                            </div>
                        </>
                    ) : (
                        <Typography variant="caption">
                            Mídia indisponível para visualização.
                        </Typography>
                    )}
                </>
            )}
        </Modal>
    );
};

ModalMidia.propTypes = {
    onClose: PropTypes.func.isRequired,
    urls: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.oneOf(Object.values(TitleMidia)).isRequired,
    extraImageUrl: PropTypes.string,
    inquiryId: PropTypes.number,
};
export default ModalMidia;
