import { Button } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBox from "../../../../components/CheckBox";
import Input from "../../../../components/Input/Input";
import { TooltipHint } from "../../../../components/TooltipHint";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";
import { AccountContext } from "../../../../shared/context/AccountContext";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import { DocumentType } from "../../../../shared/models/document-type";
import { usePlanService } from "../../../../shared/services/usePlanService";
import { useSubscriptionService } from "../../../../shared/services/useSubscriptionService";
import { removeMask } from "../../../../shared/utils/remove-mask";
import {
  FormContainer,
  HorizontalButtonsContainer,
  InstructionsText,
  ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";
import { ValidationUtils } from "../../../../utils/validation-utils";
import InquiryPriceAndBalanceCards from "../InquiryPriceAndBalanceCards";
import {
  DocumentInquiryFirstStepChooseTypeContainer,
  DocumentInquiryFirstStepChooseTypeOptionContainer,
  DocumentInquiryFirstStepInstructionsTitleText,
} from "../styles";

interface DocumentInquiryFormProps {
  onNext: () => void;
  cpf: string;
  setCpf: (cpf: string) => void;
  docType?: DocumentType;
  setDocType: (docType: DocumentType) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  withSelfie?: boolean;
  hint?: ReactNode;
}

const DocumentInquiryForm = ({ ...props }: DocumentInquiryFormProps) => {
  const [inquiryPrice, setInquiryPrice] = useState<number>();
  const { ids } = useContext(AccountContext);
  const { buscarAssinaturaAtiva } = useSubscriptionService();
  const { getProductPrices } = usePlanService();
  const location = useLocation();

  useEffect(() => {
    props.setLoading(true);
    buscarAssinaturaAtiva(ids.unitId)
      .then((subscription) => {
        getProductPrices(subscription.planoId).then((prices) => {
          const isAutomatic = location.pathname.includes("automatic");
          const inquiryType = props.withSelfie
            ? isAutomatic
              ? EInquiryType.DocumentoComSelfieAutomatic
              : EInquiryType.DocumentoComSelfie
            : isAutomatic
            ? EInquiryType.DocumentoAutomatic
            : EInquiryType.Documento;
          const price = prices?.find(
            (price) => price.type == inquiryType
          )?.price;
          setInquiryPrice(price);
          if (!price) {
            toast.error("Seu plano atual não permite esse tipo de consulta.", {
              autoClose: false,
              closeButton: true,
              toastId: "no-price-error",
            });
          }
        });
      })
      .finally(() => props.setLoading(false));
  }, [ids, location, props.withSelfie]);
  useEffect(() => {
    if (removeMask(props.cpf)?.length < 11) props.setDocType(undefined);
  }, [props.cpf]);
  const theme = useThemeWhiteLabel();
  return (
    <>
      <ScreenWithSubHeaderContainer>
        <>
          <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice} />
          <InstructionsText>
            {
              "Neste tipo de consulta serão aceitos somente RG ou CNH físicos (documentos digitais não são permitidos)."
            }

            <TooltipHint hint={props.hint} />
          </InstructionsText>
          <InstructionsText>
            {
              "Para iniciar a consulta por documento, insira o CPF que deseja buscar."
            }
          </InstructionsText>
          <FormContainer>
            <Input
              placeholder="Insira o CPF."
              mask="999.999.999-99"
              onChange={props.setCpf}
              required
              validationCallback={ValidationUtils.validateCpf}
              error={"CPF inválido"}
              value={props.cpf}
              label={"CPF"}
            />
            <DocumentInquiryFirstStepChooseTypeContainer>
              <DocumentInquiryFirstStepInstructionsTitleText>
                {"Tipo de documento"}
              </DocumentInquiryFirstStepInstructionsTitleText>
              <DocumentInquiryFirstStepChooseTypeOptionContainer>
                <CheckBox
                  primaryColor={theme.primaryColor}
                  disabled={removeMask(props.cpf)?.length < 11}
                  id={"rg"}
                  label={"RG - Registro Geral"}
                  isChecked={props.docType == "rg"}
                  onPress={() => props.setDocType("rg")}
                />
                <CheckBox
                  primaryColor={theme.primaryColor}
                  disabled={removeMask(props.cpf)?.length < 11}
                  id={"cnh"}
                  label={"CNH - Carteira Nacional de Habilitação"}
                  isChecked={props.docType == "cnh"}
                  onPress={() => props.setDocType("cnh")}
                />
              </DocumentInquiryFirstStepChooseTypeOptionContainer>
              <HorizontalButtonsContainer>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={props.onNext}
                  disabled={!props.docType || !inquiryPrice || props.loading}
                >
                  {"Prosseguir"}
                </Button>
              </HorizontalButtonsContainer>
            </DocumentInquiryFirstStepChooseTypeContainer>
          </FormContainer>
        </>
      </ScreenWithSubHeaderContainer>
    </>
  );
};
export default DocumentInquiryForm;
