 class RequestError {
     public code?: number;
     public message: string;
     public shouldLogout?: boolean;
     constructor(message: string, code?: number, shouldLogout?: boolean) {
            this.message = message;
            this.code = code;
            this.shouldLogout = shouldLogout;
     }
}
export default RequestError;