import { Box, Button } from "@mui/material";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import PropTypes from "prop-types";
import Input from "../../../../components/Input/Input";

interface ModalChangeDataProps {
  onClose: () => void;
  question: string;
  handleTextChange: (text: string) => void;
  sendHelp: () => void;
}
const ModalQuestion = ({
  onClose,
  question,
  handleTextChange,
  sendHelp,
}: ModalChangeDataProps) => {
  return (
    <Modal title="Envie sua dúvida" onClose={onClose}>
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="caption">
        Preencha o campo abaixo e clique no botão “enviar” abaixo.
      </Typography>
      <Input
        placeholder={"Digite sua dúvida aqui!"}
        value={question}
        onChange={handleTextChange}
        width="100%"
      />
      <Typography variant="caption">
        Você receberá a resposta em até 48 horas no e-mail cadastrado.
      </Typography>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button size="medium" onClick={onClose}>
          Voltar
        </Button>
        <Button
          size="medium"
          variant={"contained"}
          onClick={sendHelp}
          disabled={!question}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  </Modal>
  );
};
ModalQuestion.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendData: PropTypes.func.isRequired,
};

export default ModalQuestion;
