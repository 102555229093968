export enum FileRequestDocumentEnum {
  RG = 1,
  CNH = 2,
  CTPS = 3,
  Passport = 4,
}

export enum FileTypeEnum {
  Selfie = 1,
  SingleFileDocument = 2,
  DocumentFront = 3,
  DocumentBack = 4,
  PersonalDataVideo = 5,
  ComplementTextVideo = 6,
  ContractVideo = 7,
  Signature = 8,
  Contract = 9,
  ContractSigned = 10,
}

export const documentTypeDescriptions: {
  [key in FileRequestDocumentEnum]: string;
} = {
  [FileRequestDocumentEnum.RG]: "RG",
  [FileRequestDocumentEnum.CNH]: "CNH",
  [FileRequestDocumentEnum.CTPS]: "Outros",
  [FileRequestDocumentEnum.Passport]: "Outros",
};

export const fileTypeDescriptions: { [key in FileTypeEnum]: string } = {
  [FileTypeEnum.Selfie]: "selfie.png",
  [FileTypeEnum.SingleFileDocument]: "Documento de arquivo único",
  [FileTypeEnum.DocumentFront]: "frente.png",
  [FileTypeEnum.DocumentBack]: "verso.png",
  [FileTypeEnum.PersonalDataVideo]: "personaldata.mp4",
  [FileTypeEnum.ComplementTextVideo]: "complementartext.mp4",
  [FileTypeEnum.ContractVideo]: "contractinfos.mp4",
  [FileTypeEnum.Signature]: "Assinatura do contrato",
  [FileTypeEnum.Contract]: "Contrato",
  [FileTypeEnum.ContractSigned]: "Contrato Assinado",
};
