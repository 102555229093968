import { Button, useMediaQuery, useTheme } from "@mui/material";
import {
  CircleDiv,
  FlexContainer,
  InnerContainerVideo,
  StyledDivCenterButton,
  StyledWebcam,
} from "../../onBoardingVideo/styles";
import Typography from "../../../../components/Typography";
import { ReceiveOnBoardingInviteResponse } from "../../../../shared/models/requests/receive-invite-onboarding";



interface VideoRecorderProps {
  handleStart: (previewRef: React.RefObject<HTMLVideoElement>) => void;
  handleStop: (
    mediaRecorder: MediaRecorder | null,
    chunksRef: React.RefObject<BlobPart[]>,
    videoRef: React.RefObject<HTMLVideoElement>,
    previewRef: React.RefObject<HTMLVideoElement>
  ) => void;
  navigateToNext: () => void;
  recording: boolean;
  data?: ReceiveOnBoardingInviteResponse;
  videoRef: React.RefObject<HTMLVideoElement>;
  previewRef: React.RefObject<HTMLVideoElement>;
  chunksRef: React.RefObject<BlobPart[]>;
  mediaRecorder: MediaRecorder | null;
  textPayroll?: string;
}


const VideoRecorder: React.FC<VideoRecorderProps> = ({
  handleStart,
  handleStop,
  navigateToNext,
  recording,
  data,
  videoRef,
  previewRef,
  chunksRef,
  mediaRecorder,
  textPayroll,
}: VideoRecorderProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <FlexContainer>
      <Typography variant="caption" align="center">
          {textPayroll || data?.inviteDTO.telepromptText}
        </Typography>
        <InnerContainerVideo>
          <CircleDiv isSmallScreen={isSmallScreen}>
            <StyledWebcam audio={false} mirrored={true} />
          </CircleDiv>
        </InnerContainerVideo>
      </FlexContainer>
      <StyledDivCenterButton>
        <Button
          onClick={
            recording
              ? () => {
                handleStop(mediaRecorder, chunksRef, videoRef, previewRef);
                navigateToNext();
              }
              : () => handleStart(previewRef)
          }
          color="primary"
          variant={"contained"}
        >
          {recording ? "Parar gravação" : "Iniciar gravação"}
        </Button>
      </StyledDivCenterButton>
    </>
  );
};

export default VideoRecorder;
