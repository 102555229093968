import {SubscriptionRequest} from "../models/requests/subscription-request";
import {Subscription} from "../models/subscription";
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useSubscriptionService = () => {

    const consultaiApi = useConsultaiApi();
    const postSubscription = (request: SubscriptionRequest) => consultaiApi.post<Subscription>(`/financeiro/pagamento/subscribe-plan`, 1, request)
    const requestSubscriptionCancel = (unityId: number, cancelReason: string) =>
        consultaiApi.delete(`/financeiro/pagamento/subscription/${unityId}/cancel`, 1, {cancelReason})
    const buscarAssinaturaAtiva = (id: number): Promise<Subscription> => consultaiApi.get<Subscription>(`/Cadastros/Assinatura/buscarAtiva/${id}`)

    return {
        postSubscription, requestSubscriptionCancel, buscarAssinaturaAtiva
    }
}