import {MudarDadosUnidadeRequest} from '../models/requests/nova-unidade-request';
import {DadosUnidadeResponse, PagarmeIdResponse} from '../models/responses/unidade-response';
import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {AddNewUnityToAccountRequest} from "../models/requests/unities/add-unity-to-account-request";
import {ShortUnity, Unity} from "../models/unity";
import {PagedList, PagedListFilter} from "../models/responses/paged-list";
import {UnityUserDto} from "../models/requests/unities/unity-user-dto";

export const useUnitService = () => {
    const consultaiApi = useConsultaiApi();

    const createUnityInAccount = (request: AddNewUnityToAccountRequest) =>
        consultaiApi.post<Unity>('/unities', 2, request)

    const getAccountUnities = (params: PagedListFilter) =>
        consultaiApi.get<PagedList<ShortUnity>>(`/unities`, 2, params)

    const buscarIdPagarmeUnidade = (unidadeId: number) => consultaiApi.get<PagarmeIdResponse>(`/cadastros/unidade/${unidadeId}/pagarme`, 1)

    const findUnityById = (unityId: number) => consultaiApi.get<DadosUnidadeResponse>(`/cadastros/unidade/buscar/${unityId}`,1)

    const getAccountData = (unidadeId: number) => consultaiApi.get<DadosUnidadeResponse>(`/cadastros/conta/buscar/${unidadeId}`, 1)

    const solicitarAlterarDadosUnidade = (request: MudarDadosUnidadeRequest) => consultaiApi.patch<DadosUnidadeResponse>(`/cadastros/conta/request-changes`, 1, request)

    const getUsersFromUnity = (unityId: number) =>
        consultaiApi.get<UnityUserDto[]>(`/unities/${unityId}/users`, 2)

    const unlinkUserFromUnity = (unityId: string, userId: number) =>
        consultaiApi.delete(`/unities/${unityId}/users/${userId}`, 2)

    const getUnityById = (unityId: number) =>
        consultaiApi.get<Unity>(`/unities/${unityId}`, 2)

    const linkUserToUnity = (unityId: number, userId: number) =>
        consultaiApi.post(`/unities/${unityId}/users`, 2,{
            userIds: [userId]
        })

    return {
        createUnityInAccount,
        getAccountUnities,
        buscarIdPagarmeUnidade,
        getAccountData,
        solicitarAlterarDadosUnidade,
        findUnityById,
        getUsersFromUnity,
        unlinkUserFromUnity,
        getUnityById,
        linkUserToUnity
    }
}

