import styled from "styled-components";
import { Palette } from "../../../../shared/theme/palette";

interface StyledButtonProps {
  borderLeftColor?: string;
}

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
`;

export const InnerContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledButton = styled.div<StyledButtonProps>`
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e1e1e1;
  border-left: 4px solid;
  border-left-color: ${(props) => props.borderLeftColor || "black"};
  width: 360px;
  min-height: 150px;
  background-color: ${Palette.white};
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  cursor: pointer;
`;
export const StyledDivIcon = styled.div`
  position: absolute;
  margin-top: 5px;
  margin-left: 300px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: -10px 5px;
`;
