import React, { useContext } from "react";
import Input from "../../../../components/Input/Input";
import "./Password.css";
import CreateAccountContext from "../../context/CreateAccountContext";

const Password = () => {
  const context = useContext(CreateAccountContext);

  return (
    <div>
      <label className="register-title">
        A senha deve conter no mínimo 8 digítos, uma letra maiúscula e um
        caractere especial.
      </label>
      <div id="password-form">
        <Input
          label="Senha *"
          value={context.password ?? ""}
          onChange={context.setPassword}
          isPassword
        />

        <Input
          label="Confirme a senha *"
          value={context.passwordConfirmation ?? ""}
          onChange={context.setPasswordConfirmation}
          isPassword
        />
      </div>
    </div>
  );
};

export default Password;
