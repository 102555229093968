import React, {useContext, useEffect, useRef, useState} from "react";
import {
    HeaderBolderText,
    HeaderInfoContainer,
    HeaderInfoOptionContainer,
    HeaderInfoOptionIconContainer,
    HeaderInfoOptionsButtonContainer,
    IconWrapper,
    StyledHeader
} from "./styles";
import Logo from "../Logo";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useNavigate} from "react-router-dom";
import Routes from "../../constants/Routes";
import {AccountContext} from "../../shared/context/AccountContext";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import StarIcon from "@mui/icons-material/StarBorderOutlined";
import Typography from "../Typography";
import {useAuthenticationService} from "../../shared/services/useAuthenticationService";
import {useSubscriptionService} from "../../shared/services/useSubscriptionService";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

type HeaderProps = {
    iconHelp?: boolean;
};

const Header: React.FC<HeaderProps> = ({iconHelp}) => {
    const navigate = useNavigate();
    const [showUnities, setShowUnities] = useState(false);
    const accountContext = useContext(AccountContext);
    const unitiesButtonRef = useRef<HTMLDivElement>(null);
    const unitiesDropDownRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const {requestLoginUnity} = useAuthenticationService();
    const {buscarAssinaturaAtiva} = useSubscriptionService();

    useEffect(() => {
        if (unitiesButtonRef.current && unitiesDropDownRef.current) {
            unitiesDropDownRef.current.style.left = `${unitiesButtonRef.current.offsetLeft}px`;
            unitiesDropDownRef.current.style.width = `${unitiesButtonRef.current.offsetWidth}px`;
        }
    }, [showUnities]);

    const changeCurrentUnity = async (id: number) => {
        const sessionUser = await requestLoginUnity(id);
        const accountId = sessionUser.user.accountId;
        const unitId = sessionUser.user.currentUnityId
        accountContext.setIds({accountId, unitId});
        accountContext.setUser(sessionUser);
        const subscription = await buscarAssinaturaAtiva(unitId);
        accountContext.setSubscription(subscription);
        if (subscription.plano !== null) accountContext.setPlan(subscription.plano);
        navigate(Routes.Home);
    }
    const theme = useThemeWhiteLabel();
    return (
        <StyledHeader primaryColor={theme.primaryColor}
        ref={containerRef}>
            <Logo/>
            {accountContext?.user?.user &&
                <>
                    <HeaderInfoContainer>
                        <span>{'Usuário: '} </span>
                        <HeaderBolderText>{accountContext.user.user.name}</HeaderBolderText>
                    </HeaderInfoContainer>
                    <HeaderInfoContainer ref={unitiesButtonRef} onClick={() => setShowUnities(!showUnities)}>
                        <span>{'Unidade: '} </span>
                        <HeaderBolderText>{accountContext.user.user.userUnities?.find(unity =>
                            unity.id === accountContext.user.user.currentUnityId)?.name}</HeaderBolderText>
                        {
                            accountContext.user.user.userUnities?.length > 1 &&
                            <ExpandCircleDownIcon
                                color={'primary'}
                                style={{
                                    marginLeft: 5,
                                    transform: `rotate(${showUnities ? 180 : 0}deg)`,
                                    transition: 'transform 0.3s ease'
                                }}
                            />}
                        {showUnities && accountContext.user.user.userUnities &&
                            <HeaderInfoOptionsButtonContainer
                                ref={unitiesDropDownRef}>
                                {accountContext.user.user.userUnities?.map((unity, k) => (
                                    <HeaderInfoOptionContainer key={k}
                                                               onClick={() => changeCurrentUnity(unity.id)}>
                                        <HeaderInfoOptionIconContainer>
                                            <StarIcon
                                                color={'primary'}
                                                style={{display: accountContext.user.user.currentUnityId === unity.id ? 'flex' : 'none'}}
                                            />
                                        </HeaderInfoOptionIconContainer>
                                        <Typography variant={'h2'}>{unity.name}</Typography>
                                    </HeaderInfoOptionContainer>
                                ))}
                            </HeaderInfoOptionsButtonContainer>}
                    </HeaderInfoContainer>
                </>
            }
            {iconHelp && (
                <IconWrapper onClick={() => navigate(Routes.Faqs)}>
                    <HelpOutlineIcon fontSize="large" style={{color: theme.primaryColor}}/>
                </IconWrapper>
            )}
        </StyledHeader>
    );
};

export default Header;
