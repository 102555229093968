import Layout from "../../layout";
import SubHeader from "../../components/SubHeader/SubHeader";
import {ScreenWithSubHeaderContainer} from "../../theme/GlobalStyles";
import React, {useEffect, useState} from "react";
import {PerformanceReportContainer} from "./styles";
import PerformanceCardsHeaderSection from "./components/PerformanceCardsHeaderSection";
import useInquiryReportService from "../../shared/services/useInquiryReportService";
import InquiryReportResponse from "../../shared/models/responses/inquiry-report-response";
import {Grid} from "@mui/material";
import PerfomanceCard from "./components/PerfomanceCard";
import Filter from "../../components/FilterComponent/Filter";
import dayjs, {Dayjs} from "dayjs";
import {InquiryTypeOptions} from "../../shared/models/backend/inquiry/EInquiryType";
import FilterHeader from "../../components/FilterComponent/FilterHeader";

const PerformanceReportScreen = () => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs().subtract(15, "day"));
    const [dateTo, setDateTo] = useState<Dayjs>(dayjs());
    const [filteredTypes, setFilteredTypes] = useState([1, 2, 3, 4, 5]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState<InquiryReportResponse>();
    const {getReportData} = useInquiryReportService();
    const getPerformanceReport = async () => {
        setIsLoading(true);
        setReportData(await getReportData(dateFrom.toDate(), dateTo.toDate(), filteredTypes));
        setIsLoading(false);
    };
    useEffect(() => {
        getPerformanceReport();
    }, []);
    const noRiskTotal = reportData?.items.map(item => item.totalNoRisk).reduce((a, b) => a + b, 0);
    const undefinedRiskTotal = reportData?.items.map(item => item.totalRiskUndefined).reduce((a, b) => a + b, 0);
    const withRiskTotal = reportData?.items.map(item => item.totalWithRisk).reduce((a, b) => a + b, 0);

    return (
        <Layout>
            <SubHeader title="Relatórios" subtitle="Performance"/>
            <ScreenWithSubHeaderContainer>
                <FilterHeader
                    dateFrom={dateFrom.toDate()}
                    dateTo={dateTo.toDate()}
                    onFilterPress={() => setFilterVisible((prev) => !prev)}
                    isFilterOpen={filterVisible}
                />
                {
                    filterVisible &&
                    <Filter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        types={filteredTypes}
                        setDateFrom={setDateFrom}
                        setDateTo={setDateTo}
                        setTypes={setFilteredTypes}
                        getData={getPerformanceReport}
                        onClose={() => setFilterVisible(false)}
                        onTypesChange={(newTypes) => setFilteredTypes(newTypes)}
                        options={InquiryTypeOptions}
                    />
                }
                <PerformanceCardsHeaderSection spentValue={reportData?.totalSpent}/>
                <PerformanceReportContainer>
                    <Grid container rowSpacing={2} columnSpacing={3}>
                        {!filteredTypes &&
                            <Grid item>
                                <PerfomanceCard
                                    noRiskTotal={noRiskTotal}
                                    undefinedRiskTotal={undefinedRiskTotal}
                                    withRiskTotal={withRiskTotal}
                                />
                            </Grid>}
                        {reportData?.items.map((item, index) => (
                            <Grid item key={index}>
                                <PerfomanceCard
                                    type={item.type}
                                    noRiskTotal={item.totalNoRisk}
                                    undefinedRiskTotal={item.totalRiskUndefined}
                                    withRiskTotal={item.totalWithRisk}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </PerformanceReportContainer>
            </ScreenWithSubHeaderContainer>
        </Layout>
    )
}
export default PerformanceReportScreen;