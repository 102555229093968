import { Grid } from "@mui/material";
import { ContractSectionTitle, ContractText } from "../styles";

const AccountProposalContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          1 - PROPOSTA DE ABERTURA DE CONTA - PESSOA FÍSICA - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Adesão aos serviços essenciais* gratuitos contidos na Resolução
          3.919/2010 do Conselho Monetário Nacional:
        </ContractText>
        <ContractText>
          {"a) fornecimento de cartão com função débito;"}
        </ContractText>
        <ContractText>{`b) fornecimento de segunda via do cartão referido na alínea "a", exceto nos casos de pedidos de reposição formulados pelo correntista decorrentes de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente;`}</ContractText>
        <ContractText>
          {
            "c) realização de até quatro saques, por mês em terminal de autoatendimento;"
          }
        </ContractText>
        <ContractText>
          {
            "d) realização de até duas transferências de recursos entre contas na própria instituição, por mês, pela internet;"
          }
        </ContractText>
        <ContractText>
          {"e) realização de consultas mediante utilização da internet; "}
        </ContractText>
        <ContractText>
          {"f) fornecimento do extrato consolidado anual;"}
        </ContractText>
        <ContractText>{"g) compensação de cheques;"}</ContractText>
        <br />
        <ContractText>
          {`* Nesta data, não estão
          disponíveis os demais serviços essenciais. Os serviços usufruídos que
          extrapolarem a quantidade prevista para os serviços essenciais
          relacionados ou que não estejam previstos como serviços essenciais
          isentos de cobrança poderão ser adquiridos através dos Pacotes de
          Serviços disponibilizados pelo BANCO CREFISA. No caso de não
          aceitação, serão cobrados de acordo com os valores máximos da(s)
          tarifa(s) equivalente(s) descritas na Tabela de Tarifas divulgada pelo
          BANCO CREFISA, em seu website www.crefisa.com.br`}
        </ContractText>
      </Grid>
    </>
  );
};

export default AccountProposalContractSection;
