import {Body, BodyContainer, PaginationContainer, Row, TypographyWithNegativeMargin} from "./styles";
import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../shared/context/AccountContext";
import {useInquiryFinancialService} from "../../shared/services/useInquiryFinancialService";
import Routes from "../../constants/Routes";
import Layout from "../../layout";
import SubHeader from "../../components/SubHeader/SubHeader";
import SubSection from "../../components/SubSection";
import Typography from "../../components/Typography";
import {FormatUtils} from "../../shared/utils/format-utils";
import {Button, Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import dayjs, {Dayjs} from "dayjs";
import {optionsSelect} from "./consts/SelectConsts";
import FilterHeader from "../../components/FilterComponent/FilterHeader";
import Filter from "../../components/FilterComponent/Filter";
import Section from "./components/Section";
import {Constants} from "../../constants/Constants";

type Option = {
    description: string;
    value: number;
    color?: string;
    id?: number;
    typeDescription?: string;
    date?: string;
    time?: string;
};

function FinancialReport() {
    const [isFilterVisible, setFilterVisible] = useState(false);
    const [dateTo, setDateTo] = useState(dayjs());
    const [types, setTypes] = useState<number[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [totalSpent, setTotalSpent] = useState(0);
    const [balance, setBalance] = useState(0);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const navigate = useNavigate();
    const [dateFrom, setDateFrom] = useState(() => {
        return dayjs().subtract(15, "day");
    });

    const {ids, subscription} = useContext(AccountContext);
    const {getInquiryReport} = useInquiryFinancialService();


    useEffect(() => {
        fetchInquiryReport();
    }, [dateFrom, dateTo, types, page]);

    useEffect(() => {
        setPage(0);
    }, [dateFrom, dateTo, types]);

    const fetchInquiryReport = async () => {
        setLoading(true);
        try {
            let balance = 0;
            let totalSpent = 0;
            setOptions([]);
            const selectedTypes = types.length > 0 ? types : [undefined];
            const params = {
                id: ids.unitId,
                DateFrom: dateFrom.toISOString(),
                DateTo: dateTo.toISOString(),
                Page: page,
                PerPage: 9,
                TransactionTypes: selectedTypes,
            };
            const response = await getInquiryReport(params);
            const newOptions = response.transactions as Option[];
            setOptions((prevOptions) => [...prevOptions, ...newOptions]);

            balance += response.balance;
            totalSpent += response.totalSpent;
            setTotalSpent(totalSpent);
            setBalance(balance);
            setIsLastPage(newOptions.length < params.PerPage);
        } finally {
            setLoading(false);
        }
    };

    const clearListAndBackToFirstPage = () => {
        setPage(0);
        setOptions([]);
        fetchInquiryReport();
    };
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };
    return (
        <Layout>
            <SubHeader title="Relatórios" subtitle="Extrato financeiro">
                <BodyContainer>
                    {
                        subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                        <Body>
                        <Typography variant="caption2">Saldo atual</Typography>
                        <TypographyWithNegativeMargin>
                            <Typography variant="h1" align="right" color="green">
                                {FormatUtils.toBRLCurrency(balance)}
                            </Typography>
                        </TypographyWithNegativeMargin>
                    </Body>}
                    <Body>
                        <Typography variant="caption2">Consumo total</Typography>
                        <TypographyWithNegativeMargin>
                            <Typography variant="h1" align="right" color="red">
                                {FormatUtils.toBRLCurrency(totalSpent)}
                            </Typography>
                        </TypographyWithNegativeMargin>
                    </Body>
                </BodyContainer>
            </SubHeader>
            <SubSection>
                <Row>
                    <Typography variant="caption">
                        Nesta página você poderá visualizar todo o histórico de consultas e
                        o valor gasto em cada uma.
                        {subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                            subscription?.planoId !== Constants.FREE_PLAN_ID &&
                            ' Para realizar uma recarga de créditos,\n' +
                            '            clique no botão verde ao lado.'}
                    </Typography>
                    {
                        subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                        subscription?.planoId !== Constants.FREE_PLAN_ID &&
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(Routes.Recharge)}
                        >
                            + Recarregar crédito
                        </Button>}
                </Row>
                <FilterHeader
                    dateFrom={dateFrom.toDate()}
                    dateTo={dateTo.toDate()}
                    onFilterPress={() => setFilterVisible((prev) => !prev)}
                    isFilterOpen={isFilterVisible}
                />
                {isFilterVisible && (
                    <Filter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        types={types}
                        setDateFrom={(date: Dayjs) => setDateFrom(date)}
                        setDateTo={(date: Dayjs) => setDateTo(date)}
                        setTypes={setTypes}
                        getData={clearListAndBackToFirstPage}
                        onClose={() => setFilterVisible(false)}
                        onTypesChange={(newTypes) => setTypes(newTypes)}
                        options={optionsSelect}
                    />
                )}
                <>
                    {loading ? (
                        <Typography variant="caption2" align="center">
                            Carregando...
                        </Typography>
                    ) : options.length > 0 ? (
                        <Section options={options}/>
                    ) : (
                        <Typography variant="caption2" align="center">
                            Você não possui transações desse tipo no período selecionado.
                        </Typography>
                    )}
                </>
                <PaginationContainer>
                    <Pagination
                        count={isLastPage ? page + 1 : page + 2}
                        page={page + 1}
                        onChange={handlePaginationChange}
                        color="primary"
                        shape="rounded"
                    />
                </PaginationContainer>
            </SubSection>
        </Layout>
    );
}

export {FinancialReport};
