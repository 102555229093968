import React from 'react';
import Typography from "../../../../components/Typography";
import EInquiryType from '../../../../shared/models/backend/inquiry/EInquiryType';
import ResultTimeInfoCard from '../../../Inquiries/components/ResultTimeInfoCard';
import useWhitelabel from '../../../../hooks/useWhitelabel';
import { Constants } from '../../../../constants/Constants';

const ResultOnBoardingVideo: React.FC = () => {
    const { currentWhitelabel } = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;

    return (
        <>
            <Typography variant="h1" color="green">
                {isWhitelabel ? "Vídeo enviado com sucesso!" : "Documentação enviada com sucesso!"}
            </Typography>
            <Typography variant="caption">
                {isWhitelabel
                    ? "Tudo pronto! O vídeo foi enviado com sucesso e já estamos realizando a validação."
                    : "Tudo certo! Todos os documentos foram enviados e nós já iniciamos a validação dos mesmos."}
            </Typography>
            <ResultTimeInfoCard inquiryType={EInquiryType.VideoOnboarding} />
        </>
    );
};

export default ResultOnBoardingVideo;
