import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Box, Button, CircularProgress, IconButton, Tab} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {UnityUserDto} from "../../../../shared/models/requests/unities/unity-user-dto";
import {Unity} from "../../../../shared/models/unity";
import {useUnitService} from "../../../../shared/services/useUnitService";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LinkIcon from "@mui/icons-material/Link";
import {toast} from "react-toastify";
import Modal from "../../../../components/Modal";
import {ModalContainer} from "../../styles";
import Typography from "../../../../components/Typography";
import {HorizontalButtonsContainer} from "../../../../theme/GlobalStyles";
import {FormatUtils} from "../../../../shared/utils/format-utils";
import {AccountContext} from "../../../../shared/context/AccountContext";
import {Palette} from "../../../../shared/theme/palette";
import {ProfileSelectOptions} from "../../../../shared/models/user-profile-type";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import useAccountService from "../../../../shared/services/useAccountService";
import {UserDto} from "../../../../shared/models/user-dto";

interface UnityUsersTableProps {
    unityId?: number
    onUpdate?: () => void
}

const UnityUsersTable = ({...props}: UnityUsersTableProps) => {
    if (!props.unityId) return null;
    const [users, setUsers] = React.useState<UnityUserDto[]>([]);
    const [unity, setUnity] = React.useState<Unity>();
    const [userIdToUnlink, setUserIdToUnlink] = useState<number>();
    const [userIdToLink, setUserIdToLink] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState<'linked' | 'unlinked'>('linked');
    const [unlinkedUsers, setUnlinkedUsers] = React.useState<UserDto[]>([]);
    const accountContext = useContext(AccountContext);
    const {getAllUsers} = useAccountService();
    const {getUsersFromUnity, linkUserToUnity, getUnityById,unlinkUserFromUnity} = useUnitService();

    const getUnity = async () => setUnity(await getUnityById(Number(props.unityId)));
    const getUsers = async () => setUsers(await getUsersFromUnity(Number(props.unityId)));
    const getUnlinkedUsers = async () => {
        const unlinkedPromise = getAllUsers({
            excludedUnities: props.unityId.toString()
        });
        const linkedPromise = getUsersFromUnity(parseInt(props.unityId.toString()));
        const [unlinked, linked] = await Promise.all([unlinkedPromise, linkedPromise]);
        const allUsersWithoutLinked = unlinked.filter(u => !linked.find(lu => lu.id === u.id));
        setUnlinkedUsers(allUsersWithoutLinked);
    };
    const unlinkUser = async () => {
        try {
            setLoading(true)
            await unlinkUserFromUnity(props.unityId.toString(), userIdToUnlink)
            await getUsers()
            toast.success('Usuário desvinculado com sucesso!')
            !!props?.onUpdate && props?.onUpdate()
            setUserIdToUnlink(undefined)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
            setUserIdToUnlink(undefined)
        }
    }

    const linkUser = async (userId: number) => {
        try {
            setLoading(true)
            await linkUserToUnity(props.unityId, userId)
            await getUnlinkedUsers()
            toast.success('Usuário vinculado com sucesso!');
            !!props?.onUpdate && props?.onUpdate()
            setUserIdToLink(undefined)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUnity()
        currentTab === 'unlinked' ? getUnlinkedUsers() : getUsers()
    }, [props.unityId,currentTab]);

    const TableCellText = (text?: string, isAdmin?: boolean) => {
        return <span style={{color: isAdmin ? Palette.lightGrey3 : Palette.black, cursor: "pointer"}}>{text}</span>
    }
    return <>
        {userIdToUnlink && <Modal
            width={'90%'}
            title={'Desvincular usuário'}
            onClose={() => setUserIdToUnlink(undefined)}>
            <ModalContainer
            >
                <Typography variant='caption'>
                    {`Tem certeza que deseja desvincular o usuário da unidade ${unity?.name}?`}
                </Typography>
                <HorizontalButtonsContainer>
                    <Button
                        onClick={() => setUserIdToUnlink(undefined)}
                        variant={'text'}
                    >{'Voltar'}</Button>
                    <Button
                        variant={'outlined'}
                        color={'error'}
                        onClick={() => unlinkUser()}
                        disabled={loading}
                    >{`Desvincular Usuário`}</Button>
                </HorizontalButtonsContainer>
            </ModalContainer>
        </Modal>}
        {
            userIdToLink && <Modal
                title={'Vincular usuário'}
                onClose={() => setUserIdToLink(undefined)}>
                <ModalContainer>
                    <Typography variant='caption'>
                        {`Tem certeza que deseja vincular o usuário à unidade ${unity?.name}?`}
                    </Typography>
                    <HorizontalButtonsContainer>
                        <Button
                            onClick={() => setUserIdToLink(undefined)}
                            variant={'text'}
                        >{'Voltar'}</Button>
                        <Button
                            variant={'outlined'}
                            color={'success'}
                            onClick={() => linkUser(userIdToLink)}
                            disabled={loading}
                        >{`Vincular Usuário`}</Button>
                    </HorizontalButtonsContainer>
                </ModalContainer>
            </Modal>
        }
        {
            <Box sx={{width: '100%'}}>
                <TabContext value={currentTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(_event, value) => setCurrentTab(value)}>
                            <Tab label="VINCULADOS" value="linked"/>
                            <Tab label="NÃO VINCULADOS" value="unlinked"/>
                        </TabList>
                    </Box>
                    <TabPanel value="linked">
                        {!users?.length ? <CircularProgress color="primary"/> :
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50} align={'center'}>#</TableCell>
                                            <TableCell>{'Nome'}</TableCell>
                                            <TableCell>{'CPF/CNPJ'}</TableCell>
                                            <TableCell>{'Perfil'}</TableCell>
                                            <TableCell>{'E-mail'}</TableCell>
                                            <TableCell>{'Telefone'}</TableCell>
                                            <TableCell>{'Unidades Vinculadas'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user, k) => {
                                            const isUnityAdmin = user.cpf === unity?.cnpj
                                                || user.cpf === accountContext.user.user.accountCpfCnpj;
                                            return <TableRow
                                                key={k}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => isUnityAdmin ? toast.warn('O Administrador (CNPJ) da unidade não pode ser desvinculado.') : setUserIdToUnlink(user.id)}>
                                                        <LinkOffIcon color={!isUnityAdmin ? 'error' : 'disabled'}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{TableCellText(user.name, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(FormatUtils.maskCpfOrCnpj(user.cpf), isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(ProfileSelectOptions.find(p => p.value === user.profile)?.label, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(user.email, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(FormatUtils.formatPhoneNumber(user.phone), isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(user.unitiesQty?.toString(), isUnityAdmin)}</TableCell>
                                            </TableRow>;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                    </TabPanel>
                    <TabPanel value="unlinked">
                        {!unlinkedUsers?.length ? <CircularProgress color="primary"/> :
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50} align={'center'}>#</TableCell>
                                            <TableCell>{'Nome'}</TableCell>
                                            <TableCell>{'CPF/CNPJ'}</TableCell>
                                            <TableCell>{'Perfil'}</TableCell>
                                            <TableCell>{'E-mail'}</TableCell>
                                            <TableCell>{'Telefone'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {unlinkedUsers.map((user, k) => {
                                            const isUnityAdmin = user.login === unity?.cnpj
                                                || user.login === accountContext.user.user.accountCpfCnpj;
                                            return <TableRow
                                                key={k}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => isUnityAdmin ? toast.warn('O Administrador (CNPJ) da unidade não pode ser desvinculado.') : setUserIdToLink(user.id)}>
                                                        <LinkIcon color={!isUnityAdmin ? 'success' : 'disabled'}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{TableCellText(user.name, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(FormatUtils.maskCpfOrCnpj(user.login), isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(ProfileSelectOptions.find(p => p.value === user.profile)?.label, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(user.email, isUnityAdmin)}</TableCell>
                                                <TableCell>{TableCellText(FormatUtils.formatPhoneNumber(user.phone), isUnityAdmin)}</TableCell>
                                            </TableRow>;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                    </TabPanel>
                </TabContext>
            </Box>
        }
    </>;
}
export default UnityUsersTable;