import MenuGroup from "../components/Item/menuGroup";
import {accountMenuOptions, configurationsMenuOptions, inquiryMenuOptions, reportsMenuOptions} from './menu-options';
import {Palette} from "../../../shared/theme/palette";

export const MenuGroups: MenuGroup[] = [
    {
        iconName: "chevron-right",
        iconColor: Palette.darkGrey1,
        backgroundColor: Palette.white,
        options: inquiryMenuOptions,
        label: "Fechar menu",
    },
    {
        iconName: "search",
        options: inquiryMenuOptions,
        label: "Consultas",
    },
    {
        iconName: "chart",
        options: reportsMenuOptions,
        label: 'Relatórios',
    },
    {
        iconName: "settings",
        options: configurationsMenuOptions,
        label: 'Configurações',
    },
    {
        iconName: "user",
        options: accountMenuOptions,
        label: 'Minha conta',
    },
];