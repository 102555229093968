import ItemProps from "./ItemInquiryListProps";
import ItemLine from "./ItemInquiryListLine";
import React, {useState} from "react";
import Typography from "../../../../components/Typography";
import useInquiryService from "../../../../shared/services/useInquiryService";
import EInquiryType, {getInquiryTypeAsText,} from "../../../../shared/models/backend/inquiry/EInquiryType";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
import EInquiryStatus from "../../../../shared/models/backend/inquiry/EInquiryStatus";
import {Palette} from "../../../../shared/theme/palette";
import EScoreType from "../../../../shared/models/backend/inquiry/EScoreType";
import {InnerContainer, StyledButton, StyledDivIcon,} from "./ItemInquiryListStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import WarningIcon from "@mui/icons-material/Warning";
import Routes from "../../../../constants/Routes";
import {Button} from "@mui/material";
import {FormatUtils} from "../../../../shared/utils/format-utils";
import {format} from "date-fns";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const ItemInquiryList = (props: ItemProps) => {
    const [loading, setLoading] = useState(false);
    const {postResendInquiry} = useInquiryService();
    const theme = useThemeWhiteLabel();
    const navigate = useNavigate();
    const resendInquiry = async () => {
        setLoading(true);
        try {
            await postResendInquiry({id: props.inquiry.id});
            props.getData();
            toast.success("Consulta reenviada com sucesso");
        } finally {
            setLoading(false);
        }
    };

    const inquiryType = getInquiryTypeAsText(props.inquiry.inquiryType);
    const getCardColor = () => {
        if (
            props.inquiry.status == EInquiryStatus.RefundedForTimeExpired ||
            props.inquiry.status == EInquiryStatus.RefundedForFailure
        )
            return Palette.darkGrey2;
        switch (props.inquiry?.scoreType) {
            case EScoreType.NoRisk:
                return theme.primaryColor;
            case EScoreType.WithRisk:
                return Palette.red;
            case EScoreType.RiskUndefined:
                return theme.alternativeColor;
            default:
                return Palette.lightGrey2;
        }
    };
    const getIconComponent = () => {
        if (
            props.inquiry.status == EInquiryStatus.RefundedForTimeExpired ||
            props.inquiry.status == EInquiryStatus.RefundedForFailure
        )
            return <CancelIcon style={{color: getCardColor()}}/>;
        switch (props.inquiry?.scoreType) {
            case EScoreType.NoRisk:
                return <ThumbUpIcon style={{color: getCardColor()}}/>;
            case EScoreType.WithRisk:
                return <ThumbDownIcon style={{color: getCardColor()}}/>;
            case EScoreType.RiskUndefined:
                return <WarningIcon style={{color: getCardColor()}}/>;
            default:
                return <HourglassEmptyIcon style={{color: getCardColor()}}/>;
        }
    };
    return (
        <StyledButton
            onClick={() => {
                if (!props.inquiry?.scoreType) {
                    toast.error("Falha no processamento. Favor refazer esta consulta.");
                    return;
                }
                navigate(Routes.Inquiries.Details(inquiryType, props.inquiry.id));
            }}
            borderLeftColor={getCardColor()}
        >
            <StyledDivIcon>{getIconComponent()}</StyledDivIcon>
            <ItemLine
                label="Data: "
                value={`${format(props.inquiry.date, 'dd/MM/yyyy')} às ${format(props.inquiry.date, 'HH:mm')}`}
                fontSize={12}
            />
            <ItemLine label="Tipo: " value={inquiryType} fontSize={12}/>
            <ItemLine
                label="ID da transação: "
                value={props.inquiry.id}
                fontSize={14}
            />
            <ItemLine
                label="Status: "
                value={props.inquiry.statusDescription}
                fontSize={12}
            />
            {props.inquiry.phoneInquiryInput &&
                props.inquiry.phoneInquiryInput.phone && (
                    <ItemLine
                        label="Telefone: "
                        value={FormatUtils.formatPhoneNumber(
                            props.inquiry.phoneInquiryInput.phone
                        )}
                        fontSize={12}
                    />
                )}
            {props.inquiry.documentInquiryInput &&
                props.inquiry.documentInquiryInput.cpf && (
                    <ItemLine
                        label="CPF: "
                        value={props.inquiry.documentInquiryInput.cpf}
                        fontSize={12}
                    />
                )}
            {props.inquiry.payrollDocumentInquiryInput &&
                props.inquiry.payrollDocumentInquiryInput.beneficiaryCpf && (
                    <ItemLine
                        label="CPF do Beneficiário: "
                        value={props.inquiry.payrollDocumentInquiryInput.beneficiaryCpf}
                        fontSize={12}
                    />
                )}
            {props.inquiry.payrollDocumentInquiryInput &&
                props.inquiry.payrollDocumentInquiryInput.legalRepresentativeCpf && (
                    <ItemLine
                        label="CPF do Representante Legal: "
                        value={props.inquiry.payrollDocumentInquiryInput.legalRepresentativeCpf}
                        fontSize={12}
                    />
                )}
            {props.inquiry.emailInquiryInput &&
                props.inquiry.emailInquiryInput.email && (
                    <ItemLine
                        label="Email: "
                        value={props.inquiry.emailInquiryInput.email}
                        fontSize={12}
                    />
                )}
            {(props.inquiry.status == EInquiryStatus.RefundedForTimeExpired ||
                    props.inquiry.status == EInquiryStatus.RefundedForFailure) &&
                props.inquiry.inquiryType !== EInquiryType.VideoOnboarding && (
                    <InnerContainer>
                        <Typography variant="caption2" color="red">
                            Erro durante a consulta. Por favor, clique no botão abaixo para
                            refazê-la.
                        </Typography>
                        <div onClick={(e) => e.stopPropagation()}>
                            <Button
                                variant={"contained"}
                                size="medium"
                                onClick={resendInquiry}
                                disabled={loading}
                            >
                                Reenviar consulta
                            </Button>
                        </div>
                    </InnerContainer>
                )}
        </StyledButton>
    );
};

export default ItemInquiryList;
