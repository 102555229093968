import {SelectOption} from "../models/SelectOption";

export class EnumUtils {
    static enumToArray = <T>(enumObj: T, descriptions: { [key: number]: string }): Array<SelectOption> => {
        return Object.keys(enumObj)
            .filter(key => isNaN(Number(key)))
            .map(key => {
                const value = enumObj[key as keyof T] as unknown as number;
                return {
                    name: key,
                    value: value,
                    description: descriptions[value]
                };
            });
    };
}