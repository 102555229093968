import React, {useCallback, useEffect, useState} from "react";
import useInquiryService from "../../../../shared/services/useInquiryService";
import {LoaderContainer, PaginationContainer} from "../../styles";
import {ClipLoader} from "react-spinners";
import EInquiryStatus from "../../../../shared/models/backend/inquiry/EInquiryStatus";
import ItemInquiryList from "../ItemInquiryList/ItemInquiryList";
import {Grid, Pagination} from "@mui/material";
import ExtendedInquiryPagedListDTO from "../../../../shared/models/backend/inquiry/inquiry-extended-list-dto";

interface InquiryCardsByStatusProps {
    status: "all" | "waiting" | "error",
    filterText?: string
}

const InquiryCardsByStatus = ({...props}: InquiryCardsByStatusProps) => {
    const [loading, setLoading] = useState(false);
    const [pagedInquiries, setPagedInquiries] =
        useState<ExtendedInquiryPagedListDTO>();
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };
    const {getListAsyncV2} = useInquiryService();
    const getInquiries = useCallback(async () => {
        setLoading(true);
        const statuses =
            props.status === "waiting"
                ? [EInquiryStatus.WaitingVideoOnboarding, EInquiryStatus.Sent]
                : props.status === "error"
                    ? [
                        EInquiryStatus.RefundedForFailure,
                        EInquiryStatus.RefundedForTimeExpired,
                        EInquiryStatus.ExpiredByInviteExpired,
                    ]
                    : [
                        EInquiryStatus.WaitingVideoOnboarding,
                        EInquiryStatus.Sent,
                        EInquiryStatus.Completed,
                    ];
        const response = await getListAsyncV2(page, statuses, props.filterText).finally(() =>
            setLoading(false)
        );
        if (response) {
            setPagedInquiries(response);
            setIsLastPage(page + 1 >= response.totalPages);
        }
    }, [page, props.filterText]);

    useEffect(() => {
        getInquiries();
    }, [getInquiries]);

    return (
        <div>
            {loading ? (
                <LoaderContainer>
                    <ClipLoader/>
                </LoaderContainer>
            ) : (
                <>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        {pagedInquiries?.inquiries?.map((inquiry) => (
                            <Grid key={inquiry.id} item>
                                <ItemInquiryList inquiry={inquiry} getData={getInquiries}/>
                            </Grid>
                        ))}
                    </Grid>
                    {pagedInquiries?.inquiries?.length > 0 && (
                        <PaginationContainer>
                            <Pagination
                                count={isLastPage ? page + 1 : page + 2}
                                page={page + 1}
                                onChange={handlePaginationChange}
                                color="primary"
                                shape="rounded"
                            />
                        </PaginationContainer>
                    )}
                </>
            )}
        </div>
    );
};
export default InquiryCardsByStatus;
