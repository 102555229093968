import "./BreadCrumb.css";
import BreadCrumbProps from "./BreadCrumbProps";

const BreadCrumb = (props: BreadCrumbProps) => {
  return (
    <div id="breadcrumb-container" onClick={props.onBack}>
      <span className="material-symbols-outlined">
        arrow_back_ios
      </span>
      <span id="breadcrumb-title">Cadastro</span>
    </div>
  );
};

export default BreadCrumb;
