import styled from "styled-components";
import { Palette } from "../../../../../../shared/theme/palette";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-top: 24px;
`;

export const ContractContainerDiv = styled.div`
    position: relative;
  padding: 5px 16px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 0.8px solid #e1e1e1;
  background: ${Palette.white};
  box-shadow: 0px 1px 10px 0px rgba(220, 220, 220, 0.7);
  max-width: 648px;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30x;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
 align-items: start;
`;
export const IconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    cursor: pointer;
    padding: 5px;
`;
export const FlexDiv = styled.div`
max-width: 100%;
padding: 0px 60px 0px 10px;
`;