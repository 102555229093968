import { createContext } from "react";
import ICreateAccountContext from "./ICreateAccountContext";

const defaultEmptyFunction = () => void 0;

const CreateAccountContext = createContext<ICreateAccountContext>({
  setName: defaultEmptyFunction,
  setCpfCnpj: defaultEmptyFunction,
  setEmail: defaultEmptyFunction,
  setPhone: defaultEmptyFunction,
  setCep: defaultEmptyFunction,
  setState: defaultEmptyFunction,
  setCity: defaultEmptyFunction,
  setAddress: defaultEmptyFunction,
  setNumber: defaultEmptyFunction,
  setComplement: defaultEmptyFunction,
  setNeighborhood: defaultEmptyFunction,
  setPassword: defaultEmptyFunction,
  setPasswordConfirmation: defaultEmptyFunction,
  setTermsAccepted: defaultEmptyFunction,
  setPrivacyPolicyAccepted: defaultEmptyFunction,
});

export default CreateAccountContext;
