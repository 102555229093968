interface Item {
    videoUrl?: string;
    facematchBestFrameUrl?: string;
    documentFrontImageUrl?: string;
    selfieFrontImageUrl?: string;
}

export const buttonConfig = (item: Item) => {
    const config = {
        Deepfake: {
            title: "Visualizar Vídeo",
            url: item?.videoUrl,
        },
        Facematch: {
            title: "Visualizar Melhor Selfie",
            url: item.facematchBestFrameUrl,
        },
        Documento: {
            title: "Visualizar Documento",
            url: item.documentFrontImageUrl,
        },
        Selfie: {
            title: "Visualizar Selfie",
            url: item.selfieFrontImageUrl,
        },
    };

    
    return Object.keys(config)
        .filter(key => config[key].url)
        .map(key => config[key]);
};