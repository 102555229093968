import {CriarContaResponse} from '../models/responses/criar-conta-response';
import {CriarContaRequest} from '../models/requests/criar-conta-request';
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useCreateAccountService = () => {
    const consultaiApi = useConsultaiApi();
    const postCriarConta = (request: CriarContaRequest) => consultaiApi.post<CriarContaResponse>('/cadastros/conta', 1, request)
    return {
        postCriarConta
    }
}

