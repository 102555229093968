export enum EInquiryFileType {
    Selfie = 1,
    SingleFileDocument = 2,
    DocumentFront = 3,
    DocumentBack = 4,
    PersonalDataVideo = 5,
    ComplementTextVideo = 6,
    ContractVideo = 7,
  }
  export const EInquiryFileTypeDescriptions = {
    [EInquiryFileType.Selfie]: "selfie.png",
    [EInquiryFileType.SingleFileDocument]: "Documento de arquivo único",
    [EInquiryFileType.DocumentFront]: "frente.png",
    [EInquiryFileType.DocumentBack]: "verso.png",
    [EInquiryFileType.PersonalDataVideo]: "personaldata.mp4",
    [EInquiryFileType.ComplementTextVideo]: "complementartext.mp4",
    [EInquiryFileType.ContractVideo]: "contractinfos.mp4",
  };