import PropTypes from "prop-types";
import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useLocation } from "react-router-dom";
import androidButton from "../../assets/buttonsAppStores/android.png";
import iosButton from "../../assets/buttonsAppStores/ios.png";
import Logo from "../../components/Logo";
import Routes from "../../constants/Routes";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";
import { InstructionsText } from "../../theme/GlobalStyles";
import {
  ContainerCenter,
  ContainerText,
  StyledImage,
} from "../CreateAccount/styles";
import { StyledHeader } from "./styles";

const DeviceMobile = ({ setIsOnboarding }) => {
  const location = useLocation();

  const isOnboardingRoute =
    location.pathname.startsWith(Routes.OnBoarding.Video("")) ||
    location.pathname.startsWith(Routes.PayRollOnboarding.Video("")) ||
    location.pathname.startsWith(Routes.MailingOnboarding.Root(""));

  useEffect(() => {
    setIsOnboarding(isOnboardingRoute);
  }, [location, setIsOnboarding]);
  const theme = useThemeWhiteLabel();
  return (
    <div>
      <StyledHeader primaryColor={theme.primaryColor}>
        <Logo />
      </StyledHeader>
      <ContainerText>
        <InstructionsText>
          {
            "Para dispositivos móveis, baixe nosso app na sua loja de aplicativos ou acesse nosso site pelo seu desktop."
          }
        </InstructionsText>
      </ContainerText>
      {isAndroid && (
        <ContainerCenter
          href="https://play.google.com/store/apps/details?id=com.whoid.consultai&pli=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledImage src={androidButton} />
        </ContainerCenter>
      )}
      {isIOS && (
        <ContainerCenter
          href="https://apps.apple.com/br/app/consulta%C3%AD-app-de-consultas/id6471491479?l=en-GB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledImage src={iosButton} />
        </ContainerCenter>
      )}
    </div>
  );
};
DeviceMobile.propTypes = {
  setIsOnboarding: PropTypes.func.isRequired,
};
export default DeviceMobile;
