import Modal from "../../../../components/Modal";
import {
    SectionText,
    SectionTextBold,
    SectionTextBoldNoPadding,
    SectionTextWithTabulation
} from "../PrivacyPolicyModal/styles";
import {Button} from "@mui/material";
import useWhitelabel from "../../../../hooks/useWhitelabel";

interface ServiceTermsModalProps {
    onClose: (read?: boolean) => void;
}

const ServiceTermsModal = ({...props}: ServiceTermsModalProps) => {
    const {whitelabelName} = useWhitelabel();
    return <Modal title={`TERMOS GERAIS DE USO DO ${whitelabelName}`}>
        <>
            <SectionText>{'Estes Termos Gerais de Uso (“Termos”) regem o relacionamento entre você (“Usuário”) e a' +
                ' '}<SectionTextBoldNoPadding>{'BRT Intelligence'}</SectionTextBoldNoPadding>{', aqui denominada apenas '}<SectionTextBoldNoPadding>{''}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{''}
            </SectionTextBoldNoPadding>{', para os fins de regular a utilização dos produtos e ' +
                'serviços disponibilizados aos Usuários pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' por meio do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', que em conjunto ' +
                'com o Aviso de Privacidade, constituem contrato entre você e a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{'.'}
            </SectionText>
            <SectionText>{'Antes de se cadastrar e utilizar o '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', é imprescindível que o Usuário leia e ' +
                'aceite esses Termos. Caso o Usuário discorde, em qualquer momento, com qualquer uma das condições ' +
                'desses Termos e do Aviso de Privacidade, o Usuário deverá cessar imediatamente o uso do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' ' +
                'e de seus recursos.'}</SectionText>
            <SectionTextBold>{'Para facilitar sua leitura, estes Termos estão divididos da seguinte forma:\n'}</SectionTextBold>
            <SectionTextWithTabulation>{'1.\tServiços fornecidos pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding></SectionTextWithTabulation>
            <SectionTextWithTabulation>{'2.\tAssinatura e pagamento\n'}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'3.\tCadastro do Usuário no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding></SectionTextWithTabulation>
            <SectionTextWithTabulation>{'4.\tCondições de acesso e de utilização dos serviços no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding></SectionTextWithTabulation>
            <SectionTextWithTabulation>{'5.\tLimites da responsabilidade\n'}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'6.\tPropriedade intelectual\n'}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'7.\tVigência\n'}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'8.\tDisposições Gerais\n'}</SectionTextWithTabulation>
            <SectionTextBold>{'1.\tServiços fornecidos pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{''}
            </SectionTextBold>
            <SectionText>{'1.1.\tO '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' tem por finalidade disponibilizar solução que facilita o acesso dos Usuários aos serviços de validação de identidade (documentos e fotos), bem como de informações de e-mail, telefone e de créditos financeiros.'}
            </SectionText>
            <SectionText>{'1.2. Na execução de seus serviços, a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' poderá consultar bancos de dados públicos e privados para conferir a veracidade das informações fornecidas pelos Usuários. A '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' não é responsável pelas informações constantes dos bancos de dados de órgãos públicos e particulares subcontratados, isentando-se de qualquer responsabilidade decorrente de imprecisões ou atualizações em tais informa ções.'}
            </SectionText>
            <SectionText>{'1.3. A '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' poderá lançar novas funcionalidades, bem como oferecer novos produtos ou serviços em conexão com o aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'. Os produtos e serviços adicionais eventualmente oferecidos e contratados pelos Usuários, poderão ser regidos por instrumentos contratuais próprios, como por exemplo, por meio de contrato de prestação de serviços assinado entre a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' e o Usuário. Nessa hipótese, quando aplicáveis, permanecerão as disposições destes Termos de Serviço e o Aviso de Privacidade do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'.'}
            </SectionText>
            <SectionTextBold>{'2.\tAssinatura e pagamento'}</SectionTextBold>
            <SectionText>{'2.1.\tAo aderir aos Termos de Uso do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', o Usuário realiza assinatura de um dos planos disponíveis na tabela do Anexo I deste documento, por meio de uma das formas de pagamento válida disponíveis, na modalidade de aquisição de créditos, que tem, em regra, validade de 30 (trinta) dias corridos.'}
            </SectionText>
            <SectionText>{'2.2.\tNão há prazo mínimo para contratação. A assinatura do plano contratado terá renovação automática, com cobranças mensais no valor do plano escolhido , até que o Usuário realize o cancelamento, o que poderá ocorrer a qualquer momento, sem qualquer custo.'}</SectionText>
            <SectionText>{'2.3.\tO usuário pagará por cada serviço, por meio de uma das formas de pagamento disponibilizadas na plataforma, as tarifas demonstradas na tabela do Anexo I, conforme plano contratado.\n'}</SectionText>
            <SectionText>{'2.4.\tO plano pode ser alterado pelo Usuário a qualquer momento, na plataforma do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'. Nesse caso, a data considerada para a renovação será a data da alteração do plano. '}
            </SectionText>
            <SectionTextWithTabulation>{'2.4.1.\t Caso haja alteração do plano, os créditos existentes serão somados aos créditos do plano adquirido, e o período de validade será renovado, igualmente, para 30 dias corridos. '}</SectionTextWithTabulation>
            <SectionText>{'2.5.\tCaso os créditos sejam consumidos antes da data da renovação do plano, o Usuário poderá realizar recarga avulsa de qualquer valor. a partir de R$ 15,00 (quinze reais).'}</SectionText>
            <SectionTextWithTabulation>{'2.5.1.\tQuando realizada recarga avulsa, os valores das tarifas dos serviços serão os mesmos do plano contratado.'}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'2.5.2.\tPara que uma recarga avulsa seja realizada, deve haver, necessariamente, uma assinatura ativa, de qualquer um dos planos existentes. '}</SectionTextWithTabulation>
            <SectionText>{'2.6.\tCaso haja erro no processamento ou qualquer outro inconveniente relativo ao correto funcionamento da plataforma, que impossibilite o recebimento do serviço pretendido, o valor da consulta será estornado, automaticamente, em até 3 (três) horas, contados do horário da tentativa da consulta. '}</SectionText>
            <SectionTextBold>{'3.\tCadastro do Usuário no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{''}
            </SectionTextBold>
            <SectionText>{'3.1.\tPara acessar os serviços disponibilizados pelo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', o Usuário deverá se cadastrar no aplicativo, informando dados pessoais e de pessoa jurídica necessários para a assinatura, cadastro e contato. Após realização do   cadastro, será criado login e senha para autenticação do Usuário no aplicativo.  '}
            </SectionText>
            <SectionTextWithTabulation>{'3.1.1.\t Caso o cadastro seja realizado por uma Pessoa Jurídica, esta poderá, a seu exclusivo critério, adicionar à sua conta outros usuários que façam parte de seu quadro de colaboradores, que também deverão se comprometer com esses Termos e com o Aviso de Privacidade.'}</SectionTextWithTabulation>
            <SectionText>{'3.2.\tO Usuário é integralmente responsável pelo sigilo de sua senha e, qualquer caso tenha conhecimento de qualquer indício de uso indevido de seu login e senha por qualquer terceiro, o Usuário deverá informar imediatamente à '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{', através do e-mail contato@consultai.app    '}
            </SectionText>
            <SectionTextWithTabulation>{'3.2.1.\tQualquer forma de divulgação dessas informações, compartilhamento da conta e demais consequências e resultados advindos dessas práticas, serão de responsabilidade integral do Usuário. '}</SectionTextWithTabulation>
            <SectionText>{'3.3.\tAo realizar o cadastro, o Usuário declara que é pessoa física  , maior, absolutamente capaz para os atos da vida civil, bem como que tem poder de representação da pessoa jurídica, quando for o caso, e se compromete com a veracidade e autenticidade de todos os dados fornecidos em seu cadastro.    '}</SectionText>
            <SectionTextWithTabulation>{'3.3.1.\tO Usuário compreende que a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' poderá, a seu exclusivo critério, realizar as buscas que entender necessárias, para apuração de eventuais dados incorretos, inverídicos ou desatualizados, bem como solicitar informações ou documentos adicionais, a fim de verificar a conformidade das informações fornecidas.'}
            </SectionTextWithTabulation>
            <SectionTextWithTabulation>{'3.3.2.\tCaso seja detectada alguma inconsistência, se for possível, o Usuário será informado por e-mail ou telefone, para que providencie a regularização. '}</SectionTextWithTabulation>
            <SectionTextWithTabulation>{'3.3.3.\tSe constatada qualquer irregularidade, o Usuário declara estar ciente que será o único responsável por eventuais consequências, inclusive legais, que advirem de sua prestação de informações.'}</SectionTextWithTabulation>
            <SectionText>{'3.4.\tTodos os dados pessoais relacionados com estes Termos, informados no cadastro ou coletados a partir do acesso e utilização dos serviços do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' estarão sujeitos ao Aviso de Privacidade. As informações fornecidas durante o cadastro poderão ser atualizadas ou corrigidas, a qualquer momento, mediante solicitação   do Usuário no aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'.'}
            </SectionText>
            <SectionTextBold>{'4.\tCondições de acesso e utilização dos serviços no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{''}
            </SectionTextBold>
            <SectionText>{'4.1.\tAo acessar o '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e utilizar os serviços oferecidos pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{', o Usuário expressa de imediato a sua aceitação a estes Termos. Da mesma forma, o Usuário aceita e declara estar ciente que é terminantemente proibido utilizar o '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' para quaisquer atos ilícitos e/ou qualquer tipo de violação da legislação vigente, utilizando os serviços apenas para fins lícitos e que respeitem quaisquer direitos de terceiros.'}
            </SectionText>
            <SectionText>{'4.2.\tO Usuário reconhece e aceita que é proibido examinar ou fazer qualquer teste de vulnerabilidade do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' ou de qualquer rede a ele conectada, violar suas medidas de segurança, fazer pesquisa reversa, tentar rastrear informações sobre outro Usuário ou fontes do aplicativo, ou qualquer outra ação que vise revelar qualquer tipo de informação além daquelas a que se destina o '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'.'}
            </SectionText>
            <SectionText>{'4.3.\tÉ vedado ao Usuário utilizar qualquer sistema, aplicação, inteligência artificial ou qualquer outro artifício para enviar requisições de acesso e utilização dos serviços de forma massificada, capaz de superar, em um dado período, o que seria normalmente possível responder, ocasionando problemas de acesso, deteriorando ou de qualquer forma alterando a experiência de utilização de seu conteúdo.    '}</SectionText>
            <SectionText>{'4.4.\tÉ vedado ao Usuário, ao acessar o '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e utilizar os serviços: (i) exibir, enviar ou de qualquer forma divulgar mensagens, arquivos, fotografias ou quaisquer dados ou materiais com conteúdo ilegal, violento, difamatório, calunioso, sigiloso, abusivo, perigoso, degradante, pornográfico, discriminatório, racista ou de qualquer modo ilegais ou atentatórios à ordem pública; (ii) praticar ou fomentar a prática de quaisquer atos ou atividades ilegais; e (iii) exibir, enviar ou de qualquer forma divulgar mensagens, arquivos, fotografias ou quaisquer dados ou materiais que violem direitos de propriedade intelectual ou qualquer outro direito, seja da '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' ou de terceiros.'}
            </SectionText>
            <SectionText>{'4.5.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' poderá eliminar qualquer conteúdo relativo ao perfil do Usuário: (i) por determinação legal; (ii) para evitar ou corrigir quaisquer elementos que possam trazer ou tenham trazido prejuízos ou qualquer tipo de dano à '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' ou a qualquer terceiro; (iii) para identificar, corrigir ou evitar quaisquer problemas técnicos na operação dos serviços; e (iv) quando tais conteúdos estiverem em desacordo com o previsto nos Termos Gerais ou na legislação aplicável.'}
            </SectionText>
            <SectionText>{'4.6.\tCaso seja detectada qualquer suspeita de atividade fraudulenta, ou outra ação ilegal ou contrária a estes Termos, a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' ou qualquer responsável pela operação do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', poderá, a seu exclusivo critério, suspender, bloquear ou proibir o acesso de um ou mais Usuários, eliminando consequentemente qualquer conteúdo relativo ao seus respectivos perfis e contas.   '}
            </SectionText>
            <SectionTextBold>{'5.\tLimites da responsabilidade'}</SectionTextBold>
            <SectionText>{'5.1.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' envidará seus melhores esforços para que os serviços do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' sejam plenamente acessíveis a todo e qualquer tempo. Todavia, não há garantia de que o acesso e a sua utilização ocorram sem qualquer falha ou de forma ininterrupta. A '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' não se responsabiliza por eventuais dificuldades de acesso enfrentadas pelo Usuário, bem como por erros na transmissão de dados da rede ou disponibilidade de conexão de internet. A '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' se exime, em toda a extensão permitida pelo ordenamento jurídico brasileiro e qualquer outro aplicável, de qualquer responsabilidade pelos danos de qualquer natureza, lucros cessantes e danos emergentes, que possam ser ocasionados ou decorrer da falta de continuidade ou funcionamento do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'.'}
            </SectionText>
            <SectionText>{'5.2.\tO Usuário declara e concorda que será o único responsável por quaisquer consequências advindas de sua utilização incorreta ou ilegal do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', respondendo, inclusive perante terceiros, por qualquer reivindicação que venha a ser apresentada à '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{', judicial ou extrajudicialmente, em virtude de eventuais danos morais e/ou materiais advindos de sua conduta.  '}
            </SectionText>
            <SectionText>{'5.3.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' se exime de qualquer responsabilidade pelos danos e prejuízos diretos e/ou indiretos de qualquer natureza que possam ser devidos em virtude do acesso e da utilização dos serviços, do conteúdo disponibilizado por seus Usuários, incluindo danos decorrentes da falta de veracidade, vigência e autenticidade da informação que os Usuários fornecem acerca de si próprios, além daqueles decorrentes da simulação por um Usuário da personalidade de um terceiro.  '}
            </SectionText>
            <SectionText>{'5.4.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' não é responsável pelas informações disponíveis em bancos de dados públicos e privados de terceiros ao acessar o aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e utilizar os serviços, a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' se exime de quaisquer responsabilidades decorrentes de imprecisões ou atualizações em tais informações de terceiros.'}
            </SectionText>
            <SectionText>{'5.5.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' envidará seus melhores esforços para, dentro dos padrões recomendados de segurança, garantir que todos os dados pessoais tratados sejam protegidos e mantidos confidenciais. Todavia, a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' não pode garantir que a proteção dos dados e sua segurança jamais venham a ser violados. Nessas circunstâncias, o Usuário acorda que a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' não poderá ser responsabilizada pela presença de vírus, práticas não autorizadas de atos de terceiros que promovam interceptação, eliminação, alteração, modificação ou manipulação dos conteúdos da aplicação e dos dados pessoais e demais informações e materiais disponibilizados pelos Usuários, ou qualquer outro elemento nocivo inserido por terceiros.'}
            </SectionText>
            <SectionText>{'5.6.\tO Usuário é o único responsável pela utilização dos serviços oferecidos, direta ou indiretamente, pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{', isentando e obrigando-se a indenizar desde já a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' ou qualquer terceiro por eventuais danos advindos desse uso.'}
            </SectionText>
            <SectionText>{'5.7.\tO Usuário tem ciência e concorda, que deverá se certificar de possuir a legitimidade necessária para a realização de tais consultas, principalmente, mas não se limitando, àquela relacionada à Lei Geral de Proteção de Dados, garantindo o respeito ao direito dos titulares, princípios e existência de base legal adequada para o tratamento, conforme melhor detalhado no Aviso de Privacidade do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{'. '}
            </SectionText>
            <SectionText>{'5.8.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' e qualquer responsável pela operação do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' não são responsáveis pela prestação de quaisquer serviços, conteúdos ou bens, vendidos, anunciados ou simplesmente direcionados por meio do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' para sites de terceiros, como anunciantes, patrocinadores, outros Usuários ou parceiros.'}
            </SectionText>
            <SectionText>{'5.9.\tO Usuário assume integralmente a responsabilidade em caso de qualquer demanda judicial ou arbitral por parte de terceiros que, de qualquer modo, afete a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' seus diretores, empregados e colaboradores, com relação ou em decorrência do seu acesso ao '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e da utilização dos serviços em violação aos presentes Termos e à legislação em vigor. Caberá ao Usuário indenizar regressivamente a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' caso venha a ser condenada ao pagamento de indenização ou outro pagamento de natureza distinta em virtude de atos praticados pelo Usuário quando do seu acesso ao '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e utilização dos serviços.'}
            </SectionText>
            <SectionTextBold>{'6.\tPropriedade intelectual'}</SectionTextBold>
            <SectionText>{'6.1.\tO Usuário está ciente de que as marcas, nomes, logotipos, bem como todo e qualquer conteúdo, desenho, arte ou layout presentes no '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e decorrentes do uso dos serviços são de propriedade exclusiva da '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{', de modo que a utilização do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' não importa em autorização ao Usuário, para que possa citar tais nomes, marcas e logotipos.'}
            </SectionText>
            <SectionText>{'6.2.\tSão vedados quaisquer atos ou contribuições tendentes a modificação das características, ampliação, alteração ou incorporação de quaisquer outros programas ou sistemas da '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{'. Toda e qualquer forma de reprodução dos serviços e do '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades, formas ou títulos é expressamente vedada.'}
            </SectionText>
            <SectionText>{'6.3.\tO conteúdo disponibilizado no aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e/ou acessado em razão do uso dos serviços caracteriza somente autorização ao Usuário para visualizar os conteúdos disponibilizados, não implicando qualquer licença, cessão ou transferência de titularidade de direitos da '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' ao Usuário relacionados ao conteúdo, marca ou outorga de demais direitos. Em caso de violação, a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' se reserva o direito de determinar a imediata remoção do conteúdo, sem prejuízo de outras sanções cíveis e penais estabelecidas na legislação pertinente.'}
            </SectionText>
            <SectionTextBold>{'7.\tVigência'}</SectionTextBold>
            <SectionText>{'7.1.\tOs presentes Termos são celebrados por prazo indeterminado, podendo ser rescindido pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' a qualquer momento, a seu exclusivo critério, independentemente do pagamento de qualquer compensação ou indenização, seja a qual título for.  '}
            </SectionText>
            <SectionText>{'7.2.\tO Usuário poderá rescindir os presentes Termos, a qualquer momento e sem qualquer custo. Nesse caso, os créditos adquiridos poderão ser utilizados até sua finalização. '}</SectionText>
            <SectionText>{'7.3.\tA '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' poderá, a qualquer tempo, promover modificações nos presentes Termos Gerais de Uso e Aviso de Privacidade. A continuação do uso dos Serviços após as alterações implica a aceitação de seus novos termos por parte dos Usuários.'}
            </SectionText>
            <SectionTextBold>{'8.\t Disposições Gerais'}</SectionTextBold>
            <SectionText>{'8.1.\tA versão válida e eficaz dos presentes Termos é aquela disponibilizada atualmente no aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{', sendo responsabilidade do Usuário verificar, periodicamente, o conteúdo destes Termos, certificando-se de que analisou sua versão mais atualizada, com base na data indicada ao final do documento.7.2. Essa versão rege todas as relações passadas e presentes entre os Usuários e a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' no que diz respeito à utilização do aplicativo '}<SectionTextBoldNoPadding>{`${whitelabelName}`}</SectionTextBoldNoPadding>{' e de seus Serviços, respeitados direitos adquiridos, atos jurídicos perfeitos e coisas julgadas. O Usuário deverá sempre ler atentamente os Termos de Serviço e não poderá escusar-se deles alegando ignorância sobre seus termos, inclusive quanto a eventuais modificações.'}
            </SectionText>
            <SectionText>{'8.2.\tO não exercício pela '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' de quaisquer direitos ou faculdades que lhes sejam conferidos pelos Termos Gerais ou pela legislação em vigor, bem como a eventual tolerância contra infrações aos dispositivos dos instrumentos legais e contratuais acima mencionados, não importará renúncia ou novação, podendo exercer seus direitos a qualquer tempo. A aceitação destes Termos bem como do Aviso de Privacidade, não importa a criação de qualquer vínculo trabalhista, societário, de parceria ou associativo entre a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' e o Usuário.'}
            </SectionText>
            <SectionText>{'8.3.\tO Aviso de Privacidade e demais regras que governem o acesso e a utilização dos serviços complementam estes Termos.'}</SectionText>
            <SectionText>{'8.4. Estes Termos serão regidos pelas leis da República Federativa do Brasil, independentemente de qualquer disposição sobre conflito de leis.'}</SectionText>
            <SectionText>{'8.5.\tAs partes elegem o Foro Central da Comarca de Belo Horizonte, renunciando a qualquer outro, por mais privilegiado que seja, para dirimir quaisquer questões oriundas do presente instrumento.'}</SectionText>
            <SectionText>{'8.6.\tO Usuário poderá entrar em contato com a '}<SectionTextBoldNoPadding>{'Who'}</SectionTextBoldNoPadding>{' pelo seguinte e-mail: suporte@consultai.app'}
            </SectionText>
            <SectionTextBold>{'Atualizado em 12 de fevereiro de 2024.'}</SectionTextBold>
            <Button size='large'
                    fullWidth
                    color={'secondary'}
                    onClick={() => props.onClose(true)}
            >
                Voltar
            </Button>
        </>
    </Modal>
}
export default ServiceTermsModal;