import React, { useContext, useState } from "react";
import Input from "../../../../components/Input/Input";

import CreateAccountContext from "../../context/CreateAccountContext";
import CheckBox from "../../../../components/CheckBox";
import { Grid } from "@mui/material";
import { TermsModalLink, TermsModalLinkText } from "../PrivacyPolicyModal/styles";
import { toast } from "react-toastify";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import ServiceTermsModal from "../ServiceTermsModal";
import { Palette } from "../../../../shared/theme/palette";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const PersonalData = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [termsRead, setTermsRead] = useState(false);
    const [policyRead, setPolicyRead] = useState(false);
    const context = useContext(CreateAccountContext);
    const { whitelabelName } = useWhitelabel();
    const theme = useThemeWhiteLabel();
    function acceptTerms() {
        if (!termsRead) return toast.error('Leia os termos para continuar.');
        if (!policyRead) return toast.error('Leia a política de privacidade para continuar.');
        context.setTermsAccepted(!context.termsAccepted)
        context.setPrivacyPolicyAccepted(!context.privacyPolicyAccepted);
    }

    return (
        <div>
            {showPrivacyPolicy &&
                <PrivacyPolicyModal onClose={(read) => {
                    setPolicyRead(read)
                    setShowPrivacyPolicy(false)
                }}></PrivacyPolicyModal>}
            {showTerms &&
                <ServiceTermsModal onClose={(read) => {
                    setTermsRead(read)
                    setShowTerms(false)
                }}></ServiceTermsModal>}
            <label className="register-title">
                Preencha os campos abaixo para completar o seu cadastro.
            </label>
            <div id="personal-data-form">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Razão Social *"
                            value={context.name ?? ""}
                            onChange={context.setName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="CNPJ *"
                            value={context.cpfCnpj ?? ""}
                            onChange={context.setCpfCnpj}
                            mask="99.999.999/9999-99"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="E-mail *"
                            value={context.email ?? ""}
                            onChange={context.setEmail}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Número de telefone *"
                            value={context.phone ?? ""}
                            onChange={context.setPhone}
                            mask="+99 (99) 99999-9999"
                        />
                    </Grid>
                </Grid>
            </div>
            <TermsModalLinkText>
                {'Clique aqui ler os '}
            </TermsModalLinkText>
            <TermsModalLink onClick={() => setShowTerms(true)} color={termsRead ? Palette.purple : theme.primaryColor}>
                {'termos de uso '}
            </TermsModalLink>
            <TermsModalLinkText>
                {'e '}
            </TermsModalLinkText>
            <TermsModalLink onClick={() => setShowPrivacyPolicy(true)} color={policyRead ? Palette.purple : theme.primaryColor}>
                {'política de privacidade '}
            </TermsModalLink>
            <TermsModalLinkText>
                {`do ${whitelabelName}.`}
            </TermsModalLinkText>
            <CheckBox
                primaryColor={theme.primaryColor}
                isChecked={context.termsAccepted}
                onPress={acceptTerms}
                id="cb-terms-conditions"
                label={`Eu aceito os termos de uso e a política de privacidade do ${whitelabelName}`}
            />
        </div>
    );
};

export default PersonalData;
