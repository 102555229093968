import styled from "styled-components";
import { Palette } from "../../shared/theme/palette";

export const Container = styled.div`
  padding: 10px;
`;
export const InnerContainer = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${Palette.white};
  align-items: center;
  padding-left: 16px;
  flex-direction: row;
  gap: 35px;
  border: 1px solid ${Palette.lightGrey2};
  max-width: 640px;
  max-height: 64px;
`;
export const CustomDiv = styled.div`
  display: flex;
  padding-left: 106px;

  & > * {
    margin-right: 180px; 
  }
`;
export const TextsDiv = styled.div`
  flex-direction: row;
`;

export const ImageCards = styled.img`
  width: 30px;
  height: 19px;
`;
