import { Grid } from "@mui/material";
import { ContractSectionTitle, ContractText } from "../styles";

const MagneticCardTermsContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          2 - TERMOS E CONDIÇÕES DO CARTÃO MAGNÉTICO
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          {
            "1) Os funcionários ou prepostos do Banco Crefisa S/A, não tem conhecimento da senha, pois a mesma é de uso exclusivamente pessoal, sendo intransferível e confidencial. A senha valerá para todos os efeitos de direito como minha assinatura por meio eletrônico. "
          }
        </ContractText>
        <ContractText>
          {
            "2) Declaro que, neste ato, recebi cartão magnético contendo a senha que será utilizada para todas as transações. Declaro, ainda, que, no momento em que recebi o cartão, preenchi meus dados pessoais (nome e número do benefício) no verso do cartão."
          }
        </ContractText>
        <ContractText>
          {
            "3) Não deverei fornecer informações a terceiros sobre meu Cartão Magnético de Recebimento de Benefício do INSS."
          }
        </ContractText>
        <ContractText>
          {
            "4)Até que ocorra alguma das hipóteses de cancelamento, a posse do Cartão Magnético será feita: "
          }
        </ContractText>
        <ContractText>
          {
            "4.1 Na condição de Fiel Depositário(a), assumindo os encargos e responsabilidades fixados pela legislação vigente. "
          }
        </ContractText>
        <ContractText>
          {
            " 4.2 Com ciência de que o Cartão Magnético é para uso exclusivo e intransferível da pessoa nele identificada. "
          }
        </ContractText>
        <ContractText>
          {
            "5) Deverei comunicar ao Banco Crefisa S/A, a perda, furto, roubo ou extravio do Cartão Magnético, ou suspeita de que o mesmo está sendo utilizado por terceiros, permanecendo, até o momento da comunicação, como único responsável pelo seu uso indevido: "
          }
        </ContractText>
        <ContractText>
          {
            "5.1 Não estarei isento da responsabilidade se porventura do Cartão Magnético vier a ser utilizado por terceiro, com o conhecimento da minha senha. "
          }
        </ContractText>
        <ContractText>
          {
            "6) Poderei usar o Cartão Magnético em qualquer equipamento de rede própria e do Banco 24 Horas, bem como, em estabelecimentos de acordo com a bandeira contida no cartão."
          }
        </ContractText>
        <ContractText>
          {
            "7) Na ocasião de compras realizadas dentro do Território Nacional, o sistema fará automaticamente o débito em meu Cartão Magnético e não pagarei nenhuma tarifa pela utilização deste serviço. "
          }
        </ContractText>
        <ContractText>
          {
            "8) O Banco debitará do Cartão Magnético os valores decorrentes da sua utilização, inclusive os encargos, e a tarifa decorrente da substituição do Cartão magnético, desde que essa substituição tenha sido solicitada por mim. "
          }
        </ContractText>
        <ContractText>
          {
            "9) Quaisquer valores existentes no Cartão Magnético apenas e tão somente estarão disponíveis após o processamento dos débitos autorizados das parcelas de empréstimos contratados com a Crefisa S/A Credito, Financiamento e Investimentos, CNPJ nº 60.779.196/0001-96. "
          }
        </ContractText>
        <ContractText>
          {
            "10) Autorizo, ainda, o Banco a proceder ao estorno no Cartão Magnético, das importâncias eventualmente creditadas indevidamente e revertê-las ao INSS a seu pedido. "
          }
        </ContractText>
        <ContractText>
          {
            "11) De acordo com a Resolução Nº 3.919/2010, inciso I-b, estou de acordo com o desconto/cobrança do valor gerado para emissão da 2ª via de solicitação."
          }
        </ContractText>
      </Grid>
    </>
  );
};

export default MagneticCardTermsContractSection;
