import { DateUtils } from "../../../../shared/utils/date-utils";
import PlanCardProps from "./PlanCardProps";
import PlanCardValue from "./PlanCardValue";
import './PlanCard.css'

const PlanCard = (props: PlanCardProps) => {
  return (
    <div id="plan-confirm-card-container">
      <p className="title">{props.plan.nome}</p>
      <PlanCardValue
        label="Mensalidade"
        value={props.plan.valor.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      />
      <PlanCardValue
        label="Data da Contratação"
        value={new Date().toLocaleDateString("pt-BR")}
      />
      <PlanCardValue
        label="Próxima cobrança"
        value={DateUtils.addDays(new Date(), 30).toLocaleDateString("pt-BR")}
      />
    </div>
  );
};

export default PlanCard;
