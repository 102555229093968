import {NovaUnidadeResponse} from '../models/responses/unidade-response';
import {HelpRequest} from '../models/requests/help-request';
import useConsultaiApi from "./context/hooks/useConsultaiApi";


export const useHelpService = () => {
    const consultaiApi = useConsultaiApi();
    const requestHelp = (request: HelpRequest) => consultaiApi.post<NovaUnidadeResponse>('/contact', 1, request,)
    return {
        requestHelp
    }
}

