import { useQuery } from "@tanstack/react-query";

import { MailingResponseData } from "../../shared/models/backend/mailing/mailing";
import useConsultaiApi from "../../shared/services/context/hooks/useConsultaiApi";
import { mailingKeys } from "../key-factories/mailing.qkeys";

/**
 * Queries
 */
export function useGetMailingQuery(mailingId: string) {
  const consultaiApi = useConsultaiApi();

  return useQuery({
    queryKey: mailingKeys.findOne(mailingId),
    queryFn: async () => {
      const response = await consultaiApi.get<MailingResponseData>(
        `/mailing/${mailingId}`,
        1
      );
      return response;
    },
  });
}
