import EInquiryType from "./EInquiryType";
import {SelectOption} from "../../SelectOption";
import {EnumUtils} from "../../../utils/enum-utils";

enum EScoreType {
    NoRisk = 1,
    WithRisk = 2,
    RiskUndefined = 3
}
export const getScoreTypeAsText = (type: EScoreType) => {
    switch (type) {
        case EScoreType.WithRisk:
            return 'Com risco de fraude aparente';
        case EScoreType.RiskUndefined:
            return 'Risco indefinido';
        case EScoreType.NoRisk:
            return 'Sem risco de fraude aparente';
        default:
            return EInquiryType[type];
    }
};
export const EScoreTypeDescriptions: { [key in EScoreType]: string } = {
    [EScoreType.NoRisk]: 'Sem risco de fraude aparente',
    [EScoreType.WithRisk]: 'Com risco de fraude aparente',
    [EScoreType.RiskUndefined]: 'Risco indefinido'
};
export const EScoreTypeOptions: Array<SelectOption> = EnumUtils.enumToArray(EScoreType, EScoreTypeDescriptions);
export default EScoreType;
