import Typography from '../../../../components/Typography';
import DetailLine from '../../../ListUsers/DetailLine';
import {applyMask} from '../../../../shared/utils/remove-mask';
import React from 'react';
import {ShortUnity} from "../../../../shared/models/unity";
import {CardTitleLineDiv, HorizontalButtonsContainer, ModalContainer, StyledCard} from "../../styles";
import OptionIcon from "@mui/icons-material/MoreVert";
import {Button, IconButton} from "@mui/material";
import Modal from "../../../../components/Modal";
import {useNavigate} from "react-router-dom";
import Routes from "../../../../constants/Routes";
import UnityUsersTable from "../UnityUsersTable";
import {ToastContainer} from "react-toastify";

interface UnityCardProps {
    unity: ShortUnity
}

const UnityCard = ({...props}: UnityCardProps) => {
    const maxTitleLength = 30;
    const [showOptions, setShowOptions] = React.useState(false);
    const navigate = useNavigate();
    const unityInfoComponents = () => {
        return {
            name: <DetailLine label={'Unidade: '} value={props.unity.name}/>,
            cnpj: <DetailLine label={'CPF: '} value={applyMask(props.unity.cnpj, '99.999.999/9999-99')}/>,
            email: <DetailLine label={'E-mail: '} value={props.unity.email}/>,
            phone: <DetailLine label={'Telefone: '} value={applyMask(props.unity.phone, '+55 (99) 99999-9999')}/>,
            users: <DetailLine label={'Usuários vinculados: '} value={props.unity.totalUsers.toString()}/>
        }
    }

    const linkUsers = () => navigate(Routes.Unities.LinkUsers(props.unity.id.toString()))

    return (
        <>
            {showOptions && <Modal width={'90%'}
                                   title={'Gerenciar Unidade'}
                                   onClose={() => setShowOptions(false)}>
                <ModalContainer>
                    {unityInfoComponents().name}
                    {unityInfoComponents().cnpj}
                    {unityInfoComponents().email}
                    {unityInfoComponents().phone}
                    {unityInfoComponents().users}
                    <hr/>
                    <UnityUsersTable unityId={props.unity.id}/>
                    <HorizontalButtonsContainer>
                        <Button variant={'text'} onClick={() => setShowOptions(false)}>{'Voltar'}</Button>
                        <Button variant={'outlined'} color={'primary'}
                                onClick={linkUsers}>{'Vincular Usuários'}</Button>
                    </HorizontalButtonsContainer>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        limit={1}
                        theme="colored"
                    />
                </ModalContainer>
            </Modal>}
            <StyledCard>
                <CardTitleLineDiv>
                    <Typography
                        variant='caption3'>{props.unity.name.length > maxTitleLength ? props.unity.name.slice(0, maxTitleLength) + '...' : props.unity.name}</Typography>
                    <IconButton onClick={() => setShowOptions(!showOptions)}><OptionIcon/></IconButton>
                </CardTitleLineDiv>
                {unityInfoComponents().cnpj}
                {unityInfoComponents().email}
                {unityInfoComponents().phone}
                {unityInfoComponents().users}
            </StyledCard>
        </>
    );
}
export default UnityCard;