import { useEffect, useState } from "react";

const useMicrophone = () => {
  const [hasMic, setHasMic] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const checkMicrophoneAccess = async () => {
      try {
        // Check if any microphone devices are available
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasMic = devices.some((device) => device.kind === "audioinput");
        setHasMic(hasMic);

        if (hasMic) {
          // Request microphone access
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          setPermissionGranted(true);

          // Stop all audio tracks after checking access
          stream.getTracks().forEach((track) => track.stop());
        } else {
          setErrorMessage("Não identificamos uma microfone no seu computador.");
        }
      } catch (error) {
        setPermissionGranted(false); // User denied access or an error occurred
        setErrorMessage("Acesso ao microfone negado.");
      }
    };

    checkMicrophoneAccess();
  }, []);

  return { hasMic, permissionGranted, errorMessage };
};
export default useMicrophone;
