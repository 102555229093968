import React from "react";
import Typography from "../../../../components/Typography";
import { Button } from "@mui/material";
import { Line, StyledDivButton } from "../../onBoardingVideo/styles";
import CheckBox from "../../../../components/CheckBox";
import { OnboardingVideoDocType } from "../../onBoardingVideo/hooks/useOnBoardingVideo";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";


interface DocumentTypeProps {
    handleDocumentSelection: (documentId: number) => void;
    navigateToNext: () => void;
    setSelectedDocumentType: (document: OnboardingVideoDocType) => void;
    selectDocument: string;
    documentIds: { [key: string]: number };
}

const DocumentType: React.FC<DocumentTypeProps> = ({
    handleDocumentSelection,
    navigateToNext,
    setSelectedDocumentType,
    selectDocument,
    documentIds
}) => {

    const handleDocumentSelectionWithId = (documentType: OnboardingVideoDocType) => {
        const documentId = documentIds[documentType];
        setSelectedDocumentType(documentType);
        handleDocumentSelection(documentId);
    };
    const theme = useThemeWhiteLabel();
    return (
        <>
            <Typography variant="h1">
                Selecione o Tipo de documento
            </Typography>
            <Typography variant="caption">Selecione abaixo qual documento será utilizado para o envio das
                fotos.</Typography>
            <Line onClick={() => handleDocumentSelectionWithId('RG')}>
                <CheckBox
                    primaryColor={theme.primaryColor}
                    isChecked={selectDocument === 'RG'}
                    onPress={() => handleDocumentSelectionWithId('RG')}
                />
                <Typography variant="filterLabel">
                    RG - Registro Geral
                </Typography>
            </Line>
            <Line onClick={() => handleDocumentSelectionWithId('CNH')}>
                <CheckBox
                    primaryColor={theme.primaryColor}
                    isChecked={selectDocument === 'CNH'}
                    onPress={() => handleDocumentSelectionWithId('CNH')}
                />
                <Typography variant="filterLabel">
                    CNH - Carteira Nacional de Habilitação
                </Typography>
            </Line>
            <Line onClick={() => handleDocumentSelectionWithId('Passaporte')}>
                <CheckBox
                    primaryColor={theme.primaryColor}
                    isChecked={selectDocument === 'Passaporte'}
                    onPress={() => handleDocumentSelectionWithId('Passaporte')}
                />
                <Typography variant="filterLabel">
                    Passaporte
                </Typography>
            </Line>
            <Line onClick={() => handleDocumentSelectionWithId('Carteira de Trabalho')}>
                <CheckBox
                    primaryColor={theme.primaryColor}
                    isChecked={selectDocument === 'Carteira de Trabalho'}
                    onPress={() => handleDocumentSelectionWithId('Carteira de Trabalho')}
                />
                <Typography variant="filterLabel">Carteira de Trabalho</Typography>
            </Line>
            <StyledDivButton>
                <Button variant={"contained"} color="primary" disabled={selectDocument === null}
                    onClick={navigateToNext}>
                    {"Próximo"}
                </Button>
            </StyledDivButton>
        </>
    );
};

export default DocumentType;