import ItemValidationProps from "./ItemValidationProps";
import "./ItemValidation.css";

const ItemValidation = (props: ItemValidationProps) => {
  return (
    <div className={`item-validation-container ${props.className}`}>
      <label className="item-validation-label">{props.label}</label>
      <label className="item-validation-value">{props.value}</label>
    </div>
  );
};

export default ItemValidation;
