import Layout from "../../../../layout";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import {InstructionsText, InstructionsTextBold, ScreenWithSubHeaderContainer} from "../../../../theme/GlobalStyles";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    RequestInquiriesReportToExportForm
} from "../../../../shared/models/requests/request-inquiries-report-to-export-form";
import {EInquiryTypeOptions} from "../../../../shared/models/backend/inquiry/EInquiryType";
import {EScoreTypeOptions} from "../../../../shared/models/backend/inquiry/EScoreType";
import useUserService from "../../../../shared/services/useUserService";
import {EExportFileFormatOptions} from "../../../../shared/models/backend/inquiry/EExportFileFormat";
import useInquiryReportService from "../../../../shared/services/useInquiryReportService";
import {toast} from "react-toastify";
import {DateUtils} from "../../../../shared/utils/date-utils";
import Input from "../../../../components/Input/Input";
import Routes from "../../../../constants/Routes";
import {useNavigate} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers";

const RequestNewInquiriesResultReportForm = () => {
    const [form, setForm] = useState<RequestInquiriesReportToExportForm>({
        periodStart: DateUtils.formatDateToBrazilian(DateUtils.addDays(new Date(), -30)),
        periodEnd: DateUtils.formatDateToBrazilian(new Date()),
        outputFileFormat: null,
        unityIds: [],
        inquiryScoreResults: [],
        inquiryTypes: [],
        includeFiles: null
    });
    const [unities, setUnities] = useState([]);
    const {getUnities} = useUserService();
    const {requestInquiriesResultToExport} = useInquiryReportService();
    const navigate = useNavigate();
    useEffect(() => {
        getUnities().then((unities) => {
            setUnities(unities);
        });
    }, []);

    function requestReport() {
        if (!form.outputFileFormat) {
            toast.warn('Selecione o formato do arquivo de saída.');
            return
        }
        requestInquiriesResultToExport({
            ...form,
            periodStart: DateUtils.parseDateInUTC(form.periodStart.toString()),
            periodEnd: DateUtils.parseDateInUTC(form.periodEnd.toString()),
        }).then(() => {
            navigate(Routes.Reports.DownloadInquiriesResultsSent)
        });
    }

    return (
        <Layout>
            <SubHeader title="Baixar Relatório de Consulta" subtitle="Solicitar Novo Relatório"/>
            <ScreenWithSubHeaderContainer>
                <InstructionsTextBold>
                    {'Busca de resultados por período'}
                </InstructionsTextBold>
                <InstructionsText>
                    {'Para buscar um lote de resultados, selecione abaixo o período da busca (máximo de 31 dias). ' +
                        'Todos os resultados contidos no período selecionado serão incluídos no download. '}
                </InstructionsText>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Input
                                type={'date'}
                                placeholder={'Data de Início'}
                                value={form?.periodStart.toString()}
                                onChange={(value) =>{
                                    setForm({...form, periodStart: (value)})
                                }}
                                required
                                label={'Data de Início'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Input
                                type={'date'}
                                placeholder={'Data de Fim'}
                                value={form?.periodEnd.toString()}
                                onChange={(value) => setForm({...form, periodEnd: (value)})}
                                required
                                label={'Data de Fim'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <InstructionsTextBold>
                    {'Filtros Opcionais'}
                </InstructionsTextBold>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="inquiry-type-label">{'Tipo de Consulta'}</InputLabel>
                            <Select
                                labelId="inquiry-type-label"
                                multiple
                                value={form.inquiryTypes}
                                onChange={(event) => setForm({...form, inquiryTypes: event.target.value as any})}
                                input={<OutlinedInput label="Tipo de Consulta"/>}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {EInquiryTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={form.inquiryTypes?.indexOf(option.value) > -1}/>
                                        <ListItemText primary={option.description}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="inquiry-result-label">{'Resultado'}</InputLabel>
                            <Select
                                labelId="inquiry-result-label"
                                multiple
                                value={form.inquiryScoreResults}
                                onChange={(event) => setForm({...form, inquiryScoreResults: event.target.value as any})}
                                input={<OutlinedInput label="Resultado"/>}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {EScoreTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={form.inquiryScoreResults?.indexOf(option.value) > -1}/>
                                        <ListItemText primary={option.description}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="inquiry-result-label">{'Unidade'}</InputLabel>
                            <Select
                                labelId="inquiry-result-label"
                                multiple
                                value={form.unityIds}
                                onChange={(event) => setForm({...form, unityIds: event.target.value as any})}
                                input={<OutlinedInput label="Unidade"/>}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {unities.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={form.unityIds?.indexOf(option.value) > -1}/>
                                        <ListItemText primary={option.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <InstructionsTextBold>
                    {'Configurações do Arquivo'}
                </InstructionsTextBold>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="inquiry-file-label">{'Extensão dos Arquivos Exportados*'}</InputLabel>
                            <Select
                                labelId="inquiry-file-label"
                                value={form.outputFileFormat}
                                onChange={(event) => setForm({...form, outputFileFormat: event.target.value as any})}
                                input={<OutlinedInput label="Unidade"/>}
                            >
                                {EExportFileFormatOptions.map((option) => <MenuItem key={option.value}
                                                                                    value={option.value}>{option.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={form.includeFiles}
                                              onChange={(event) => setForm({
                                                  ...form,
                                                  includeFiles: event.target.checked
                                              })}/>
                                }
                                label={'Incluir fotos e vídeos no download caso existam.'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Button
                                variant="text"
                                color={'primary'}
                                // onClick={next}
                                // disabled={!isFormValid}
                            >
                                {'Voltar'}
                            </Button>
                            <Button
                                variant="contained"
                                color={'primary'}
                                onClick={requestReport}
                            >
                                {'Gerar relatório'}
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </ScreenWithSubHeaderContainer>
        </Layout>)
}
export default RequestNewInquiriesResultReportForm;