import EInquiryType from "../models/backend/inquiry/EInquiryType";

export class InquiryUtils {
  static getEstimatedTime(inquiryType) {
    switch (inquiryType) {
      case EInquiryType.Telefone:
      case EInquiryType.Email:
        return "10 minutos.";
      case EInquiryType.NoDocVideoOnboarding:
        return "3 minutos, após o envio da documentação.";
      case EInquiryType.VideoOnboarding:
      case EInquiryType.PayrollLoanVideoOnboarding:
        return "15 minutos, após o envio da documentação.";
      case EInquiryType.Documento:
      case EInquiryType.DocumentoAutomatic:
      case EInquiryType.DocumentoComSelfie:
      case EInquiryType.DocumentoComSelfieAutomatic:
        return "15 minutos.";
      default:
        return "1 hora.";
    }
  }
}
