import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Layout from "../../layout";
import SubHeader from "../../components/SubHeader/SubHeader";
import InquiryInputInfoCard from "./InquiryInputInfoCard";
import InquiryResultInfoCard from "./InquiryResultInfoCard";
import SubSection from "../../components/SubSection";
import { Container } from "./styles";
import useOnBoardingInviteService from "../../shared/services/useOnBoardingService";
import { InquiryReturnDetailsDTO } from "../../shared/models/backend/inquiry/inquiry-details-onboarding-dto";
import InquiryAdditionalInfo from "./InquirySpecifics/InquiryAdditionalInfo";
import InputUserCard from "./InputUserCard";
import {IconButton, Tooltip} from "@mui/material";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";

const InquiryDetails = () => {
    const [loading, setLoading] = useState(false);
    const [inquiry, setInquiry] = useState<InquiryReturnDetailsDTO<any>>();
    const { getInquiryV2Return, getPdf } = useOnBoardingInviteService();
    const { id } = useParams<{ id: string }>();
    
    const inquiryId = Number(id);

    useEffect(() => {
        const getInquiry = async () => {
            setLoading(true);
            const inquiryDetails = await getInquiryV2Return<any>(inquiryId);
            setLoading(false);
            setInquiry(inquiryDetails);
        }
        getInquiry();
    }, [inquiryId]);

    return (
        <Layout>
            <SubHeader title={"Detalhes"} subtitle={`Consulta ${inquiryId}`} />
            {loading
                ? <ClipLoader />
                : <SubSection>
                    <Container>
                        <div>
                            <Tooltip title="Download">
                            <IconButton  onClick={() => getPdf(inquiryId).then((linkSource) =>
                                window.open(linkSource, '_blank'))}><DownloadIcon color={'primary'}/></IconButton>
                            </Tooltip>
                        </div>
                        {inquiry && <>
                            <InquiryInputInfoCard inquiry={inquiry} />
                            <InquiryResultInfoCard inquiry={inquiry} />
                            <InquiryAdditionalInfo id={inquiryId} inquiryType={inquiry.type} />
                            <InputUserCard name={inquiry.inputUserName}
                                login={inquiry.inputUserLogin}
                            ></InputUserCard>
                        </>}
                    </Container>
                </SubSection>}
        </Layout>
    );
};

export default InquiryDetails;