import React, {ReactElement} from "react";
import styled from "styled-components";

const Container = styled.div`
    padding: 20px 60px;
`;

type Props = {
    children?: JSX.Element[] | ReactElement[] | JSX.Element | ReactElement | undefined;
};

const SubSection = ({children}: Props) => {
    return <Container>{children}</Container>;
};

export default SubSection; 