import React from "react";
import SubSection from "../../components/SubSection";
import Typography from "../../components/Typography";
import SubHeader from "../../components/SubHeader/SubHeader";
import Layout from "../../layout";
import {TextContainer} from "./styles";
import ListInquiryList from "./components/ListInquiryList/ListInquiryList";

const InquiryList = () => {
    return (
        <Layout>
            <>
                <SubHeader title={"Consultas"} subtitle={"Histórico de consultas"}/>
                <SubSection>
                    <></>
                    <TextContainer>
                        <Typography variant="caption">
                            Clique nos cards abaixo para visualizar o detalhe das consultas.
                            Se o resultado da consulta estiver pendente não será possível
                            abrir o detalhamento.
                        </Typography>
                    </TextContainer>
                    <ListInquiryList></ListInquiryList>
                </SubSection>
            </>
        </Layout>
    );
};

export default InquiryList;
