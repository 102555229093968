import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalLoader } from "../../../components/GlobalLoader/GlobalLoader";
import { useGetMailingQuery } from "../../../data/queries/mailing.query";
import { useCrefisaWhiteLabelData } from "../../../hooks/useCrefisaWhiteLabelData";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import { EMailingStepsEnum, MailingWithDocVariantProps } from "../index.types";
import { InstructionStepVariantsEnum } from "../InstructionsStep";
import { getDocVariant } from "../utils";
import TakePhotoMultiFile from "./MultiFile";
import TakePhotoSingleFile from "./SingleFile";

const TakePhotoStep = ({
  variant,
  setActiveStep,
  photoToEdit,
}: { photoToEdit?: "front" | "back" } & MailingWithDocVariantProps) => {
  const { id } = useParams();
  const [docVariant, setDocVariant] =
    useState<InstructionStepVariantsEnum | null>(null);
  const { data: mailingData, isLoading, isFetched } = useGetMailingQuery(id);
  // const { whitelabelLogo, whitelabelName } = useWhitelabel();
  const { logo, name } = useCrefisaWhiteLabelData();
  const theme = useThemeWhiteLabel();

  useEffect(() => {
    const _docVariant =
      variant || getDocVariant(mailingData?.additionalInfo?.documentType);
    setDocVariant(_docVariant);

    if (!!mailingData && isFetched && !_docVariant) {
      setActiveStep(EMailingStepsEnum.DocumentChooseType);
    }
  }, [mailingData, isFetched, variant]);

  if (isLoading || !docVariant || !mailingData)
    return (
      <GlobalLoader
        logo={logo}
        alt={`${name}-logo`}
        color={theme.primaryColor}
        isFixed
      />
    );

  if (docVariant === InstructionStepVariantsEnum.rg)
    return (
      <TakePhotoMultiFile
        setActiveStep={setActiveStep}
        additionalInfo={mailingData?.additionalInfo}
        photoToEdit={photoToEdit}
      />
    );

  return (
    <TakePhotoSingleFile
      setActiveStep={setActiveStep}
      variant={docVariant}
      additionalInfo={mailingData?.additionalInfo}
    />
  );
};

export default TakePhotoStep;
