import styled from 'styled-components';
import { Palette } from '../../../../shared/theme/palette';

export const HomeCardContainer = styled.div`
  width: 1440px;
  height: 900px;
  background: var(--Fundo, ${Palette.lightGrey4});
  padding: 24px 24px;
`;
export const HomeCard = styled.div`
  display: flex;
  width: 1312px;
  height: 636px;
  padding: 24px 43px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Blue-Blue-1, ${Palette.lightBlue});
  background: var(--Grey-Grey-0, ${Palette.white});
  justify-content: space-between;
`;
export const HomeCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
`;
export const HomeCardTitle = styled.text<{ primaryColor: string }>`
  width: 665px;
  color: ${(props) => props.primaryColor};;
  font-family: GilroyExtraBold;
  font-size: 3.4375rem;
  font-style: normal;
  font-weight: 800;
  line-height: 72.387px; /* 131.612% */
  letter-spacing: -0.32px;
`;
export const HomeCardText = styled.text`
  width: 665px;
  color: ${Palette.black};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 33.5px; /* 209.375% */
  letter-spacing: 0.5px;
`;
export const HomeCardImage = styled.img`
  width: 433px;
  height: 418px;
  left: 950px;
  padding: 40px;
  position: absolute;
  background-size:  cover;
  background-repeat: no-repeat;
`;