import React, {useState} from "react";
import SubSection from "../../components/SubSection";
import Routes from "../../constants/Routes";
import {ValidationUtils} from "../../utils/validation-utils";
import useUserService from "../../shared/services/useUserService";
import {toast} from "react-toastify";
import SubHeader from "../../components/SubHeader/SubHeader";
import Layout from "../../layout";
import Input from "../../components/Input/Input";
import {Box, Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {removeMask} from "../../shared/utils/remove-mask";
import {useNavigate} from "react-router-dom";
import Typography from "../../components/Typography";
import {InnerContainer, InputContainer} from "./styles";
import {ProfileSelectOptions, UserProfileType} from "../../shared/models/user-profile-type";

const NewUser = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [profile, setProfile] = useState<UserProfileType>()
    const navigate = useNavigate();
    const {insertUserAsync} = useUserService();

    const isValid = () => {
        return (
            ValidationUtils.validateCpf(cpf) &&
            ValidationUtils.validateUserName(name) &&
            ValidationUtils.validateEmail(email) &&
            ValidationUtils.validatePhone(phone, false)
        );
    };

    const send = async () => {
        if (!isValid()) {
            return;
        }

        setLoading(true);

        try {
            await insertUserAsync({
                name,
                cpf: removeMask(cpf),
                phone: removeMask(phone),
                email,
                profile
            });
            toast.success("Usuário inserido com sucesso!");
            navigate(Routes.User.List);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <SubHeader
                title="Configurações - Gerenciamento de contas"
                subtitle="Novo usuário"
            />
            <SubSection>
                <></>
                <InnerContainer>
                    <Typography variant="caption">
                        Digite os dados do novo usuário. Ao final do processo o usuário
                        receberá um e-mail com as instruções de acesso.
                    </Typography>
                    <InputContainer>
                        <Input
                            value={name}
                            onChange={setName}
                            placeholder="Nome"
                            maxLength={100}
                            disabled={loading}
                            width="250px"
                        />
                        <Input
                            value={cpf}
                            onChange={setCpf}
                            placeholder="CPF"
                            mask={"999.999.999-99"}
                            disabled={loading}
                            maxLength={14}
                            width="250px"
                        />
                        <Input
                            value={phone}
                            onChange={setPhone}
                            placeholder="DDD + telefone"
                            mask={"(99) 99999-9999"}
                            maxLength={15}
                            disabled={loading}
                            width="250px"
                        />
                        <Input
                            value={email}
                            onChange={setEmail}
                            placeholder="E-mail"
                            maxLength={100}
                            disabled={loading}
                            width="250px"
                        />
                        <FormControl fullWidth>
                            <InputLabel id="profile-select-label">Perfil</InputLabel>
                            <Select
                                labelId="profile-select-label"
                                id="profile-select"
                                value={profile}
                                label="Perfil"
                                onChange={(v) => setProfile(v.target.value as UserProfileType)}
                            >
                                {ProfileSelectOptions.map(option => {
                                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </InputContainer>
                    <Typography variant="caption2">
                        O usuário receberá no e-mail uma senha aleatória para que seja
                        realizado o primeiro acesso.
                    </Typography>

                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button size="medium" onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                        <Button
                            size="medium"
                            variant={"contained"}
                            onClick={send}
                            disabled={!isValid() || loading}
                        >
                            Criar Usuário
                        </Button>
                    </Box>
                </InnerContainer>
            </SubSection>
        </Layout>
    );
};

export default NewUser;
