import React, { useState } from "react";
import UserItemProps from "./UserItemProps";
import DetailLine from "./DetailLine";
import Typography from "../../components/Typography";
import { applyMask } from "../../shared/utils/remove-mask";
import {  StyledDiv, SwitchContainer } from "./styles";
import { ProfileSelectOptions } from "../../shared/models/user-profile-type";
import useUserService from "../../shared/services/useUserService";
import { toast } from "react-toastify";
import { Switch } from "@mui/material";


const UserItem = (props: UserItemProps) => {
    const [isActive, setIsActive] = useState(props.user.active);
    const { disableUser, enableUser } = useUserService();
    const disable = async () => {
        await disableUser(props.user.id);
        toast.success("Usuário inativado com sucesso!");
    };
    const enable = async () => {
        await enableUser(props.user.id);
        toast.success("Usuário ativado com sucesso!");
    }
    const toggleActiveStatus = async () => {
        setIsActive(!isActive);
        if (!isActive) {
            await enable();
        } else {
            await disable();
        }
    };
    return (
        <StyledDiv isActive={isActive}>
            <Typography variant="caption3">{props.user.name}</Typography>
            <SwitchContainer>
            {(props.user.cpf.length === 11 || props.user.profile !== 'ADMCONTA') && (
            <Switch
                checked={isActive}
                onChange={toggleActiveStatus}
                name="active"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        )}
            </SwitchContainer>
            <DetailLine
                label="CPF: "
                value={applyMask(
                    props.user.cpf,
                    props.user.cpf.length === 11 ? "999.999.999-99" : "99.999.999/9999-99"
                )} />
            <DetailLine label="E-mail: " value={props.user.email} />
            {props.user.phone && (
                <DetailLine
                    label="Telefone: "
                    value={applyMask(props.user.phone, "+99 (99) 99999-9999")}
                />
            )}
            <DetailLine label='Perfil: '
                value={ProfileSelectOptions.find((option) => option.value == props.user.profile).label} />
        </StyledDiv>
    );
};

export default UserItem;
