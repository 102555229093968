import useDate from './useDate';

const useUrl = () => {
  const { formatDate } = useDate();

  const toUrlParams = (params: unknown) => {
    const qParams = [];

    const keys = Object.keys(params);
    const values = Object.values(params);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      if (isDate(value)) {
        const valueStr = formatDate(value as Date, 'yyyy-MM-DDT00:00[Z]');
        qParams.push(`${key}=${valueStr}`);
      } else if (isArray(value)) {
        const array = value as Array<unknown>;

        for (const item of array) qParams.push(`${key}=${item.toString()}`);
      } else qParams.push(`${key}=${value.toString()}`);
    }

    return `?${qParams.join('&')}`;
  };

  const isDate = (value: unknown) => value['getFullYear'] !== undefined;

  const isArray = (value: unknown) => value['push'] !== undefined;

  return { toUrlParams };
};

export default useUrl;
