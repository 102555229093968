import { Dispatch, SetStateAction } from "react";
import { InstructionStepVariantsEnum } from "./InstructionsStep";

export type MailingStepProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export type MailingWithDocVariantProps = {
  variant: InstructionStepVariantsEnum;
} & MailingStepProps;

export enum EMailingStepsEnum {
  Welcome = 0,
  DataCollectionAuthorization = 1,
  DocumentChooseType = 2,
  DocumentInstructions = 3,
  DocumentUpload = 4,
  VideoInstructions = 5,
  VideoRecording = 6,
  VideoUpload = 7,
  PersonalDataForm = 8,
  ContractDataForm = 9,
  FormConfirmation = 10,
  ContractReading = 11,
  ContractSignature = 12,
  SentConfirmation = 13,
}

export const stepDescriptions: { [key in EMailingStepsEnum]: string } = {
  [EMailingStepsEnum.Welcome]: "Boas-vindas",
  [EMailingStepsEnum.DataCollectionAuthorization]:
    "Autorização de coleta de dados",
  [EMailingStepsEnum.DocumentChooseType]: "Documento - Escolher Tipo",
  [EMailingStepsEnum.DocumentInstructions]: "Documento - Instruções",
  [EMailingStepsEnum.DocumentUpload]: "Documento - Upload",
  [EMailingStepsEnum.VideoInstructions]: "Vídeo - Instruções",
  [EMailingStepsEnum.VideoRecording]: "Vídeo - Gravação",
  [EMailingStepsEnum.VideoUpload]: "Vídeo - Confirmação e envio",
  [EMailingStepsEnum.PersonalDataForm]:
    "Formulário - Dados pessoais e endereço",
  [EMailingStepsEnum.ContractDataForm]: "Formulário - Dados do contrato",
  [EMailingStepsEnum.FormConfirmation]: "Formulário - Confirmação",
  [EMailingStepsEnum.ContractReading]: "Contrato - Leitura",
  [EMailingStepsEnum.ContractSignature]: "Contrato - Assinatura",
  [EMailingStepsEnum.SentConfirmation]: "Confirmação de envio",
};
