import {
    DocumentInquiryCnhInstructionsImage,
    DocumentInquiryCnhInstructionsImageContainer,
    DocumentInquiryRgInstructionsImage,
    DocumentInquiryRgInstructionsImageContainer,
    DocumentInquirySelfieInstructionsImage,
    DocumentInquiryTakePhotoButtonContainer
} from "../styles";
import CheckBox from "../../../../components/CheckBox";
import {InstructionsText, ScreenWithSubHeaderContainer} from "../../../../theme/GlobalStyles";
import {useEffect, useState} from "react";
import rgBackExampleImage from "../../../../shared/assets/rg-back-example.png";
import rgFrontExampleImage from "../../../../shared/assets/rg-front-example.png";
import cnhExampleImage from "../../../../shared/assets/cnh-example.png";
import selfieExampleImage from "../../../../shared/assets/webcam-selfie-example.png";
import selfieFileExampleImage from "../../../../shared/assets/selfie-file-sample.png";
import docFileExampleImage from "../../../../shared/assets/upload-rg-file-sample.png";
import {DocumentType} from "../../../../shared/models/document-type";
import {Button} from "@mui/material";
import EPhotoSourceType from "../../../../shared/models/backend/inquiry/EPhotoSourceType";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface DocumentInquiryInstructionsProps {
    docType?: DocumentType
    onBack: () => void;
    onNext: () => void;
    withSelfie?: boolean;
    source: EPhotoSourceType
}

const DocumentInquiryInstructions = ({...props}: DocumentInquiryInstructionsProps) => {
    const [skipInstructions, setSkipInstructions] = useState(false);
    const localStorageKey = 'skipDocumentInquiryInstructions' + props.docType

    useEffect(() => {
        const skip = localStorage.getItem(localStorageKey);
        if (!!skip && skip == true.toString()) props.onNext()
    }, []);
    const theme = useThemeWhiteLabel();
    return (
        <ScreenWithSubHeaderContainer>
            {props.docType === 'cnh' &&
                <>
                    {props.source === EPhotoSourceType.Camera &&
                        <InstructionsText>
                            {'Utilize um documento de CNH físico (documentos digitais não são permitidos).'}
                        </InstructionsText>}
                    {props.source === EPhotoSourceType.File &&
                        <InstructionsText>
                            Envie os arquivos referentes ao documento, incluindo a frente e, se houver, o verso
                            {props.withSelfie && ', e um arquivo contendo a imagem da selfie'}. 
                            Cada arquivo não deve ultrapassar os <b>10mb</b>.
                            <br/>
                            Formatos suportados: JPG, PNG ou PDF.
                        </InstructionsText>}
                    <InstructionsText>
                        {'Abra a carteira de habilitação e tire uma foto da frente e do verso, conforme o exemplo abaixo.'}
                    </InstructionsText>
                    <DocumentInquiryCnhInstructionsImageContainer>
                        <DocumentInquiryCnhInstructionsImage src={cnhExampleImage} />
                    </DocumentInquiryCnhInstructionsImageContainer>
                </>
            }
            {props.docType === 'rg' &&
                <>
                    {props.source === EPhotoSourceType.Camera &&
                        <InstructionsText>
                            Utilize um documento de RG físico (documentos digitais não são permitidos).
                            <b> Primeiro, tire a foto da frente do documento</b>, depois do
                            verso{props.withSelfie ? ', e por fim, a selfie.' : '.'}
                        </InstructionsText>}
                    {props.source === EPhotoSourceType.File &&
                        <InstructionsText>
                            Envie os arquivos referentes ao documento, incluindo a frente e o verso
                            {props.withSelfie && ', e um arquivo contendo a imagem da selfie'}. 
                            Cada arquivo não deve ultrapassar os <b>10mb</b>.
                            <br/>
                            Formatos suportados: JPG, PNG ou PDF.
                        </InstructionsText>}
                    <InstructionsText>
                        {props.source === EPhotoSourceType.Camera
                            ? 'A frente do documento contém a foto do portador.'
                            : 'A frente e o verso do documento podem ser enviados em arquivos separados.'}
                    </InstructionsText>
                    <DocumentInquiryRgInstructionsImageContainer>
                        <DocumentInquiryRgInstructionsImage
                            src={props.source === EPhotoSourceType.Camera ? rgFrontExampleImage : docFileExampleImage} />
                    </DocumentInquiryRgInstructionsImageContainer>
                    {props.source === EPhotoSourceType.Camera &&
                        <>
                            <InstructionsText>
                                {'O verso do documento contém os dados pessoais do portador.'}
                            </InstructionsText>
                            <DocumentInquiryRgInstructionsImageContainer>
                                <DocumentInquiryRgInstructionsImage src={rgBackExampleImage} />
                            </DocumentInquiryRgInstructionsImageContainer>
                        </>}
                </>
            }
           {props?.withSelfie &&
                <>
                    <InstructionsText>
                        {'Para tirar a selfie, é recomendado remover óculos e chapéus.'}
                    </InstructionsText>
                    <DocumentInquirySelfieInstructionsImage
                        src={props.source === EPhotoSourceType.Camera ? selfieExampleImage : selfieFileExampleImage}/>
                </>
            }
            <InstructionsText>
                Clique no botão <b>“Iniciar”</b> abaixo para prosseguir.
            </InstructionsText>
              <CheckBox
                                    primaryColor={theme.primaryColor}
                square
                onPress={() => {
                    setSkipInstructions(!skipInstructions)
                    localStorage.setItem(localStorageKey, (!skipInstructions).toString())
                }}
                isChecked={skipInstructions}
                label={'Não exibir mais as instruções por hoje.'}/>
            <DocumentInquiryTakePhotoButtonContainer>
                <Button
                    onClick={props.onBack}
                    variant={'outlined'}
                >
                    {'Voltar'}
                </Button>
                <Button
                    onClick={props.onNext}
                    variant={'contained'}
                    color={'primary'}
                >
                    {'Iniciar'}
                </Button>
            </DocumentInquiryTakePhotoButtonContainer>
        </ScreenWithSubHeaderContainer>
    );    
}
export default DocumentInquiryInstructions;