import Header from "../../components/Header";
import {
    FormContainer,
    HorizontalButtonsContainerLarge,
    InstructionsText,
    ScreenWithHeaderContainer,
} from "../../theme/GlobalStyles";
import {ResetPasswordTitleContainer, ResetPasswordTitleText} from "./styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Input from "../../components/Input/Input";
import React, {useState} from "react";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Routes from "../../constants/Routes";
import {useAuthenticationService} from "../../shared/services/useAuthenticationService";
import {toast} from "react-toastify";
import {ValidationUtils} from "../../utils/validation-utils";
import {removeMask} from "../../shared/utils/remove-mask";
import './ResetPassword.css'

const ResetPasswordScreen = () => {
    const [cnpj, setCnpj] = React.useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {requestPasswordRecovery, requestPasswordResetByCode} =
        useAuthenticationService();
    const [currentStep, setCurrentStep] = useState(0);
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    function next() {
        switch (currentStep) {
            case 0:
                sendEmail();
                break;
            case 1:
                if (code?.length < 4) {
                    toast.error("Código inválido");
                    return;
                }
                setCurrentStep(currentStep + 1);
                break;
            case 2:
                resetPassword();
                break;
        }
    }

    function sendEmail() {
        if (!ValidationUtils.validateCpfCnpj(cnpj)) return;
        setLoading(true);
        requestPasswordRecovery({login: removeMask(cnpj)})
            .then((successMessage) => {
              console.log({successMessage})
                if (!successMessage) {
                    toast.error(
                        "Erro ao enviar o código, verifique os dados informados e tente novamente"
                    );
                    return;
                }
                toast.success(successMessage);
                setCurrentStep(currentStep + 1);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function resetPassword() {
        if (!ValidationUtils.validatePassword(password, true, true)) return;

        if (
            !ValidationUtils.validatePasswordConfirmation(
                password,
                passwordConfirmation,
                true
            )
        )
            return;
        setLoading(true);
        requestPasswordResetByCode({
            login: removeMask(cnpj),
            code,
            password,
            passwordConfirmation,
        })
            .then(() => {
                toast.success("Senha redefinida com sucesso!");
                navigate(Routes.Login);
            })
            .catch(() => {
                toast.error(
                    "Erro ao redefinir a senha, verifique os dados informados e tente novamente"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validatePassword = (value: string) => ValidationUtils.validatePassword(value, false, true);

    const validatePasswordConfirmation = (value: string) => ValidationUtils.validatePasswordConfirmation(value, password);

    const onVoltar = () => currentStep === 0 ? window.history.back() : setCurrentStep(currentStep - 1);

    const getButtonDisabled = () => {
        if (loading) return true;

        switch (currentStep) {
            case 0:
                return cnpj.length < 14;
            case 1:
                return code.length < 4;
            case 2:
                return !ValidationUtils.validatePassword(password, false, true) 
                    || !ValidationUtils.validatePasswordConfirmation(password, passwordConfirmation)
        }

        return false
    }

    const nextButtonDisabled = getButtonDisabled()

    const nextButtonText = currentStep === 2 ? 'Alterar Senha' : 'Próximo'

    return (
        <div>
            <Header></Header>
            <ScreenWithHeaderContainer>
                <ResetPasswordTitleContainer onClick={() => navigate(-1)}>
                    <ChevronLeftIcon/>
                    <ResetPasswordTitleText>Redefinir a Senha</ResetPasswordTitleText>
                </ResetPasswordTitleContainer>
                <InstructionsText>
                    {currentStep == 0
                        ? "Digite o seu CPF ou CNPJ cadastrado abaixo para receber o seu código."
                        : currentStep == 1
                            ? "Digite abaixo o código de 4 dígitos enviados para o seu e-mail."
                            : "Crie uma nova senha de acesso.\n" +
                            "A senha deve conter no mínimo 8 dígitos, uma letra maiúscula e um caractere especial."}
                </InstructionsText>
                {currentStep == 0 && (
                    <FormContainer>
                        <Input
                            placeholder="CPF/CNPJ *"
                            onChange={setCnpj}
                            value={cnpj}
                            mask={len => len <= 14 ? '999.999.999-99' : '99.999.999/9999-99'}
                            label="CPF/CNPJ *"
                        />
                    </FormContainer>
                )}
                {currentStep == 1 && (
                    <FormContainer>
                        <Input
                            placeholder="Código *"
                            onChange={setCode}
                            value={code}
                            maxLength={4}
                            label={"Código *"}
                        />
                    </FormContainer>
                )}
                {currentStep == 2 && (
                    <div id="reset-password-passwords-container">
                        <Input
                            validationCallback={validatePassword}
                            placeholder="Senha *"
                            isPassword
                            onChange={setPassword}
                            value={password}
                            label="Senha *"
                            error="Senha fora do padrão"
                        />
                        <Input
                            validationCallback={validatePasswordConfirmation}
                            placeholder="Confirmar a senha *"
                            isPassword
                            onChange={setPasswordConfirmation}
                            value={passwordConfirmation}
                            label="Confirmar a senha *"
                            error="As senhas não conferem"
                        />
                    </div>
                )}
                <HorizontalButtonsContainerLarge>
                    <Button onClick={onVoltar} variant="outlined">Voltar</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={next}
                        disabled={nextButtonDisabled}
                    >
                        {nextButtonText}
                    </Button>
                </HorizontalButtonsContainerLarge>
            </ScreenWithHeaderContainer>
        </div>
    );
};
export default ResetPasswordScreen;
