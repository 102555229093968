import {SelectOption} from "../../SelectOption";
import {EnumUtils} from "../../../utils/enum-utils";

export enum EExportFileFormat {
    Excel = 1,
    Csv = 2
}

const EExportFileFormatDescriptions: { [key in EExportFileFormat]: string } = {
    [EExportFileFormat.Excel]: 'Excel',
    [EExportFileFormat.Csv]: 'CSV',
};
export const EExportFileFormatOptions: Array<SelectOption> = EnumUtils.enumToArray(EExportFileFormat, EExportFileFormatDescriptions);