import React from "react";
import StepItem from "./StepItem";
import StepsProps from "./StepsProps";

const Steps = (props: StepsProps) => {
  return (
    <div id="steps-container">
      <div id="steps-indicators">
        <StepItem type="dados-pessoais" alignment="flex-start" active />
        <StepItem
          type="endereco"
          alignment="center"
          active={props.currentStep > 0}
        />
        <StepItem
          type="senha"
          alignment="flex-end"
          active={props.currentStep == 2}
          lastItem
        />
      </div>
    </div>
  );
};

export default Steps;
