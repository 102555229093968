enum EInquiryStatus {
    NotCreatedByBalanceReason = 0,
    Created = 1,
    Sent = 2,
    RefundedForFailure = 3,
    Completed = 4,
    RefundedForTimeExpired = 5,
    WaitingVideoOnboarding = 6,
    ExpiredByInviteExpired = 7,
}

export default EInquiryStatus;
