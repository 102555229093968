import SubHeader from "../../components/SubHeader/SubHeader";
import React, {useEffect, useState} from "react";
import {InstructionsText, InstructionsTextBold, ScreenWithSubHeaderContainer} from "../../theme/GlobalStyles";
import Layout from "../../layout";
import {NewReportRequestContainer, NewReportRequestTextContainer} from "./styles";
import Typography from "../../components/Typography";
import {Button} from "@mui/material";
import useInquiryReportService from "../../shared/services/useInquiryReportService";
import {InquiriesFileJob} from "../../shared/models/backend/inquiry/inquiries-file-job";
import InquiriesFileJobsTable from "./components/InquiriesFileJobsTable";
import RequestNewInquiriesResultReportForm from "./components/RequestNewInquiriesResultReportForm";

const DownloadInquiriesResultReport = () => {
    const [reportRequests, setReportRequests] = useState<InquiriesFileJob[]>([]);
    const [showNewReportRequestForm, setShowNewReportRequestForm] = useState<boolean>(false);
    const {getInquiriesResultToExportRequests} = useInquiryReportService();
    useEffect(() => {
        const fetchReportRequests = async () => {
            const inquiries = await getInquiriesResultToExportRequests();
            setReportRequests(inquiries);
        };
        fetchReportRequests();
    }, []);
    return showNewReportRequestForm
        ? <RequestNewInquiriesResultReportForm/>
        : (<Layout>
            <SubHeader title="Relatórios" subtitle="Baixar Resultados de Consulta"/>
            <ScreenWithSubHeaderContainer>
                <InstructionsText>
                    {'Abaixo você encontra todos os links solicitados nas últimas 24 horas. ' +
                        'Após este período os links serão expirados e você terá de solicitar um novo.'}
                </InstructionsText>
                <InstructionsTextBold>
                    {'Relatórios solicitados nas últimas 24 horas'}
                </InstructionsTextBold>
                <NewReportRequestContainer>
                    <NewReportRequestTextContainer>
                        <Typography align={'center'}
                                    variant={'buttonText'}>
                            {'Para solicitar um novo relatório customizado, clique no botão ao lado.'}
                        </Typography>
                    </NewReportRequestTextContainer>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowNewReportRequestForm(true)}
                        size={'small'}>{'Solicitar novo relatório'}</Button>
                </NewReportRequestContainer>
                {reportRequests.length > 0 && <InquiriesFileJobsTable jobs={reportRequests}/>}
                {!reportRequests.length && <Typography variant={'caption4'}>{'Nenhum relatório solicitado nas últimas 24 horas.'}</Typography>}
            </ScreenWithSubHeaderContainer>
        </Layout>)
}
export default DownloadInquiriesResultReport;