import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography";
import { ButtonContainer, IconContainer, InnerContainer, StyledDiv, StyledTypography, } from "../styles";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import { Palette } from "../../../shared/theme/palette";
import InquiryCardRow from "../InquiryCardRow";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";
import { TitleMidia } from "../../../shared/hooks/useInquirySpecifics";
import ModalMidia from "../modalMidia/modalMidia";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";

interface ButtonConfig {
    title: string;
    url: string;
}

interface InquiryResultCardProps {
    title: TitleMidia;
    description: string;
    extraTitle?: string[];
    extraDescription?: string[];
    extraIcons?: { infoIcon: boolean; tooltipText: string }[]; 
    showButtons?: ButtonConfig[];
    buttonTitle?: string;
    iconType?: "success" | "warning" | "error";
    extraImageUrl?: string;
    inquiryId: number;
    inquiryType: EInquiryType;
}

const InquirySpecificsCard = ({
    title,
    description,
    extraTitle,
    extraDescription,
    showButtons = [],
    iconType,
    extraImageUrl,
    inquiryId,
    inquiryType,
    extraIcons,
}: InquiryResultCardProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [icon, setIcon] = useState<React.ReactNode>();
    const [modalUrls, setModalUrls] = useState<string[]>([]);
    const theme = useThemeWhiteLabel();

    const handleButtonClick = (url: string) => {
        if (url) {
            if (url.includes(".pdf")) {
                window.open(url, "_blank");
                return;
            }
            setModalUrls([url]);
            return setIsModalOpen(true);
        }
        setIsModalOpen(true);
    };

    useEffect(() => {
        switch (iconType) {
            case "success":
                setIcon(<CheckCircleIcon style={{ color: theme.primaryColor }} />);
                break;
            case "warning":
                setIcon(<ErrorIcon style={{ color: theme.alternativeColor }} />);
                break;
            case "error":
                setIcon(<CancelIcon style={{ color: Palette.red }} />);
                break;
            default:
                setIcon(null);
        }
    }, [iconType]);

    return (
        <StyledDiv>
        <InnerContainer>
            <Typography variant={"filterTitle"} color={"green"}>
                {title}
            </Typography>
            {inquiryType !== EInquiryType.DocumentoAutomatic && inquiryType !== EInquiryType.DocumentoComSelfieAutomatic && inquiryType !== EInquiryType.Telefone && (
                <IconContainer>{icon}</IconContainer>
            )}
            <StyledTypography>
                <InquiryCardRow label={'Descrição'} value={description} />
                {extraTitle && extraTitle.map((title, index) => (
                    <InquiryCardRow key={index}
                        label={title}
                        value={extraDescription ? extraDescription[index] : ''}
                        infoIcon={extraIcons ? extraIcons[index].infoIcon : false}
                        tooltipText={extraIcons ? extraIcons[index].tooltipText : ''} />
                ))}
            </StyledTypography>
            {showButtons.length > 0 && (
                <ButtonContainer>
                    {showButtons.map((button, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleButtonClick(button.url)}>
                            {button.title}
                        </Button>
                    ))}
                </ButtonContainer>
            )}
        </InnerContainer>
        {isModalOpen && (
            <ModalMidia
                onClose={() => setIsModalOpen(false)}
                title={title}
                urls={modalUrls}
                extraImageUrl={extraImageUrl}
                inquiryId={inquiryId} />
        )}
    </StyledDiv>
    );
};

export default InquirySpecificsCard;