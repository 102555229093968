import moment from 'moment';

const useDate = () => {
  const formatDate = (date: string | Date, format = 'DD/MM/yyyy', utcoffset = -3) => {
    return moment(date)
        .add(utcoffset, 'h')
        .format(format);
  };

  const addMonth = (date: string | Date, months: number) => {
    return moment(date).add(months, 'M');
  };

  return { formatDate, addMonth };
};

export default useDate;
