import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {SentSuccessfullyTitleText} from "../../components/styles";
import "./LinkSent.css";
import {InstructionsText} from "../../../../theme/GlobalStyles";
import {Button} from "@mui/material";
import LinkSentProps from "./LinkSentProps";
import ResultTimeInfoCard from "../../components/ResultTimeInfoCard";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";


const LinkSent = (props: LinkSentProps) => {
    const isVideoOnlyOnboarding = props.inquiryType === EInquiryType.NoDocVideoOnboarding;
    const isPayrollOnboarding = props.inquiryType === EInquiryType.PayrollLoanVideoOnboarding;
    const theme = useThemeWhiteLabel();
    return (
        <div id="onboarding-link-sent-container">
            <div id="success-container">
                <CheckCircleIcon color="success"/>
                <SentSuccessfullyTitleText primaryColor={theme.primaryColor}>
                    Consulta enviada com sucesso!
                </SentSuccessfullyTitleText>
            </div>
            <InstructionsText>
                {isVideoOnlyOnboarding
                    ? "Tudo certo! Agora basta esperar que o vídeo seja enviado para dar início à validação do seu onboarding."
                    : "Tudo certo! Agora basta esperar que o vídeo e as fotos dos documentos sejam enviados para dar início à validação do seu onboarding."}
                <br />
                <br />
                {isVideoOnlyOnboarding
                    ? "Não precisa se preocupar. Você será notificado assim que o vídeo for enviado."
                    : "Não precisa se preocupar. Você será notificado assim que os documentos forem enviados."}
            </InstructionsText>
            <br/>
            <ResultTimeInfoCard inquiryType={EInquiryType.VideoOnboarding}></ResultTimeInfoCard>
            <Button
                variant="outlined"
                className="button button-new-onboarding"
                onClick={props.onNewOnboarding}
            >
                {isPayrollOnboarding
                    ? "Novo Onboarding Consignado"
                    : "Novo onboarding por vídeo"}
            </Button>
            <Button
                variant="outlined"
                className="button button-return-menu"
                onClick={props.onReturnMenu}
            >
                Retornar ao menu
            </Button>
        </div>
    );
};

export default LinkSent;
