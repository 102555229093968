import { List, ListItem, Stack, Typography } from "@mui/material";
import Routes from "../../../constants/Routes";
import { UserProfileType } from "../../../shared/models/user-profile-type";
import MenuGroup from "../components/Item/menuGroup";
import { MenuOption } from "../components/Section/menuOption";
const AnyRole: UserProfileType[] = [
  "ADMCONTA",
  "Financial",
  "GeneralInquirer",
  "Inquirer",
];
export const inquiryMenuOptions: MenuOption[] = [
  {
    iconName: "document",
    text: "Documento",
    multiOption: [
      {
        iconName: "video-outline",
        text: "Análise manual",
        route: Routes.Inquiries.Document,
        hint: "Análise realizada por uma mesa especializada em documentoscopia. Os documentos de identificação analisados são RG e CNH.",
      },
      {
        iconName: "video-outline",
        text: "Digital híbrida",
        route: Routes.Inquiries.DocumentAutomatic,
        hint: "Análise digital realizada por um software. Nos casos de dúvida do software, direcionamento para uma mesa especializada em documentoscopia. Os documentos de identificação analisados são RG e CNH físicos. (documentos digitais não são permitidos).",
      },
    ],
    roles: AnyRole,
  },
  {
    iconName: "id-card",
    text: "Documento + Selfie",
    route: Routes.Inquiries.DocumentWithSelfie,
    multiOption: [
      {
        iconName: "video-outline",
        text: "Análise manual",
        route: Routes.Inquiries.DocumentWithSelfie,
        hint: "Análise realizada por uma mesa especializada em documentoscopia, de qualquer documento de identificação aceito nacionalmente. + comparativo da foto do documento com a foto da selfie, através de nosso facematch.",
      },
      {
        iconName: "video-outline",
        text: "Digital híbrida",
        route: Routes.Inquiries.DocumentWithSelfieAutomatic,
        hint: "Análise digital realizada por um software. Nos casos de dúvida do software, direcionamento para uma mesa especializada em documentoscopia. Análise de qualquer documento de identificação aceito nacionalmente. + comparativo da foto do documento com a foto da selfie, através de nosso facematch.",
      },
    ],
    roles: AnyRole,
  },
  {
    iconName: "phone",
    text: "Consulta por Telefone",
    route: Routes.Inquiries.Phone,
    roles: AnyRole,
    hint: "Análise de risco do número do telefone e retorno de informações cadastrais do número, disponíveis nas operadoras.",
  },
  {
    iconName: "envelope",
    text: "Consulta por E-mail",
    route: Routes.Inquiries.Email,
    roles: AnyRole,
    hint: "Análise de risco do e-mail, baseado nos dados da solução emailage de nosso parceito LexisNexis.",
  },
  {
    iconName: "video-outline",
    text: "Onboarding por Vídeo",
    multiOption:
      process.env.REACT_APP_ENV != "PROD"
        ? [
            {
              iconName: "video-outline",
              text: "Geral (Vídeo + Doc)",
              route: Routes.Inquiries.VideoOnboardingDoc,
              hint: (
                <Stack>
                  <Typography>
                    Experiência de captura do vídeo para formalização digital +
                    análise do vídeo em 5 camadas + documentoscopia híbrida.
                  </Typography>
                  <List dense>
                    <ListItem>• Análise do vídeo</ListItem>
                    <List dense sx={{ paddingLeft: "16px" }}>
                      <ListItem>• Conversão da voz em texto</ListItem>
                      <ListItem>
                        • Geração e identificação da palavra chave
                      </ListItem>
                      <ListItem>• Análise de manipulação do vídeo</ListItem>
                      <ListItem>
                        • Extração do melhor frame do vídeo (selfie do vídeo)
                      </ListItem>
                      <ListItem>
                        • Facematch do melhor frame com a foto do documento
                      </ListItem>
                    </List>
                  </List>
                </Stack>
              ),
            },
            {
              iconName: "video-outline",
              text: "Geral (Vídeo)",
              route: Routes.Inquiries.VideoOnboardingNoDoc,
              hint: (
                <Stack>
                  <Typography>
                    Experiência de captura do vídeo para formalização digital +
                    análise do vídeo em 5 camadas
                  </Typography>
                  <List dense>
                    <ListItem>• Análise do vídeo</ListItem>
                    <List dense sx={{ paddingLeft: "16px" }}>
                      <ListItem>• Conversão da voz em texto</ListItem>
                      <ListItem>
                        • Geração e identificação da palavra chave
                      </ListItem>
                      <ListItem>• Análise de manipulação do vídeo</ListItem>
                      <ListItem>
                        • Extração do melhor frame do vídeo (selfie do vídeo)
                      </ListItem>
                      <ListItem>
                        • Facematch do melhor frame com a foto do documento
                      </ListItem>
                    </List>
                  </List>
                </Stack>
              ),
            },
            {
              iconName: "video-outline",
              text: "Consignado (Vídeo + Doc)",
              route: Routes.Inquiries.PayrollLoanVideoOnboarding,
              hint: (
                <Stack>
                  <Typography>
                    Experiência de captura do vídeo para formalização digital
                    adaptado para o contexto do empréstimo consignado + análise
                    do vídeo em 5 camadas + documentoscopia híbrida.
                  </Typography>
                  <List dense>
                    <ListItem>
                      • Contexto do consignado com as opções de:
                    </ListItem>
                    <List dense sx={{ paddingLeft: "16px" }}>
                      <ListItem>• Representante legal</ListItem>
                      <ListItem>• Multiplos contratos</ListItem>
                      <ListItem>• Dados bancários</ListItem>
                      <ListItem>• Texto complementar</ListItem>
                    </List>
                    <ListItem>• Análise do vídeo</ListItem>
                    <List dense sx={{ paddingLeft: "16px" }}>
                      <ListItem>• Conversão da voz em texto</ListItem>
                      <ListItem>
                        • Geração e identificação da palavra chave
                      </ListItem>
                      <ListItem>• Análise de manipulação do vídeo</ListItem>
                      <ListItem>
                        • Extração do melhor frame do vídeo (selfie do vídeo)
                      </ListItem>
                      <ListItem>
                        • Facematch do melhor frame com a foto do documento
                      </ListItem>
                    </List>
                  </List>
                </Stack>
              ),
            },
          ]
        : [
            {
              iconName: "video-outline",
              text: "Geral",
              route: Routes.Inquiries.VideoOnboardingNoDoc,
            },
          ],
    roles: AnyRole,
  },
  {
    iconName: "calendar",
    text: "Histórico de consultas",
    route: Routes.Inquiries.List,
    roles: AnyRole,
  },
];

export const reportsMenuOptions: MenuOption[] = [
  {
    iconName: "chart",
    text: "Relatório de performance",
    route: Routes.Reports.Performance,
    roles: AnyRole,
  },
  {
    iconName: "piggybank",
    text: "Extrato financeiro",
    route: Routes.Reports.Financial,
    roles: ["ADMCONTA", "Financial"],
  },
  {
    iconName: "download",
    text: "Baixar resultados de consultas",
    route: Routes.Reports.DownloadInquiriesResults,
    roles: AnyRole,
  },
];

export const configurationsMenuOptions: MenuOption[] = [
  {
    iconName: "manage-unities",
    text: "Gerenciar Unidades",
    route: Routes.Unities.List,
    roles: ["ADMCONTA"],
  },
  {
    iconName: "user-cog",
    text: "Gerenciar Usuários",
    route: Routes.User.List,
    roles: ["ADMCONTA"],
  },
  {
    iconName: "credit-card",
    text: "Planos / Pagamento",
    route: Routes.SettingsPlans,
    roles: ["ADMCONTA", "Financial"],
  },
];

export const accountMenuOptions: MenuOption[] = [
  {
    iconName: "address-card",
    text: "Dados da conta",
    route: Routes.Account.Profile,
    roles: AnyRole,
  },
  {
    iconName: "key",
    text: "Alterar senha",
    route: Routes.Account.ChangePassword,
    roles: AnyRole,
  },
];

export const logoutMenuOption: MenuGroup = {
  iconName: "logout",
  options: [],
  label: "Sair do app",
};
