import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import {LinkUserOption, LinkUserOptionsContainer, LinkUserOptionText, SearchBarContainer} from "../styles";
import Typography from "../../../components/Typography";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useUnitService} from "../../../shared/services/useUnitService";
import Input from "../../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";
import SubSection from "../../../components/SubSection";
import {Unity} from "../../../shared/models/unity";
import useAccountService from "../../../shared/services/useAccountService";
import {AccountUserFilter} from "../../../shared/models/requests/account-user-filter";
import {UserDto} from "../../../shared/models/user-dto";
import {toast, ToastContainer} from "react-toastify";
import UnityUsersTable from "../components/UnityUsersTable";
import {CircularProgress} from "@mui/material";

const LinkUsersToUnityScreen = () => {
    const {id} = useParams<{ id: string }>();
    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState<AccountUserFilter>({search: ''});
    const [userToLinkOptions, setUserToLinkOptions] = React.useState<UserDto[]>([]);
    const [unity, setUnity] = React.useState<Unity>();
    const {getUsersFromUnity, linkUserToUnity, getUnityById} = useUnitService();
    const {getAllUsers} = useAccountService();

    const getUnlinkedUsers = async () => {
        const unlinkedPromise = getAllUsers({
            ...filter,
            excludedUnities: id.toString()
        });
        const linkedPromise = getUsersFromUnity(parseInt(id));
        const [unlinked, linked] = await Promise.all([unlinkedPromise, linkedPromise]);
        const allUsersWithoutLinked = unlinked.filter(u => !linked.find(lu => lu.id === u.id));
        setUserToLinkOptions(allUsersWithoutLinked);
    };
    useEffect(() => {
        const getUnity = async () => setUnity(await getUnityById(parseInt(id)));
        getUnity();
    }, [id]);

    useEffect(() => {
        getUnlinkedUsers();
    }, [filter]);

    const linkUser = async (userId: number) => {
        try {
            setLoading(true)
            await linkUserToUnity(parseInt(id), userId)
            toast.success('Usuário vinculado com sucesso!');
            setFilter({...filter, search: ''})
        } finally {
            setLoading(false)
        }
    }

    return <Layout>
        <SubHeader title={"Vincular usuário"} subtitle={''}/>
        <SubSection>
            <Typography variant="caption3">
                {unity?.name && `Unidade: ${unity?.name}`}
            </Typography>
            <Typography variant="caption">
                {'Utilize o campo de busca para selecionar um ou mais usuários'}
            </Typography>
        </SubSection>
        <SearchBarContainer>
            <></>
            <Input startAdornment={<SearchIcon/>}
                   placeholder={'Buscar Nome, CNPJ, E-mail, Telefone...'}
                   fullWidth
                   value={filter.search}
                   onChange={(search) => setFilter({...filter, search})}
            />
            {filter.search && <LinkUserOptionsContainer>
                {
                    userToLinkOptions?.map((user, k) =>
                        <LinkUserOption key={k}
                                        onClick={() => linkUser(user.id)}>
                            <LinkUserOptionText>{user.name}</LinkUserOptionText>
                        </LinkUserOption>)}
            </LinkUserOptionsContainer>}
        </SearchBarContainer>
        <SubSection>
            {loading ? <CircularProgress color="primary" /> : <UnityUsersTable unityId={parseInt(id)} onUpdate={getUnlinkedUsers}/>}
        </SubSection>
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            theme="colored"
        />
    </Layout>
}
export default LinkUsersToUnityScreen;