import Typography from "../../../../../../components/Typography";
import DescriptionTextWithLabel from "./descriptionTextWithLabel";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {ContractContainerDiv, FlexDiv, IconContainer, Row} from "./styles";
import PropTypes from "prop-types";
import {FormatUtils} from "../../../../../../shared/utils/format-utils";
import {Contract} from "../../../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";

interface ContractContainerProps {
    contract: Contract;
    onEdit: (contract: Contract) => void;
    onDelete?: (contract: Contract) => void;
}

const ContractContainer = ({
                               contract,
                               onEdit,
                               onDelete,
                           }: ContractContainerProps) => {
    return (
        <ContractContainerDiv>
            <IconContainer>
                <EditIcon onClick={() => onEdit(contract)} color="primary"/>
                {onDelete && <DeleteIcon onClick={() => onDelete(contract)} color="error"/>}
            </IconContainer>
            <FlexDiv>
                <Typography variant={"caption5"}>
                    Contrato Número {contract.contractNumber}
                </Typography>
            </FlexDiv>
            <Row>
                <DescriptionTextWithLabel
                    title="Valor"
                    value={FormatUtils.toBRLCurrency(contract.value)}
                />
                <DescriptionTextWithLabel
                    title="Valor das parcelas"
                    value={FormatUtils.toBRLCurrency(contract.installmentsValue)}
                />
                <DescriptionTextWithLabel
                    title="Parcelas"
                    value={contract.installments.toString()}
                />
                <DescriptionTextWithLabel title="Banco" value={contract.bankName}/>
                <DescriptionTextWithLabel
                    title="Data do contrato"
                    value={contract.contractDate}
                />
            </Row>
        </ContractContainerDiv>
    );
};
ContractContainer.propTypes = {
    contract: PropTypes.shape({
        contractNumber: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        installmentsValue: PropTypes.number.isRequired,
        installments: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        contractDate: PropTypes.string.isRequired,
    }).isRequired,
};

export default ContractContainer;
