import axios from "axios";
import {useContext, useMemo} from "react";
import {IPagarMeApiContext} from "../interfaces/IPagarMeApiContext";
import PagarMeApiContext from "../pagarme-api-context";
import {PagarmeInterceptors} from "../../interceptors/pagarme-interceptors";
import {useConsultaiAppContext} from "./useConsultaiApp";


const usePagarMeApi = (): IPagarMeApiContext => {
    const context = useContext(PagarMeApiContext);
    const {setLoading} = useConsultaiAppContext();
    const API_CONFIG = useMemo(() => ({
        timeout: context?.timeout ?? 60000,
        baseURL: context?.baseUrl,
        headers: {
            Authorization: `Basic ${context?.basicToken}`,
            'Content-Type': 'application/json',
        }
    }), [context?.timeout, context?.baseUrl, context?.basicToken]);
    const apiClient = axios.create(API_CONFIG);
    const interceptors = PagarmeInterceptors();
    const applyInterceptors = () => {
        apiClient.interceptors.request.use((c) => interceptors.SEND_INTERCEPTOR(c, context?.enableLogs, context?.env));
        apiClient.interceptors.response.use(interceptors.ON_FULFILLED_INTERCEPTOR, (e) => {
            interceptors.ON_REJECTED_INTERCEPTOR(e, context?.enableLogs, context?.env).catch((error) => {
                context?.onError && context?.onError(error.message)
            });
        });
    }

    const makeRequest = async <R>(method: 'get' | 'post' | 'put' | 'patch' | 'delete', url: string, version = 1, data?: any): Promise<R> => {
        try {
            applyInterceptors();
            setLoading(true);
            const fullUrl = `v${version}/${url}`;
            let response;
            switch (method) {
                case 'get':
                    response = await apiClient[method]<R>(fullUrl);
                    break;
                case 'delete':
                    response = await apiClient[method]<R>(fullUrl, {data});
                    break;
                case 'post':
                case 'put':
                case 'patch':
                    response = await apiClient[method]<R>(fullUrl, data);
                    break;
            }
            setLoading(false);
            return response?.data
        } catch {
            throw new Error('Erro ao obter dados da API');
        } finally {
            setLoading(false);
        }
    };

    const post = <R>(url: string, version = 1, data?: any) => makeRequest<R>('post', url, version, data);
    const get = <R>(url: string, version = 1) => makeRequest<R>('get', url, version);
    const put = <R>(url: string, version = 1, data?: any) => makeRequest<R>('put', url, version, data);
    const patch = <R>(url: string, version = 1, data?: any) => makeRequest<R>('patch', url, version, data);
    const remove = <R>(url: string, version = 1) => makeRequest<R>('delete', url, version);

    return {
        onLogout: context?.onLogout,
        basicToken: context?.basicToken,
        setBasicToken: context?.setBasicToken,
        env: context?.env,
        baseUrl: context?.baseUrl,
        enableLogs: context?.enableLogs,
        onError: context?.onError,
        onWarn: context?.onWarn,
        onInfo: context?.onInfo,
        timeout: context?.timeout,
        setBaseUrl: context?.setBaseUrl,
        setEnv: context?.setEnv,
        setEnableLogs: context?.setEnableLogs,
        setTimeout: context?.setTimeout,
        post,
        get,
        put,
        patch,
        delete: remove,
        instance: apiClient
    };
};

export default usePagarMeApi;