import { Box, Button } from "@mui/material";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  BoldTypography,
  MailingList,
  MailingListItem,
  MailingTextTypography,
  OnboardingCardLowRadius,
  TitleExtraBoldTypography,
} from "../styles";

const VideoRecordInstructionsStep = ({ setActiveStep }: MailingStepProps) => {
  const theme = useThemeWhiteLabel();

  return (
    <ContainerContent centered>
      <OnboardingCardLowRadius sx={{ maxWidth: 1094 }}>
        <Box maxWidth={803}>
          <TitleExtraBoldTypography
            variant="h1"
            color={theme.primaryColor}
            align="center"
          >
            Agora, precisamos que você grave um vídeo. Isso é importante para
            confirmar sua identidade.
          </TitleExtraBoldTypography>
          <MailingTextTypography fontWeight={600} mb="24px">
            Instuções para gravar o vídeo:
          </MailingTextTypography>
          <MailingList>
            <MailingListItem>
              Você deverá gravar um vídeo lendo todas as palavras exibidas na
              tela a seguir.
            </MailingListItem>
            <MailingListItem>
              Na tela a seguir, clique no botão{" "}
              <BoldTypography>“Iniciar Gravação”</BoldTypography> e leia o texto
              de forma atenta e sem pressa.
            </MailingListItem>
            <MailingListItem>
              Não se preocupe se errar alguma palavra, você poderá tentar
              quantas vezes forem necessárias.
            </MailingListItem>
            <MailingListItem>
              Para gravar o vídeo, é recomendado remover óculos e chapéus.
            </MailingListItem>
          </MailingList>
        </Box>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => setActiveStep(EMailingStepsEnum.VideoRecording)}
        >
          Continuar
        </Button>
      </OnboardingCardLowRadius>
    </ContainerContent>
  );
};

export default VideoRecordInstructionsStep;
