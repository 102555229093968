import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import SubSection from "../../../components/SubSection";
import React, {useContext, useEffect, useState} from "react";
import UnityRegistrationDataForm from "../components/UnityRegistrationDataForm";
import UnityAddressForm from "../components/UnityAddressForm";
import UnityPasswordForm from "../components/UnityPasswordForm";
import {useUnitService} from "../../../shared/services/useUnitService";
import {AddNewUnityToAccountRequest} from "../../../shared/models/requests/unities/add-unity-to-account-request";
import {useNavigate} from "react-router-dom";
import Routes from "../../../constants/Routes";
import Steps from "../../CreateAccount/components/Steps";
import {FormContainer} from "../styles";
import {ENewUnityFormStep} from "../../../shared/models/enums/e-new-unity-form.step";
import {toast} from "react-toastify";
import useUserService from "../../../shared/services/useUserService";
import {useSubscriptionService} from "../../../shared/services/useSubscriptionService";
import {AccountContext} from "../../../shared/context/AccountContext";
import {useAuthenticationService} from "../../../shared/services/useAuthenticationService";

export interface NewUnityScreenFormStepProps {
    form: Partial<AddNewUnityToAccountRequest>;
    setForm: (form: Partial<AddNewUnityToAccountRequest>) => void;
    onNext: () => void;
    onBack: () => void;
    currentStep: ENewUnityFormStep;
}

const NewUnityScreen = () => {
    const navigate = useNavigate();
    const [step, setStep] = React.useState<ENewUnityFormStep>(ENewUnityFormStep.RegistrationData);
    const [form, setForm] = useState<Partial<AddNewUnityToAccountRequest>>();
    const {createUnityInAccount} = useUnitService();
    const {requestLoginUnity} = useAuthenticationService();
    const accountContext = useContext(AccountContext);
    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {buscarSaldo} = useUserService();
    const stepConfig = {
        form,
        setForm,
        onNext: () => setStep(step + 1),
        onBack: () => step === ENewUnityFormStep.RegistrationData ? navigate(Routes.Home) : setStep(step - 1),
        currentStep: step
    }
    useEffect(() => {
        if (step === ENewUnityFormStep.Send) {
            createUnityInAccount(form as AddNewUnityToAccountRequest)
                .then(() => {
                    toast.success('Unidade criada com sucesso.')
                    updateSession().then(() => navigate(Routes.Unities.List))
                })
                .catch(() => setStep(ENewUnityFormStep.Password))
        }
    }, [step]);
    const updateSession = async () => {
        const unitId = accountContext.user.user.currentUnityId
        const sessionUser = await requestLoginUnity(unitId);
        accountContext.setIds({...accountContext.ids, unitId});
        accountContext.setUser(sessionUser);
        const subscription = await buscarAssinaturaAtiva(unitId);
        accountContext.setSubscription(subscription);
        accountContext.setAccount({...accountContext.account, saldo: (await buscarSaldo(unitId)).saldo})
        if (subscription.plano !== null) accountContext.setPlan(subscription.plano);
    }
    return (
        <Layout>
            <>
                <SubHeader title={"Gerenciar Unidades"} subtitle={"Nova Unidade"}/>
                <SubSection>
                    <FormContainer>
                        <Steps currentStep={step - 1}/>
                        <UnityRegistrationDataForm {...stepConfig}/>
                        <UnityAddressForm {...stepConfig}/>
                        <UnityPasswordForm {...stepConfig}/>
                    </FormContainer>
                </SubSection>
            </>
        </Layout>
    );
}
export default NewUnityScreen;