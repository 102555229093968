import {AccountContextType} from "../context/AccountContext";

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_USER = 'SET_USER';
export const SET_PLAN = 'SET_PLAN';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_IDS = 'SET_IDS';

export const accountReducer = (state: AccountContextType, action): AccountContextType => {
  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, account: action.payload };
    case SET_ADDRESS:
      return { ...state, address: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_PLAN:
      return { ...state, plan: action.payload };
    case SET_SUBSCRIPTION:
      return { ...state, subscription: action.payload };
    case SET_IDS:
      return { ...state, ids: action.payload };
    default:
      return state;
  }
};
