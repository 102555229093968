import ConsultaiApiContext from "../consultai-api-context";
import {IConsultaiApiContext} from "../interfaces/IConsultaiApiContext";
import axios, {CreateAxiosDefaults} from "axios";
import {useContext, useEffect} from "react";
import {ConsultaiInterceptors} from "../../interceptors/consultai-interceptors";
import RequestError from "../../../models/services/common/request-error";
import {AuthenticationResponse} from "../../../models/services/responses/AuthenticationResponse";
import {useConsultaiAppContext} from "./useConsultaiApp";


const useConsultaiApi = (): IConsultaiApiContext => {
    const context = useContext(ConsultaiApiContext);
    const {setLoading} = useConsultaiAppContext();
    const API_CONFIG: CreateAxiosDefaults = {
        timeout: context?.timeout ?? 60000,
        baseURL: context?.baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    const apiClient = axios.create(API_CONFIG);
    const refreshTokenClient = axios.create({
        ...API_CONFIG,
        headers: {...API_CONFIG.headers, Authorization: `Bearer ${context?.accessToken}`}
    });
    const interceptors = ConsultaiInterceptors();
    const onRefreshToken = async (): Promise<string> => {
            const newAccessToken = (await refreshTokenClient.post<AuthenticationResponse>(`v${1}/autenticacao/refresh-token?refreshToken=${context?.refreshToken}`))?.data?.accessToken;
            context?.setAccessToken(newAccessToken);
            return `Bearer ${newAccessToken}`;
        }
    ;
    useEffect(() => {
        apiClient.interceptors.request.use((config) => interceptors.SEND_INTERCEPTOR(config, context?.accessToken, context?.env, context?.enableLogs));
        apiClient.interceptors.response.use(interceptors.ON_FULFILLED_INTERCEPTOR,
            (e) => interceptors.ON_REJECTED_INTERCEPTOR(e, context?.enableLogs, context?.env, onRefreshToken)
                .catch((error: RequestError) => onRequestError(error)));
    }, [context])
    const onRequestError = (error: RequestError) => {
        console.log({error})
        let message = error?.message || 'Ocorreu um erro';
        if (message.includes('Exception')) message = 'Verifique os dados informados e tente novamente';
        context?.onError && context?.onError(message);
        if (!error?.shouldLogout) return;
        context?.setAccessToken(undefined);
        context?.setRefreshToken(undefined);
        context?.onError('Sessão expirada, faça login novamente.')
        context?.onLogout();
    }
    const makeRequest = async <R>(method: 'get' | 'post' | 'put' | 'patch' | 'delete', url: string, version = 1, data?: any): Promise<R> => {
        try {
            setLoading(true);
            const fullUrl = `v${version}${url}`;
            const config = {
                timeout: context?.timeout ?? 60000,
                baseURL: context?.baseUrl,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${context?.accessToken}`
                }
            };
            let response;
            switch (method) {
                case 'get':
                    response = await apiClient[method]<R>(fullUrl, {...config, params: data});
                    break;
                case 'delete':
                    response = await apiClient[method]<R>(fullUrl, {...config, data});
                    break;
                case 'post':
                case 'put':
                case 'patch':
                    response = await apiClient[method]<R>(fullUrl, data, config);
                    break;
            }
            setLoading(false);
            return response?.data
        } catch (e) {
            onRequestError(new RequestError(e?.response?.data, e?.response?.status));
            return Promise.reject(new RequestError(e?.response?.data, e?.response?.status))
        } finally {
            setLoading(false);
        }
    };

    const post = <R>(url: string, version = 1, data?: any) => makeRequest<R>('post', url, version, data);
    const get = <R>(url: string, version = 1, params?: any) => makeRequest<R>('get', url, version, params);
    const put = <R>(url: string, version = 1, data?: any) => makeRequest<R>('put', url, version, data);
    const patch = <R>(url: string, version = 1, data?: any) => makeRequest<R>('patch', url, version, data);
    const remove = <R>(url: string, version = 1, data?: any) => makeRequest<R>('delete', url, version, data ?? {});

    return {
        instance: apiClient,
        onLogout: context?.onLogout,
        accessToken: context?.accessToken,
        setAccessToken: context?.setAccessToken,
        refreshToken: context?.refreshToken,
        setRefreshToken: context?.setRefreshToken,
        env: context?.env,
        baseUrl: context?.baseUrl,
        enableLogs: context?.enableLogs,
        onError: context?.onError,
        onWarn: context?.onWarn,
        onInfo: context?.onInfo,
        timeout: context?.timeout,
        setBaseUrl: context?.setBaseUrl,
        setEnv: context?.setEnv,
        setEnableLogs: context?.setEnableLogs,
        setTimeout: context?.setTimeout,
        post,
        get,
        put,
        patch,
        delete: remove,
    };
};

export default useConsultaiApi;