import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import CheckBox from "../../../../components/CheckBox";
import { TooltipHint } from "../../../../components/TooltipHint";
import Typography from "../../../../components/Typography";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";
import InquiryPriceAndBalanceCards from "../../components/InquiryPriceAndBalanceCards";

const OnBoardingType = ({
  onNext,
  setPresential,
  inquiryPrice,
  presential,
  hint,
}) => {
  const [inLive, setInlive] = useState(false);
  const [remote, setRemote] = useState(false);

  const handleWhatsAppCheck = () => {
    setRemote(!remote);
    if (!remote) {
      setInlive(false);
    }
    setPresential(remote ? null : false);
    onNext();
  };
  const handlePresentialCheck = () => {
    setInlive(!inLive);
    if (!inLive) {
      setRemote(false);
    }
    setPresential(inLive ? null : true);
    onNext();
  };
  const theme = useThemeWhiteLabel();
  return (
    <div>
      <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice} />
      <Box mt={3} mb={5}>
        <Stack direction="row" alignItems="center" gap="4px">
          <Typography variant="caption">
            Para Iniciar o processo de onboarding de vídeo, selecione o tipo a
            ser realizado:
          </Typography>
          {hint && <TooltipHint withAdjustment hint={hint} />}
        </Stack>

        <Typography variant="caption3">Selecione o Tipo</Typography>
      </Box>
      <Box mt={2} mb={2}>
        <CheckBox
          primaryColor={theme.primaryColor}
          label={"Onboarding por WhatsApp"}
          onPress={handleWhatsAppCheck}
          isChecked={remote}
        />
      </Box>
      <Box mb={2}>
        <CheckBox
          primaryColor={theme.primaryColor}
          label={"Onboarding Presencial"}
          onPress={handlePresentialCheck}
          isChecked={inLive}
        />
      </Box>
    </div>
  );
};
OnBoardingType.propTypes = {
  onNext: PropTypes.func.isRequired,
  setPresential: PropTypes.func.isRequired,
  inquiryPrice: PropTypes.number.isRequired,
  presential: PropTypes.bool.isRequired,
  whatsapp: PropTypes.bool.isRequired,
  hint: PropTypes.any,
};

export default OnBoardingType;
