import styled from "styled-components";

export const CheckboxLabel = styled.label`
  display: flex;
  padding-block: 6px;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + span {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11' width='14' height='11'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8532 2.27709C13.0095 2.43334 13.0876 2.62307 13.0876 2.84629C13.0876 3.0695 13.0095 3.25923 12.8532 3.41548L6.79297 9.47573L5.65458 10.6141C5.49833 10.7704 5.30859 10.8485 5.08538 10.8485C4.86217 10.8485 4.67244 10.7704 4.51619 10.6141L3.37779 9.47573L0.347656 6.4456C0.191406 6.28935 0.113281 6.09962 0.113281 5.8764C0.113281 5.65319 0.191406 5.46346 0.347656 5.30721L1.48605 4.16881C1.6423 4.01256 1.83203 3.93444 2.05525 3.93444C2.27846 3.93444 2.46819 4.01256 2.62444 4.16881L5.08538 6.63812L10.5764 1.13867C10.7327 0.982422 10.9224 0.904297 11.1456 0.904297C11.3688 0.904297 11.5586 0.982422 11.7148 1.13867L12.8532 2.27709Z' fill='black'/%3E%3C/svg%3E");
    }
  }

  .checkbox-label {
    position: relative;
    top: 2px;
  }
`;

export const CheckboxMark = styled.span`
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  margin-right: 4px;
  transition: background-color 0.3s, border-color 0.3s;
  background-image: unset;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
`;
