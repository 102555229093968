import styled from "styled-components";

export const StyledDivButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    right: 20px;
    margin-right: 20px;
`;
