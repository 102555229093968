import { Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../components/Input/Input";
import { TooltipHint } from "../../../../components/TooltipHint";
import { AccountContext } from "../../../../shared/context/AccountContext";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import { usePlanService } from "../../../../shared/services/usePlanService";
import { useSubscriptionService } from "../../../../shared/services/useSubscriptionService";
import {
  FormContainer,
  HorizontalButtonsContainer,
  InstructionsText,
  ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";
import { ValidationUtils } from "../../../../utils/validation-utils";
import InquiryPriceAndBalanceCards from "../InquiryPriceAndBalanceCards";

interface PhoneInquiryFormProps {
  onNext: () => void;
  phone: string;
  setPhone: (cpf: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const PhoneInquiryForm = ({ ...props }: PhoneInquiryFormProps) => {
  const [inquiryPrice, setInquiryPrice] = useState<number>();
  const { ids } = useContext(AccountContext);
  const { buscarAssinaturaAtiva } = useSubscriptionService();
  const { getProductPrices } = usePlanService();
  useEffect(() => {
    props.setLoading(true);
    buscarAssinaturaAtiva(ids.unitId)
      .then((subscription) => {
        getProductPrices(subscription.planoId).then((prices) => {
          const price = prices?.find(
            (price) => price.type == EInquiryType.Telefone
          )?.price;
          setInquiryPrice(price);
          if (!price) {
            toast.error("Seu plano atual não permite esse tipo de consulta.", {
              autoClose: false,
              closeButton: true,
              toastId: "no-price-error",
            });
          }
        });
      })
      .finally(() => props.setLoading(false));
  }, [ids]);
  return (
    <>
      <ScreenWithSubHeaderContainer>
        <>
          <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice} />
          <InstructionsText>
            {
              "Para fazer uma consulta por telefone, basta preencher o número desejado no campo abaixo."
            }

            <TooltipHint
              hint={
                <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
                  Análise de risco do número do telefone e retorno de
                  informações cadastrais do número, disponíveis nas operadoras.
                  <br /> <br />
                  O que esperar como resultado?
                  <br />
                  Um score de probabilidade de 0 a 100 do telefone está ou não
                  envolvido em uma fraude. Quanto mais próximo de ZERO é risco
                  de envolvimento em fraude, quanto mais próximo de CEM é um bom
                  telefone. O score é organizado por faixas de classificação,
                  onde recomendamos fraude na parte vermelha, bom telefone na
                  parte verde e inconclusivo na parte amarela. Para os casos de
                  pouca informação na base de dados, o resulta será
                  inconclusivo.
                  <br />
                  + status de tráfego de ligações nas operadoras nos últimos 90
                  dias
                  <br />
                  + informações da linha
                  <br />
                  + dados cadastrais do proprietário da linha, disponíveis nas
                  operadoras
                  <br /> <br />
                  Para fazer uma consulta por telefone, basta preencher o número
                  desejado no campo abaixo.
                </Typography>
              }
            />
          </InstructionsText>
          <FormContainer>
            <Input
              placeholder="Número *"
              mask="(99) 99999-9999"
              onChange={props.setPhone}
              value={props.phone}
              required
              validationCallback={ValidationUtils.validatePhone}
              error={"Telefone inválido"}
              label={"Número"}
            />
            <HorizontalButtonsContainer>
              <Button
                variant={"contained"}
                color={"primary"}
                disabled={!inquiryPrice || props.loading || !props.phone}
                onClick={props.onNext}
              >
                {"Solicitar Consulta"}
              </Button>
            </HorizontalButtonsContainer>
          </FormContainer>
        </>
      </ScreenWithSubHeaderContainer>
    </>
  );
};
export default PhoneInquiryForm;
