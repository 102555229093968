import React from 'react';
import {Step, StepLabel, Stepper} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {StepIconProps} from '@mui/material/StepIcon';
import {Palette} from "../../shared/theme/palette";
import {EDocumentInquiryStep} from "../../screens/Inquiries/DocumentInquiry";
import {DocumentType} from "../../shared/models/document-type";
import {ColorlibConnector, ColorlibStepIconRoot, StepperContainer} from "./styles";

interface ProgressStepperProps {
    currentStep: EDocumentInquiryStep;
    docType: DocumentType
    withSelfie?: boolean;
}

const ProgressStepper = ({currentStep, docType, withSelfie}: ProgressStepperProps) => {
    const getCurrentStep = () => {
        if (docType == 'cnh') {
            switch (currentStep) {
                case EDocumentInquiryStep.FrontPhoto:
                    return 0;
                case EDocumentInquiryStep.SelfiePhoto:
                    return 1;
            }
        }
        if (docType == 'rg') {
            switch (currentStep) {
                case EDocumentInquiryStep.FrontPhoto:
                    return 0;
                case EDocumentInquiryStep.BackPhoto:
                    return 1;
                case EDocumentInquiryStep.SelfiePhoto:
                    return 2;
            }
        }
    }
    const getSteps = () => {
        let steps = [];
        if (docType == 'cnh') {
            steps = ['1 - Tirar Foto']
            if(!withSelfie) return steps;
                return [...steps, '2 - Selfie']
        }
        if (docType == 'rg') {
            steps = ['1 - Foto Frente', '2 - Foto Verso']
            if(!withSelfie) return steps;
            return [...steps, '3 - Selfie']
        }
    }
    function renderStepIcons(props: StepIconProps) {
        const {active, completed, className} = props;
        const icons: { [index: string]: React.ReactElement } = {
            1: <PhotoCameraIcon style={{ color: Palette.white }} />,
            2: <PhotoCameraIcon style={{ color: Palette.white }} />,
            3: <PhotoCameraIcon style={{ color: Palette.white }} />,
        };
        return (
            <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }
    return (
        <StepperContainer>
            <Stepper alternativeLabel activeStep={getCurrentStep()} connector={<ColorlibConnector/>}>
                {getSteps()?.map((step) => (
                    <Step style={{minWidth: getSteps()?.length == 2 ? 300 : 150}} key={step} >
                        <StepLabel StepIconComponent={(props) => renderStepIcons(props)}>{step}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </StepperContainer>
    )
}
export default ProgressStepper;