import { useState } from "react";
import { Button, Grid } from "@mui/material";
import CheckBox from "../CheckBox";
import Input from "../Input/Input";
import AcceptedCards from "./AcceptedCards";
import CardFormProps from "./CardFormProps";
import CreateCardData from "./CreateCardData";
import { ValidationUtils } from "../../utils/validation-utils";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

const CardForm = (props: CardFormProps) => {
  const [card, setCard] = useState<CreateCardData>(props.card);

  const onNext = () => {
    const isValid =
      ValidationUtils.validateCardNumber(card?.cardNumber, true) &&
      ValidationUtils.validateCardDueDate(card?.cardDueDate, true) &&
      ValidationUtils.validateCardHolderName(card?.cardHolderName, true) &&
      ValidationUtils.validateCardCvv(card?.cardCvv, true) &&
      ValidationUtils.validateCpfCnpj(card?.cardHolderDocument, true);

    isValid && props.onNext(card);
  };

  const nextButtonEnabled =
    card &&
    card.cardNumber &&
    card.cardExpirationMonth &&
    card.cardExpirationYear &&
    card.cardHolderName &&
    card.cardHolderDocument &&
    card.cardName;
  const theme = useThemeWhiteLabel();
  return (
    <>
      <p>
        No momento aceitamos somente cartões de crédito como forma de pagamento.
      </p>
      <AcceptedCards />
      <Input
        fullWidth
        value={card?.cardNumber ?? ""}
        onChange={(value) => setCard({ ...card, cardNumber: value })}
        placeholder="Número do Cartão"
        maxLength={19}
        mask="9999 9999 9999 9999"
        isNormal
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Input
            fullWidth
            value={card?.cardDueDate ?? ""}
            onChange={(value) =>
              setCard({
                ...card,
                cardDueDate: value,
                cardExpirationMonth: Number(value?.split("/")[0]),
                cardExpirationYear: Number(value?.split("/")[1]),
              })
            }
            placeholder="Validade"
            maxLength={5}
            mask="99/99"
            isNormal
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            fullWidth
            value={card?.cardCvv}
            onChange={(value) => setCard({ ...card, cardCvv: value })}
            placeholder="CVV"
            maxLength={4}
            mask="999"
            isNormal
          />
        </Grid>
      </Grid>
      <Input
        fullWidth
        value={card?.cardHolderName}
        onChange={(value) => setCard({ ...card, cardHolderName: value })}
        placeholder="Nome do Titular"
        isNormal
      />
      <Input
        fullWidth
        value={card?.cardHolderDocument ?? ""}
        onChange={(value) => setCard({ ...card, cardHolderDocument: value })}
        placeholder="CPF/CNPJ do Titular"
        maxLength={18}
        mask={(len) => (len <= 14 ? "999.999.999-99" : "99.999.999/9999-99")}
        isNormal
      />
      <Input
        fullWidth
        placeholder="Apelido do cartão"
        value={card?.cardName}
        onChange={(value) => setCard({ ...card, cardName: value })}
        isNormal
      />
      <CheckBox
        primaryColor={theme.primaryColor}
        square
        isChecked={card?.remember}
        label="Desejo guardar este cartão de crédito."
        onPress={() => setCard({ ...card, remember: !card?.remember })}
      />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button size="medium" variant="text" onClick={props.onCancel}>
            Voltar
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            onClick={onNext}
            disabled={!nextButtonEnabled}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CardForm;
