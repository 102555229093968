import { Button } from "@mui/material";
import Typography from "../../../../components/Typography";
import { StyledDivButton } from "../../onBoardingVideo/styles";
import { ImageHorizontalContainer } from "./styles";
import RgFront from '../../../../shared/assets/rg-front-example.png';
import RgBack from '../../../../shared/assets/rg-back-example.png';
import PropTypes from 'prop-types';
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface InstructionsRgProps {
  navigateToNext: () => void;
  title? : string;
}

const InstructionsOnBoardingVideoRg: React.FC<InstructionsRgProps> = ({ navigateToNext, title }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h1" color="green">
        {title || 'Instruções para a foto do documento'}
      </Typography>
      <Typography variant="caption">Utilize um documento de RG físico (documentos digitais não são permitidos). Primeiro você deverá tirar a foto da frente do documento, e em seguida do verso.</Typography>
      <Typography variant="caption">
        A frente do documento contém a foto do portador.
      </Typography>
      <ImageHorizontalContainer  isSmallScreen={isSmallScreen} src={RgFront} />
      <Typography variant="caption">
        O verso do documento contém os dados pessoais do portador.
      </Typography>
      <ImageHorizontalContainer  isSmallScreen={isSmallScreen} src={RgBack} />
      <Typography variant="caption">
        Clique no botão “Próximo” abaixo para prosseguir.
      </Typography>
      <StyledDivButton>
        <Button
          variant={"contained"}
          color="primary"
          onClick={navigateToNext}
        >
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

InstructionsOnBoardingVideoRg.propTypes = {
  navigateToNext: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default InstructionsOnBoardingVideoRg;
  