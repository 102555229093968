import React, {useEffect} from "react";
import Typography from "../../components/Typography";
import {IconWithMargin, RowDiv, RowDivSpaceBetween, ScoreText, StyledContainer, VelocimeterImage,} from "./styles";
import {Palette} from "../../shared/theme/palette";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import WarningIcon from "@mui/icons-material/Warning";
import {InquiryReturnDetailsDTO} from "../../shared/models/backend/inquiry/inquiry-details-onboarding-dto";
import EScoreType, {EScoreTypeDescriptions} from "../../shared/models/backend/inquiry/EScoreType";
import InquiryCardRow from "./InquiryCardRow";
import {FormatUtils} from "../../shared/utils/format-utils";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

interface InquiryResultCardProps {
    inquiry?: InquiryReturnDetailsDTO<any>;
}

const InquiryResultInfoCard = ({
                               inquiry,
                           }: InquiryResultCardProps) => {
    const [icon, setIcon] = React.useState<React.ReactNode>();
    const theme = useThemeWhiteLabel();
    const [riskImage, setRiskImage] = React.useState<React.ReactNode>();
    useEffect(() => {
        switch (inquiry.scoreType) {
            case EScoreType.NoRisk:
                setIcon(<ThumbUpIcon style={{color: theme.tertiaryColor}}/>);
                setRiskImage(<VelocimeterImage src={require(`../../shared/assets/low-risk.png`)}/>);
                break
            case EScoreType.WithRisk:
                setIcon(<ThumbDownIcon style={{color: Palette.red}}/>);
                setRiskImage(<VelocimeterImage src={require(`../../shared/assets/high-risk.png`)}/>);
                break
            case EScoreType.RiskUndefined:
                setIcon(<WarningIcon style={{color: theme.alternativeColor }} fontSize={'small'}/>);
                setRiskImage(<VelocimeterImage src={require(`../../shared/assets/mid-risk.png`)}/>);
                break
            default:
                setIcon(<HourglassEmptyIcon style={{color: Palette.lightGrey9}}/>);
                setRiskImage(<VelocimeterImage src={require(`../../shared/assets/mid-risk.png`)}/>);
        }
    }, []);

    return (
        <StyledContainer>
            <Typography variant={"filterTitle"} color={"green"}>{" Resultado "}</Typography>
                <>
                    <RowDiv>
                        <Typography variant={"caption"}>{EScoreTypeDescriptions[inquiry.scoreType]}</Typography>
                        <IconWithMargin>{icon}</IconWithMargin>
                    </RowDiv>
                    <RowDivSpaceBetween>
                        <ScoreText>{` ${(inquiry?.score)?.toFixed(0)}/100 `}</ScoreText>
                        {riskImage}
                    </RowDivSpaceBetween>
                </>
            <InquiryCardRow
                label="Descrição"
                value={inquiry?.additionalInfo?.scoreTypeDescription || inquiry?.additionalInfo?.resultDescription || inquiry?.additionalInfo?.description}/>
            <InquiryCardRow
                label="Tipo" value={inquiry.additionalInfo.PhoneType}/>
            <InquiryCardRow label="Mensagem"
                            value={FormatUtils.formatPhoneNumber(inquiry.additionalInfo.PhoneNumber)}/>
            <InquiryCardRow label="Operadora"
                            value={inquiry.additionalInfo.Operator}/>
            <InquiryCardRow label="Fuso Horário"
                            value={inquiry.additionalInfo.TimezoneDescription}/>
        </StyledContainer>
    )
}
export default InquiryResultInfoCard;
