import React, {useContext} from 'react';
import {
    HomeHeaderChoosePlanButtonContainer,
    HomeHeaderContainer,
    HomeHeaderContent,
    HomeHeaderNoPlanAlertContainer,
    HomeHeaderNoPlanAlertText,
    HomeHeaderNoPlanAlertTextContainer,
    HomeHeaderSubtitle,
    HomeHeaderTitle,
    HomeHeaderTitleContainer
} from "./styles";
import BalanceCard from "../BalanceCard";

import {useNavigate} from "react-router-dom";
import Routes from "../../../../constants/Routes";
import {AccountContext} from "../../../../shared/context/AccountContext";
import {Palette} from "../../../../shared/theme/palette";
import {Button} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {Constants} from '../../../../constants/Constants';
import useWhitelabel from "../../../../hooks/useWhitelabel";
import { useThemeWhiteLabel } from '../../../../hooks/useThemeWhiteLabel';

const HomeHeader = () => {
    const {subscription} = useContext(AccountContext)
    const navigate = useNavigate()
    const showMessage = !subscription?.ativo || subscription.planoId === Constants.FREE_PLAN_ID
    const {whitelabelName} = useWhitelabel();
    const theme = useThemeWhiteLabel();
    return (
        <HomeHeaderContainer>
            <HomeHeaderContent>
                <HomeHeaderTitleContainer>
                    <HomeHeaderTitle primaryColor={theme.primaryColor}>
                        {`Boas-vindas!`}
                    </HomeHeaderTitle>
                    <HomeHeaderSubtitle>
                        Utilize o menu à esquerda para acessar os recursos
                    </HomeHeaderSubtitle>
                    {
                        showMessage &&
                        <HomeHeaderNoPlanAlertContainer>
                            <HomeHeaderNoPlanAlertTextContainer>
                                <WarningIcon style={{color: Palette.red}} fontSize="small"/>
                                <HomeHeaderNoPlanAlertText>
                                    {`Para utilizar os serviços do ${whitelabelName}, será necessário escolher um plano. Clique no botão abaixo para ver as opções.`}
                                </HomeHeaderNoPlanAlertText>
                            </HomeHeaderNoPlanAlertTextContainer>
                            <HomeHeaderChoosePlanButtonContainer>
                                <Button
                                    style={{width: '17rem'}}
                                    onClick={() => navigate(Routes.OtherPlans)}
                                    size={'medium'}
                                    variant={'contained'}>
                                    {'Escolher Plano'}
                                </Button>
                            </HomeHeaderChoosePlanButtonContainer>
                        </HomeHeaderNoPlanAlertContainer>
                    }
                </HomeHeaderTitleContainer>
                <BalanceCard></BalanceCard>
            </HomeHeaderContent>
        </HomeHeaderContainer>
    );
};
export default HomeHeader;