import {DocumentType} from "../../../../shared/models/document-type";
import {ScreenWithSubHeaderContainer} from "../../../../theme/GlobalStyles";
import {
    SentSuccessfullyButtonsContainer,
    SentSuccessfullyButtonsContainerContent,
    SentSuccessfullyTextContainer,
    SentSuccessfullyTitleContainer,
    SentSuccessfullyTitleText
} from "../styles";

import {useNavigate} from "react-router-dom";
import Routes from "../../../../constants/Routes";
import {Button} from "@mui/material";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ResultTimeInfoCard from "../ResultTimeInfoCard";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface SentSuccessfullyProps {
    docType?: DocumentType;
    inquiryId?: number;
    onNewInquiry?: () => void;
    withSelfie?: boolean;
    inquiryType?: EInquiryType;
}


const InquirySentSuccessfully = ({...props}: SentSuccessfullyProps) => {
    const navigate = useNavigate();
    const theme = useThemeWhiteLabel();
    const getResendButtonText = () => {
        switch (props.inquiryType) {
            case EInquiryType.DocumentoComSelfie:
                return `Nova consulta por documento + selfie (Análise Manual)`;
            case EInquiryType.Documento:
                return `Nova consulta por documento (Análise Manual)`;
            case EInquiryType.Telefone:
                return `Nova consulta por telefone`;
            case EInquiryType.Email:
                return `Nova consulta por email`;
            case EInquiryType.DocumentoAutomatic:
                return `Nova consulta por documento (digital híbrida)`;
            case EInquiryType.DocumentoComSelfieAutomatic:
                return `Nova consulta por documento + selfie (digital híbrida)`;
        }
    }
    return (
        <ScreenWithSubHeaderContainer>
            <SentSuccessfullyTitleContainer>
            <CheckCircleIcon color={'primary'} />
                <SentSuccessfullyTitleText primaryColor={theme.primaryColor}>{'Consulta realizada com sucesso!'}</SentSuccessfullyTitleText>
            </SentSuccessfullyTitleContainer>
            <SentSuccessfullyTextContainer>
                <ResultTimeInfoCard inquiryType={props.inquiryType}></ResultTimeInfoCard>
                <SentSuccessfullyButtonsContainer>
                    <SentSuccessfullyButtonsContainerContent>
                        <Button
                            variant={'contained'}
                            fullWidth
                            onClick={() => navigate(Routes.Inquiries.List)}>{'Consultar resultado'}</Button>
                        <Button variant={'outlined'}
                                fullWidth
                                onClick={props.onNewInquiry}>{getResendButtonText()}</Button>
                        <Button variant={'outlined'}
                                fullWidth
                                onClick={() => navigate(Routes.Home)}>{'Retornar ao início'}</Button>
                    </SentSuccessfullyButtonsContainerContent>
                </SentSuccessfullyButtonsContainer>
            </SentSuccessfullyTextContainer>
        </ScreenWithSubHeaderContainer>
    );
}
export default InquirySentSuccessfully;