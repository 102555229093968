import { List, ListItem, Typography } from "@mui/material";

export const useVideoOnboardingHint = ({
  isNoDocOnboarding,
}: {
  isNoDocOnboarding: boolean;
}) => {
  if (isNoDocOnboarding) {
    return (
      <>
        <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          Experiência de captura do vídeo para formalização digital + análise do
          vídeo em 5 camadas
        </Typography>
        <List dense sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          <ListItem>• Análise do vídeo</ListItem>
          <List dense sx={{ paddingLeft: "16px" }}>
            <ListItem>• Conversão da voz em texto</ListItem>
            <ListItem>• Geração e identificação da palavra chave</ListItem>
            <ListItem>• Análise de manipulação do vídeo</ListItem>
            <ListItem>
              • Extração do melhor frame do vídeo (selfie do vídeo)
            </ListItem>
          </List>
        </List>
        <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          O que esperar como resultado?
          <br /> <br />
          Um score de probabilidade de 0 a 100 do onboarding ser ou não uma
          fraude. Quanto mais próximo de ZERO é risco de fraude, quanto mais
          próximo de CEM é um bom onboarding. O score é organizado por faixas de
          classificação, onde recomendamos fraude na parte vermelha, bom
          onboarding na parte verde e inconclusivo na parte amarela. Para os
          casos de má qualidade no vídeo e documento ilegível, o resulta será
          inconclusivo.
          <br />
          + Todos os dados cadastrados para o onboarding
          <br />
          + Resultados das 4 etapas.
          <br />+ Possibilidade de visualização das imagens do documento, selfie
          e do vídeo. Sempre com hash de identificação e marca d`água.
        </Typography>
      </>
    );
  }
  if (!isNoDocOnboarding) {
    return (
      <>
        <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          Experiência de captura do vídeo para formalização digital + análise do
          vídeo em 5 camadas + documentoscopia híbrida.
        </Typography>
        <List dense sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          <ListItem>• Análise do vídeo</ListItem>
          <List dense sx={{ paddingLeft: "16px" }}>
            <ListItem>• Conversão da voz em texto</ListItem>
            <ListItem>• Geração e identificação da palavra chave</ListItem>
            <ListItem>• Análise de manipulação do vídeo</ListItem>
            <ListItem>
              • Extração do melhor frame do vídeo (selfie do vídeo)
            </ListItem>
            <ListItem>
              • Facematch do melhor frame com a foto do documento
            </ListItem>
          </List>
        </List>
        <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
          O que esperar como resultado?
          <br /> <br />
          Um score de probabilidade de 0 a 100 do onboarding ser ou não uma
          fraude. Quanto mais próximo de ZERO é risco de fraude, quanto mais
          próximo de CEM é um bom onboarding. O score é organizado por faixas de
          classificação, onde recomendamos fraude na parte vermelha, bom
          onboarding na parte verde e inconclusivo na parte amarela. Para os
          casos de má qualidade no vídeo e documento ilegível, o resulta será
          inconclusivo.
          <br />
          + Todos os dados cadastrados para o onboarding
          <br />
          + Resultados das 5 etapas.
          <br />+ Possibilidade de visualização das imagens do documento, selfie
          e do vídeo. Sempre com hash de identificação e marca d`água.
        </Typography>
      </>
    );
  }
  return "";
};
