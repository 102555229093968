import React, { useEffect } from "react";
import { InnerContainer, StyledDiv, StyledDivWithBackgroud } from "./styles";
import ResponsiveContainer from "../../../components/ResponsiveSection/ResponsiveContainer";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import IntroductionOnBoardingVideo from "../components/Introduction/Introduction";
import VideoRecorder from "../components/VideoRecorder/VideoRecorder";
import VideoDisplay from "../components/VideoDisplay/VideoDisplay";
import DocumentType from "../components/DocumentType/DocumentType";
import DocumentPhoto from "../components/DocumentPhoto/DocumentPhoto";
import ResultOnBoardingVideo from "../components/onBoardingVideoResult/onBoardingVideoResult";
import useOnboardingVideo from "./hooks/useOnBoardingVideo";
import InstructionsOnBoardingVideoRg from "../components/Instructions/InstructionsRg";
import InstructionsOnBoardingVideoCnh from "../components/Instructions/InstructionsCnh";
import InstructionsOnBoardingVideoCTPS from "../components/Instructions/InstructionsCTPS";
import InstructionsOnBoardingVideoPassaporte from "../components/Instructions/InstructionsPassaporte";
import EOnboardingStep from "../../../shared/models/backend/inquiry/EOnboardingStep";
import ChoosePhotoSource from "../../Inquiries/components/ChoosePhotoSource";
import EPhotoSourceType from "../../../shared/models/backend/inquiry/EPhotoSourceType";
import InstructionsUploadFile from "../components/Instructions/InstructionsUploadFile";
import OnboardingDocumentFileUpload from "../components/OnboardingDocumentFileUpload";
import OnBoardingFailed from "../components/onBoardingVideoFailed/onBoardingVideoFailed";


const OnBoardingVideo = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {
        mediaRecorder,
        recording,
        videoSize,
        videoUrl,
        isCaptured,
        selectedDocument,
        data,
        step,
        canvasRef,
        videoRef,
        previewRef,
        chunksRef,
        filesSource,
        frontPhotoId,
        documentIds,
        setFrontPhotoId,
        setBackPhotoId,
        setSelectedDocumentId,
        setSelectedDocument,
        setIsCaptured,
        nextStep,
        handleStart,
        handleStop,
        handleRerecord,
        sendVideo,
        handleCapture,
        handleNext,
        setFilesSource,
        setStep,
        badRequest,
        onBoardingExpired,
        loading
    } = useOnboardingVideo();
    useEffect(() => {
        renderStep();
    }, []);

    interface StepContainerProps {
        children: React.ReactNode;
    }
    
    const StepContainer = ({ children }: StepContainerProps) => {
        return (
            <StyledDiv>
                <InnerContainer isSmallScreen={isSmallScreen}>
                    {children}
                </InnerContainer>
            </StyledDiv>
        );
    };
    
    const renderStep = () => {
        if (onBoardingExpired) {
            return (
                <StepContainer>
                    <OnBoardingFailed />
                </StepContainer>
            );
        }
    
        if (badRequest) {
            return (
                <StepContainer>
                    <OnBoardingFailed isBadRequest />
                </StepContainer>
            );
        }
    
        if (!data) {
            return (
                <StepContainer>
                    <OnBoardingFailed />
                </StepContainer>
            );
        }
    
        if (data.inviteDTO.isNoDocOnboarding) {
            switch (step) {
                case EOnboardingStep.Introduction:
                    return (
                        <StepContainer>
                            <IntroductionOnBoardingVideo
                                data={data}
                                navigateToNext={nextStep}
                            />
                        </StepContainer>
                    );
                case EOnboardingStep.VideoRecorder:
                    return (
                        <StyledDivWithBackgroud>
                            <VideoRecorder
                                handleStart={handleStart}
                                handleStop={handleStop}
                                navigateToNext={nextStep}
                                recording={recording}
                                data={data}
                                videoRef={videoRef}
                                previewRef={previewRef}
                                chunksRef={chunksRef}
                                mediaRecorder={mediaRecorder}
                            />
                        </StyledDivWithBackgroud>
                    );
                case EOnboardingStep.VideoDisplay:
                    return (
                        <StepContainer>
                            <VideoDisplay
                                videoSize={parseFloat(videoSize)}
                                data={data}
                                videoUrl={videoUrl}
                                handleRerecord={handleRerecord}
                                sendVideo={sendVideo}
                                navigateToNext={() => handleNext(null, true)}
                                videoRef={videoRef}
                            />
                        </StepContainer>
                    );
                case EOnboardingStep.Result:
                    return (
                        <StepContainer>
                            <ResultOnBoardingVideo />
                        </StepContainer>
                    );
                default:
                    return null;
            }
        }
    
        let InstructionComponent;
    
        switch (step) {
            case EOnboardingStep.Introduction:
                return (
                    <StepContainer>
                        <IntroductionOnBoardingVideo
                            data={data}
                            navigateToNext={nextStep}
                        />
                    </StepContainer>
                );
            case EOnboardingStep.VideoRecorder:
                return (
                    <StyledDivWithBackgroud>
                        <VideoRecorder
                            handleStart={handleStart}
                            handleStop={handleStop}
                            navigateToNext={nextStep}
                            recording={recording}
                            data={data}
                            videoRef={videoRef}
                            previewRef={previewRef}
                            chunksRef={chunksRef}
                            mediaRecorder={mediaRecorder}
                        />
                    </StyledDivWithBackgroud>
                );
            case EOnboardingStep.VideoDisplay:
                return (
                    <StepContainer>
                        <VideoDisplay
                            videoSize={parseFloat(videoSize)}
                            data={data}
                            videoUrl={videoUrl}
                            handleRerecord={handleRerecord}
                            sendVideo={sendVideo}
                            navigateToNext={nextStep}
                            videoRef={videoRef}
                        />
                    </StepContainer>
                );
            case EOnboardingStep.DocumentType:
                return (
                    <StepContainer>
                        <DocumentType
                            documentIds={documentIds}
                            handleDocumentSelection={(documentId: number) => {
                                setSelectedDocumentId(documentId);
                            }}
                            setSelectedDocumentType={setSelectedDocument}
                            selectDocument={selectedDocument}
                            navigateToNext={nextStep}
                        />
                    </StepContainer>
                );
            case EOnboardingStep.SetDocumentFileSource:
                return (
                    <StepContainer>
                        <ChoosePhotoSource
                            source={filesSource}
                            setSource={setFilesSource}
                            onBack={() => setStep(EOnboardingStep.DocumentType)}
                            onNext={() => setStep(EOnboardingStep.Instructions)}
                        />
                    </StepContainer>
                );
            case EOnboardingStep.Instructions:
                if (filesSource === EPhotoSourceType.File)
                    return (
                        <StepContainer>
                            <InstructionsUploadFile
                                previousStep={() => setStep(EOnboardingStep.SetDocumentFileSource)}
                                nextStep={() => setStep(EOnboardingStep.UploadDocument)}
                                docType={selectedDocument} />
                        </StepContainer>
                    );
    
                switch (selectedDocument) {
                    case "RG":
                        InstructionComponent = InstructionsOnBoardingVideoRg;
                        break;
                    case "CNH":
                        InstructionComponent = InstructionsOnBoardingVideoCnh;
                        break;
                    case "Passaporte":
                        InstructionComponent = InstructionsOnBoardingVideoPassaporte;
                        break;
                    case "Carteira de Trabalho":
                        InstructionComponent = InstructionsOnBoardingVideoCTPS;
                        break;
                    default:
                        InstructionComponent = null;
                }
                return (
                    <StepContainer>
                        {InstructionComponent && (
                            <InstructionComponent navigateToNext={nextStep} />
                        )}
                    </StepContainer>
                );
            case EOnboardingStep.UploadDocument:
                return (
                    <StepContainer>
                        <OnboardingDocumentFileUpload
                            setFrontDocumentFile={(fileIdentifier) => setFrontPhotoId(fileIdentifier)}
                            setBackDocumentFile={(fileIdentifier) => setBackPhotoId(fileIdentifier)}
                            documentFile={frontPhotoId}
                            docOwnerInfoText={`O documento enviado deve pertencer ao beneficiário ${data.inviteDTO.name}.`}
                            previousStep={() => setStep(EOnboardingStep.SetDocumentFileSource)}
                            nextStep={() => handleNext(null, true)}
                        ></OnboardingDocumentFileUpload>
                    </StepContainer>
                );
            case EOnboardingStep.DocumentPhotoFront:
                return (
                    <StyledDiv>
                        <InnerContainer isSmallScreen={isSmallScreen}>
                            <DocumentPhoto
                                key="front"
                                handleCapture={handleCapture}
                                navigateToNext={() => handleNext(setFrontPhotoId)}
                                isCaptured={isCaptured}
                                selectedDocument={selectedDocument}
                                side="front"
                                canvasRef={canvasRef}
                                videoRef={videoRef}
                                setIsCaptured={setIsCaptured}
                            />
                        </InnerContainer>
                    </StyledDiv>
                );
            case EOnboardingStep.DocumentPhotoBack:
                if (selectedDocument === "CNH" || selectedDocument === "Passaporte") {
                    break;
                }
                return (
                    <StyledDiv>
                        <InnerContainer isSmallScreen={isSmallScreen}>
                            <DocumentPhoto
                                key="back"
                                handleCapture={handleCapture}
                                navigateToNext={() => handleNext(setBackPhotoId, true)}
                                isCaptured={isCaptured}
                                selectedDocument={selectedDocument}
                                side="back"
                                canvasRef={canvasRef}
                                videoRef={videoRef}
                                setIsCaptured={setIsCaptured}
                            />
                        </InnerContainer>
                    </StyledDiv>
                );
            case EOnboardingStep.Result:
                return (
                    <StepContainer>
                        <ResultOnBoardingVideo />
                    </StepContainer>
                );
            default:
                return null;
        }
    };

    return (
        <ResponsiveContainer>
            <Header />
            {loading ? null : renderStep()}
        </ResponsiveContainer>
    );
};

export default OnBoardingVideo;
