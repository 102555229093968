import React, { useEffect } from "react";
import { CheckBoxContainer, CheckBoxInput, CheckBoxLabel } from "./styles";
import CheckBoxProps from "./CheckBoxProps";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

const CheckBox = ({ id, isChecked, onCheck, onPress, label, disabled, square }: CheckBoxProps) => {
    useEffect(() => {
        if (isChecked && onCheck) onCheck(id);
    }, [isChecked]);
    const theme = useThemeWhiteLabel();
    return (
        <CheckBoxContainer onClick={() => !disabled && onPress()}>
            <CheckBoxInput 
                primaryColor={theme.primaryColor}
                isChecked={isChecked}
                label={label}
                square={square}
                disabled={disabled} />
            {label &&
                <CheckBoxLabel htmlFor={id}
                    disabled={disabled}>
                    {label}
                </CheckBoxLabel>}
        </CheckBoxContainer>
    );
};

export default CheckBox;