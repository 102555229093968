import {
  MailingDigitalDataRequest,
  MailingFileRequest,
  MailingResponseData,
} from "../models/backend/mailing/mailing";
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useMailingOnBoardingService = () => {
  const consultaiApi = useConsultaiApi();

  const getMailing = async (id: string): Promise<MailingResponseData> => {
    return await consultaiApi.get<MailingResponseData>(`/mailing/${id}`, 1);
  };

  const getMailingSignedContract = async (id: string): Promise<any> => {
    return await consultaiApi.get<any>(`/mailing/${id}/signed-contract`, 1);
  };

  const putMailingStep = async (id: string, step: number) => {
    return await consultaiApi.put<string>(`/mailing/${id}/step`, 1, { step });
  };

  const sendMailingDigitalData = async (
    id: string,
    mailingDigitalData: MailingDigitalDataRequest
  ) => {
    return await consultaiApi.post<string>(`/mailing/${id}/digital-data`, 1, {
      mailingDigitalData,
    });
  };

  const sendMailingUserData = async (
    publicId: string,
    MailingUserData: any
  ) => {
    return await consultaiApi.post<string>(`/mailing/user-data`, 1, {
      publicId,
      MailingUserData,
    });
  };

  const sendMailingFile = async (data: MailingFileRequest) => {
    const { publicId, ...rest } = data;
    return await consultaiApi.post<string>(`/mailing/${publicId}/file`, 1, {
      ...rest,
    });
  };

  const finishMailingFlow = async (id: string) => {
    return await consultaiApi.post(`/Inquiries/mailing/${id}/send`, 1);
  };

  return {
    getMailing,
    putMailingStep,
    sendMailingFile,
    sendMailingDigitalData,
    finishMailingFlow,
    sendMailingUserData,
    getMailingSignedContract,
  };
};
export default useMailingOnBoardingService;
