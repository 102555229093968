import {useWhitelabelService} from "../shared/services/useWhitelabelService";
import {useEffect, useState} from "react";
import {Whitelabel} from "../shared/models/whitelabel";
import {Constants} from "../constants/Constants";

const useWhitelabel = () => {
    const currentWhitelabelLocalStorageKey = 'currentWhitelabel';
    const [currentWhitelabel, setCurrentWhitelabel] = useState<Whitelabel>();
    const {getCurrentWhitelabel} = useWhitelabelService();
    useEffect(() => {
        const loadWhitelabel = async () =>
            getCurrentWhitelabel(window.location.href).then((whitelabel) => {
                if(!whitelabel) return;
                setCurrentWhitelabel(whitelabel);
                localStorage.setItem(currentWhitelabelLocalStorageKey, JSON.stringify(whitelabel));
            })

        localStorage.getItem(currentWhitelabelLocalStorageKey)
            ? setCurrentWhitelabel(JSON.parse(localStorage.getItem(currentWhitelabelLocalStorageKey)) as Whitelabel)
            : loadWhitelabel();
    }, []);

    return {
        currentWhitelabel,
        whitelabelName: currentWhitelabel?.name,
        whitelabelLogo: currentWhitelabel?.logoImagePath,
        isDefaultWhitelabel: currentWhitelabel?.id == Constants.CONSULTAI_WHITELABEL_ID
    }
}
export default useWhitelabel;