import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button } from "@mui/material";
import { AccountContext } from "../../../shared/context/AccountContext";
import { useUnitService } from "../../../shared/services/useUnitService";
import { ValidationUtils } from "../../../utils/validation-utils";
import { removeMask } from "../../../shared/utils/remove-mask";
import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import Typography from "../../../components/Typography";
import Input from "../../../components/Input/Input";
import ModalChangeData from "./components/modalChangeData";
import SubSection from "../../../components/SubSection";
import {useNavigate} from "react-router-dom";
import {InnerContainer, InputContainer} from "./components/styles";
import {useProfileData} from "./hooks/useProfileData";
import {FormatUtils} from "../../../shared/utils/format-utils";

export default function ProfileData() {
    const {
        modalVisible,
        newName,
        newCnpj,
        newEmail,
        newPhone,
        setNewCnpj,
        setNewEmail,
        setNewName,
        setNewPhone,
        send,
        openModal,
        closeModal,
    } = useProfileData();
    const {user} = useContext(AccountContext);
    const isAdmin = user?.user?.profile == 'ADMCONTA';
    const navigate = useNavigate();
    if (!user?.user) return null;
    return (
        <Layout>
            <SubHeader title="Minha conta" subtitle="Dados cadastrais"/>
            <SubSection>
                <></>
                <InnerContainer>
                    {isAdmin && (
                        <>
                            <Typography variant="caption">
                                {'Caso necessite alterar algum dado, a equipe da Who irá avaliar as ' +
                                    'novas informações e aprovar as mudanças'} </Typography>
                            <Typography variant="caption">
                                {'Faça as alterações e clique no botão “Solicitar alteração de dados” abaixo.'}
                            </Typography>
                        </>
                    )}
                    <Typography variant={'h1'}>{'Dados Pessoais'}</Typography>
                    <InputContainer>
                        <Input
                            value={user.user.name}
                            placeholder="Nome"
                            maxLength={100}
                            onChange={() => {
                            }}
                            width="250px"
                            disabled={true}
                        />
                        <Input
                            value={user.user.login.length > 14
                                ? FormatUtils.formatCNPJ(user.user.login)
                                : FormatUtils.formatCPF(user.user.login)}
                            placeholder="CPF/CNPJ"
                            onChange={() => {
                            }}
                            width="250px"
                            disabled={true}
                        />
                        <Input
                            value={user.user.email}
                            placeholder="E-mail"
                            maxLength={100}
                            onChange={() => {
                            }}
                            width="250px"
                            disabled={true}
                        />
                        <Input
                            value={FormatUtils.formatPhoneNumber(user.user.phone)}
                            placeholder="Telefone"
                            onChange={() => {
                            }}
                            width="250px"
                            disabled={true}
                        />
                    </InputContainer>
                    <hr/>
                    <Typography variant={'h1'}>{'Dados da Empresa (Unidade)'}</Typography>
                    <InputContainer>
                        <Input
                            value={newName || ""}
                            placeholder="Razão Social*"
                            onChange={(value) => setNewName(value)}
                            maxLength={100}
                            width="250px"
                            disabled={!isAdmin}
                        />
                        <Input
                            value={newCnpj ? FormatUtils.formatCNPJ(newCnpj) : ""}
                            placeholder="CNPJ"
                            onChange={(value) => setNewCnpj(value)}
                            mask={"99.999.999/9999-99"}
                            maxLength={18}
                            width="250px"
                            disabled={!isAdmin}
                        />
                        {
                            isAdmin &&
                            <Input
                                value={newEmail || ""}
                                placeholder="E-mail"
                                onChange={(value) => setNewEmail(value)}
                                maxLength={100}
                                width="250px"
                                disabled={!isAdmin}
                            />
                        }
                        {
                            isAdmin &&
                            <Input
                                value={newPhone ? FormatUtils.formatPhoneNumber(newPhone) : ""}
                                placeholder="Telefone"
                                onChange={(value) => setNewPhone(value)}
                                maxLength={15}
                                mask={newPhone ? "(99) 99999-9999" : undefined}
                                width="250px"
                                disabled={!isAdmin}
                            />}
                    </InputContainer>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button size="medium" onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                        {
                            user.user.profile == 'ADMCONTA' &&
                            <Button size="medium" variant={"contained"} onClick={openModal}>
                                Solicitar alteração de dados
                            </Button>
                        }
                    </Box>
                    {modalVisible && (
                        <ModalChangeData onClose={closeModal} sendData={send}/>
                    )}
                </InnerContainer>
            </SubSection>
        </Layout>
    )
        ;
}
export {ProfileData};
