import styled from "styled-components";
import {Palette} from "../../../../shared/theme/palette";

export const TermsModalLinkText = styled.text`
  font-family: Inter;
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${Palette.black};
`;
export const TermsModalLink = styled.text<{color: string}>`
  font-family: Inter;
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${p => p.color};
  cursor: pointer;
`;
export const SectionTitleText = styled.text`
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 10px;
  padding-bottom: 5px
`
export const SectionText = styled.text`
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
`
export const SectionTextWithTabulation = styled.text`
  width: 100%;
  padding-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
`
export const SectionTextBold = styled.text`
  width: 100%;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
`
export const SectionTextBoldNoPadding = styled.text`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
`
export const SectionTextLink = styled.text`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: underline;
`
export const PrivacyPolicyModalScrollableContentContainer = styled.div`
  flex-direction: column;
    justify-content: left;
`