import React, {useState} from 'react';
import ConsultaiAppContext from '../consultai-app-context';
import {useConsultaiAppContext} from '../hooks/useConsultaiApp';

interface ConsultaiAppContextProviderProps {
    children: React.ReactNode;
}

export const ConsultaiAppContextProvider = ({
                                                children,
                                            }: ConsultaiAppContextProviderProps) => {
    const context = useConsultaiAppContext();
    const [loading, setLoading] = useState<boolean>(false);
    const initialValue = {
        ...context,
        loading,
        setLoading
    }
    return (
        <ConsultaiAppContext.Provider value={initialValue}>
            {children}
        </ConsultaiAppContext.Provider>
    );
};
