import { InsertDriveFileOutlined as FileCopyIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { getDocument } from "pdfjs-dist";
import { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import {
  DragAndDropFilePickerText,
  DragAndDropFilePreviewBox,
  DragAndDropFilePreviewCard,
  DragAndDropFilePreviewContainer,
  DragAndDropFilePreviewImg,
} from "./styles";

function base64ToBlob(dataUri) {
  // Split the data URI into the MIME type and Base64 content
  const [header, base64String] = dataUri.split(",");
  const mimeType = header.match(/data:([^;]+);base64/)[1]; // Extract MIME type

  // Decode Base64 string into binary data
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i += 512) {
    const slice = byteCharacters.slice(i, i + 512);
    const byteNumbers = new Array(slice.length);

    for (let j = 0; j < slice.length; j++) {
      byteNumbers[j] = slice.charCodeAt(j);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

type DragAndDropFilePreviewProps = {
  files: Array<{ fileContent: string; fileName: string }>;
};

export const DragAndDropFilePreview = ({
  files,
}: DragAndDropFilePreviewProps) => {
  async function convertPdfToImageBase64(pdfBlob) {
    const pdfArrayBuffer = await pdfBlob.arrayBuffer();
    const pdfDocument = await getDocument(pdfArrayBuffer).promise;

    const imageBase64s = [];
    for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
      const page = await pdfDocument.getPage(pageNum);

      // Set the scale and viewport
      const scale = 2; // Adjust for higher resolution if needed
      const viewport = page.getViewport({ scale });

      // Create a canvas to render the PDF page
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      await page.render(renderContext).promise;

      // Convert the canvas content to Base64
      const base64Image = canvas.toDataURL("image/png"); // Change to "image/jpeg" if needed
      imageBase64s.push(base64Image);

      // Clean up
      page.cleanup();
    }

    return imageBase64s;
  }

  const [allFiles, setAllFiles] = useState<any[]>([]);

  useEffect(() => {
    const processFiles = async () => {
      const result = [];
      for (const file of files) {
        if (file.fileContent.startsWith("data:application/pdf")) {
          const imagesBase64 = await convertPdfToImageBase64(
            base64ToBlob(file.fileContent)
          );
          imagesBase64.forEach((image) => {
            result.push({ fileContent: image, fileName: file.fileName });
          });
        } else {
          result.push(file);
        }
      }
      setAllFiles(result);
    };

    processFiles();
  }, [files]);

  return (
    <PhotoProvider>
      <DragAndDropFilePreviewContainer direction={{ xs: "column", sm: "row" }}>
        {allFiles?.map((file, index) => (
          <PhotoView src={file.fileContent} key={`${file.fileName}-${index}`}>
            <DragAndDropFilePreviewBox
              onClick={(event) => event.preventDefault()}
            >
              <DragAndDropFilePreviewCard>
                <DragAndDropFilePreviewImg
                  src={file.fileContent}
                  alt={file.fileName}
                />
              </DragAndDropFilePreviewCard>
              <Stack direction="row">
                <FileCopyIcon color={"primary"} fontSize={"small"} />
                <DragAndDropFilePickerText style={{ wordBreak: "break-all" }}>
                  {file.fileName}
                </DragAndDropFilePickerText>
                <br />
              </Stack>
            </DragAndDropFilePreviewBox>
          </PhotoView>
        ))}
      </DragAndDropFilePreviewContainer>
    </PhotoProvider>
  );
};
