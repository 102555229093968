import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {Whitelabel} from "../models/whitelabel";
import {VideoOnboardingConfig} from "../models/video-onboarding-config";

export const useWhitelabelService = () => {
    const consultaiApi = useConsultaiApi();

    const getCurrentWhitelabel = (webAddress: string) =>
        consultaiApi.get<Whitelabel>(`/whitelabels`, 1, {webAddress});

    const getCurrentWhitelabelVideoOnboardingConfigs = (webAddress: string) =>
        consultaiApi.get<VideoOnboardingConfig[]>(`/whitelabels/video-onboarding-configs`, 1, {webAddress});

    return {
        getCurrentWhitelabel,
        getCurrentWhitelabelVideoOnboardingConfigs
    }
}
