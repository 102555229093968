import React from "react";
import styled, { css } from "styled-components";
import { Palette } from "../shared/theme/palette";
import { useThemeWhiteLabel } from "../hooks/useThemeWhiteLabel";

type VariantType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "caption"
  | "body1"
  | "caption2"
  | "caption3"
  | "caption4"
  | "caption5"
  | "cardTitle"
  | "cardSubtitle"
  | "cardIndicator"
  | "cardLabel"
  | "cardValue"
  | "filterSummaryLabel"
  | "filterSummaryValue"
  | "filterTitle"
  | "filterLabel"
  | "inputPlaceHolder"
  | "buttonText"
  | "checkBoxLabel"
  | "modalCancel"
  | "planName"
  | "modalOk"
  | "paymentDescription"
  | "registerFont";

type AlignType = "left" | "center" | "right";

type ColorType = keyof typeof Palette;
type PaletteColor = keyof typeof Palette;

type Props = {
  variant: VariantType;
  align?: AlignType;
  color?: PaletteColor;
  children: string | string[] | number;
  fontSize?: number;
};

const StyledText = styled.p<{
  align: AlignType;
  variant: VariantType;
  color?: ColorType;
  fontSize?: number;
}>`
  text-align: ${(props) => props.align};

  ${({ variant }) => {
    switch (variant) {
      case "h1":
        return css`
          font-family: "Poppins Regular";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        `;
      case "registerFont":
        return css`
          font-family: "Poppins Regular";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        `;
      case "h2":
        return css`
          font-size: 18px;
          font-weight: 500;
        `;
      case "h3":
        return css`
          font-size: 15px;
          font-weight: 500;
        `;
      case "h4":
        return css`
          font-family: "Poppins Regular";
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        `;
      case "caption":
        return css`
          font-size: 16px;
          font-weight: 400;
          font-family: "Inter Regular";
          line-height: 24px;
        `;
      case "caption2":
        return css`
          font-size: 14px;
          font-weight: 400;
        `;
      case "caption3":
        return css`
          font-family: "Inter Regular";
          font-size: 16px;
          font-weight: 600;
        `;
      case "caption4":
        return css`
          font-family: "Inter Regular";
          font-size: 14px;
          font-weight: 400;
        `;
      case "caption5":
        return css`
          font-family: "Inter Regular";
          font-size: 14px;
          font-weight: 900;
          line-height: 24px;
        `;
      case "cardTitle":
        return css`
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
        `;
      case "cardSubtitle":
        return css`
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
        `;
      case "cardIndicator":
        return css`
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
        `;
      case "cardLabel":
        return css`
          font-size: 14px;
          font-weight: 400;
          color: ${Palette.darkGrey4};
        `;
      case "cardValue":
        return css`
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        `;
      case "filterSummaryLabel":
        return css`
          font-family: "Inter Regular";
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
        `;
      case "filterSummaryValue":
        return css`
          font-family: "Inter Regular";
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
        `;
      case "filterTitle":
        return css`
          font-family: "Poppins Regular";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
        `;
      case "filterLabel":
        return css`
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
        `;
      case "inputPlaceHolder":
        return css`
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.32px;
        `;
      case "buttonText":
        return css`
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        `;
      case "checkBoxLabel":
        return css`
          font-size: 15px;
          font-weight: 400;
        `;
      case "modalCancel":
        return css`
          font-size: 14px;
          font-weight: 400;
        `;
      case "planName":
        return css`
          font-family: "Poppins Regular";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18.291px; /* 130.652% */
        `;
      case "modalOk":
        return css`
          font-size: 16px;
          font-weight: 500;
        `;
      case "paymentDescription":
        return css`
          font-family: "Poppins Regular";
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        `;
      default:
        return css`
          font-size: 22px;
          font-weight: 500;
        `;
    }
  }}

  ${({ color, theme }) =>
    color &&
    css`
      color: ${color === 'green' ? theme.primaryColor : Palette[color as keyof typeof Palette]};
    `}
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}
`;

const Typography = ({
  variant,
  children,
  color,
  align = "left",
  fontSize,
}: Props) => {
  const theme = useThemeWhiteLabel();

  return (
    <StyledText
      variant={variant}
      color={color}
      align={align}
      fontSize={fontSize}
      theme={theme}
    >
      {children}
    </StyledText>
  );
};

export default Typography;