import PlanCardValueProps from "./PlanCardValueProps";

const PlanCardValue = (props: PlanCardValueProps) => {
  return (
    <div>
      <p className="card-value-label">{props.label}</p>
      <p className="card-value-value">{props.value}</p>
    </div>
  );
};

export default PlanCardValue;
