import { Button, Grid, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import { FileTypeEnum } from "../../../shared/models/enums/files.enum";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import ContainerContent from "../components/ContainerContent";
import {
  ContractDrawArea,
  ContractDrawSignatureLine,
} from "../components/Contract/styles";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import { SubTitleInterTypography, TitleTypography } from "../styles";

const DigitalSignatureStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const theme = useThemeWhiteLabel();
  const { sendMailingFile } = useMailingOnBoardingService();

  const handleSaveSignature = async () => {
    if (canvasRef.current) {
      try {
        const blob = await canvasRef.current.exportImage("png");
        const data = {
          publicId: id,
          fileContent: blob,
          fileName: `${id}-signature.png`,
          fileType: FileTypeEnum.Signature,
        };
        setIsLoading(true);
        sendMailingFile(data)
          .then(() => {
            setActiveStep(EMailingStepsEnum.ContractDataForm);
          })
          .finally(() => {
            setIsLoading(true);
          });
      } catch (error) {
        console.error("Failed to export the image", error);
      }
    }
  };

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  };

  return (
    <ContainerContent>
      <Grid container height="100%">
        <Grid item xs={12} pb={{ xs: "16px", sm: "24px" }}>
          <TitleTypography color={theme.primaryColor}>
            Assinatura digital
          </TitleTypography>
          <SubTitleInterTypography>
            Faça sua assinatura sobre a linha abaixo. Caso erre ou não fique
            legível, clique no botão “Apagar” para limpar e começar novamente.
          </SubTitleInterTypography>
        </Grid>
        <Grid item xs={12}>
          <ContractDrawArea>
            <ReactSketchCanvas
              ref={canvasRef}
              style={{
                width: "100%",
                height: "100%",
              }}
              strokeWidth={4}
              strokeColor="black"
            />
            <Stack alignItems="center">
              <ContractDrawSignatureLine />
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleClear}
                >
                  Apagar
                </Button>
              </div>
            </Stack>
          </ContractDrawArea>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            gap="24px"
            mt="48px"
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              disabled={isLoading}
              onClick={() => setActiveStep(EMailingStepsEnum.ContractReading)}
            >
              Voltar
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isLoading}
              startIcon={
                isLoading ? <StyledCircularProgress size={16} /> : undefined
              }
              onClick={handleSaveSignature}
            >
              Salvar assinatura
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </ContainerContent>
  );
};

export default DigitalSignatureStep;
