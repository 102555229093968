import React, { useState } from "react";
import { Palette } from "../../../../shared/theme/palette";
import useDate from "../../../../shared/hooks/useDate";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Typography from "../../../../components/Typography";
import { FormatUtils } from "../../../../shared/utils/format-utils";
import {
  Body,
  ButtonContainer,
  Container,
  InnerContainer,
  OptionsContainer,
  RotatingIcon,
  Row,
  Texts,
} from "./styles";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";
type Option = {
  description: string;
  value: number;
  id?: number;
  typeDescription?: string;
  date?: string;
  time?: string;
  createdAt?: Date;
};

type Props = {
  options: Option[];
};

const Section = ({ options }: Props) => {
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});
  const theme = useThemeWhiteLabel();
  const { formatDate } = useDate();

  const toggleExpand = (sectionKey: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey],
    }));
  };

  return (
    <>
      <OptionsContainer>
        {options.map((option, index) => {
          const sectionKey = `${option.id}_${index}`; 
          return (
            <Container
              key={sectionKey}
              borderColor={option.value < 0 ? Palette.red2 : theme.secondaryColor}
              expanded={expandedSections[sectionKey] || false}
            >
              <InnerContainer>
                <Body>
                  <ButtonContainer>
                    <RotatingIcon
                      style={{ color: theme.primaryColor, fontSize: 20 }}
                      rotate={expandedSections[sectionKey]}
                      onClick={() => toggleExpand(sectionKey)}
                    />
                  </ButtonContainer>
                  <Row>
                  <Texts>
                    <Typography variant="filterSummaryValue">Data:</Typography>
                    <Typography variant="filterSummaryLabel">
                      {" "}
                      {formatDate(option.createdAt)}
                    </Typography>
                  </Texts>
                  <Texts>
                    <Typography variant="filterSummaryValue">Hora:</Typography>
                    <Typography variant="filterSummaryLabel">
                      {formatDate(option.createdAt, "HH:mm")}
                    </Typography>
                  </Texts>
                  </Row>
                  <Texts>
                    <Typography variant="filterSummaryValue" align="left">
                      Valor:
                    </Typography>
                    <Typography variant="filterSummaryLabel" align="left">
                      {FormatUtils.toBRLCurrency(option?.value)}
                    </Typography>
                  </Texts>
                  <Texts>
                        <Typography variant="filterSummaryValue">
                          Descrição:
                        </Typography>
                        <Typography variant="filterSummaryLabel">
                          {option.description}
                        </Typography>
                      </Texts>
                  {expandedSections[sectionKey] && (
                    <>
                      <Texts>
                        <Typography variant="filterSummaryValue">
                          Tipo:
                        </Typography>
                        <Typography variant="filterSummaryLabel">
                          {option.typeDescription}
                        </Typography>
                      </Texts>
                      <Texts>
                        <Typography variant="filterSummaryValue">
                          ID da transação:
                        </Typography>
                        <Typography variant="filterSummaryLabel">
                          {option.id}
                        </Typography>
                      </Texts>
                    
                    </>
                  )}
                </Body>
              </InnerContainer>
            </Container>
          );
        })}
      </OptionsContainer>
    </>
  );
};

export default Section;
