import {useParams} from "react-router";
import {ReceiveOnBoardingInviteResponse} from "../../../../shared/models/requests/receive-invite-onboarding";
import useOnBoardingInviteService from "../../../../shared/services/useOnBoardingService";
import useInquiryService from "../../../../shared/services/useInquiryService";
import {useEffect, useState} from "react";
import {OnBoardingRequest} from "../../../../shared/models/requests/onboarding-request";
import EOnboardingStep from "../../../../shared/models/backend/inquiry/EOnboardingStep";
import EPhotoSourceType from "../../../../shared/models/backend/inquiry/EPhotoSourceType";
import useCamera from "./useCamera";

export type OnboardingVideoDocType = 'RG' | 'CNH' | 'Passaporte' | 'Carteira de Trabalho' | null;

const useOnboardingVideo = () => {
    const {id} = useParams<{ id: string }>();
    const {
        receiveOnBoardingInvite,
        sendVideoOnBoarding,
        sendInquiryOnBoarding,
    } = useOnBoardingInviteService();
    const {saveInquiryPhotoAsync} = useInquiryService();
    const [shouldSendOnBoardingData, setShouldSendOnBoardingData] =
        useState(false);
    const [frontPhotoId, setFrontPhotoId] = useState<string | null>(null);
    const [backPhotoId, setBackPhotoId] = useState<string | null>(null);
    const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(
        null
    );
    const [selectedDocument, setSelectedDocument] = useState<OnboardingVideoDocType | null>(null);
    const [data, setData] = useState<ReceiveOnBoardingInviteResponse | null>(
        null
    );
    const [videoOnBoardingId, setVideoOnBoardingId] = useState<string | null>(
        null
    );
    const [step, setStep] = useState<EOnboardingStep>(EOnboardingStep.Introduction);
    const [filesSource, setFilesSource] = useState<EPhotoSourceType>(EPhotoSourceType.Camera);
    const [badRequest, setBadRequest] = useState(false);
    const [onBoardingExpired, setOnBoardingExpired] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        previewRef,
        videoRef,
        chunksRef,
        mediaRecorder,
        videoUrl,
        recording,
        videoSize,
        canvasRef,
        isCaptured,
        handleCapture,
        handleStart,
        handleStop,
        setVideoUrl,
        setIsCaptured,
    } = useCamera();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            const response = await receiveOnBoardingInvite(id);
            setLoading(false); 
            if (!response?.isSuccess) {
                if (response?.status === 2) {
                    setBadRequest(true);
                    return;
                }
                if (response?.status === 5) {
                    setOnBoardingExpired(true);
                    return;
                }
                return;
            }
            setData(response);
        };
        fetchData();
    }, [id]);

    const documentIds = {
        RG: 1,
        CNH: 2,
        Passaporte: 8,
        "Carteira de Trabalho": 9,
    };

    const handleRerecord = () => {
        setStep((step) => step - 1);
        setVideoUrl(null);
    };
    const sendVideo = async () => {
        const response = await fetch(videoUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise<string>((resolve) => {
            reader.onloadend = async function () {
                const base64data = reader.result;
                const videoId = await sendVideoOnBoarding(base64data as string);
                setVideoOnBoardingId(videoId);
                resolve(videoId);
            };
        });
    };
    const handleNext = async (
        setPhotoId: React.Dispatch<React.SetStateAction<string | null>>,
        shouldSend = false
    ): Promise<void> => {
        if (data.inviteDTO.isNoDocOnboarding && step == EOnboardingStep.VideoDisplay) {
            setStep(EOnboardingStep.Result);
            setShouldSendOnBoardingData(shouldSend);
            return;
        }
        if(step == EOnboardingStep.UploadDocument && shouldSend){
            setStep(EOnboardingStep.Result);
            setShouldSendOnBoardingData(shouldSend);
            return;
        }
        if (canvasRef.current) {
            const base64Image = canvasRef.current.toDataURL();
            const fileContent = base64Image.replace(/^data:image\/\w+;base64,/, "");
            const photoId = await saveInquiryPhotoAsync({fileContent});
            setPhotoId(photoId);
            setIsCaptured(false);
            if (selectedDocument === "CNH" || selectedDocument === "Passaporte") {
                shouldSend = true;
                setStep(EOnboardingStep.Result);
                setShouldSendOnBoardingData(shouldSend);
                return;
            }
            setShouldSendOnBoardingData(shouldSend);
            nextStep();
        }
    };
    useEffect(() => {
        if (shouldSendOnBoardingData) {
            sendOnBoardingData();
        }
    }, [shouldSendOnBoardingData, backPhotoId]);

    const sendOnBoardingData = async () => {
        const request: OnBoardingRequest = {
            documentType: selectedDocumentId,
            publicId: id,
            video: videoOnBoardingId,
            frontPhoto: frontPhotoId,
            backPhoto: backPhotoId,
        };
        await sendInquiryOnBoarding(request);
    };
    const nextStep = () => {
        if (step === EOnboardingStep.DocumentType)
            return setStep(EOnboardingStep.SetDocumentFileSource);
        setStep(step + 1);
    };
    return {
        mediaRecorder,
        recording,
        videoSize,
        videoUrl,
        isCaptured,
        selectedDocument,
        data,
        step,
        canvasRef,
        videoRef,
        previewRef,
        chunksRef,
        filesSource,
        frontPhotoId,
        setFrontPhotoId,
        setBackPhotoId,
        setSelectedDocumentId,
        setSelectedDocument,
        setIsCaptured,
        handleStart,
        handleStop,
        nextStep,
        handleRerecord,
        sendVideo,
        handleCapture,
        handleNext,
        setFilesSource,
        documentIds,
        setStep,
        sendOnBoardingData,
        badRequest,
        onBoardingExpired,
        loading,
    };
};

export default useOnboardingVideo;
