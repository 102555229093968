import React, {useEffect} from "react";
import EInquiryType, {EInquiryTypeDescriptions} from "../../shared/models/backend/inquiry/EInquiryType";
import {Palette} from "../../shared/theme/palette";
import Typography from "../../components/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import VideocamIcon from "@mui/icons-material/Videocam";
import {FormatUtils} from "../../shared/utils/format-utils";
import {IconContainer, IconRow, RowDivDetailsSection, StyledContainer} from "./styles";
import {InquiryReturnDetailsDTO} from "../../shared/models/backend/inquiry/inquiry-details-onboarding-dto";
import InquiryCardRow from "./InquiryCardRow";
import {ELoanModalityDescriptions} from "../../shared/models/enums/ELoanModality";
import {ELoanTypeDescriptions} from "../../shared/models/enums/ELoanType";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

interface InquiryDataCardProps {
    inquiry?: InquiryReturnDetailsDTO<any>;
}

const InquiryInputInfoCard = ({inquiry}: InquiryDataCardProps) => {
    const [icon, setIcon] = React.useState<React.ReactNode | null>(null);
    const theme = useThemeWhiteLabel();

    useEffect(() => {
        const iconMap = {
            [EInquiryType.Email]: <EmailIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.Telefone]: <PhoneIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.VideoOnboarding]: <VideocamIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.PayrollLoanVideoOnboarding]: <VideocamIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.DocumentoAutomatic]: <InsertDriveFileIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.DocumentoComSelfieAutomatic]: <ContactPageIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.NoDocVideoOnboarding]: <VideocamIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.Documento]: <InsertDriveFileIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            [EInquiryType.DocumentoComSelfie]: <ContactPageIcon style={{ color: theme.primaryColor }} fontSize="large" />,
            default: <HelpIcon style={{ color: theme.primaryColor }} fontSize="large" />
        };

        setIcon(iconMap[inquiry?.type] || iconMap.default);
    }, [inquiry]);
    if (!inquiry) return null;

    return (
        <StyledContainer>
            <Typography variant={"filterTitle"} color={"green"}>{'Dados de Entrada'}</Typography>
            <IconRow>
                <InquiryCardRow label="Tipo" value={EInquiryTypeDescriptions[inquiry.type]}/>
                <IconContainer>{icon}</IconContainer>
            </IconRow>
            {
                inquiry?.additionalInfo.Beneficiary && <>

                    <InquiryCardRow label="Nome completo do beneficiário"
                                    value={inquiry?.additionalInfo?.Beneficiary?.Name}/>
                    <InquiryCardRow label="CPF do beneficiário"
                                    mask={'document'}
                                    value={inquiry?.additionalInfo?.Beneficiary?.Cpf}/>
                    <InquiryCardRow label="Data de Nascimento do Beneficiário"
                                    mask={'date'}
                                    value={inquiry?.additionalInfo?.Beneficiary?.Birthdate}/>
                    <InquiryCardRow label="WhatsApp do Beneficiário"
                                    mask={'phone'}
                                    value={FormatUtils.formatPhoneNumber(inquiry?.additionalInfo?.Beneficiary?.Whatsapp)}/>
                </>
            }
            {
            inquiry?.additionalInfo.LineData?.OriginalInputPhone && (
                <InquiryCardRow label="Telefone de entrada"
                                mask={'phone'}
                                value={inquiry?.additionalInfo?.LineData?.OriginalInputPhone}/>
            )
            }
            {
                inquiry?.additionalInfo.LegalRepresentative &&
                <>

                    <InquiryCardRow label="Nome completo do Responsável Legal"
                                    value={inquiry?.additionalInfo?.LegalRepresentative?.Name}/>
                    <InquiryCardRow label="CPF do Responsável Legal"
                                    mask={'document'}
                                    value={inquiry?.additionalInfo?.LegalRepresentative?.Cpf}/>
                    <InquiryCardRow label="Data de Nascimento do Responsável Legal"
                                    mask={'date'}
                                    value={inquiry?.additionalInfo?.LegalRepresentative?.Birthdate}/>
                    <InquiryCardRow label="WhatsApp do Responsável Legal"
                                    mask={'phone'}
                                    value={FormatUtils.formatPhoneNumber(inquiry?.additionalInfo?.LegalRepresentative?.Whatsapp)}/>
                </>
            }
            {
                <>
                    {inquiry?.additionalInfo?.Contracts?.map((contract, index) => {
                        return (
                            <div key={index}>

                                <InquiryCardRow label={"Contrato Número " + contract.ContractNumber} value={' '}/>
                                <RowDivDetailsSection>
                                    <InquiryCardRow
                                        label="Valor do Empréstimo"
                                        mask={'currency'}
                                        value={contract.Value}/>
                                    <InquiryCardRow label="Valor da Parcela"
                                                    mask={'currency'}
                                                    value={contract.InstallmentValue}/>
                                    <InquiryCardRow label="Quantidade de Parcelas"
                                                    value={contract.Installments}/>
                                </RowDivDetailsSection>
                                <RowDivDetailsSection>
                                    <InquiryCardRow label="Data de Contratação"
                                                    mask={'date'}
                                                    value={contract.ContractDate}/>
                                    <InquiryCardRow label="Banco" value={contract.BankName}/>
                                </RowDivDetailsSection>
                            </div>
                        );
                    })}
                </>
            }
            {inquiry?.additionalInfo?.ComplementText &&
                <div>

                    <InquiryCardRow label="Texto complementar" value={inquiry?.additionalInfo?.ComplementText}/>
                </div>
            }
            <div>

                <InquiryCardRow label="Nome completo" value={inquiry?.additionalInfo?.fullName}/>
                <InquiryCardRow label="Número"
                                mask={'phone'}
                                value={(inquiry?.additionalInfo?.phone)}
                />
                <InquiryCardRow label="Documento"
                                mask={'document'}
                                value={(inquiry?.additionalInfo?.cpf || inquiry?.additionalInfo?.document)}
                />
                <InquiryCardRow label="Email" value={inquiry?.additionalInfo?.email}/>
                <InquiryCardRow label="CPF" mask={'document'} value={(inquiry.additionalInfo?.cpf)}/>
                <InquiryCardRow label="WhatsApp" mask={'phone'} value={inquiry.additionalInfo?.phone}/>
                <InquiryCardRow label="Valor do empréstimo" mask={'currency'}
                                value={inquiry.additionalInfo?.loanValue}/>
                <InquiryCardRow label="Data da contratação" mask={'date'}
                                value={(inquiry.additionalInfo?.contractDate)}/>
                <InquiryCardRow label="Valor da parcela"
                                mask={'currency'}
                                value={inquiry.additionalInfo?.installmentValue}/>
                <InquiryCardRow label="Quantidade de parcelas"
                                value={inquiry.additionalInfo?.installmentsQuantity}/>
                <InquiryCardRow label="Tipo de empréstimo"
                                value={ELoanTypeDescriptions[inquiry.additionalInfo?.loanType]}/>
                <InquiryCardRow label="Modalidade de empréstimo"
                                value={ELoanModalityDescriptions[inquiry.additionalInfo?.loanModality]}/>
                <InquiryCardRow label="Banco" value={inquiry.additionalInfo.bankName}/>
                <InquiryCardRow label="Modelo de Onboarding"
                                hidden={inquiry?.type !== EInquiryType.VideoOnboarding}
                                value={inquiry.additionalInfo.presential ? "Presencial" : "Remoto - Via WhatsApp"}/>
                <InquiryCardRow label="Número"
                                mask={'phone'}
                                value={FormatUtils.formatPhoneNumber(inquiry.additionalInfo.PhoneNumber)}/>
                <InquiryCardRow label="Data e hora"
                                mask={'datetime'}
                                value={inquiry.createdOn}/>
                <InquiryCardRow label="CPF" value={inquiry.additionalInfo?.inputCpf || inquiry.inputCpf}/>
            </div>
        </StyledContainer>
    );
};


export default InquiryInputInfoCard;



