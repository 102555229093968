import { LoopOutlined, PhotoCamera, Warning } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import useWebcam from "../../../hooks/useWebcam";
import { MailingAdditionalInfoProps } from "../../../shared/models/backend/mailing/mailing";
import {
  FileRequestDocumentEnum,
  FileTypeEnum,
} from "../../../shared/models/enums/files.enum";
import FileInput from "../../../shared/models/fileInput";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import { Palette } from "../../../shared/theme/palette";
import {
  cameraConfig,
  isImageExtension,
} from "../../../shared/utils/webcam-utils";
import { FilePickerInput } from "../../Inquiries/components/styles";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingWithDocVariantProps } from "../index.types";
import { InstructionStepVariantsEnum } from "../InstructionsStep";
import {
  BoldTitleTypography,
  BoldTypography,
  CenterBox,
  OnboardingCardLowRadius,
  PhotoWebcamContainer,
  SubTitle500Typography,
  SubTitleInterTypography,
} from "../styles";

const TakePhotoSingleFile = ({
  variant,
  setActiveStep,
  additionalInfo,
}: {
  additionalInfo: MailingAdditionalInfoProps;
} & MailingWithDocVariantProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const hasImage =
    !!additionalInfo?.documentFrontImageUrl &&
    additionalInfo?.documentType !== FileRequestDocumentEnum.RG;
  const [image, setImage] = useState<FileInput | undefined>(
    hasImage
      ? {
          fileName: "document.png",
          fileContent: additionalInfo?.documentFrontImageUrl,
        }
      : undefined
  );
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const theme = useThemeWhiteLabel();
  const { permissionGranted, hasCamera, errorMessage } = useWebcam();
  const { sendMailingFile } = useMailingOnBoardingService();

  const handlePickImageChange = (event) => {
    const file = event.target.files[0];

    if (file && isImageExtension(file.name)) {
      const reader = new FileReader();
      reader.onloadend = () =>
        setImage({ fileName: file.name, fileContent: reader.result as string });
      reader.readAsDataURL(file);
      return;
    }
    toast.error(
      "Por favor, selecione um arquivo de imagem válido (*.png, *.jpeg, *.jpg)."
    );
  };

  const handleUploadImage = (isEditing?: boolean) => {
    const data = {
      publicId: id,
      documentType:
        variant === InstructionStepVariantsEnum.cnh
          ? FileRequestDocumentEnum.CNH
          : FileRequestDocumentEnum.Passport,
      fileName: image.fileName,
      fileContent: image.fileContent,
      fileType: FileTypeEnum.DocumentFront,
    };
    setIsLoading(true);
    sendMailingFile(data)
      .then(() => {
        setActiveStep(
          isEditing
            ? EMailingStepsEnum.PersonalDataForm
            : EMailingStepsEnum.VideoInstructions
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContainerContent>
      <BoldTitleTypography align="center" mb="40px">
        Utilize a câmera do seu dispositivo para tirar a fotografia.
        Certifique-se de que informações estão legíveis.
      </BoldTitleTypography>

      <OnboardingCardLowRadius sx={{ borderRadius: "10px !important" }}>
        <Grid container>
          <Grid item xs={12} md={8} order={0}>
            {errorMessage && !image && (
              <CenterBox>
                <Warning style={{ color: Palette.red }} fontSize="small" />
                <SubTitle500Typography sx={{ marginLeft: "8px" }}>
                  {errorMessage}
                </SubTitle500Typography>
              </CenterBox>
            )}
            {permissionGranted && hasCamera && !image && (
              <PhotoWebcamContainer
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  ...cameraConfig,
                  facingMode: "environment",
                }}
                mirrored={!isMobile}
              />
            )}
            {image?.fileContent && (
              <img
                src={image.fileContent}
                alt="foto do documento"
                style={isMobile ? undefined : { transform: "scaleX(-1)" }}
              />
            )}
            <FilePickerInput
              ref={fileInputRef}
              type="file"
              onChange={handlePickImageChange}
              accept="image/png, image/jpeg"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            pl={{ xs: "0px", md: "48px" }}
            order={{ xs: 2, md: 1 }}
          >
            <Stack gap="16px" height="100%" justifyContent="center">
              <Button
                color="primary"
                variant="outlined"
                size="large"
                disabled={!image || isLoading}
                startIcon={
                  <LoopOutlined
                    sx={{
                      color: !image
                        ? "rgba(0, 0, 0, 0.26)"
                        : theme.primaryColor,
                    }}
                  />
                }
                onClick={() => {
                  setImage(undefined);
                }}
              >
                Trocar Foto
              </Button>

              {hasImage ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={
                    image?.fileContent ===
                      additionalInfo?.documentFrontImageUrl || isLoading
                  }
                  sx={{ paddingInline: "24px" }}
                  startIcon={
                    image?.fileContent &&
                    image?.fileContent !==
                      additionalInfo?.documentFrontImageUrl ? undefined : isLoading ? (
                      <StyledCircularProgress size={16} />
                    ) : (
                      <PhotoCamera
                        sx={{
                          color:
                            hasImage && !!image
                              ? "rgba(0, 0, 0, 0.26)"
                              : theme.alternativeColor,
                        }}
                      />
                    )
                  }
                  onClick={() => {
                    if (image) {
                      handleUploadImage(true);
                      return;
                    }
                    setImage({
                      fileName: `document.png`,
                      fileContent: webcamRef.current.getScreenshot(),
                    });
                  }}
                >
                  {image?.fileContent &&
                  image?.fileContent !== additionalInfo?.documentFrontImageUrl
                    ? "Salvar alteração"
                    : "Tirar foto do documento"}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  sx={{ paddingInline: "24px" }}
                  startIcon={
                    image ? (
                      isLoading ? (
                        <StyledCircularProgress size={16} />
                      ) : undefined
                    ) : (
                      <PhotoCamera sx={{ color: theme.alternativeColor }} />
                    )
                  }
                  onClick={() => {
                    if (image) {
                      handleUploadImage();
                      return;
                    }
                    setImage({
                      fileName: `document.png`,
                      fileContent: webcamRef.current.getScreenshot(),
                    });
                  }}
                >
                  {image ? "Continuar" : "Tirar foto do documento"}
                </Button>
              )}

              <Button
                color="primary"
                variant="outlined"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (!hasImage) {
                    setActiveStep(EMailingStepsEnum.DocumentInstructions);
                    return;
                  }
                  setActiveStep(EMailingStepsEnum.PersonalDataForm);
                }}
              >
                {hasImage ? "Cancelar" : "Voltar para instruções"}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} order={{ xs: 1, md: 2 }}>
            <SubTitleInterTypography
              mt={{ xs: "24px", md: "40px" }}
              mb={{ xs: "24px", md: "0px" }}
              textAlign="center"
            >
              {hasImage &&
                additionalInfo?.documentFrontImageUrl ===
                  image?.fileContent && (
                  <span>
                    Para trocar a foto, basta clicar no botão{" "}
                    <BoldTypography>“Trocar foto”</BoldTypography>. Para
                    desistir da alteração{" "}
                    <BoldTypography>clique no botão “Cancelar”</BoldTypography>.
                  </span>
                )}

              {hasImage &&
                additionalInfo?.documentFrontImageUrl !==
                  image?.fileContent && (
                  <span>
                    Caso queira trocar a foto, basta clicar no botão{" "}
                    <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                    tenha ficado satisfatória,{" "}
                    <BoldTypography>
                      clique no botão “Salvar alteração”
                    </BoldTypography>{" "}
                    para confirmar a alteração.
                  </span>
                )}
              {image && !additionalInfo?.documentFrontImageUrl && (
                <span>
                  Caso queira trocar a foto, basta clicar no botão{" "}
                  <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                  tenha ficado satisfatória,{" "}
                  <BoldTypography>clique no botão “Continuar”</BoldTypography>{" "}
                  para realizar as próximas etapas.
                </span>
              )}
              {!image && (
                <span>
                  Quando o documento estiver bem posicionado na câmera, clique
                  no botão “Tirar foto do documento”.
                </span>
              )}
            </SubTitleInterTypography>
          </Grid>
        </Grid>
      </OnboardingCardLowRadius>
    </ContainerContent>
  );
};

export default TakePhotoSingleFile;
