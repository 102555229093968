import styled from "styled-components";

export const NewReportRequestContainer = styled.div`
    flex-direction: row;
    height: 48px;
    display: flex;
    justify-content: space-between;
`;
export const NewReportRequestTextContainer = styled.div`
    display: flex;
    height: 24px;
    gap: 32px;
    margin-right: 20px;
    justify-content: center;
`;