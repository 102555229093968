import styled from "styled-components";
import { Palette } from "../../../shared/theme/palette";

export const Container = styled.div`
  border-radius: 8px;
`;

export const InnerContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: ${Palette.white};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 35px;
`;

export const Textsdiv = styled.div`
  flex-direction: row;
`;

export const div = styled.div`
  flex-direction: column;
  gap: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; 
  margin-left: 220px;
`;
