import styled from "styled-components";
import { Palette } from "../../../shared/theme/palette";


export const ButtonContainer = styled.div`
  position: absolute;
  right: 80px;
`;
export const Row = styled.div`
  display: flex;
  gap: 30px;
  padding: 50px;
`;
export const FilterContainer = styled.div`
  position: relative;
  width: 1128px;
  height: 262px;
  padding: 20px 32px;
  border-radius: 8px;
  gap: 40px;
  background-color: ${Palette.white};
  box-shadow: 0px 4px 22px 0px #00000014;
  align-items: center;
`;
