
import styled from "styled-components";
import { Palette } from "../../../../shared/theme/palette";

export const HomeHeaderContainer = styled.div`
  padding: 42px 42px 42px 74px;
  flex-direction: column;
  background-color: ${Palette.white};
  border-bottom: 5px;
  border-color: ${Palette.lightBlue};
`;
export const HomeHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
export const HomeHeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;
export const HomeHeaderTitle = styled.text<{ primaryColor: string }>`
  font-size: 24px;
  font-weight: 500;
  font-family: 'Poppins Regular';
  color: ${(props) => props.primaryColor};
`;
export const HomeHeaderSubtitle = styled.text`
  font-size: 18px;
  font-weight: 400;
  font-family: 'Poppins Regular';
  color: ${Palette.black};
`;
export const HomeHeaderNoPlanAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const HomeHeaderNoPlanAlertTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;
export const HomeHeaderNoPlanAlertText = styled.text`
  width: 494px;
  flex-shrink: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: ${Palette.red};
`;
export const HomeHeaderChoosePlanButtonContainer = styled.div`
  display: flex;
  width: 272px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const HomeBalanceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;
export const HomeBalanceCardAlertTextContainer = styled.text`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
export const HomeBalanceCardAlertText = styled.text`
  color: ${Palette.red};
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
export const HomeBalanceCardRechargeButtonContainer = styled.text`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;