export class FormatUtils {
  static toPTBRDateFormat(date?: string | Date) {
    if (!date) return date;
    const parts = date.toString()?.split("T");
    const stringDate = parts[0];
    const currentFormatParts = stringDate.includes("/") ? stringDate?.split("/") : stringDate?.split("-");
    return `${currentFormatParts[2]}/${currentFormatParts[1]}/${currentFormatParts[0]}`;
  }

  static toDateFormat(date: Date, format = 'dd/mm/yyyy') {
    if (!date) return "";

    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear();
    
    switch (format) {
      case 'dd mm yyyy':
        return `${day} ${month} ${year}`;
      case 'yyyy-mm-dd':
        return `${year}-${month}-${day}`;
      case 'dd/mm/yyyy':
        return `${day}/${month}/${year}`;
      default:
        throw { message: 'Formato não implementado!' }
    }
  }

  static fromBRLCurrency(value: string): number {
    const numericValue = parseFloat(value.replace("R$", "").replace(",", "."));
    return isNaN(numericValue) ? 0 : numericValue;
  }
  static toBRLCurrency(value) {
    if (!value) return "R$ 0,00";
    return `R$ ${parseFloat(value).toFixed(2).replace(".", ",")}`;
  }
  static formatCPF(input) {
    if (!input) return input;

    let cpf = input.toString().replace(/\D/g, "").padStart(11, "0");
    cpf = cpf.length > 11 ? cpf.slice(0, 11) : cpf;

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  static formatCNPJ(input) {
    if (!input) return input;

    let cnpj = input.toString().replace(/\D/g, "").padStart(14, "0");
    cnpj = cnpj.length > 14 ? cnpj.slice(0, 14) : cnpj;

    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  static maskCpfOrCnpj(input: string) {
    if (!input) return input;
    return input.length <= 11 ? this.formatCPF(input) : this.formatCNPJ(input);
  }

  static formatPhoneNumber(input) {
    if (!input) return input;
    let phone = input.toString().replace(/\D/g, "");
    if (phone.length > 13) phone = phone.slice(0, 13);
    if (phone.length === 13) {
      return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
    }
    if (phone.length === 11) {
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
    return phone;
  }
  static unMaskMoney(value: string): number {
    const numericValue = parseFloat(value.replace("R$", "").replace(/\./g, "").replace(",", "."));
    return isNaN(numericValue) ? 0 : numericValue;
}
}
