
import { removeMask } from "../shared/utils/remove-mask";
import apiCorreios from "../shared/services/api/api-correios";
import {CompleteAddressData} from "../shared/models/complete-address-data";
import {AddressByCepResult} from "../shared/models/via-cep/address-by-cep-result";

const useCepSearch = () => {
    const searchCompleteAddressByZipCode = async (zipCode: string,
                                                  setCompleteAddress: (address: CompleteAddressData) => void
    ) => {
        try {
            if (!zipCode || removeMask(zipCode)?.length !== 8) return;
            const completeAddress = (await apiCorreios.get<AddressByCepResult>(`/${removeMask(zipCode)}/json/`)).data;
            !!setCompleteAddress && setCompleteAddress({
                addressComplement: completeAddress.complemento,
                addressName: completeAddress.logradouro,
                city: completeAddress.localidade,
                neighborhood: completeAddress.bairro,
                state: completeAddress.uf,
                zipCode: zipCode,
                country: 'BR',
            });
        } catch {
           alert('CEP não encontrado');
        }
    };

    return [searchCompleteAddressByZipCode];
};

export default useCepSearch;

