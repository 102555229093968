const useMoney = () => {
  const toString = (value?: number) => {
    return value?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return { toString };
};

export default useMoney;
