import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  .shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
export const TextsContainer = styled.div`
  max-width: 648px;
  padding-bottom: 15px;
`;

export default GlobalStyles;
