import { Variants, motion } from "framer-motion";

export type FadeInProps = {
  show?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
} & React.PropsWithChildren;

export function FadeIn({
  show = true,
  fullWidth = false,
  fullHeight = false,
  children,
}: FadeInProps) {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  } as Variants;

  return (
    <motion.div
      initial="hidden"
      animate={show ? "visible" : "hidden"}
      exit="hidden"
      style={{
        width: fullWidth ? "100%" : "auto",
        height: fullHeight ? "100%" : "auto",
      }}
      variants={variants}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}
