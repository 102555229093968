

import PropTypes from "prop-types";
import { FlexDiv } from "./styles";
import Typography from "../../../../../../components/Typography";
const DescriptionTextWithLabel = ({ title, value }) => {
  if (value !== null && value !== undefined) {
    return (
      <FlexDiv>
        <Typography variant={"caption5"}>{title}</Typography>
        <Typography variant={"modalCancel"}>{value}</Typography>
      </FlexDiv>
    );
  }
  return null;
};

DescriptionTextWithLabel.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default DescriptionTextWithLabel;
