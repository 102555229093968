import { useEffect, useState } from "react";
import { useParams } from "react-router";
import EPhotoSourceType from "../../../../shared/models/backend/inquiry/EPhotoSourceType";
import { PayrollLoanOnboardingFilesRequest } from "../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";
import { EInquiryFileTypeDescriptions } from "../../../../shared/models/enums/EInquiryFileType";
import { FileTypeEnum } from "../../../../shared/models/enums/files.enum";
import { PayrollLoanOnboardingResponse } from "../../../../shared/models/responses/payroll-loan-onboarding-response";
import usePayrollLoanOnboardingService from "../../../../shared/services/usePayrollLoanOnboardingService";
import useCamera from "../../../onBoardingVideo/onBoardingVideo/hooks/useCamera";
import { OnboardingVideoDocType } from "../../../onBoardingVideo/onBoardingVideo/hooks/useOnBoardingVideo";

const usePayRollLoanOnboardingOnBoardingVideo = () => {
  const { id } = useParams<{ id: string }>();

  const {
    receivePayrollLoanOnboardingInvite,
    sendFilesPayRollVideoOnBoarding,
    sendPayRollVideoOnBoarding,
  } = usePayrollLoanOnboardingService();
  const [selectedDocumentId, setSelectedDocumentId] = useState<number>();
  const [selectedDocument, setSelectedDocument] =
    useState<OnboardingVideoDocType | null>(null);
  const [data, setData] = useState<PayrollLoanOnboardingResponse | null>(null);
  const [step, setStep] = useState(0);
  const [badRequest, setBadRequest] = useState(false);
  const [onBoardingExpired, setOnBoardingExpired] = useState(false);
  const [filesSource, setFilesSource] = useState<EPhotoSourceType>(
    EPhotoSourceType.Camera
  );

  const documentIds = {
    RG: 1,
    CNH: 2,
    CTPS: 8,
    Passport: 9,
  };
  const {
    previewRef,
    videoRef,
    chunksRef,
    mediaRecorder,
    videoUrl,
    recording,
    videoSize,
    canvasRef,
    isCaptured,
    setVideoUrl,
    setIsCaptured,
    handleCapture,
    handleStart,
    handleStop,
  } = useCamera();

  const handleRerecord = () => {
    setStep((step) => step - 1);
    setVideoUrl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await receivePayrollLoanOnboardingInvite(id);
      if (!response?.isSuccess) {
        if (response?.status === 2) setBadRequest(true);
        if (response?.status === 5 || !response) setOnBoardingExpired(true);
        return;
      }
      setData(response);
    };
    fetchData();
  }, [id]);

  const handleNext = async (
    fileName: string,
    fileType: FileTypeEnum,
    shouldCallNextStep: boolean = true,
    contractId: number = null
  ): Promise<void> => {
    let fileContent = "";
    if (
      fileType === FileTypeEnum.PersonalDataVideo ||
      fileType === FileTypeEnum.ComplementTextVideo ||
      fileType === FileTypeEnum.ContractVideo
    ) {
      const response = await fetch(fileName);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      fileContent = await new Promise<string>((resolve) => {
        reader.onloadend = function () {
          resolve(reader.result as string);
        };
      });
      fileName = EInquiryFileTypeDescriptions[fileType];
    } else if (canvasRef.current) {
      const base64Image = canvasRef.current.toDataURL();
      fileContent = base64Image.replace(/^data:image\/\w+;base64,/, "");
    }
    const request: PayrollLoanOnboardingFilesRequest = {
      contractId: contractId,
      fileName: fileName,
      fileContent: fileContent,
      fileType: fileType,
      documentType: selectedDocumentId,
    };
    await sendFilesPayRollVideoOnBoarding(request, id);
    setIsCaptured(false);
    if (shouldCallNextStep) nextStep();
  };
  const nextStep = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    if (nextStep === 9)
      sendPayRollVideoOnBoarding(id).catch(() => setStep(step - 1));
  };
  return {
    mediaRecorder,
    recording,
    videoSize,
    videoUrl,
    isCaptured,
    selectedDocument,
    data,
    step,
    canvasRef,
    videoRef,
    previewRef,
    chunksRef,
    documentIds,
    filesSource,
    setSelectedDocumentId,
    setSelectedDocument,
    setIsCaptured,
    nextStep,
    handleNext,
    setVideoUrl,
    handleCapture,
    handleStart,
    handleStop,
    handleRerecord,
    badRequest,
    onBoardingExpired,
    setStep,
    setFilesSource,
  };
};

export default usePayRollLoanOnboardingOnBoardingVideo;
