import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;
export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: auto; 
`;
export const Line = styled.hr<{ primaryColor: string }>`
  flex-grow: 1;
  border: none;
  border-top: 1px solid ${(props) => props.primaryColor}; 
`;
