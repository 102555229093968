import InquiryListDto from '../models/backend/inquiry/inquiry-list-dto';
import SendDocumentInquiryInput from '../models/backend/inquiry/send-document-inquiry-input';
import CreateAndSendEmailInquiryRequest from '../models/backend/inquiry/CreateAndSendEmailInquiryRequest';
import CreateAndSendInquiryResponse from '../models/backend/inquiry/create-and-send-inquiry-response';
import CreateAndSendPhoneInquiryRequest from '../models/backend/inquiry/create-and-send-phone-inquiry-request';
import SaveInquiryPhotoRequest from '../models/backend/inquiry/save-inquiry-photo-request';
import {ResendInquiryRequest} from '../models/requests/enviar-consulta-request';
import {EnviarConsultaResponse} from '../models/responses/enviar-consulta-response';
import useConsultaiApi from './context/hooks/useConsultaiApi';
import CreateOnboardingInviteCommand from '../models/requests/CreateOnboardingInviteCommand';
import EInquiryStatus from '../models/backend/inquiry/EInquiryStatus';
import InquiryListItemDto from '../models/backend/inquiry/inquiry-extended-list-dto';
import WatermarkedVideoGenerationResponse from '../models/backend/watermark/WatermarkedVideoGenerationResponse';
import FileInput from "../models/fileInput";
import {InquiryReturnDetailsDTO} from "../models/backend/inquiry/inquiry-details-onboarding-dto";

const useInquiryService = () => {
    const consultaiApi = useConsultaiApi();

    const getListAsync = async (page?: number, statuses?: EInquiryStatus[]) => {
        const perPage = 9;
        let url = `/consulta?Page=${page}&PageSize=${perPage}`;
        if (statuses && statuses.length > 0) {
            url += statuses.map((status) => `&InquiryStatuses=${status}`).join('');
        }
        return await consultaiApi.get<InquiryListDto>(url, 1);
    };

    const createAndSendEmailInquiryAsync = async (
        request: CreateAndSendEmailInquiryRequest
    ): Promise<CreateAndSendInquiryResponse> =>
        await consultaiApi.post<CreateAndSendInquiryResponse>(
            '/inquiries/mail/send',
            2,
            request
        );

    const createAndSendPhoneInquiryAsync = async (
        request: CreateAndSendPhoneInquiryRequest
    ): Promise<CreateAndSendInquiryResponse> =>
        await consultaiApi.post<CreateAndSendInquiryResponse>(
            '/inquiries/phone/send',
            2,
            request
        );

    const createAndSendDocumentInquiryAsync = async (
        input: SendDocumentInquiryInput
    ) =>
        await consultaiApi.post<CreateAndSendInquiryResponse>(
            '/inquiries/doc/send',
            2,
            input
        );

    const createAndSendDocumentWithSelfieInquiryAsync = async (
        input: SendDocumentInquiryInput
    ) =>
        await consultaiApi.post<CreateAndSendInquiryResponse>(
            '/inquiries/doc-selfie/send',
            2,
            input
        );

        const createAndSendDocumentAutomaticInquiryAsync = async (
            input: SendDocumentInquiryInput
        ) =>
            await consultaiApi.post<CreateAndSendInquiryResponse>(
                '/inquiries/doc-automatic/send',
                2,
                input
            );
    
        const createAndSendDocumentAutomaticWithSelfieInquiryAsync = async (
            input: SendDocumentInquiryInput
        ) =>
            await consultaiApi.post<CreateAndSendInquiryResponse>(
                '/inquiries/doc-automatic-selfie/send',
                2,
                input
            );
    
    const saveInquiryPhotoAsync = async (
        request: SaveInquiryPhotoRequest
    ): Promise<string> =>
        await consultaiApi.post<string>('/inquiries/photo', 2, request);

    const saveInquiryFileAsync = async (
        request: FileInput
    ): Promise<string> =>
        await consultaiApi.post<string>('/inquiries/file', 2, request);

    const postResendInquiry = (request: ResendInquiryRequest) =>
        consultaiApi.post<EnviarConsultaResponse>(
            `/Consulta/${request.id}/resend`,
            1
        );

    const createOnboardingInvite = async (
        request: CreateOnboardingInviteCommand
    ) => consultaiApi.post(`/inquiries/onboarding/invite`, 1, request);

    const getListAsyncV2 = async (page?: number, statuses?: EInquiryStatus[], filter?: string) => {
        filter = filter ? filter : '';
        let url = `/Inquiries/list?Filter.Page=${page}&Filter.Search=${filter}`;
        if (statuses && statuses.length > 0) {
            url += statuses
                .map((status) => `&Filter.InquiryStatuses=${status}`)
                .join('');
        }
        return await consultaiApi.get<InquiryListItemDto>(url, 2);
    };

    const generateWatermarkedVideoAsync = async (inquiryId: number) => {
        return await consultaiApi.post<WatermarkedVideoGenerationResponse>(
            `/inquiries/onboarding/generate-watermarked-video/${inquiryId}`
        );
    };

    const getWatermarkedVideoUrlAsync = async (inquiryId: number) => {
        return await consultaiApi.get<string>(
            `/inquiries/onboarding/watermarked-video-url/${inquiryId}`
        );
    };
    const getAsync = async <T>(id: number): Promise<InquiryReturnDetailsDTO<T>> => {
        return await consultaiApi.get<InquiryReturnDetailsDTO<T>>(`/inquiries/${id}/return`, 2);
    };

    return {
        getAsync,
        getListAsync,
        createAndSendDocumentInquiryAsync,
        createAndSendDocumentWithSelfieInquiryAsync,
        createAndSendDocumentAutomaticInquiryAsync,
        createAndSendDocumentAutomaticWithSelfieInquiryAsync,
        saveInquiryPhotoAsync,
        saveInquiryFileAsync,
        createAndSendEmailInquiryAsync,
        createAndSendPhoneInquiryAsync,
        postResendInquiry,
        createOnboardingInvite,
        getListAsyncV2,
        generateWatermarkedVideoAsync,
        getWatermarkedVideoUrlAsync,
    };
};

export default useInquiryService;
