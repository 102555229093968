import { Grid, Stack } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import {
  BoldContractText,
  ContractSectionTitle,
  ContractText,
} from "../styles";

const PersonalDataRegisterContractSection = ({ page }: { page: 3 | 4 }) => {
  if (page === 3) {
    return (
      <>
        <Grid item xs={12}>
          <ContractSectionTitle>
            4 - FICHA CADASTRAL - PESSOA FÍSICA DADOS PESSOAIS - NÃO SE APLICA
          </ContractSectionTitle>
        </Grid>
        <Grid item xs={12}>
          <ContractText>
            Nome Completo (sem abreviações): CLAUDIANE CORREIA DE SAMPAIO
          </ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Sexo:</ContractText>
          <Stack direction="row" gap="16px">
            <MailingContractCheckbox label="Masculino" />
            <MailingContractCheckbox label="Feminino" />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Data de Nascimento:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>CPF:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Profissão:</ContractText>
        </Grid>

        <Grid item xs={12}>
          <ContractText>Documento de identificação:</ContractText>
          <Stack direction="row" gap="24px">
            <MailingContractCheckbox label="RG" />
            <MailingContractCheckbox label="CNH" />
            <MailingContractCheckbox label="Carteira de classe profissional" />
            <MailingContractCheckbox label="RNE" />
            <MailingContractCheckbox label="Passaporte" />
            <MailingContractCheckbox label="Outros: _______________" />
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <ContractText>Número do Documento:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Órgão emissor:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Data de emissão:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Data de Vencimento (se aplicável):</ContractText>
          <ContractText>&nbsp;</ContractText>
        </Grid>

        <Grid item xs={3}>
          <ContractText>Local de Nascimento:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>UF:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>Nacionalidade:</ContractText>
        </Grid>
        <Grid item xs={3}>
          <ContractText>
            Data de entrada no Brasil (se estrangeiro):
          </ContractText>
          <ContractText>&nbsp;</ContractText>
        </Grid>

        <Grid item xs={12}>
          <ContractText>Possui Domicílio Fiscal no Exterior? </ContractText>
          <Stack direction="row" gap="16px">
            <MailingContractCheckbox label="Sim" />
            <MailingContractCheckbox label="Não" />
          </Stack>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <BoldContractText>
          Caso tenha indicado acima o local de nascimento nos Estados Unidos da
          América (E.U.A.), cidadania ou nacionalidade americana e/ou tenha
          endereço nos E.U.A., porém não seja residente fiscal nos E.U.A., é
          necessário preencher e assinar o ANEXO FATCA, que formará parte
          integrante desta ficha cadastral.
        </BoldContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>Nome do Pai:</ContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>Nome da Mãe:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>
      <Grid item xs={12}>
        <ContractText>Estado Civil: </ContractText>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox label="Solteiro(a)" />
          <MailingContractCheckbox label="Casado(a)" />
          <MailingContractCheckbox label="União estável" />
          <MailingContractCheckbox label="Divorciado(a)" />
          <MailingContractCheckbox label="Separado(a) judicialmente" />
          <MailingContractCheckbox label="Viúvo(a)" />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <ContractText>Nome do Cônjuge/Companheiro (a):</ContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>CPF:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>

      <Grid item xs={3}>
        <ContractText>Endereço Residencial: </ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>N.º: </ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Complemento:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Bairro:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>

      <Grid item xs={4}>
        <ContractText>Cidade/UF:</ContractText>
        <ContractText>SAO PAULO/SP</ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>CEP:</ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>Residência: </ContractText>
        <Stack direction="row" gap="8px">
          <MailingContractCheckbox label="Própria" />
          <MailingContractCheckbox label="Mora com pais/avós" />
          <MailingContractCheckbox label="Alugada" />
        </Stack>
      </Grid>

      <Grid item xs={4}>
        <ContractText>Telefone: </ContractText>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox label="Próprio" />
          <MailingContractCheckbox label="Para recado" />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <ContractText>DDI/DDD - Número </ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>Ramal:</ContractText>
      </Grid>

      <Grid item xs={6}>
        <ContractText>Celular: DDI /DDD - Número</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>E-mail:</ContractText>
      </Grid>

      <Grid item xs={12}>
        <ContractText>
          Empresa para a qual trabalha/ Fonte Pagadora:
        </ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>

      <Grid item xs={4}>
        <ContractText>Endereço comercial:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>N.º:</ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>Complemento:</ContractText>
      </Grid>

      <Grid item xs={3}>
        <ContractText>Bairro:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Cidade/UF:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>CEP:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Telefone:</ContractText>
      </Grid>

      <Grid item xs={12}>
        <ContractSectionTitle>
          REFERÊNCIA PESSOAL - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={6}>
        <ContractText>Nome da Referência:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>

      <Grid item xs={6}>
        <ContractText>Telefone: (DDI/DDD Número - Ramal)</ContractText>
      </Grid>

      <Grid item xs={12}>
        <ContractSectionTitle>
          RENDIMENTOS MENSAIS - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>

      <Grid item xs={6}>
        <ContractText>1. Salário/Pró-labore:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>

      <Grid item xs={6}>
        <ContractText>
          2. Outros Rendimentos Mensais (Especificar):
        </ContractText>
      </Grid>

      <Grid item xs={12}>
        <ContractSectionTitle>
          DADOS PATRIMONIAIS - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>

      <Grid item xs={12}>
        <ContractText>Possui bens móveis e imóveis?</ContractText>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox label="Não" />
          <MailingContractCheckbox label="Sim, conforme abaixo:" />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Imóveis:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Automóveis:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Investimentos:</ContractText>
      </Grid>
      <Grid item xs={3}>
        <ContractText>Outros:</ContractText>
        <ContractText>&nbsp;</ContractText>
      </Grid>
    </>
  );
};

export default PersonalDataRegisterContractSection;
