import React from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import Routes from "../constants/Routes";
import {Clickable} from "../theme/GlobalStyles";
import useWhitelabel from "../hooks/useWhitelabel";
import {Skeleton} from "@mui/material";

const LogoImage = styled.img`
    width: 135px;
    max-height: 80px;
    padding: 6px;
`;

const Logo: React.FC = () => {
    const navigate = useNavigate();
    const {whitelabelLogo} = useWhitelabel();
    return (
        <Clickable onClick={() => navigate(Routes.Home)}>
            {whitelabelLogo
                ? <LogoImage src={whitelabelLogo} alt="Logo" style={{objectFit: 'contain'}}/>
                : <Skeleton variant="rectangular" width={135}/>
            }
        </Clickable>
    );
};

export default Logo;