/* eslint-disable */
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Palette } from '../shared/theme/palette';
import { useThemeWhiteLabel } from '../hooks/useThemeWhiteLabel';

interface MaterialUiThemeProviderProps {
  children: React.ReactNode;
}

const MaterialUiThemeProvider: React.FC<MaterialUiThemeProviderProps> = ({ children }) => {
  const theme = useThemeWhiteLabel();

  const materialUiTheme = createTheme({
    palette: {
      primary: {
        main: theme.primaryColor,
        light: theme.secondaryColor,
        dark: theme.tertiaryColor,
      },
      secondary: {
        main: Palette.darkGrey1,
        light: Palette.lightGrey4,
        dark: Palette.darkGrey2,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Remove the transformation of text to uppercase
            paddingTop: '0.5rem',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            fontFamily: 'Inter',
            minWidth: '13.125rem',
          },
          outlined: {
            borderWidth: 1,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: '1rem',
            fontFamily: 'Inter',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  return (
    <ThemeProvider theme={materialUiTheme}>
      {children}
    </ThemeProvider>
  );
};

export default MaterialUiThemeProvider;