
export class DateUtils {
  static addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate?.getDate() + days);
    return newDate;
  }

  static setUTCTime(date: Date): Date {
    const newDate = new Date(date);
    newDate.setUTCHours(12, 0, 0, 0);
    return newDate;
  }

  static formatDateToBrazilian(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  static stringToFormattedDate(date: string, format = "dd/mm/yyyy", separator = "/") : Date {
    const { day, month, year } = DateUtils.getDateParts(
      date,
      format,
      separator
    );

    return DateUtils.setUTCTime(new Date(year, month, day));
  }

  static isValid(date: string, format = "dd/mm/yyyy", separator = "/") : boolean {
    const { day, month, year } = DateUtils.getDateParts(
      date,
      format,
      separator
    );

    const dateObj = DateUtils.setUTCTime(new Date(Date.UTC(year, month, day)));

    return (
      dateObj.getDate() === day &&
      dateObj.getMonth() === month &&
      dateObj.getFullYear() === year
    );
  }

  static getDateParts(date: string, format = "dd/mm/yyyy", separator = "/") {
    const parts = date.split(separator);

    let day: number, month: number, year: number;

    switch (format) {
      case "dd/mm/yyyy":
        day = parseInt(parts[0]);
        month = parseInt(parts[1]) - 1;
        year = parseInt(parts[2]);
        break;
      case "yyyy-mm-dd":
        day = parseInt(parts[2]);
        month = parseInt(parts[1]) - 1;
        year = parseInt(parts[0]);
        break;
      default:
        throw { message: "Formato de data não implementado" };
    }

    return { day, month, year };
  }
  static parseDate(date: string): Date {
    return new Date(date.split("/").reverse().join("-"));
  }
  static parseDateInUTC(date: string): Date {
    return this.setUTCTime(new Date(date.split("/").reverse().join("-")));
  }
  static getTime(date: Date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  }
  static stringToDateISOString = (date: string | Date) => {
    if (!date) return null;
    const formattedDate = DateUtils.stringToFormattedDate(date as string, "dd/mm/yyyy");
    return formattedDate.toISOString();
  };
}
