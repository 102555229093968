import { Box, Button, Grid } from "@mui/material";
import Modal from "../../../../../components/Modal";
import { ValidationUtils } from "../../../../../utils/validation-utils";
import Typography from "../../../../../components/Typography";
import Input from "../../../../../components/Input/Input";
import { ButtonContainer } from "./contracts/styles";
import { useState } from "react";

interface ModalEditDataProps {
    onClose: () => void;
    title: string;
    name: string;
    cpf: string;
    phone: string;
    birthDate: string;
    setName: (value: string) => void;
    setCPF: (value: string) => void;
    setPhone: (value: string) => void;
    setbirthDate: (value: string) => void;
}

const ModalEditData = ({
    onClose,
    title,
    name: initialName,
    cpf: initialCpf,
    phone: initialPhone,
    birthDate: initialBirthDate,
    setName,
    setCPF,
    setPhone,
    setbirthDate,
}: ModalEditDataProps) => {
    const [name, setNameLocal] = useState(initialName);
    const [cpf, setCpfLocal] = useState(initialCpf);
    const [phone, setPhoneLocal] = useState(initialPhone);
    const [birthDate, setBirthDateLocal] = useState(initialBirthDate);

    const handleSave = () => {
        setName(name);
        setCPF(cpf);
        setPhone(phone);
        setbirthDate(birthDate);
        onClose();
    };

    const handleClose = () => {
        setNameLocal(initialName);
        setCpfLocal(initialCpf);
        setPhoneLocal(initialPhone);
        setBirthDateLocal(initialBirthDate);
        onClose();
    };

    return (
        <Modal onClose={onClose} title={title}>
            <Box>
                <Typography variant="caption3">Dados do representante legal</Typography>
                <Typography variant="caption">
                    Preencha os campos abaixo com os dados do representante legal, essas
                    informações serão utilizadas no texto-guia que deverá ser lido durante
                    a gravação do vídeo de onboarding. É obrigatório que o/a representante
                    legal esteja com registro em dia no INSS.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            placeholder="Nome Completo do beneficiário*"
                            value={name}
                            onChange={setNameLocal}
                            required
                            width={"280px"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            placeholder="CPF"
                            value={cpf}
                            onChange={setCpfLocal}
                            required
                            validationCallback={ValidationUtils.validateCpf}
                            mask="999.999.999-99"
                            label={"CPF"}
                            width={"280px"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            placeholder="WhatsApp do beneficiário"
                            onChange={setPhoneLocal}
                            value={phone}
                            required
                            validationCallback={ValidationUtils.validatePhone}
                            mask="(99) 99999-9999"
                            label={"WhatsApp"}
                            width={"280px"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            placeholder="Data de nascimento"
                            value={birthDate}
                            onChange={setBirthDateLocal}
                            required
                            width={"280px"}
                            mask="99/99/9999"
                        />
                    </Grid>
                </Grid>
                <ButtonContainer>
                    <Button variant="contained" color="inherit" onClick={handleClose}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Salvar
                    </Button>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};

export default ModalEditData;
