import {ButtonContainer, ContractContainerDiv, IconContainer, Row,} from "./contracts/styles";
import DescriptionTextWithLabel from "./contracts/descriptionTextWithLabel";
import ModalContract from "./contracts/modalContract";
import ContractContainer from "./contracts/contractContainer";
import PropTypes from "prop-types";
import {useState} from "react";
import ModalEditData from "./modalEditData";
import EditIcon from "@mui/icons-material/Edit";
import ModalEditBankData from "./modalEditBankData";
import Typography from "../../../../../components/Typography";
import ModalEditComplementarMessage from "./modalExtraMessage";
import {Button} from "@mui/material";
import {Contract} from "../../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";

interface confirmDataFunctions {
    setContractNumber: (value: string) => void;
    setValue: (value: number) => void;
    setInstallmentsValue: (value: number) => void;
    setInstallments: (value: number) => void;
    setBankName: (value: string) => void;
    setAgency: (value: string) => void;
    setAccount: (value: string) => void;
    setContractDate: (value: string) => void;
    setContracts: React.Dispatch<React.SetStateAction<Contract[]>>;
    back: () => void;
    next: () => void;
    setName: (value: string) => void;
    setCPF: (value: string) => void;
    setPhone: (value: string) => void;
    setbirthDate: (value: string) => void;
    setNameRepresentant: (value: string) => void;
    setCPFRepresentant: (value: string) => void;
    setPhoneRepresentant: (value: string) => void;
    setbirthDateRepresentant: (value: string) => void;
    setComplementText: (value: string) => void;
    setReplaceCurrentUnityComplementText: (value: boolean) => void;
    send: () => void;
}

interface confirmDataProps extends Contract, confirmDataFunctions {
    nameRepresentant: string;
    cpfRepresentant: string;
    phoneRepresentant: string;
    birthDateRepresentant: string;
    name: string;
    cpf: string;
    phone: string;
    birthDate: string;
    bankName: string;
    agency: string;
    account: string;
    contracts: Contract[];
    complementText: string;
    replaceCurrentUnityComplementText: boolean;
}

const ConfirmData = ({
                         setContracts,
                         contracts,
                         nameRepresentant,
                         cpfRepresentant,
                         phoneRepresentant,
                         birthDateRepresentant,
                         name,
                         cpf,
                         phone,
                         birthDate,
                         contractNumber,
                         setContractNumber,
                         value,
                         setValue,
                         installmentsValue,
                         setInstallmentsValue,
                         installments,
                         setInstallments,
                         bankName,
                         setBankName,
                         contractDate,
                         setContractDate,
                         setName,
                         setCPF,
                         setPhone,
                         setbirthDate,
                         back,
                         next,
                         setNameRepresentant,
                         setCPFRepresentant,
                         setPhoneRepresentant,
                         setbirthDateRepresentant,
                         setAgency,
                         setAccount,
                         setComplementText,
                         complementText,
                         replaceCurrentUnityComplementText,
                         setReplaceCurrentUnityComplementText,
                         send,
                         agency,
                         account,
                     }: confirmDataProps) => {
    const [contractToEdit, setContractToEdit] = useState<Contract | null>(null);
    const [isModalContractOpen, setisModalContractOpen] = useState(false);
    const [isModalEditBankData, setisModalEditBankData] = useState(false);
    const [isModalEditTextComplement, setisModalEditTextComplement] =
        useState(false);
    const [isModalEditRecipientData, setisModalEditRecipientData] =
        useState(false);
    const [isModalEdiRepresentantData, setisModalEditRepresentantData] =
        useState(false);
    const editContract = (contract: Contract) => {
        setContractToEdit(contract);
        setisModalContractOpen(true);
    };

    const saveContract = () => {
        let updatedContracts;

        if (contractToEdit) {
            updatedContracts = contracts.map((contract) =>
                contract === contractToEdit
                    ? {
                        contractNumber,
                        value,
                        installmentsValue,
                        installments,
                        bankName,
                        contractDate,
                    }
                    : contract
            );
        } else {
            updatedContracts = [
                ...contracts,
                {
                    contractNumber,
                    value,
                    installmentsValue,
                    installments,
                    bankName,
                    contractDate,
                },
            ];
        }

        setContracts(updatedContracts);
        setisModalContractOpen(false);
        setContractToEdit(null);
    };

    return (
        <>
            <Typography variant="caption3">Verificação de dados</Typography>
            <Typography variant="caption">
                Antes de enviar o acesso para o cliente, verifique atentamente os dados
                abaixo.
            </Typography>
            <ContractContainerDiv>
                <IconContainer>
                    <EditIcon
                        onClick={() => setisModalEditRecipientData(true)}
                        color="primary"
                    />
                </IconContainer>
                <DescriptionTextWithLabel
                    title="Nome completo do beneficiário"
                    value={name}
                />
                <Row>
                    <DescriptionTextWithLabel title="CPF" value={cpf}/>
                    <DescriptionTextWithLabel
                        title="Telefone do beneficiário"
                        value={phone}
                    />
                    <DescriptionTextWithLabel
                        title="Data de nascimento"
                        value={birthDate}
                    />
                </Row>
            </ContractContainerDiv>
            {nameRepresentant && (
                <ContractContainerDiv>
                    <IconContainer>
                        <EditIcon
                            onClick={() => setisModalEditRepresentantData(true)}
                            color="primary"
                        />
                    </IconContainer>
                    <DescriptionTextWithLabel
                        title="Nome completo do representante"
                        value={nameRepresentant}
                    />
                    <Row>
                        <DescriptionTextWithLabel title="CPF" value={cpf}/>
                        <DescriptionTextWithLabel
                            title="Telefone do representante"
                            value={phoneRepresentant}
                        />
                        <DescriptionTextWithLabel
                            title="Data de nascimento do representante"
                            value={birthDateRepresentant}
                        />
                    </Row>
                </ContractContainerDiv>
            )}
            {agency && (
                <ContractContainerDiv>
                    <IconContainer>
                        <EditIcon
                            onClick={() => setisModalEditBankData(true)}
                            color="primary"
                        />
                    </IconContainer>
                    <DescriptionTextWithLabel title="Banco" value={bankName}/>
                    <Row>
                        <DescriptionTextWithLabel title="Agência" value={agency}/>
                        <DescriptionTextWithLabel title="Conta" value={birthDate}/>
                    </Row>
                </ContractContainerDiv>
            )}
            {contracts.map((contract, index) => {
                return (
                    <ContractContainer
                        key={index}
                        contract={contract}
                        onEdit={editContract}
                    />
                );
            })}
            <ContractContainerDiv>
                <IconContainer>
                    <EditIcon
                        onClick={() => setisModalEditTextComplement(true)}
                        color="primary"
                    />
                </IconContainer>
                <DescriptionTextWithLabel
                    title="Texto complementar (Opcional)"
                    value={complementText ? complementText : "Nenhuma frase cadastrada"}
                />
            </ContractContainerDiv>
            {isModalContractOpen && (
                <ModalContract
                    onClose={() => setisModalContractOpen(false)}
                    onSave={saveContract}
                    contractNumber={contractNumber}
                    setContractNumber={setContractNumber}
                    value={value}
                    setValue={setValue}
                    installmentsValue={installmentsValue}
                    setInstallmentsValue={setInstallmentsValue}
                    installments={installments}
                    setInstallments={setInstallments}
                    bankName={bankName}
                    setBankName={setBankName}
                    contractDate={contractDate}
                    setContractDate={setContractDate}
                />
            )}
            {isModalEditRecipientData && (
                <ModalEditData
                    title="Editar dados do beneficiário"
                    onClose={() => setisModalEditRecipientData(false)}
                    name={name}
                    setName={setName}
                    cpf={cpf}
                    setCPF={setCPF}
                    phone={phone}
                    setPhone={setPhone}
                    birthDate={birthDate}
                    setbirthDate={setbirthDate}
                />
            )}
            {isModalEdiRepresentantData && (
                <ModalEditData
                    title="Editar dados do representante"
                    onClose={() => setisModalEditRepresentantData(false)}
                    name={nameRepresentant}
                    setName={setNameRepresentant}
                    cpf={cpfRepresentant}
                    setCPF={setCPFRepresentant}
                    phone={phoneRepresentant}
                    setPhone={setPhoneRepresentant}
                    birthDate={birthDateRepresentant}
                    setbirthDate={setbirthDateRepresentant}
                />
            )}
            {isModalEditBankData && (
                <ModalEditBankData
                    onClose={() => setisModalEditBankData(false)}
                    bankName={bankName}
                    agency={agency}
                    account={account}
                    setBankName={setBankName}
                    setAgency={setAgency}
                    setAccount={setAccount}
                />
            )}
            {isModalEditTextComplement && (
                <ModalEditComplementarMessage
                    onClose={() => setisModalEditTextComplement(false)}
                    complementText={complementText}
                    setComplementText={setComplementText}
                    replaceCurrentUnityComplementText={replaceCurrentUnityComplementText}
                    setReplaceCurrentUnityComplementText={
                        setReplaceCurrentUnityComplementText
                    }
                />
            )}
            <ButtonContainer>
                <Button variant="contained" color="inherit" onClick={back}>
                    Voltar
                </Button>
                <Button variant="contained" onClick={send}>
                    Próximo
                </Button>
            </ButtonContainer>
        </>
    );
};
ConfirmData.propTypes = {
    contract: PropTypes.shape({
        contractNumber: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        installmentsValue: PropTypes.number.isRequired,
        installments: PropTypes.number.isRequired,
        bankName: PropTypes.string.isRequired,
        contractDate: PropTypes.string.isRequired,
    }).isRequired,
};

export default ConfirmData;
