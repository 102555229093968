import React, {useState} from "react";
import Typography from "../../../../../../components/Typography";
import ModalContract from "./modalContract";
import {Box, Button} from "@mui/material";
import {Contract} from "../../../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";
import {ButtonContainer} from "./styles";
import ContractContainer from "./contractContainer";
import {toast} from "react-toastify";

interface SetContractFunctions {
    setContractNumber: (value: string) => void;
    setValue: (value: number) => void;
    setInstallmentsValue: (value: number) => void;
    setInstallments: (value: number) => void;
    setBankName: (value: string) => void;
    setContractDate: (value: string) => void;
    setContracts: React.Dispatch<React.SetStateAction<Contract[]>>;
    back: () => void;
    next: () => void;
}

interface ContractsProps extends Contract, SetContractFunctions {
    contracts: Contract[];
}

const Contracts = ({
                       contracts,
                       setContracts,
                       contractNumber,
                       setContractNumber,
                       value,
                       setValue,
                       installmentsValue,
                       setInstallmentsValue,
                       installments,
                       setInstallments,
                       bankName,
                       setBankName,
                       contractDate,
                       setContractDate,
                       back,
                       next,
                   }: ContractsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contractToEdit, setContractToEdit] = useState<Contract | null>(null);
    const deleteContract = (contractToDelete: Contract) => {
        setContracts(contracts.filter((contract) => contract !== contractToDelete));
    };

    const editContract = (contract: Contract) => {
        setContractToEdit(contract);
        setIsModalOpen(true);
    };
    const handleAddContractClick = () => {
        if (contracts.length >= 5) {
            toast.error("Você só pode adicionar até 5 contratos.");
            return;
        }
        setIsModalOpen(true);
    };
    const saveContract = () => {
        if (contracts.length >= 5) {
            toast.error("Você só pode adicionar até 5 contratos.");
            return;
        }

        let updatedContracts;

        if (contractToEdit) {
            updatedContracts = contracts.map((contract) =>
                contract === contractToEdit
                    ? {
                        contractNumber,
                        value,
                        installmentsValue,
                        installments,
                        bankName,
                        contractDate,
                    }
                    : contract
            );
        } else {
            updatedContracts = [
                ...contracts,
                {
                    contractNumber,
                    value,
                    installmentsValue,
                    installments,
                    bankName,
                    contractDate,
                },
            ];
        }

        setContracts(updatedContracts);
        setIsModalOpen(false);
        setContractToEdit(null);
    };

    return (
        <div>
            <Box
                style={{
                    maxWidth: "650px",
                }}
                mt={2}
                mb={2}
            >
                <Typography variant="caption3">Contratos</Typography>
                <Typography variant="caption">
                    Adicione ao menos um contrato para prosseguir. Para cada contrato
                    adicionado deverá ser gravado um vídeo adicional durante o processo de
                    onboarding. Você poderá anexar até 05 contratos.
                </Typography>
                {contracts.map((contract, index) => {
                    return (
                        <ContractContainer
                            key={index}
                            contract={contract}
                            onEdit={editContract}
                            onDelete={deleteContract}
                        />
                    );
                })}

                <Box
                    style={{
                        maxWidth: "650px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    mt={2}
                    mb={2}
                >
                    {contracts.length === 0 && (
                        <Typography variant="caption">
                            Nenhum contrato adicionado.
                        </Typography>
                    )}
                    <Button
                        variant={contracts.length >= 5 ? "contained" : "outlined"}
                        onClick={handleAddContractClick}
                        color={contracts.length >= 5 ? "inherit" : "primary"}
                    >
                        + Adicionar novo contrato
                    </Button>
                </Box>
                <ButtonContainer>
                    <Button variant="contained" color="inherit" onClick={back}>
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={next}
                        color={contracts.length === 0 ? "inherit" : "primary"}
                        disabled={contracts.length === 0}
                    >
                        Próximo
                    </Button>
                </ButtonContainer>
            </Box>

            {isModalOpen && (
                <ModalContract
                    onClose={() => setIsModalOpen(false)}
                    onSave={saveContract}
                    contractNumber={contractNumber}
                    setContractNumber={setContractNumber}
                    value={value}
                    setValue={setValue}
                    installmentsValue={installmentsValue}
                    setInstallmentsValue={setInstallmentsValue}
                    installments={installments}
                    setInstallments={setInstallments}
                    bankName={bankName}
                    setBankName={setBankName}
                    contractDate={contractDate}
                    setContractDate={setContractDate}
                />
            )}
        </div>
    );
};

export default Contracts;
