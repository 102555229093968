import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { Dayjs } from "dayjs";
import DropDownOption from "../../DropDown/DropDownOption";
import TypeValueFilter from "../../TypeValueFilter/TypeValue";
import DatePicker from "../../DatePicker";
import { ButtonContainer, FilterContainer, Row } from "./styles";
import "dayjs/locale/pt-br";

interface FilterProps {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  types: number[];
  setDateFrom: (date: Dayjs) => void;
  setDateTo: (date: Dayjs) => void;
  setTypes: (types: number[]) => void;
  getData: () => void;
  onClose: () => void;
  onTypesChange: (types: number[]) => void;
  options: DropDownOption[];
}

const Filter = (props: FilterProps) => {
  const [types, setTypes] = useState<number[]>(props.types);
  const [isDateValid, setIsDateValid] = useState(true);

  const onClose = () => {
    props.onClose();
  };

  const onApplyFilter = () => {
    props.setTypes(types);
    props.getData();
    onClose();
  };

  const onTypeFilterOk = (options: DropDownOption[]) => {
    const newTypes = options.map((option) => option.value as number);
    setTypes(newTypes);
    props.onTypesChange(newTypes);
  };
  useEffect(() => {
    if (props.dateTo >= props.dateFrom) {
      setIsDateValid(true);
      return;
    }

    toast.error("A data final deve ser maior que a data inicial.");
    setIsDateValid(false);
  }, [props.dateFrom, props.dateTo]);

  return (
    <FilterContainer>
      <Row>
        <DatePicker
          value={props.dateFrom}
          onChange={props.setDateFrom}
          format="DD/MM/YYYY"
          label={`Data de início: ${props.dateFrom.format("DD/MM/YYYY")}`}
        />
        <DatePicker
          value={props.dateTo}
          onChange={props.setDateTo}
          format="DD/MM/YYYY"
          label={`Data de término: ${props.dateTo.format("DD/MM/YYYY")}`}
        />
        <TypeValueFilter onOk={onTypeFilterOk} options={props.options} label={'Tipo de movimentação'} />
      </Row>
      <ButtonContainer>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={onApplyFilter}
          disabled={!isDateValid}
        >
          Aplicar filtros
        </Button>
      </ButtonContainer>
    </FilterContainer>
  );
};

export default Filter;
