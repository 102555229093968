enum EDocumentType {
  NaoDocumentoscopia = 0,
  RG = 1,
  CNH = 2,
  OAB = 3,
  CRM = 4,
  CPF = 5,
  Email = 6,
  Telefone = 7,
}

export default EDocumentType;
