import styled from "styled-components";

type PhotoProps = {
  isSmallScreen: boolean;
};

export const ImageHorizontalContainer = styled.img<PhotoProps>`
  width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "35%")};
  height: auto;
  align-self: center;
  padding: 10px;
`;

export const ImageVerticalContainer = styled.img<PhotoProps>`
  width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "30%")};
  height: auto;
  align-self: center;
  padding: 40px;
`;
export const DocumentInquiryFileUploadInstructionsImage = styled.img`
  max-width: calc(456px - 20px);
  max-height: calc(297px - 20px);
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
`;
export const Container = styled.div`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
`;
