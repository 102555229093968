import React from "react";
import styled from "styled-components";
import {CARD_IMAGES} from "../../constants/AcceptedCards";
const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: 30px;
  flex-wrap: wrap;
  align-self: center;
 
`;

const ImageCards = styled.img`
  width: 30px;
  height: 19px;
`;
const AcceptedCards = () => {
  return (
    <InnerContainer>
      <ImageCards src={CARD_IMAGES["Visa"]} />
      <ImageCards src={CARD_IMAGES["Mastercard"]} />
      <ImageCards src={CARD_IMAGES["American"]} />
      <ImageCards src={CARD_IMAGES["Elo"]} />
      <ImageCards src={CARD_IMAGES["Hipercard"]} />
      <ImageCards src={CARD_IMAGES["JCB"]} />
      <ImageCards src={CARD_IMAGES["Diners"]} />
      <ImageCards src={CARD_IMAGES["Discover"]} />
      <ImageCards src={CARD_IMAGES["Aura"]} />
    </InnerContainer>
  );
};
export default AcceptedCards;
