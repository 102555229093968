import { useEffect, useState } from "react";
import UserItem from "./UserItem";

import useUserService from "../../shared/services/useUserService";
import { UserResponseDto } from "../../shared/models/backend/user/user-response-dto";
import { PagedList, PagedListFilter } from "../../shared/models/responses/paged-list";

interface ListProps {
  data: PagedList<UserResponseDto> | null;
}

const List = ({ data }: ListProps) => {


  return (
    <>
      {data.itens.map((user, index) => (
        <UserItem key={index} user={user} />
      ))}
    </>
  );
};

export default List;