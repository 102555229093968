import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import RequestError from "../../models/services/common/request-error";

export const ConsultaiInterceptors = () => {

    const SEND_INTERCEPTOR = async (config: InternalAxiosRequestConfig<any>, token?: string, env?: string, enableLogs = false) => {
        enableLogs && console.log('[api]', env, config.url, JSON.stringify(config?.data, null, 2), token ? 'hasToken:' + token?.slice(-3) : 'noToken')
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    };

    const ON_FULFILLED_INTERCEPTOR = (response: AxiosResponse<any, any>) => response;

    const ON_REJECTED_INTERCEPTOR = async (axiosError: any, enableLogs: boolean = false, env?: string, onRefreshToken?: () => Promise<string>, onError?: (e: string) => void) => {
        if (axios.isAxiosError(axiosError)) {
            console.log({axiosError}, 'axiosError')
            if(axiosError.code == 'ERR_NETWORK'){
                console.log({code: 'ERR_NETWORK', err: axiosError.response?.status})
                return Promise.reject(new RequestError("Sessão expirada", axiosError.response?.status, true));
            }
            enableLogs && console.log('[api]', env, `[ERRO ${axiosError.response?.status}] `, axiosError.response?.config?.method, axiosError.response?.config.url)
            axiosError = axiosError as AxiosError<any, any>;
            switch (axiosError.response?.status) {
                case 400: {
                    const errorMessage = axiosError?.response?.data;
                    console.log({errorMessage}, 'itccp')
                    onError && onError(errorMessage);
                    return Promise.reject(new RequestError(errorMessage, axiosError.response?.status));
                }
                case 401: {
                    if (axiosError.response.config.url.includes("/autenticacao") || axiosError.response.config.url.includes("v2/auth"))
                        return Promise.reject(new RequestError("Usuário ou senha inválidos", axiosError.response?.status));
                    try {
                        if (axiosError.response.config.url.includes("/autenticacao/refresh-token")) {
                            const errorMessage = 'Sessão expirada, faça login novamente.';
                            onError && onError(errorMessage);
                            return Promise.reject(new RequestError(errorMessage, axiosError.response?.status, true));
                        }
                        const newHeader = await onRefreshToken();
                        const newConfig = {...axiosError.config, headers: {Authorization: newHeader}};
                        return axios.request(newConfig);
                    } catch (error) {
                        console.error('[ERROR]', 'Erro ao obter e armazenar o token de acesso:', JSON.stringify(error, null, 2));
                        const errorMessage = 'Sessão expirada, faça login novamente.';
                        onError && onError(errorMessage);
                        return Promise.reject(new RequestError('Sessão expirada, faça login novamente.', axiosError.response?.status, true));
                    }
                }
                case 403: {
                    const errorMessage = 'Você não tem permissão para acessar este recurso.';
                    onError && onError(errorMessage);
                    return Promise.reject(new RequestError(errorMessage, axiosError.response?.status));
                }
                case 404: {
                    const notFoundMessage = 'Verifique se os dados estão corretos e tente novamente.';
                    onError && onError(notFoundMessage);
                    return Promise.reject(new RequestError(notFoundMessage, axiosError.response?.status));
                }
                case 500: {
                    const internalErrorMessage = 'Ops, erro ao enviar solicitação, tente novamente mais tarde.';
                    onError && onError(internalErrorMessage);
                    return Promise.reject(new RequestError(internalErrorMessage, axiosError.response?.status));
                }
                case 415: {
                    const internalErrorMessage = 'Solicitação inválida.';
                    onError && onError(internalErrorMessage);
                    return Promise.reject(new RequestError(internalErrorMessage, axiosError.response?.status));
                }
                default: {
                    const internalErrorMessage = 'Houve um erro ao enviar a solicitação, tente novamente.';
                    onError && onError(internalErrorMessage);
                    return Promise.reject(new RequestError("Houve um erro ao enviar a solicitação, tente novamente.", axiosError.response?.status, true));
                }
            }
        }
        onError && onError(axiosError.toString());
        return Promise.reject(new RequestError(axiosError.toString()));
    };
    return {
        SEND_INTERCEPTOR,
        ON_FULFILLED_INTERCEPTOR,
        ON_REJECTED_INTERCEPTOR
    }
}
