import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import React, {useState} from "react";
import EmailInquiryForm from "../components/EmailInquiryForm";
import InquirySentSuccessfully from "../components/InquirySentSuccessfully";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";
import useInquiryService from "../../../shared/services/useInquiryService";
import {ValidationUtils} from "../../../utils/validation-utils";

const EmailInquiryScreen = () => {
    const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [inquiryId, setInquiryId] = useState<number>();

    const {createAndSendEmailInquiryAsync} = useInquiryService();

    function onConfirmEmailInquiry() {
        if (!ValidationUtils.validateEmail(email)) return
        setLoading(true);
        try {
            createAndSendEmailInquiryAsync({email})
                .then((sentInquiry) => {
                    setInquiryId(sentInquiry?.id)
                    resetForm()
                })
        } finally {
            setLoading(false);
        }
    }

    const resetForm = (clearId?: boolean) => {
        setEmail('')
        if (clearId) setInquiryId(undefined)
    }


    return (
        <Layout>
            <SubHeader
                title={'Consulta'}
                subtitle={'Email'}/>
            {!inquiryId &&
                <EmailInquiryForm
                    loading={loading}
                    setLoading={setLoading}
                    email={email}
                    setEmail={setEmail}
                    onNext={onConfirmEmailInquiry}
                ></EmailInquiryForm>
            }
            {inquiryId && !email &&
                <InquirySentSuccessfully
                    inquiryType={EInquiryType.Email}
                    onNewInquiry={() => resetForm(true)}
                />}
        </Layout>
    )
}
export default EmailInquiryScreen;