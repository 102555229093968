import styled from "styled-components";
import { Palette } from "../../shared/theme/palette";

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1128px;
`;
export const BodyContainer = styled.div`
  display: flex;
  gap: 15px;
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 35px;
  max-width: 1128px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 181px;
  height: 80px;
  padding: 10px;
  border-radius: 8px;
  background: ${Palette.white};
  box-shadow: 0px 12px 24px 0px #00000012;
`;
export const TypographyWithNegativeMargin = styled.div`
  margin-top: -30px;
`;
