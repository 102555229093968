import React from 'react';
import Layout from "../../layout";
import HomeHeader from "./components/Header";
import HomeContentCard from "./components/ContentCard";

const Home: React.FC = () => {

    return (
        <Layout>
            <HomeHeader/>
            <HomeContentCard></HomeContentCard>
        </Layout>
    );
};

export default Home;