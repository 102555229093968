import {
    BalanceCardContainer,
    BalanceCardContent,
    BalanceCardTextContent,
    BalanceCardTitle,
    BalanceCardValue,
    IconContainer
} from "./styles";
import React, {useContext, useEffect, useState} from "react";
import {
    HomeBalanceCardAlertText,
    HomeBalanceCardAlertTextContainer,
    HomeBalanceCardContainer,
    HomeBalanceCardRechargeButtonContainer
} from "../Header/styles";
import Routes from "../../../../constants/Routes";
import {useNavigate} from "react-router-dom";
import {AccountContext} from "../../../../shared/context/AccountContext";
import useUserService from "../../../../shared/services/useUserService";
import {useSubscriptionService} from "../../../../shared/services/useSubscriptionService";
import {Palette} from "../../../../shared/theme/palette";
import useMoney from "../../../../shared/hooks/useMoney";
import {Button} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { Constants } from "../../../../constants/Constants";

const BalanceCard = () => {
    const [visible, setVisible] = useState(false);
    const [balance, setBalance] = useState(0);
    const context = useContext(AccountContext)
    const {buscarSaldo} = useUserService();
    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {toString} = useMoney();
    const navigate = useNavigate()
    const LOW_BALANCE = balance < 2;
    useEffect(() => {
        const updateBalance = async () => {
            if (!context?.ids?.unitId) return navigate(Routes.Login);
            const subscription = await buscarAssinaturaAtiva(context.ids.unitId)
            context.setSubscription(subscription);
            setBalance((await buscarSaldo(context.ids.unitId))?.saldo);
        }
        updateBalance();
    }, [visible]);

    if (!context.subscription.planoId
        || context.subscription.planoId === Constants.FREE_PLAN_ID
        || context.subscription.planoId === Constants.ENTERPRISE_PLAN_ID) return null

    return (

        <HomeBalanceCardContainer>
            <BalanceCardContainer>
                <BalanceCardContent>
                    <BalanceCardTextContent>
                        <BalanceCardTitle>Saldo Total</BalanceCardTitle>
                        <br/>
                        <BalanceCardValue
                            style={{color: (visible && LOW_BALANCE) ? Palette.red : Palette.black}}
                        >{visible ? toString(balance) : 'R$ •••••'}</BalanceCardValue>
                    </BalanceCardTextContent>
                    <IconContainer
                        onClick={() => setVisible(!visible)}>
                        {visible ? <VisibilityOffIcon style={{ color: Palette.black }} /> : <VisibilityIcon style={{ color: Palette.black }} />}
                    </IconContainer>
                </BalanceCardContent>
            </BalanceCardContainer>
            {
                LOW_BALANCE && visible &&
                <>
                    <HomeBalanceCardAlertTextContainer>
                    <WarningIcon style={{ color: Palette.red }} fontSize="small" />
                        <HomeBalanceCardAlertText>
                            {'Seu saldo está quase acabando! Que tal fazer uma recarga?'}
                        </HomeBalanceCardAlertText>
                    </HomeBalanceCardAlertTextContainer>
                    <HomeBalanceCardRechargeButtonContainer>
                        <Button
                            onClick={() => navigate(Routes.Recharge)}
                            size={'medium'}
                            startIcon={'plus'}
                        >
                            {'Adicionar mais crédito'}
                        </Button>
                    </HomeBalanceCardRechargeButtonContainer>
                </>
            }
        </HomeBalanceCardContainer>
    );
}

export default BalanceCard;