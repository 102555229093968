import useConsultaiApi from "./context/hooks/useConsultaiApi";
import { ProductPrice } from "../models/product-price";
import PlanWithProductsDTO from "../models/backend/plan/PlanWithProductsDTO";

export const usePlanService = () => {
  const consultaiApi = useConsultaiApi();

  const buscarTodosPlanosAtivos = () => consultaiApi.get("/cadastros/plano/listar", 1);
  
  const buscarTodosPlanosAtivosComProdutos = () => consultaiApi.get<PlanWithProductsDTO[]>('/cadastros/plano/listar-com-produtos', 1);
  
  const buscarPlanoComProdutos = async (id: number) => {
    const plans = await consultaiApi.get<PlanWithProductsDTO[]>(`/cadastros/plano/listar-com-produtos/${id}`, 1);
    return plans[0];
  }

  const getProductPrices = (id: number) => consultaiApi.get<ProductPrice[]>(`/cadastros/plano/${id}/prices`, 1);

  return {
    buscarTodosPlanosAtivos,
    buscarTodosPlanosAtivosComProdutos,
    buscarPlanoComProdutos,
    getProductPrices,
  };
};
