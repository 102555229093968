import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

const DatePicker = (props: DatePickerProps<Dayjs>) => {
  return (
    <MUIDatePicker
      {...props}
      dayOfWeekFormatter={(_, date) => {
        const days = "DSTQQSS";
        const dayOfWeek = date["$W"];
        return days[dayOfWeek];
      }}
    />
  );
};

export default DatePicker;
