import { Grid, Stack } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractSmallText } from "../styles";

const CurrentAccountContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          IV - CONTA-CORRENTE - NÃO SE APLICA
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <MailingContractCheckbox
          label="Individual (OU) Agência __________ Conta-Corrente: __________________"
          description="Declaro ter sido informado e estar ciente de todos os termos e condições apresentados na PROPOSTA DE ABERTURA DE CONTA – PESSOA FÍSICA e nas CONDIÇÕES GERAIS PARA ABERTURA, MANUTENÇÃO, MOVIMENTAÇÃO E ENCERRAMENTO DE CONTA-CORRENTE, as quais li, recebi e estão disponíveis também no site www.crefisa.com.br, às quais solicito minha adesão. Declaro(amos) ter lido previamente as Condições Gerais para Abertura, Manutenção, Movimentação e Encerramento de Conta-Corrente - Pessoa Física, Individualizada, e que não tenho(amos) dúvidas sobre quaisquer de suas cláusulas e condições, aceitando, assim, os termos na integralidade. Declaro(amos), ainda, que as informações constantes na Ficha Cadastral - Pessoa Física são verdadeiras, comprometo-me(temo-nos) a mantê-las atualizadas, nos termos da legislação em vigor, bem como estou(amos) ciente(s) de que referidas informações serão enviadas aos Órgãos de Proteção ao Crédito e as empresas especializadas em processamento de cadastro bancário, as quais ficam autorizadas a fazer uso das referidas informações e dados em suas atividades. O Anexo a Proposta de Abertura de Contas - Pacotes de Tarifas informa detalhadamente a composição dos Pacotes de Serviços ofertados aos clientes. Declaro(amos) que tenho(amos) conhecimento sobre o funcionamento e benefícios oferecidos pela minha(nossa) adesão a um Pacote de Serviços e que decido(imos) neste momento:"
        />
        <MailingContractCheckbox label="Não aceitar a oferta de nenhum dos Pacotes de Serviços disponíveis nesta data." />

        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços Beneficiário do INSS." />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços I.  " />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços II.  " />
        <MailingContractCheckbox label="Autorizar minha(nossa) adesão ao Pacote de Serviços III.  " />
        <MailingContractCheckbox
          label="Autorizar minha(nossa) adesão ao Pacote de Serviços IV.  "
          description="Optando por um Pacote de Serviços, sua vigência será a partir do mês subsequente. Os valores cobrados pelos Pacotes de Serviços constam na Tabela de Tarifas divulgada pelo Banco Crefisa S/A em seu website www.crefisa.com.br"
        />
        <MailingContractCheckbox
          label="Autorizo(amos) o Banco Crefisa S/A a efetuar consultas nos órgãos de Proteção ao Crédito, serviços de prevenções a fraudes
externos, bem como tenho(emos) ciência de que eventualmente poderão ocorrer comunicações ao Conselho de Controle de
Atividades Financeiras - COAF, por questões relacionadas à movimentação de conta, e a outros órgãos e entidades que venham a ser
ou sejam previstas por norma legal para fins de inclusões e comunicações.
"
        />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A a consultar e validar meus(nossos) dados para fins de fraudes e criação de cadastro de pontuação  baseado no meu(nosso) histórico de transações." />
        <MailingContractCheckbox label="Autorizo(amos), ainda, o Banco Crefisa S/A a fornecer, mediante solicitação e identificação do interessado, as informações previstas no artigo 6º, incisos I, II e III da da Resolução 3.972/2011, referentes a cheques devolvidos e/ou sustados." />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A a cobrar as tarifas pelos serviços descritos no item IV - Tarifas Bancárias da presente Proposta de Abertura de Conta" />
        <MailingContractCheckbox label="Autorizo(amos) o envio de informações relativas à movimentação ou lançamento em conta corrente por meio de mensagem automática." />
        <MailingContractCheckbox label="Autorizo(amos) o Banco Crefisa S/A E CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS a registrar e compartilhar dados e informações com instituições do sistema financeiro com a finalidade de subsidiar procedimentos e controles para prevenção de fraudes, nos termos da Resolução Conjunta 6, de 2023, do Ministério da Fazenda/Conselho Monetário Nacional." />
        <Stack alignItems="flex-end" marginTop="8px" marginRight="32px">
          <ContractSmallText>
            [1] CNPJ 61.033.106/0001-86, COM SEDE NA RUA CANADÁ, 390, SÃO
            PAULO/SP
          </ContractSmallText>
          <ContractSmallText>
            [2] CNPJ 60.779.196/0001-96, COM SEDE NA RUA CANADÁ, 387, SÃO
            PAULO/SP
          </ContractSmallText>
        </Stack>
      </Grid>
    </>
  );
};

export default CurrentAccountContractSection;
