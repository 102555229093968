import {
    BlogBannerContainer,
    BlogBannerContent,
    BlogBannerImage,
    BlogBannerOrangeText,
    BlogBannerText,
    BlogBannerTextContainer
} from "./styles";
import bannerImage from "../../shared/assets/blog-banner-image.png";
import {Palette} from "../../shared/theme/palette";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const BlogBanner = () => {
    const BLOG_URL = 'https://whoid.com/blog?utm_source=APP_CONSULTAI&utm_medium=CONSULTAI_BANNER&utm_campaign=CONSULTAI';
    const gotoBlog = () => {
        window.open(BLOG_URL, '_blank');
    }
    return (
        <BlogBannerContainer onClick={gotoBlog}>
            <BlogBannerContent>
                <BlogBannerImage src={bannerImage}></BlogBannerImage>
                <BlogBannerTextContainer>
                    <BlogBannerText>Conheça o nosso <BlogBannerOrangeText>BLOG</BlogBannerOrangeText></BlogBannerText>
                </BlogBannerTextContainer>
                <ChevronRightIcon style={{ color: Palette.white }} />
            </BlogBannerContent>
        </BlogBannerContainer>
    );
};
export default BlogBanner;