import React from 'react';
import ItemList from './components/Item/itemList';
export interface MenuProps {
    onExpandChange?: (isExpanded: boolean) => void;
}
const Menu: React.FC<MenuProps> = ({onExpandChange}) => {
    return (
      <ItemList onExpandChange={onExpandChange}/>
    );
};

export default Menu;
