import React, { useState } from "react";
import Typography from "../../../../components/Typography";
import { Answers, Chevron, Container, ContainerText } from "./styles";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  faqs: FAQItem[];
}

const FAQComponent: React.FC<FAQProps> = ({ faqs }) => {
  const [selectedFAQ, setSelectedFAQ] = useState<number | null>(null);
  const theme = useThemeWhiteLabel();
  const handleFAQPress = (index: number) => {
       setSelectedFAQ(selectedFAQ === index ? null : index);
  };

  return (
    <Answers>
    {faqs.map((faq, index) => (
      <div key={index}>
        <Container 
          onClick={() => handleFAQPress(index)}
          isSelected={selectedFAQ === index}
        >
          <Typography variant="caption">{faq.question}</Typography>
          <Chevron primaryColor={theme.primaryColor} 
          />
        </Container>
        {selectedFAQ === index && (
          <ContainerText>
            <Typography variant="caption">{faq.answer}</Typography>
          </ContainerText>
        )}
      </div>
    ))}
  </Answers>
  );
};

export default FAQComponent;
