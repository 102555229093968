import * as React from 'react';
import { styled } from '@mui/system';

const ResponsiveContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  height: 'auto',
  alignContent: 'center',
  [theme.breakpoints.up('lg')]: {
    width: '75%',
    margin: '50px auto 0 auto',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '100%',
    margin: '20px auto 0 auto',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '15px auto 0 auto',
  },
}));

export default ResponsiveContainer;