export const removeMask = (value: string) => {
    return !value ? value : value.replace(/[^a-zA-Z0-9]/g, '');
};
/// applyMask:
// 9 - accept digit.
// A - accept alpha.
// S - accept alphanumeric.
export const applyMask = (value: string, mask: string): string => {
    if(!value || !mask) return value
    let formattedValue = '';
    let valueIndex = 0;

    for (let i = 0; i < mask.length && valueIndex < value.length; i++) {
        const maskChar = mask[i];
        const valueChar = value[valueIndex];

        if (maskChar === '9') {
            if (valueChar.match(/\d/)) {
                formattedValue += valueChar;
                valueIndex++;
            }
        } else if (maskChar === 'A') {
            if (valueChar.match(/[a-zA-Z]/)) {
                formattedValue += valueChar;
                valueIndex++;
            }
        } else if (maskChar === 'S') {
            if (valueChar.match(/[0-9a-zA-Z]/)) {
                formattedValue += valueChar;
                valueIndex++;
            }
        } else {
            formattedValue += maskChar;
            if (valueChar === maskChar) {
                valueIndex++;
            }
        }
    }

    return formattedValue;
}