import styled from 'styled-components';

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 2rem;
  width: 100%; 
`;

export const ContainerCenter = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledImage = styled.img`
  width: 45%;
  height: auto;
`;