import React from "react";
import InquirySpecificsCard from "./InquirySpecifics";
import {ContainerList} from "../styles";
import {buttonConfig} from "./consts/constsButton";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";
import {TitleMidia, useInquirySpecifics} from "../../../shared/hooks/useInquirySpecifics";

interface InquirySpecificsListProps {
    id: number;
    inquiryType: EInquiryType;
}

const InquiryAdditionalInfo = ({ id, inquiryType }: InquirySpecificsListProps) => {
    const { inquiryData } = useInquirySpecifics(id);

    return (
        <ContainerList>
            {inquiryData.map((item, index) => {
                const buttons = buttonConfig(item);
                return (
                    <InquirySpecificsCard
                        key={index}
                        title={item.title}
                        description={item.description}
                        extraTitle={item.extraTitle}
                        extraDescription={item.extraDescription}
                        extraIcons={item.extraIcons} 
                        showButtons={buttons}
                        iconType={item.iconType}
                        extraImageUrl={item.title === "Documento" ? item.documentBackImageUrl : undefined}
                        inquiryId={item.inquiryId}
                        inquiryType={inquiryType}
                    />
                );
            })}
        </ContainerList>
    );
};

export default InquiryAdditionalInfo;