import { useEffect, useState } from "react";

const usePrivateUserData = () => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    ip: null,
    city: null,
    region: null,
    country: null,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to get geolocation
    const fetchGeolocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation((prevLocation) => ({
              ...prevLocation,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }));
          },
          () => setError("Geolocation permission denied.")
        );
      } else {
        setError("Geolocation not supported by this browser.");
      }
    };

    // Function to get IP and location info
    const fetchIpInfo = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setLocation((prevLocation) => ({
          ...prevLocation,
          ip: data.ip,
        }));
      } catch (error) {
        setError("Error fetching IP information.");
      }
    };

    fetchGeolocation();
    fetchIpInfo();
  }, []);

  return {
    ip: location.ip,
    latitude: location.latitude,
    longitude: location.longitude,
    error,
  };
};

export default usePrivateUserData;
