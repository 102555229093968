import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";
import useWhitelabel from "../../hooks/useWhitelabel";
import { GlobalLoader } from "../GlobalLoader/GlobalLoader";
import { Container, IconContainer, StyledCircularProgress } from "./styles";

const Loading = ({ fullscreen = false }: { fullscreen?: boolean }) => {
  const { whitelabelLogo } = useWhitelabel();
  const theme = useThemeWhiteLabel();

  if (fullscreen)
    return (
      <GlobalLoader
        logo={whitelabelLogo}
        alt="crefisa logo"
        color={theme.primaryColor}
        isFixed
      />
    );

  return (
    <Container>
      <IconContainer>
        <StyledCircularProgress size={35} />
      </IconContainer>
    </Container>
  );
};

export default Loading;
