import React, {useContext, useEffect, useState} from "react";
import PaymentPlanList from "../../../components/PaymentPlan";
import SubSection from "../../../components/SubSection";
import CreateCreditCardModal from "../../../components/ModalPaymentCard/ModalPaymentCard";
import Typography from "../../../components/Typography";
import SubHeader from "../../../components/SubHeader/SubHeader";
import {TextsContainer} from "../../../theme/global-styles";
import ModalPlanCancel from "./components/modalCancelPlan";
import {useNavigate} from "react-router-dom";
import Routes from "../../../constants/Routes";
import {AccountContext} from "../../../shared/context/AccountContext";
import {usePagarMeService} from "../../../shared/services/usePagarMeService";
import {useSubscriptionService} from "../../../shared/services/useSubscriptionService";
import {toast} from "react-toastify";
import Layout from "../../../layout";
import {CreateCardResponse} from "../../../shared/models/responses/cards-response";
import useDate from "../../../shared/hooks/useDate";
import {Button, Stack} from "@mui/material";
import PlanWithProductsDTO from "../../../shared/models/backend/plan/PlanWithProductsDTO";
import {Constants} from "../../../constants/Constants";

const PlansPayment: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState<number | null>(null);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState<string>("");
    const [dataCancelamento, setdataCancelamento] = useState<string>("");
    const [dataInativacao, setdataInativacao] = useState<string>("");
    const [paymentPlan, setPaymentPlan] = useState<PlanWithProductsDTO | null>(null);
    const [pagarmeSubscriptionId, setPagarmeSubscriptionId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const accountContext = useContext(AccountContext);
    const navigate = useNavigate();
    const {formatDate} = useDate();
    const {pagarMeCardsService} = usePagarMeService();
    const {buscarAssinaturaAtiva, requestSubscriptionCancel} = useSubscriptionService();
    const changeSubscriptionCard = (newSubscriptionCard: CreateCardResponse) => {
        if (pagarmeSubscriptionId) {
            pagarMeCardsService.patchEditarCartao(pagarmeSubscriptionId, {
                card_id: newSubscriptionCard.id,
            })
                .then(() => toast.success("Cartão alterado com sucesso!"))
                .finally(() => setLoading(false));
        }
    };

    const fetchCurrentSubscription = async () => {
        try {
            setLoading(true);
            const subscription = await buscarAssinaturaAtiva(accountContext.ids.unitId);
            if (subscription && subscription.plano) {
                const paymentPlanData: PlanWithProductsDTO = {
                    id: subscription.id || 0,
                    name: subscription.plano.nome || "",
                    value: subscription.valor || 0,
                    observation: subscription.plano.observacao || "",
                    planProducts: []
                };
                accountContext.setPlan({...paymentPlanData});
                setPaymentPlan(paymentPlanData);
                setPagarmeSubscriptionId(subscription.pagarMeId);
                setSubscriptionId(subscription.id);
                setdataCancelamento(subscription.dataCancelamento);
                setdataInativacao(subscription.dataInativacao);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrentSubscription();
    }, []);

    const CancelSubscription = (subscriptionId: number) => {
        setLoading(true);
        requestSubscriptionCancel(subscriptionId, cancelReason)
            .then(() => {
                toast.success("Assinatura cancelada com sucesso!");
                setIsCancelModalOpen(false);
                fetchCurrentSubscription();
            })
            .finally(() => setLoading(false));
    };
    const handleOpenCancelModal = () => setIsCancelModalOpen(true);
    const handleCloseCancelModal = () => setIsCancelModalOpen(false);

    const isSubscriptionCancelled = dataCancelamento && dataInativacao

    return (
        <Layout iconHelp>
            <SubHeader title="Planos/Pagamentos" subtitle="Seleção de planos"/>
            <SubSection>
                {isSubscriptionCancelled && (
                    <>
                        <Typography variant='caption' color='red'>
                            Assinatura cancelada no dia {formatDate(dataCancelamento)} e as cobranças
                            recorrentes foram interrompidas. O saldo remanescente permanecerá disponível para consumo
                            até o
                            dia {formatDate(dataInativacao)}.
                        </Typography>
                        <Button size="medium" disabled={loading} variant="outlined"
                                onClick={() => navigate(Routes.OtherPlans)}>
                            Contratar novo plano
                        </Button>
                    </>
                )}
                {!isSubscriptionCancelled &&
                    <>
                        {paymentPlan ? (
                            <>
                                <Typography variant="h1">Plano Escolhido:</Typography>
                                <PaymentPlanList paymentPlans={[paymentPlan]}>
                                    {accountContext.subscription.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                                        <Stack direction="column" spacing={2}>
                                            <Button
                                                size="medium"
                                                disabled={loading}
                                                variant="outlined"
                                                onClick={() => navigate(Routes.OtherPlans)}
                                            >
                                                Trocar de plano
                                            </Button>
                                            <Button
                                                size="medium"
                                                disabled={loading}
                                                variant="outlined"
                                                onClick={() => setIsModalOpen(true)}
                                            >
                                                Trocar cartão de crédito
                                            </Button>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                disabled={loading}
                                                color="error"
                                                onClick={handleOpenCancelModal}
                                            >
                                                Cancelar assinatura
                                            </Button>
                                        </Stack>}
                                </PaymentPlanList>
                            </>
                        ) : (
                            <TextsContainer>
                                <Typography variant="h1">
                                    No momento você não possui nenhum plano selecionado. Clique no
                                    botão abaixo para selecionar um plano e utilizar nossos serviços.
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "24px",
                                    }}
                                >
                                    <Button size="medium" variant="outlined" onClick={() => navigate(-1)}>
                                        Voltar
                                    </Button>
                                    <Button size="medium"
                                            variant={'contained'}
                                            onClick={() => navigate(Routes.OtherPlans)}>
                                        Selecionar plano
                                    </Button>
                                </div>
                            </TextsContainer>
                        )}
                    </>
                }
                {isModalOpen ? (
                    <CreateCreditCardModal
                        onSave={changeSubscriptionCard}
                        loading={loading}
                        setLoading={setLoading}
                        onCancel={() => setIsModalOpen(false)}
                        onClose={() => setIsModalOpen(false)}
                    />
                ) : (
                    <></>
                )}
                {isCancelModalOpen ? (
                    <ModalPlanCancel
                        onClose={handleCloseCancelModal}
                        cancelSubscription={() => {
                            if (subscriptionId !== null) {
                                CancelSubscription(subscriptionId);
                            }
                        }}
                        reasonCancel={cancelReason}
                        setReasonCancel={setCancelReason}
                        loading={loading}
                    />
                ) : (
                    <></>
                )}
            </SubSection>
        </Layout>
    );
};

export default PlansPayment;
