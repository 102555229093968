import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {InquiriesFileJob} from "../../../../shared/models/backend/inquiry/inquiries-file-job";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ClockOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import {EExportFileJobStatus, getDescription} from "../../../../shared/models/backend/inquiry/EExportFileJobStatus";
import {Card, Popover} from "@mui/material";
import ItemLine from "../../../InquiryList/components/ItemInquiryList/ItemInquiryListLine";
import {getInquiryTypeAsText} from "../../../../shared/models/backend/inquiry/EInquiryType";
import {EScoreTypeDescriptions} from "../../../../shared/models/backend/inquiry/EScoreType";
import { useThemeWhiteLabel } from '../../../../hooks/useThemeWhiteLabel';

interface InquiriesFileJobsTableProps {
    jobs: InquiriesFileJob[];
}

function download(filePath: string) {
    window.open(filePath, '_blank')
}

const InquiriesFileJobsTable = ({jobs}: InquiriesFileJobsTableProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [currentJob, setCurrentJob] = React.useState<InquiriesFileJob>();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, job: InquiriesFileJob) => {
        setCurrentJob(job)
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const theme = useThemeWhiteLabel();
    const open = Boolean(anchorEl);

    const FilterCard = () => {
        if(!currentJob) return null;
        return <Card style={{padding: 10}}>
            <ItemLine
                label="Período: "
                value={`${currentJob.periodStartDateFormatted} a ${currentJob.periodEndDateFormatted} `}
                fontSize={12}
            />
            <ItemLine
                label="Tipo de Consulta: "
                value={currentJob.inquiryTypes.map(i => getInquiryTypeAsText(i)).join(', ')}
                fontSize={12}
            />
            <ItemLine
                label="Tipos de resultado: "
                value={currentJob.inquiryScoreResults.map(i => EScoreTypeDescriptions[i]).join(', ')}
                fontSize={12}
            />
            <ItemLine
                label="Unidades: "
                value={currentJob.unitIds.join(', ')}
                fontSize={12}
            />
        </Card>
    }

    return <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{'Data'}</TableCell>
                    <TableCell>{'Hora'}</TableCell>
                    <TableCell>{'Nº de Consultas'}</TableCell>
                    <TableCell>{'Filtros'}</TableCell>
                    <TableCell>{'Tamanho'}</TableCell>
                    <TableCell>{'Status'}</TableCell>
                    <TableCell align="center">{'Download'}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {jobs.map((job) => {
                    return <TableRow
                        key={job.id}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>{`${(job.createdOnDateFormatted)}`}</TableCell>
                        <TableCell>{`${(job.createdOnTimeFormatted)}`}</TableCell>
                        <TableCell>{job.totalRecords}</TableCell>
                        <TableCell>
                            <span
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={(e)=>handlePopoverOpen(e,job)}
                                onMouseLeave={handlePopoverClose}>{'Passe o mouse'}</span>
                            <Popover
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                {FilterCard()}
                            </Popover>
                        </TableCell>
                        <TableCell>{job.estimatedFileSize}</TableCell>
                        <TableCell>{getDescription(job.status)}</TableCell>
                        <TableCell align="center"
                                   onClick={() => job.status == EExportFileJobStatus.Completed && download(job.filePath)}
                                   style={{cursor: job.status == EExportFileJobStatus.Completed ? 'pointer' : 'default'}}
                        >
                            {
                                job.status == EExportFileJobStatus.Completed
                                    ? <DownloadOutlinedIcon
                                        style={{color: theme.primaryColor, fontSize: 26}}></DownloadOutlinedIcon>
                                    :
                                    <ClockOutlinedIcon style={{color: theme.primaryColor, fontSize: 26}}></ClockOutlinedIcon>
                            }
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </TableContainer>;
}
export default InquiriesFileJobsTable;