import { useContext, useState } from "react";
import {
  ImageCards,
  InnerContainer,
  TextsDiv,
  CustomDiv,
  Container,
} from "./styles";
import CheckBox from "../CheckBox";
import Typography from "../Typography";
import { AccountContext } from "../../shared/context/AccountContext";
import { useCardsService } from "../../shared/services/useCardsService";
import { UserCardOption } from "../../shared/models/user-card";
import { CARD_IMAGES } from "../../constants/AcceptedCards";
import Delete from "@mui/icons-material/Delete";

interface CardOptionProps {
  card: UserCardOption;
  onChange: () => void;
  onDelete: () => void;
  canDelete?: boolean;
}

const CardOption = ({
  card,
  onChange,
  onDelete,
  canDelete,
}: CardOptionProps) => {
  const [loading, setLoading] = useState(false);
  const accountContext = useContext(AccountContext);
  const { deleteCard } = useCardsService();
  const removeCard = (cardId: number) => {
    if (
      !canDelete ||
      !accountContext.ids ||
      accountContext.ids.unitId === null ||
      accountContext.ids.unitId === undefined
    ) {
      return;
    }

    const unityId = accountContext.ids.unitId;
    setLoading(true);
    deleteCard({ unityId, cardId })
      .then(() => {
        onDelete();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };
  return (
    <Container>
      <InnerContainer key={card.id}>
          <CheckBox
          isChecked={card.isSelected} onPress={onChange} />
        <ImageCards src={CARD_IMAGES[card.brand]} />
        <CustomDiv>
          <TextsDiv>
            <Typography variant="caption2">
              **** **** **** {card.lastDigits}
            </Typography>
          </TextsDiv>
        </CustomDiv>
        <Delete
          style={{
            color: canDelete ? "darkGrey" : "lightGrey",
            cursor: "pointer",
          }}
          onClick={() => removeCard(card.id)}
        />
      </InnerContainer>
    </Container>
  );
};
export default CardOption;
