import styled from "styled-components";
import {Palette} from "../../../../shared/theme/palette";

export const BalanceCardContainer = styled.div`
  display: flex;
  padding: 10px 14px 12px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  border: 1px solid ${Palette.lightBlue};
`;
export const BalanceCardContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
`;
export const BalanceCardTextContent = styled.div`
  width: 120px;
  height: 50px;
`;
export const BalanceCardTitle = styled.text`
  color: var(--Cinza-Claro,${Palette.darkGrey3});
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
`;
export const BalanceCardValue = styled.text`
  color: var(--Texto, ${Palette.darkGrey1});
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;
export const IconContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;