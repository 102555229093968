
import styled from "styled-components";
import { Palette } from "../../../../shared/theme/palette";
import { KeyboardArrowDown } from '@mui/icons-material';

export  const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1128px;
  gap: 20px;
  padding: 20px 0px 20px 0px;
`;
export const Container = styled.div<{ borderColor: string, expanded: boolean }>`
  position: relative;
  width: 360px;
  height: ${(props) => props.expanded ? 'auto' : '100px'}; 
  padding: 9px 9px 9px 9px;
  border-radius: 8px;
  border: 1px solid ${Palette.lightGrey1};
  border-left: 6px solid ${(props) => props.borderColor};
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E1E1E1, #E1E1E1);
  box-shadow: 0px 4px 15px 0px #00000026;
  display: flex; 
`;
export const InnerContainer = styled.div`
  border-radius: 8px;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  margin-top: -25px;
`;

export const Body = styled.div`
  display: flex;
  background-color: ${Palette.white};
  border-radius: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 15px;
`;

export const div = styled.div`
  flex-direction: column;
  gap: 20px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const ButtonContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 8px;
`;
export const RotatingIcon = styled(KeyboardArrowDown)<{ rotate: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${props => (props.rotate ? '180deg' : '0deg')});
`;