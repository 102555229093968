import React from "react";
import "./RegisterCompleted.css";
import {useNavigate} from "react-router-dom";
import Routes from "../../constants/Routes";
import {Button, Stack} from "@mui/material";
import Layout from "../../layout";
import useWhitelabel from "../../hooks/useWhitelabel";

const RegisterCompleted = () => {
    const navigate = useNavigate();
    const {whitelabelName} = useWhitelabel();

    const onChoseLater = () => {
        navigate(Routes.Home);
    };

    return (
        <Layout>
            <div id="register-completed-container">
                <div id="register-completed-inner-container">
                    <div id="register-completed-title-container">
          <span
              id="register-completed-title-icon"
              className="material-symbols-outlined"
          >
            mood
          </span>
                        <label id="register-completed-title-text">
                            Agradecemos pelo seu cadastro
                        </label>
                    </div>
                    <label id="register-completed-message1">
                        Estamos verificando as informações que você inseriu para validar o seu
                        cadastro, a confirmação irá chegar ao seu e-mail.
                    </label>
                    <label id="register-completed-title2">
                        Agora só falta escolher um plano!
                    </label>
                    <label id="register-completed-message2">
                        Para utilizar os serviços do {whitelabelName}, será necessário escolher um
                        plano. Clique no botão abaixo para ver as opções.
                    </label>
                    <div id="register-completed-buttons-container">
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined"
                                    onClick={onChoseLater}>
                                Escolher mais tarde
                            </Button>
                            <Button
                                variant={'contained'}
                                onClick={() => navigate(Routes.OtherPlans)}>Escolher Plano</Button>
                        </Stack>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default RegisterCompleted;
