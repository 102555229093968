import styled from 'styled-components';
import {Palette} from "../shared/theme/palette";

export const ScreenWithSubHeaderContainer = styled.div`
    display: flex;
    max-width: 100%;
    max-height: 100%;
    padding: 20px 76px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    background: var(--Fundo, ${Palette.lightGrey4});
`;
export const ScreenWithHeaderContainer = styled.div`
  display: flex;
  padding: 5.5rem 5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  flex: 1 0 0;
  align-self: stretch;
`;
export const Clickable = styled.div`
  cursor: pointer;
`;
export const TextSmall = styled.text`
    color: ${Palette.darkGrey1};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.5px;
    width: 40.5rem;
`;
export const TextExtraSmall = styled.text`
  color: ${Palette.darkGrey1};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.5px;
`;
export const InstructionsText = styled.p`
  color: ${Palette.black};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.5px;
  max-width: 40.5rem;
  overflow-wrap: break-word;
`;
export const InstructionsTextBold = styled.text`
  font-family: 'Poppins';
  font-weight: 900;
`;
export const TextSmallBold = styled.text`
    color: ${Palette.darkGrey1};
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 12px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;
export const HorizontalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  width: 39.5rem;
`;
export const HorizontalButtonsContainerLarge = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  width: 39.5rem;
`;
