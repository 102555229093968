export enum ELoanModality {
    New = 1,
    Portability = 2,
    Refinancing = 3,
}
export const ELoanModalityDescriptions = {
    [ELoanModality.New]: "Novo",
    [ELoanModality.Portability]: "Portabilidade",
    [ELoanModality.Refinancing]: "Refinanciamento",
};
export const ELoanModalityOptions = [
    { label: ELoanModalityDescriptions[ELoanModality.New], value: ELoanModality.New },
    { label: ELoanModalityDescriptions[ELoanModality.Portability], value: ELoanModality.Portability },
    { label: ELoanModalityDescriptions[ELoanModality.Refinancing], value: ELoanModality.Refinancing },
];