import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";

import classes from "./GlobalLoader.module.css";

export type GlobalLoaderProps = {
  /**
   * Speed in seconds of the fade in/out animation. Set 0 to disable
   */
  fadeSpeed?: number;
  logo: string;
  alt: string;
  color: string;
  isFixed?: boolean;
  hide?: boolean;
};

function $GlobalLoader({
  logo,
  alt,
  color,
  isFixed = false,
  fadeSpeed = 0,
  hide,
}: GlobalLoaderProps) {
  return (
    <AnimatePresence>
      {!hide && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: fadeSpeed }}
          className={isFixed ? classes.fixedContainer : classes.container}
        >
          <div className={classes.logoContainer}>
            <img
              src={logo}
              alt={alt}
              style={{ height: "40px", width: "auto" }}
            />
          </div>
          <div className={classes.loader} style={{ color }} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const GlobalLoader = memo($GlobalLoader);
