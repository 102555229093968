import { styled } from "styled-components";
import { Palette } from "../../shared/theme/palette";

export const List = styled.div`
  gap: 16px;
`;

export const Section = styled.div`
  flex-direction: row;
  background-color: ${Palette.white};
  border-radius: 8px;
  height: 110px;
  padding: 8px;
  gap: 20px;
  align-items: center;
`;

export const Body = styled.div`
  flex-direction: column;
  padding: 5px;
  gap: 8px;
`;

export const View = styled.div`
  padding-left: 10px;
  gap: 3px;
`;
export const CustomView = styled.div`
  flex-direction: row;
`;
export const InnerContainer = styled.div`
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LoaderContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%; // Ajuste conforme necessário
`;
export const PaginationContainer = styled.div`
    display: flex;
  justify-content: center;
  padding-top: 35px;
  max-width: 1128px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
export const TextContainer = styled.div`
  max-width: 648px;
`;