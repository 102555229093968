import useVideoOnboardingConfigs from "../../hooks/useVideoOnboardingConfigs";
import {Alert, Icon} from "@mui/material";
import {VideoOnboardingConfig} from "../../shared/models/video-onboarding-config";

const WhitelabelVideoOnboardingInstructions = () => {
    const {videoOnboardingInstructions} = useVideoOnboardingConfigs();
    const InstructionRow = (instruction: VideoOnboardingConfig, key: number) => {
        return (
            <div key={key}>
                <Alert icon={<Icon style={{marginLeft: 15}}>{instruction.icon}</Icon>} severity="success">
                    <b>{instruction?.name}: </b>{instruction?.description}
                </Alert>
            </div>
        );
    }
    if(!videoOnboardingInstructions?.length) return null;
    return (
        <div style={{margin: 15}}>
            <Alert icon={<Icon style={{marginLeft: -15}}>{'instruction.icon'}</Icon>} severity="success">
                <b>{'Dicas para gravar o vídeo corretamente:'}</b>
            </Alert>
            {videoOnboardingInstructions?.map((instruction, k) => InstructionRow(instruction, k))}
        </div>
    );
}
export default WhitelabelVideoOnboardingInstructions;