import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import {FormContainer, InstructionsText, ScreenWithSubHeaderContainer} from "../../../theme/GlobalStyles";
import Input from "../../../components/Input/Input";
import React, {useContext} from "react";
import {SentSuccessfullyButtonsContainer} from "../../Inquiries/components/styles";
import {Button, Grid} from "@mui/material";
import Routes from "../../../constants/Routes";
import {useNavigate} from "react-router-dom";
import {useAuthenticationService} from "../../../shared/services/useAuthenticationService";
import {toast} from "react-toastify";
import {AccountContext} from "../../../shared/context/AccountContext";
import {ValidationUtils} from "../../../utils/validation-utils";

const ChangePasswordScreen = () => {
    const [loading, setLoading] = React.useState(false);
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const navigate = useNavigate();
    const {requestChangePassword, requestLogin} = useAuthenticationService()
    const accountContext = useContext(AccountContext)

    function sendChangePasswordRequest() {
        if (!ValidationUtils.validatePassword(newPassword, true, true)) return
        if (!ValidationUtils.validatePasswordConfirmation(newPassword, confirmNewPassword, true)) return
        setLoading(true);
        requestLogin({email: accountContext.user.user.login, password: currentPassword,}).then(() =>
            requestChangePassword({password: newPassword, passwordConfirmation: confirmNewPassword})
                .then(() => {
                    toast.success('Senha alterada com sucesso.');
                    navigate(Routes.Home);
                }))
            .catch(() => toast.error('Erro ao alterar senha. Verifique os dados preenchidos e tente novamente.'))
            .finally(() => setLoading(false));
    }

    return (
        <Layout>
            <SubHeader title={'Minha conta'} subtitle={'Alterar senha'}/>
            <ScreenWithSubHeaderContainer>
                <InstructionsText>
                    {'Preencha os campos abaixo e clique no botão “salvar”. A senha deve conter no mínimo 10 dígitos, uma letra maiúscula e um caractere especial.'}
                </InstructionsText>
                <FormContainer>
                    <Grid container rowSpacing={1} columnSpacing={{xs: 3}}>
                        <Grid item xs={5}>
                            <Input value={currentPassword}
                                   onChange={setCurrentPassword}
                                   placeholder="Senha atual"
                                   validationCallback={ValidationUtils.validatePassword}
                                   required
                                   fullWidth
                                   isPassword/>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={5}>
                            <Input value={newPassword}
                                   onChange={setNewPassword}
                                   placeholder="Nova senha"
                                   validationCallback={ValidationUtils.validatePassword}
                                   error={'Nova senha inválida'}
                                   required
                                   fullWidth
                                   isPassword/>

                        </Grid>
                        <Grid item xs={5}>
                            <Input value={confirmNewPassword}
                                   onChange={setConfirmNewPassword}
                                   placeholder="Confirmar nova senha"
                                   validationCallback={ValidationUtils.validatePassword}
                                   error={'Confirmação de senha inválida'}
                                   required
                                   fullWidth
                                   isPassword/>
                        </Grid>
                    </Grid>
                </FormContainer>
                <SentSuccessfullyButtonsContainer>
                    <Button
                        disabled={loading}
                        variant={'outlined'}
                        onClick={() => navigate(Routes.Home)}>{'Voltar'}
                    </Button>
                    <Button
                        disabled={loading}
                        variant={'contained'}
                        onClick={sendChangePasswordRequest}>{'Salvar'}</Button>
                </SentSuccessfullyButtonsContainer>
            </ScreenWithSubHeaderContainer>
        </Layout>
    );
}

export default ChangePasswordScreen;