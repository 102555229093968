import React, {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {AccountContext} from "../../shared/context/AccountContext";
import {useCreditRechargeService} from "../../shared/services/useCreditRechargeService";
import {usePagarMeService} from "../../shared/services/usePagarMeService";
import Layout from "../../layout";
import UserCardsPicker from "../../components/UserCardsPicker";
import axios from "axios";
import SubHeader from "../../components/SubHeader/SubHeader";
import SubSection from "../../components/SubSection";
import {useNavigate} from "react-router-dom";
import Typography from "../../components/Typography";
import {Box, Button, Stack} from "@mui/material";
import Input from "../../components/Input/Input";
import {UserCardOption} from "../../shared/models/user-card";
import {ButtonContainer} from "../Settings/planConfirm/styles";
import { FormatUtils } from "../../shared/utils/format-utils";

export default function Recharge() {
    const {ids} = useContext(AccountContext);
    const [loading, setLoading] = useState(false);
    const [valor, setValor] = useState('R$ 0,00');
    const [cardPagarMeId, setCardPagarMeId] = useState(null);
    const [ip, setIp] = useState(null);
    const navigate = useNavigate();
    const {recharge, updateRechargeCredit} = useCreditRechargeService();
    const {pagarMeRechargeService} = usePagarMeService();
    useEffect(() => {
        const getIP = async () => {
            const response = await axios.get("https://api.ipify.org?format=json");
            setIp(response.data.ip);
        };

        getIP();
    }, []);
    const isValid = () => {
        const valorNumber = FormatUtils.unMaskMoney(valor)
        
        if (valorNumber <= 0) {
            toast.error("Por favor preencha o valor da recarga.");
            return false;
        }
        if (!cardPagarMeId) {
            toast.error("Por favor selecione um cartão para continuar.");
            return false;
        }
        return true;
    };

    const payRecharge = async () => {
        if (!isValid()) return;
        setLoading(true);
        try {
            await recharge({
                unitId: ids.unitId,
                value: FormatUtils.unMaskMoney(valor),
                ip,
                cardPagarMeId: cardPagarMeId,
            });

            toast.success("Recarga realizada com sucesso!");
            navigate(-1);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Layout>
            <SubHeader title="Relatórios de finanças" subtitle="Recarga de crédito"/>
            <Box maxWidth={800}>
                <SubSection>
                    <Typography variant="paymentDescription">
                        Insira o valor da recarga
                    </Typography>
                    <Input
                        value={valor.toString()}
                        isCurrency
                        onChange={setValor}
                    />
                    <UserCardsPicker
                        onChangeSelected={(card: UserCardOption) =>
                            setCardPagarMeId(card?.pagarMeCardId)
                        }
                        footer={
                            <ButtonContainer>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        size="medium"
                                        variant="text"
                                        onClick={() => navigate(-1)}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        onClick={payRecharge}
                                        disabled={!cardPagarMeId}
                                        color={cardPagarMeId ? "primary" : "inherit"}
                                        style={
                                            !cardPagarMeId ? {backgroundColor: "lightGrey"} : {}
                                        }
                                    >
                                        Recarregar crédito
                                    </Button>
                                </Stack>
                            </ButtonContainer>
                        }
                    ></UserCardsPicker>
                </SubSection>
            </Box>
        </Layout>
    );
}

export {Recharge};
