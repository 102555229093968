import { FileRequestDocumentEnum } from "../../shared/models/enums/files.enum";
import { InstructionStepVariantsEnum } from "./InstructionsStep";

export const getDocVariant = (
  fileType?: FileRequestDocumentEnum
): InstructionStepVariantsEnum | undefined => {
  if (!fileType) return undefined;
  if (fileType === FileRequestDocumentEnum.RG)
    return InstructionStepVariantsEnum.rg;
  if (fileType === FileRequestDocumentEnum.CNH)
    return InstructionStepVariantsEnum.cnh;
  return InstructionStepVariantsEnum.outro;
};
