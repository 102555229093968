import { Box, Button } from "@mui/material";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import { OnboardingCard, SubTitleTypography, TitleTypography } from "../styles";

const WelcomeStep = ({
  setActiveStep,
  isValid,
}: { isValid: boolean } & MailingStepProps) => {
  const theme = useThemeWhiteLabel();

  return (
    <ContainerContent centered>
      <OnboardingCard sx={{ maxWidth: isValid ? 836 : 640 }}>
        <Box maxWidth={isValid ? 302 : 402}>
          <TitleTypography
            variant="h1"
            color={theme.primaryColor}
            align="center"
          >
            {isValid
              ? "Olá! Estamos felizes por ter você conosco!"
              : "Link expirado."}
          </TitleTypography>
          <SubTitleTypography
            variant="h2"
            align="center"
            mb={isValid ? "40px" : "8px"}
          >
            {isValid ? (
              <span>
                Agora você iniciará a pré-ativação de seu benefício! Nessa
                jornada, você terá toda a segurança para consultar seus dados,
                confirmar se as informações estão corretas e, assim, agilizar o
                processo que será finalizado na loja Crefisa. <br />
                Siga as instruções nas próximas telas.
              </span>
            ) : (
              <span>
                Se você deseja realizar a pré-ativação de seu benefício junto ao
                INSS, fale com a gente pelo nosso Whatspp ou visite a loja
                Crefisa mais próxima de você.
              </span>
            )}
          </SubTitleTypography>

          {isValid && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={() =>
                setActiveStep(EMailingStepsEnum.DataCollectionAuthorization)
              }
            >
              Começar
            </Button>
          )}
        </Box>
      </OnboardingCard>
    </ContainerContent>
  );
};

export default WelcomeStep;
