import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {UserDto} from "../models/user-dto";
import {AccountUserFilter} from "../models/requests/account-user-filter";

const useAccountService= () => {
    const consultaiApi= useConsultaiApi();
    const getAllUsers= async (filter: AccountUserFilter) : Promise<UserDto[]> => {
        return await consultaiApi.get<UserDto[]>('/account/users', 2, filter);
    }

    return {
        getAllUsers
    }
}
export default useAccountService;