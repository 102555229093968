import { Button, useMediaQuery, useTheme } from "@mui/material";
import {
  Canvas,
  StyledDivButton,
  StyledDivCenterButton,
  Video,
} from "../../onBoardingVideo/styles";
import Typography from "../../../../components/Typography";
import { useEffect, useState } from "react";

interface DocumentPhotoProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  isCaptured: boolean;
  handleCapture: () => void;
  navigateToNext: () => void;
  selectedDocument: string;
  side: string;
  setIsCaptured: (isCaptured: boolean) => void;
  idPhoto?: number;
}
const DocumentPhoto: React.FC<DocumentPhotoProps> = ({
  videoRef,
  canvasRef,
  isCaptured,
  handleCapture,
  navigateToNext,
  selectedDocument,
  side,
  setIsCaptured,
}: DocumentPhotoProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [stream, setStream] = useState<MediaStream | null>(null);
  let title, subtitle;
  switch (selectedDocument) {
    case "RG":
      title = side === "front" ? "Tirar foto da frente do RG" : "Tirar foto do verso do RG";
      subtitle = side === "front" ? "Clique no botão “Tirar foto” para enviar a fotografia do documento. A frente do RG é o lado que possui a sua foto." : "Clique no botão “Tirar foto” para enviar a fotografia do documento. O verso do RG é o lado que NÃO possui a sua foto.";
      break;
      case "CNH":
        title = "Tirar foto da CNH";
        subtitle = "Clique no botão “Tirar foto” para enviar a fotografia do documento. Você deverá abrir a carteira de habilitação e tirar uma foto única.";
        break;
      case "Passaporte":
        title = "Tirar foto do Passaporte";
        subtitle = "Clique no botão “Tirar foto” para enviar a fotografia do documento. Você deverá fotografar a página que possui sua foto.";
        break;
    case "Carteira de Trabalho":
      title = side === "front" ? "Tirar foto da primeira página da Carteira de Trabalho" : "Tirar foto da  página de Qualificação Civil";
      subtitle = side === "front" ? "Clique no botão “Tirar foto” para enviar a fotografia do documento. A primeira página é a que possui a sua foto." : "Clique no botão “Tirar foto” para enviar a fotografia do documento. A página de Qualificação Civil é a que possui os dados pessoais do portador.";
      break;
    default:
      title = side === "front" ? "Tire uma foto da frente do documento" : "Tire uma foto do verso do documento";
      subtitle = side === "front" ? "" : "";
  }
  const initializeCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      setStream(stream);
    }
  };

  useEffect(() => {
    initializeCamera();
  }, []);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.addEventListener("loadedmetadata", () => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      });
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [videoRef, stream]);
  
  const handleButtonClick = () => {
    if (isCaptured) {
      setIsCaptured(false);
      initializeCamera(); 
    } else {
      handleCapture();
    }
  };
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Typography variant="caption">{subtitle}</Typography>
      <Video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        isCaptured={isCaptured}
        isSmallScreen={isSmallScreen}
      />
      <Canvas
        ref={canvasRef}
        isCaptured={isCaptured}
        isSmallScreen={isSmallScreen}
      />
      <StyledDivCenterButton>
      <Button onClick={handleButtonClick} variant="outlined">
        {isCaptured ? 'Trocar foto' : 'Tirar foto'}
      </Button>
      </StyledDivCenterButton>
      <Typography variant="caption">
        Certifique-se de que informações estão legíveis. Caso queira trocar a
        foto basta clicar no botão “Trocar Foto”.
      </Typography>
      <Typography variant="caption">
        Caso a foto tenha ficado satisfatória clique no botão abaixo para tirar
        a foto do verso do documento.
      </Typography>
      <StyledDivButton>
        <Button
          variant={"contained"}
          color="primary"
          onClick={navigateToNext}
          disabled={!isCaptured}
        >
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

export default DocumentPhoto;
