import {
  PayrollInviteLoanOnboardingResponse,
  PayrollLoanOnboardingFilesRequest,
  PayrollLoanOnboardingRequest,
} from "../models/backend/inquiry/payroll-loan-onboarding-request";
import { PayrollLoanOnboardingResponse } from "../models/responses/payroll-loan-onboarding-response";
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const usePayrollLoanOnboardingService = () => {
  const consultaiApi = useConsultaiApi();

  const createInvitePayRollVideoOnBoarding = async (
    request: PayrollLoanOnboardingRequest
  ) => {
    return await consultaiApi.post<PayrollInviteLoanOnboardingResponse>(
      "/inquiries/payroll-loan-onboarding/invite",
      1,
      request
    );
  };
  const getComplementarMessageVideoOnBoarding = async () => {
    return await consultaiApi.get<string>("/inquiries/complement-text", 1);
  };
  const receivePayrollLoanOnboardingInvite = (id) => {
    return consultaiApi.get<PayrollLoanOnboardingResponse>(
      `/inquiries/payroll-loan-onboarding/invite/${id}`
    );
  };
  const sendFilesPayRollVideoOnBoarding = async (
    request: PayrollLoanOnboardingFilesRequest,
    id: string
  ) => {
    return await consultaiApi.post<string>(
      `/inquiries/payroll-loan-onboarding/invite/${id}/file`,
      1,
      request
    );
  };
  const sendPayRollVideoOnBoarding = async (id: string) => {
    return await consultaiApi.post<string>(
      `/inquiries/payroll-loan-onboarding/${id}/send`,
      2
    );
  };
  return {
    createInvitePayRollVideoOnBoarding,
    getComplementarMessageVideoOnBoarding,
    receivePayrollLoanOnboardingInvite,
    sendFilesPayRollVideoOnBoarding,
    sendPayRollVideoOnBoarding,
  };
};
export default usePayrollLoanOnboardingService;
