import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";

export const ResetPasswordTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
export const ResetPasswordTitleText = styled.text`
  color: ${Palette.black};
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 100% */
`;