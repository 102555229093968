import {CreateRechargeRequest, UpdateRechargeRequest} from '../models/requests/internal-recharge-credit-request';
import {CreateRechargeResponse, UpdateRechargeResponse} from '../models/responses/internal-recharge-response';
import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {RechargeRequest} from "../models/requests/recharge-request";

export const useCreditRechargeService = () => {
    const consultaiApi = useConsultaiApi();

    const recharge = (request: RechargeRequest) => consultaiApi.post<CreateRechargeResponse>('/payment/recharge', 2, request)
    const postCreateRecharge = (request: CreateRechargeRequest) => consultaiApi.post<CreateRechargeResponse>('/financeiro/pagamento/criarrecarga', 1, request)
    const updateRechargeCredit = (request: UpdateRechargeRequest) => consultaiApi.post<UpdateRechargeResponse>('/financeiro/pagamento/atualizarrecarga', 1, request)
    return {
        recharge,
        postCreateRecharge,
        updateRechargeCredit
    }
}

