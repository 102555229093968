import React from "react";
import PlanWithProductsDTO from "../../shared/models/backend/plan/PlanWithProductsDTO";
import PaymentCard from "./PaymentCard";

interface Props {
  paymentPlans: PlanWithProductsDTO[];
  onPress?: (planId: number) => void;
  selectedPlanId?: number | null;
  size?: "small" | "default";
  showChooseButton?: boolean;
  children?: React.ReactNode;
}

const PaymentPlanList: React.FC<Props> = ({
  paymentPlans,
  size = "default",
  showChooseButton,
  children,
}) => {
  return (
    <>
      {paymentPlans?.map((plan) => (
        <PaymentCard
          key={plan.id}
          plan={plan}
          showChooseButton={showChooseButton}
        >
          {children}
        </PaymentCard>
      ))}
    </>
  );
};

export default PaymentPlanList;
