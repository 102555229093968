import {StyledDiv} from "./styles";
import InquiryCardRow from "./InquiryCardRow";
import React from "react";
import Typography from "../../components/Typography";
interface InputUserCardProps{
    name: string;
    login: string;

}
const InputUserCard = ({ name, login}: InputUserCardProps ) => {
    return (
        <StyledDiv>
            <Typography variant={"filterTitle"} color={"green"}>{'Dados do Digitador'}</Typography>
            <InquiryCardRow label="Nome" value={name} />
            <InquiryCardRow label="CPF/CNPJ" value={login} mask={'document'} />
        </StyledDiv>
    );
}
export default InputUserCard;