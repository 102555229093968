import InquiryReportResponse from '../models/responses/inquiry-report-response';
import useUrl from '../hooks/useUrl';
import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {InquiriesFileJob} from "../models/backend/inquiry/inquiries-file-job";
import {RequestInquiriesReportToExportForm} from "../models/requests/request-inquiries-report-to-export-form";

const useInquiryReportService = () => {
    const {toUrlParams} = useUrl();
    const consultaiApi = useConsultaiApi();
    const getReportData = async (dateFrom: Date, dateTo: Date, types: number[]) => {
        const params = toUrlParams({dateFrom, dateTo, types});
        const url = `/inquiryreport${params}`;
        return await consultaiApi.get<InquiryReportResponse>(url);
    };

    const requestInquiriesResultToExport = async (data: RequestInquiriesReportToExportForm): Promise<number> => {
        const url = `/inquiryreport/results/download`;
        return await consultaiApi.post<number>(url,1,data);
    }

    const getInquiriesResultToExportRequests = async (): Promise<InquiriesFileJob[]> => {
        const url = `/inquiryreport/results/download`;
        return await consultaiApi.get<InquiriesFileJob[]>(url);
    }

    return {getReportData, getInquiriesResultToExportRequests, requestInquiriesResultToExport};
};

export default useInquiryReportService;
