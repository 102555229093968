import { Grid, Stack } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const MagneticCardContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>II - CARTÃO MAGNÉTICO</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox
            label="Definitivo NB: 705.069.076-8"
            checked
          />
        </Stack>
        <ContractText>
          Declaro ter lido previamente os termos e condições que estão na fl. 03
          e que não tenho dúvidas sobre quaisquer de suas cláusulas e condições,
          aceitando, assim, os termos na integralidade.
        </ContractText>
      </Grid>
    </>
  );
};

export default MagneticCardContractSection;
