import React, {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Button, FormControl, InputLabel} from "@mui/material";
import {InstructionsText} from "../../../../theme/GlobalStyles";
import InquiryPriceAndBalanceCards from "../../components/InquiryPriceAndBalanceCards";
import {FormatUtils} from "../../../../shared/utils/format-utils";
import Input from "../../../../components/Input/Input";
import {ValidationUtils} from "../../../../utils/validation-utils";
import {AccountContext} from "../../../../shared/context/AccountContext";
import FormProps from "./FormProps";
import {DateUtils} from "../../../../shared/utils/date-utils";
import {removeMask} from "../../../../shared/utils/remove-mask";
import useVideoOnboardingConfigs from "../../../../hooks/useVideoOnboardingConfigs";
import {ELoanType, ELoanTypeOptions} from "../../../../shared/models/enums/ELoanType";
import TypeValueFilter from "../../../../components/TypeValueFilter/TypeValue";
import {ELoanModality, ELoanModalityOptions} from "../../../../shared/models/enums/ELoanModality";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import {Constants} from "../../../../constants/Constants";

const Form = (props: FormProps) => {
    const [name, setName] = useState(props.inviteCommand?.name);
    const [cpf, setCPF] = useState(FormatUtils.formatCPF(props.inviteCommand?.cpf));
    const [loanValue, setLoanValue] = useState(FormatUtils.toBRLCurrency(props.inviteCommand?.loanValue));
    const [installmentsValue, setInstallmentsValue] = useState(FormatUtils.toBRLCurrency(props.inviteCommand?.installmentsValue));
    const [installmentsQuantity, setInstallmentQuantity] = useState(props.inviteCommand?.installmentsQuantity.toString());
    const [contractDate, setContractDate] = useState(FormatUtils.toDateFormat(props.inviteCommand?.contractDate ?? new Date(), 'yyyy-mm-dd'));
    const [bankName, setBankName] = useState(props.inviteCommand?.bankName);
    const [phone, setPhone] = useState(FormatUtils.formatPhoneNumber(props.inviteCommand?.phone));
    const [loanType, setLoanType] = useState(props.inviteCommand?.loanType);
    const [loanModality, setLoanModality] = useState(props.inviteCommand?.loanModality);
    const {videoOnboardingFormFields} = useVideoOnboardingConfigs();
    const {currentWhitelabel} = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;
    useEffect(() => {
        if (isWhitelabel) setBankName(currentWhitelabel?.name)
    }, [currentWhitelabel]);
    const onChangeInstallmentsCount = (value: string) => {
        const quantity = parseInt(value);
        if (!isNaN(quantity)) setInstallmentQuantity(quantity.toString());
    };
    const {ids} = useContext(AccountContext);

    const onNext = () => {
        if (name.trim().split(' ').length < 2) {
            toast.error("Informe o nome completo do cliente!", {
                autoClose: false,
                closeButton: true,
                toastId: "no-price-error",
            });

            return;
        }

        const contractDateObj = DateUtils.stringToFormattedDate(contractDate, "yyyy-mm-dd", "-");

        props.onNext({
            name,
            cpf: removeMask(cpf),
            loanValue: FormatUtils.unMaskMoney(loanValue),
            installmentsValue: FormatUtils.unMaskMoney(installmentsValue),
            installmentsQuantity: parseInt(installmentsQuantity),
            contractDate: contractDateObj,
            bankName,
            phone: removeMask(phone),
            unityId: ids.unitId,
            companyName: "",
            loanType,
            loanModality,
            presential: props.presential,
        });
    };

    const nextButtonDisabled =
    !name ||
    !cpf ||
    !phone ||
    !loanValue ||
    !installmentsValue ||
    !installmentsQuantity ||
    !contractDate ||
    !bankName ||
    (isWhitelabel && (!loanType || !loanModality)); 

    return (
        <>
            <InquiryPriceAndBalanceCards inquiryPrice={props.inquiryPrice}/>
            <InstructionsText>
                Preencha os campos abaixo para enviar o link de acesso ao cliente. Essas
                informações serão utilizadas no texto-guia que deverá ser lido durante a
                gravação do vídeo de onboarding.
            </InstructionsText>
            <div id="video-onboarding-inputs-container">
                <Input
                    placeholder="Nome Completo do Cliente"
                    value={name}
                    onChange={setName}
                    required
                />
                <Input
                    placeholder="CPF"
                    value={cpf}
                    validationCallback={ValidationUtils.validateCpf}
                    error="CPF inválido"
                    onChange={setCPF}
                    required
                    mask="999.999.999-99"
                    label={"CPF"}
                />
                <Input
                    placeholder={`${isWhitelabel && props.presential ? 'Celular' : ' WhatsApp'} do Cliente`}
                    onChange={setPhone}
                    value={phone}
                    required
                    validationCallback={ValidationUtils.validatePhone}
                    mask="(99) 99999-9999"
                    error={`${isWhitelabel && props.presential ? 'Celular' : ' WhatsApp'} inválido`}
                    label={`${isWhitelabel && props.presential ? 'Celular' : ' WhatsApp'}`}
                />
                <Input
                    placeholder="Valor do Empréstimo"
                    value={loanValue}
                    onChange={setLoanValue}
                    isCurrency
                    required
                />
                <Input
                    placeholder="Valor da Parcela"
                    value={installmentsValue}
                    onChange={setInstallmentsValue}
                    isCurrency
                    required
                />
                <Input
                    placeholder="Quantidade de Parcelas"
                    value={installmentsQuantity}
                    onChange={onChangeInstallmentsCount}
                    required
                />
                <Input
                    placeholder="Data da Contratação"
                    value={contractDate}
                    onChange={setContractDate}
                    required
                    type="date"
                />
                {!isWhitelabel &&
                    <Input
                        placeholder="Banco"
                        value={bankName}
                        onChange={setBankName}
                        required
                    />}
                <div>

                    {videoOnboardingFormFields.find(v => v.name == 'LoanType')
                        &&
                        <FormControl fullWidth>
                            <InputLabel shrink={Boolean(loanType)}>{!loanType ? 'Tipo de Empréstimo' : ''}</InputLabel>
                            <TypeValueFilter
                                options={ELoanTypeOptions}
                                onSelect={(option) => setLoanType(option.value as ELoanType)}
                            ></TypeValueFilter>
                        </FormControl>
                    }
                    {videoOnboardingFormFields.find(v => v.name == 'LoanModality')
                        &&
                        <FormControl fullWidth>
                            <InputLabel shrink={Boolean(loanModality)}>{!loanModality ? 'Modalidade de Empréstimo' : ''}</InputLabel>
                            <TypeValueFilter
                                options={ELoanModalityOptions}
                                onSelect={(option) => setLoanModality(option.value as ELoanModality)}
                            ></TypeValueFilter>
                        </FormControl>
                    }
                </div>
            </div>
            <div id="video-onboarding-buttons-container">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                    disabled={nextButtonDisabled}
                >
                    Próximo
                </Button>
            </div>
        </>
    );
};

export default Form;
