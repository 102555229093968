export const Palette = {
  yellow: '#F9B24D',
  yellow2: '#F9B24D',
  yellow3: '#F6C000',
  green: '#006241',
  green2: '#009544',
  green3: '#4CAF79',
  lightGreen: '#D0E9E1',
  red: '#ED1717',
  red2: '#E30000',
  white: '#FFFFFF',
  darkGrey1: '#212121',
  darkGrey2: '#383838',
  darkGrey3: '#565656',
  darkGrey4: '#515151',
  darkGrey5: '#585858',
  lightGrey1: '#F7F7F7',
  lightGrey2: '#C2C2C2',
  lightGrey3: '#A0A0A0',
  lightGrey4: '#F6F6F6',
  lightGrey5: '#BFBFBF',
  lightGrey6: '#E5E5EF',
  lightGrey7: '#DCDCDC',
  lightGrey8: '#E9E9E9',
  lightGrey9: '#B9B9B9',
  lightGrey10: '#F6F6F6',
  lightGrey11: '#4B4B4B',
  lightGrey12: '#DEDEDE',
  lightGrey13: '#CFCFCF',
  lightGrey14: '#F4F4F5',
  black: '#000000',
  orange: '#EC672C',
  blue: '#1793ED',
  lightBlue: '#CAD7F0',
  purple: '#551A8B',
};
