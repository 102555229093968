import {
    PerformanceReportInquiriesDataBarsContainer,
    PerformanceReportInquiriesDataCard,
    PerformanceReportInquiriesDataCardBar,
    PerformanceReportInquiriesDataCardBarContainer,
    PerformanceReportInquiriesDataCardContainer,
    PerformanceReportInquiriesDataCardSubtitle,
    PerformanceReportInquiriesDataCardText,
    PerformanceReportInquiriesDataCardTitle,
    PerformanceReportInquiriesDataCardTitleAndIconContainer,
    PerformanceReportInquiriesDataCardTitleContainer
} from "../../styles";
import {Stack} from "@mui/material";
import EInquiryType, {getInquiryTypeAsText} from "../../../../shared/models/backend/inquiry/EInquiryType";
import {Palette} from "../../../../shared/theme/palette";

import DocIcon from '@mui/icons-material/Plagiarism';
import DocSelfieIcon from '@mui/icons-material/AssignmentInd';
import PhoneIcon from '@mui/icons-material/Smartphone';
import MailIcon from '@mui/icons-material/Mail';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface PerfomanceCardProps {
    noRiskTotal: number;
    undefinedRiskTotal: number;
    withRiskTotal: number;
    type?: EInquiryType;
}

const PerfomanceCard = (props: PerfomanceCardProps) => {
    const totalInquiries = props.noRiskTotal + props.undefinedRiskTotal + props.withRiskTotal;
    const barMaxWidth = Math.max(props.noRiskTotal, props.undefinedRiskTotal, props.withRiskTotal);
    const barWidth = (value: number): number => (value / barMaxWidth) * 100;
    const theme = useThemeWhiteLabel();
    const Icon = (inquirytype: EInquiryType) => {
        switch (inquirytype) {
            case EInquiryType.Documento:
                return <DocIcon color={'primary'}></DocIcon>
            case EInquiryType.DocumentoComSelfie:
                return <DocSelfieIcon color={'primary'}></DocSelfieIcon>
            case EInquiryType.Telefone:
                return <PhoneIcon color={'primary'}></PhoneIcon>
            case EInquiryType.Email:
                return <MailIcon color={'primary'}></MailIcon>
            case EInquiryType.VideoOnboarding:
                return <VideocamIcon color="primary" />
            default:
                return <></>
        }
    }
    return (
        <PerformanceReportInquiriesDataCard>
            <PerformanceReportInquiriesDataCardContainer>
                <PerformanceReportInquiriesDataCardTitleAndIconContainer>
                    <PerformanceReportInquiriesDataCardTitleContainer>
                        <PerformanceReportInquiriesDataCardTitle primaryColor={theme.primaryColor}>
                            {props?.type ? getInquiryTypeAsText(props?.type) : 'Total de consultas'}
                        </PerformanceReportInquiriesDataCardTitle>
                        <PerformanceReportInquiriesDataCardSubtitle>
                            {totalInquiries}
                        </PerformanceReportInquiriesDataCardSubtitle>
                        <PerformanceReportInquiriesDataBarsContainer>
                            <Stack direction="column" spacing={2}>
                                <PerformanceReportInquiriesDataCardBarContainer>
                                    <PerformanceReportInquiriesDataCardBar
                                        color={theme.primaryColor}
                                        widthPercent={barWidth(props.noRiskTotal)}
                                    />
                                    <PerformanceReportInquiriesDataCardText>
                                        {props?.noRiskTotal}
                                    </PerformanceReportInquiriesDataCardText>
                                </PerformanceReportInquiriesDataCardBarContainer>
                                <PerformanceReportInquiriesDataCardBarContainer>
                                    <PerformanceReportInquiriesDataCardBar
                                        color={theme.alternativeColor}
                                        widthPercent={barWidth(props.undefinedRiskTotal)}
                                    />
                                    <PerformanceReportInquiriesDataCardText>
                                        {props?.undefinedRiskTotal}
                                    </PerformanceReportInquiriesDataCardText>
                                </PerformanceReportInquiriesDataCardBarContainer>
                                <PerformanceReportInquiriesDataCardBarContainer>
                                    <PerformanceReportInquiriesDataCardBar
                                        color={Palette.red}
                                        widthPercent={barWidth(props.withRiskTotal)}
                                    />
                                    <PerformanceReportInquiriesDataCardText>
                                        {props?.withRiskTotal}
                                    </PerformanceReportInquiriesDataCardText>
                                </PerformanceReportInquiriesDataCardBarContainer>
                            </Stack>
                        </PerformanceReportInquiriesDataBarsContainer>
                    </PerformanceReportInquiriesDataCardTitleContainer>
                    {Icon(props?.type)}
                </PerformanceReportInquiriesDataCardTitleAndIconContainer>
            </PerformanceReportInquiriesDataCardContainer>
        </PerformanceReportInquiriesDataCard>
    );
}
export default PerfomanceCard;