export enum ELoanType {
    PayrollLoan = 1,
    FGTSAnniversaryWithdrawal = 2,
}
export const ELoanTypeDescriptions = {
    [ELoanType.PayrollLoan]: "Empréstimo consignado",
    [ELoanType.FGTSAnniversaryWithdrawal]: "Saque-aniversário FGTS",
};
export const ELoanTypeOptions = [
    { label: ELoanTypeDescriptions[ELoanType.PayrollLoan], value: ELoanType.PayrollLoan },
    { label: ELoanTypeDescriptions[ELoanType.FGTSAnniversaryWithdrawal], value: ELoanType.FGTSAnniversaryWithdrawal },
];