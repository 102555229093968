import { useContext, useState, useEffect } from "react";
import "./CreateAccount.css";
import BreadCrumb from "./components/BreadCrumb";
import Steps from "./components/Steps";
import PersonalData from "./components/PersonalData";
import Address from "./components/Address";
import Password from "./components/Password";
import { useNavigate } from "react-router-dom";
import Routes from "../../constants/Routes";
import CreateAccountContext from "./context/CreateAccountContext";
import useCreateAccountContext from "./useCreateAccountContext";
import { Constants } from "../../constants/Constants";
import Header from "../../components/Header";
import { useCreateAccountService } from "../../shared/services/useCreateAccountService";
import { Button } from "@mui/material";
import { removeMask } from "../../shared/utils/remove-mask";
import { toast } from "react-toastify";
import { AccountContext } from "../../shared/context/AccountContext";
import { useAuthenticationService } from "../../shared/services/useAuthenticationService";
import { ValidationUtils } from "../../utils/validation-utils";

const CreateAccount = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { postCriarConta } = useCreateAccountService();
    const { requestLogin } = useAuthenticationService();

    const context = useCreateAccountContext();
    const accountContext = useContext(AccountContext);

    const onBack = () => currentStep === 0 ? window.history.back() : setCurrentStep(currentStep - 1);

    const onNext = () => setCurrentStep(currentStep + 1);

    const onFinish = async () => {
        if (!ValidationUtils.validatePassword(context.password, true, true)) return;
        if (context.password !== context.passwordConfirmation) {
            toast.error('As senhas não coincidem.');
            return;
        }
        setLoading(true);
        postCriarConta({
            conta: {
                cpfCnpj: removeMask(context.cpfCnpj),
                email: context.email!,
                nome: context.name!,
                telefone: removeMask(context.phone)!,
                endereco: {
                    cep: removeMask(context.cep!),
                    estado: context.state!,
                    cidade: context.city!,
                    bairro: context.neighborhood!,
                    logradouro: context.address!,
                    numero: context.number!,
                    complemento: context.complement!,
                },
            },
            usuario: {
                login: context.email!,
                senha: context.password!,
            },
            plano: {
                planoId: Constants.FREE_PLAN_ID,
            },
        })
            .then(() => {
                requestLogin({ email: removeMask(context.cpfCnpj), password: context.password })
                    .then((sessionUser) => {
                        const accountId = sessionUser.user.accountId;
                        const unitId = sessionUser.user.currentUnityId
                        accountContext.setIds({ accountId, unitId });
                        accountContext.setUser(sessionUser);
                        navigate(Routes.RegisterCompleted)
                        toast.success("Conta criada com sucesso!")
                    })
            })
            .catch(() => {
                toast.error('Erro ao cadastrar nova conta. Verifique os dados informados e tente novamente.')
            })
            .finally(() => setLoading(false));

    };

    const getNextButtonDisabled = () => {
        if (currentStep === 0)
            return !context.name || !context.cpfCnpj || !context.email || !context.phone;

        if (currentStep === 1)
            return !context.cep ||
                !context.state ||
                !context.city ||
                !context.address ||
                !context.number ||
                !context.neighborhood;

        if (currentStep === 2)
            return !context.password ||
                !context.passwordConfirmation ||
                context.password !== context.passwordConfirmation;

        return true;
    };

    const nextButtonDisabled = getNextButtonDisabled();

    return (
        <CreateAccountContext.Provider value={context}>
            <div id="create-account-container">
                <Header />
                <div id="create-account-inner-container">
                    <BreadCrumb onBack={onBack} />
                    <Steps currentStep={currentStep} />
                    {currentStep === 0 && <PersonalData />}
                    {currentStep === 1 && <Address />}
                    {currentStep === 2 && <Password />}
                    <div id="create-account-buttons">
                        <Button
                            variant="outlined"
                            color={'primary'}
                            onClick={onBack}
                            disabled={currentStep == 0 || loading}
                        >
                            {'Voltar'}
                        </Button>
                        {currentStep < 2 && (
                            <Button onClick={onNext}
                                disabled={nextButtonDisabled || loading || !context.termsAccepted || !context.privacyPolicyAccepted}
                                variant={'contained'}
                                color={'primary'}>
                                {'Próximo'}
                            </Button>
                        )}
                        {currentStep === 2 && (
                            <Button onClick={onFinish}
                                disabled={nextButtonDisabled || loading}
                                variant={'contained'}
                                color={'primary'}>
                                {'Finalizar Cadastro'}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </CreateAccountContext.Provider>
    );
};

export default CreateAccount;