import axios, {AxiosError, AxiosResponse} from "axios";
import RequestError from "../../models/services/common/request-error";

export const PagarmeInterceptors = () => {

    const SEND_INTERCEPTOR = async (config, enableLogs, env) => {
        enableLogs && console.log('[pagarme]',env, config.url, JSON.stringify(config?.data, null, 2))
        return config
    }

    const ON_FULFILLED_INTERCEPTOR = (response: AxiosResponse<any, any>) => response;

    const ON_REJECTED_INTERCEPTOR = async (axiosError: any, enableLogs: boolean = false, env?: string,) => {
        if (!axios.isAxiosError(axiosError)) {
            return Promise.reject(new RequestError(axiosError.toString(), axiosError?.response?.status, false));
        }

        axiosError = axiosError as AxiosError;
        if (enableLogs) {
            console.group(`[PAGARME]`, env, `[ERRO ${axiosError.response?.status}]`)
            console.log(`[ERRO ${axiosError.response?.status}] `, axiosError.response?.config?.url)
            console.log(axiosError.response?.data?.errors)
            console.groupEnd()
        }
        switch (axiosError.response?.status) {
            case 422: {
                const errorMessage = 'Verifique os dados informados e tente novamente'
                enableLogs && console.log('[pagarme]', env, `[ERRO ${axiosError.response?.status}] `, axiosError?.response?.config?.url)
                enableLogs && console.log('[PAGARME | ERROR 422]', axiosError.response?.data?.errors)
                return Promise.reject(new RequestError(errorMessage, axiosError.response?.status, false));
            }
            default:
                return Promise.reject(new RequestError("Houve um erro ao enviar a solicitação, tente novamente.", axiosError.response?.status, false));
        }
    };
    return {
        SEND_INTERCEPTOR,
        ON_FULFILLED_INTERCEPTOR,
        ON_REJECTED_INTERCEPTOR
    }
}
