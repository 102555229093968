import {useWhitelabelService} from "../shared/services/useWhitelabelService";
import {VideoOnboardingConfig} from "../shared/models/video-onboarding-config";
import {useEffect, useState} from "react";
import {EVideoOnboardingConfig} from "../shared/models/enums/e-video-onboarding.config";
import {Constants} from "../constants/Constants";

const useVideoOnboardingConfigs = () => {
    const [videoOnboardingConfigs, setVideoOnboardingConfigs] = useState<VideoOnboardingConfig[]>([]);
    const [videoOnboardingInstructions, setVideoOnboardingInstructions] = useState<VideoOnboardingConfig[]>([]);
    const [videoOnboardingFormFields, setVideoOnboardingFormFields] = useState<VideoOnboardingConfig[]>([]);
    const {getCurrentWhitelabelVideoOnboardingConfigs} = useWhitelabelService();

    useEffect(() => {
        const updateConfigs = async () => {
            let configs = JSON.parse(localStorage.getItem(Constants.VIDEO_ONBOARDING_CONFIGS_LOCAL_STORAGE_KEY)) as VideoOnboardingConfig[];
            configs = configs ?? await getCurrentWhitelabelVideoOnboardingConfigs(window.location.href)
            setVideoOnboardingConfigs(configs);
            setVideoOnboardingInstructions(configs.filter((c) => isVideoOnboardingInstruction(c)));
            setVideoOnboardingFormFields(configs.filter((c) => isVideoOnboardingInviteFormFields(c)));
            localStorage.setItem(Constants.VIDEO_ONBOARDING_CONFIGS_LOCAL_STORAGE_KEY, JSON.stringify(configs));
        }
        if (!videoOnboardingConfigs.length) updateConfigs();
    }, [videoOnboardingConfigs]);

    const isVideoOnboardingInstruction = (config: VideoOnboardingConfig) =>
        config.type === EVideoOnboardingConfig.VideoOnboardingInstructions

    const isVideoOnboardingInviteFormFields = (config: VideoOnboardingConfig) =>
        config.type === EVideoOnboardingConfig.VideoOnboardingInviteFormFields

    return {
        videoOnboardingInstructions,
        videoOnboardingFormFields
    }
}

export default useVideoOnboardingConfigs;