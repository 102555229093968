import Typography from "../../../../components/Typography";
import PropTypes from "prop-types";

interface OnBoardingFailedProps {
  isBadRequest?: boolean;
}

const OnBoardingFailed: React.FC<OnBoardingFailedProps> = ({ isBadRequest = false }) => {
  if (isBadRequest) {
    return (
      <>
        <Typography variant="h1" color="green">
          Documentação enviada com sucesso!
        </Typography>
        <Typography variant="caption">
          Esta documentação já foi enviada e está em análise com o nosso time de especialistas!
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h1" color="red">
          Acesso Expirado
        </Typography>
        <Typography variant="caption">
          Infelizmente o prazo para envio dos documentos se encerrou.
        </Typography>
      </>
    );
  }
};

export default OnBoardingFailed;
OnBoardingFailed.propTypes = {
  isBadRequest: PropTypes.bool,
};