import {PerformanceReportHeadCardsContainer} from "../../styles";
import RiskLabels from "../RiskLabels";
import SpentValueCard from "../SpentValueCard";
import React from "react";
interface PerformanceCardsHeaderSectionProps {
    spentValue: number;
}
const PerformanceCardsHeaderSection = ({...props}: PerformanceCardsHeaderSectionProps) => {

    return (
        <PerformanceReportHeadCardsContainer>
            <RiskLabels/>
            <SpentValueCard spentValue={props?.spentValue}/>
        </PerformanceReportHeadCardsContainer>
    )
}
export default PerformanceCardsHeaderSection;