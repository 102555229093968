import {useEffect, useState} from "react";

const useWebcam = () => {
    const [hasCamera, setHasCamera] = useState(false);
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                if (videoDevices?.length > 0) {
                    setHasCamera(true);
                    requestCameraPermission();
                    return;
                }
                setErrorMessage('Não identificamos uma câmera no seu computador.');
            })
            .catch(err => setErrorMessage('Erro ao verificar dispositivos: ' + err.message));
    }, []);

    const requestCameraPermission = () => {
        navigator.mediaDevices.getUserMedia({video: true})
            .then(stream => {
                stream.getTracks().forEach(track => track.stop());
                setPermissionGranted(true);
            })
            .catch(() => setErrorMessage('Acesso à câmera negado.'));
    };

    return {hasCamera, permissionGranted, errorMessage};
}
export default useWebcam;