import {useState} from "react";
import {Button} from "@mui/material";
import DataValidationProps from "./DataValidationProps";
import ItemValidation from "./ItemValidation";
import {FormatUtils} from "../../../../shared/utils/format-utils";
import "./DataValidation.css";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import {Constants} from "../../../../constants/Constants";
import {ELoanTypeDescriptions} from "../../../../shared/models/enums/ELoanType";
import {ELoanModalityDescriptions} from "../../../../shared/models/enums/ELoanModality";

const DataValidation = (props: DataValidationProps) => {
    const {
        name,
        cpf,
        phone,
        loanValue,
        contractDate,
        installmentsValue,
        installmentsQuantity,
        bankName,
        loanModality,
        loanType,
        presential
    } = props.inviteCommand;
    const [timer, setTimer] = useState(15);
    const updateTimer = () => setTimer(timer - 1);
    const {currentWhitelabel} = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;
    if (timer > 0) setTimeout(updateTimer, 1000);

    const secondsWord = timer === 1 ? "segundo" : "segundos";

    const timerMessage =
        timer > 0
            ? `Você poderá prosseguir dentro de ${timer} ${secondsWord}.`
            : "Clique no botão abaixo para prosseguir.";

    const sendLinkButtonDisabled = timer > 0;

    return (
        <div id="video-onboarding-data-validation-container">
            <label id="data-validation-title">Verificação de dados</label>
            <label id="data-validation-message">
                Antes de enviar o acesso para o cliente,&nbsp;
                <label id="data-validation-message-bold">
                    verifique atentamente os dados abaixo.
                </label>
            </label>
            <div id="data-validation-data-container">
                <ItemValidation
                    label="Nome Completo do Cliente"
                    value={name}
                    className="data-validation-first-item"
                />
                <ItemValidation label="CPF" value={FormatUtils.formatCPF(cpf)}/>
                <ItemValidation
                    label={`${isWhitelabel && presential ? "Celular do Cliente" : "Whatsapp do Cliente"}`}
                    value={FormatUtils.formatPhoneNumber(phone)}
                />
                <ItemValidation
                    label="Valor do Empréstimo"
                    value={FormatUtils.toBRLCurrency(loanValue)}
                />
                <ItemValidation
                    label="Data da Contratação"
                    value={FormatUtils.toDateFormat(contractDate)?.replaceAll(
                        " ",
                        "/"
                    )}
                />
                <ItemValidation
                    label="Valor da Parcela"
                    value={FormatUtils.toBRLCurrency(installmentsValue)}
                />
                <ItemValidation
                    label="Quantidade de Parcelas"
                    value={installmentsQuantity}
                />
                <ItemValidation label="Banco" value={bankName}/>
                {loanType && (<ItemValidation label="Tipo de empréstimo" value={ELoanTypeDescriptions[loanType]}/>)}
                {loanModality && (
                    <ItemValidation label="Modalidade de empréstimo" value={ELoanModalityDescriptions[loanModality]}/>)}
            </div>
            <label id="data-validation-instructions">
                Caso precise alterar algum dado, clique no botão “Editar Dados” abaixo.
            </label>
            <Button variant="outlined" onClick={props.onEdit}>
                Editar Dados
            </Button>
            <label id="data-validation-timer-message">{timerMessage}</label>
            <Button
                color="primary"
                variant="contained"
                disabled={sendLinkButtonDisabled}
                onClick={props.onSendLink}
                style={{alignSelf: "flex-end", marginBottom: 40}}
            >
                {props.buttonText}
            </Button>
        </div>
    );
};

export default DataValidation;
