import React, {useContext, useEffect, useState} from "react";
import PaymentPlanList from "../../../components/PaymentPlan";
import SubSection from "../../../components/SubSection";
import CreateCreditCardModal from "../../../components/ModalPaymentCard/ModalPaymentCard";
import SubHeader from "../../../components/SubHeader/SubHeader";
import {TextsContainer} from "../../../theme/global-styles";
import styled from "styled-components";
import {AccountContext} from "../../../shared/context/AccountContext";
import {usePlanService} from "../../../shared/services/usePlanService";
import {useSubscriptionService} from "../../../shared/services/useSubscriptionService";
import Layout from "../../../layout";
import PlanWithProductsDTO from "../../../shared/models/backend/plan/PlanWithProductsDTO";
import './OtherPlans.css'

const StyledPaymentPlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const OtherPlans: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [paymentPlans, setPaymentPlans] = useState<PlanWithProductsDTO[]>([]);
    const {ids, setPlan, setSubscription} =
        useContext(AccountContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {buscarTodosPlanosAtivosComProdutos} = usePlanService();
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const fetchPaymentPlans = async () => {
            setLoading(true);
            try {
                const plans = await buscarTodosPlanosAtivosComProdutos()
                setPaymentPlans(plans);
            } finally {
                setLoading(false);
            }
        };
        fetchPaymentPlans();
        getCurrentSubscription();
    }, []);
    
    const filteredPaymentPlans = paymentPlans?.filter((plan) => plan.value !== 0).sort((planA, planB) => planA.value > planB.value ? 1 : -1);

    const getCurrentSubscription = async () => {
        setLoading(true);
        if (ids && ids.unitId !== null && ids.unitId !== undefined) {
            const subscription = await buscarAssinaturaAtiva(ids.unitId).finally(
                () => setLoading(false)
            );
            if (subscription && subscription.plano !== null) {
                setSubscription(subscription);
                setPlan({...subscription.plano, planoId: subscription.plano.id});
            }
        }
    };

    return (
        <Layout iconHelp>
            <SubHeader title="Planos/Pagamentos" subtitle="Seleção de planos"/>
            <SubSection>
                <TextsContainer>
                    <p id="other-plans-title">
                        Trabalhamos com planos exclusivos e tarifas diferenciadas, adaptados
                        para qualquer negócio. <b>Leia atentamente a descrição dos planos.</b>
                    </p>
                </TextsContainer>
                <StyledPaymentPlanContainer>
                    <PaymentPlanList paymentPlans={filteredPaymentPlans} size="small" showChooseButton/>
                </StyledPaymentPlanContainer>
                {isModalOpen ? (
                    <CreateCreditCardModal
                        onCancel={handleCloseModal}
                        onSave={handleCloseModal}
                        loading={loading}
                        setLoading={setLoading}
                        onClose={handleCloseModal}
                    />
                ) : (
                    <></>
                )}
            </SubSection>
        </Layout>
    );
};

export default OtherPlans;
