import React, {useEffect, useState} from "react";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input/Input";
import Typography from "../../../../components/Typography";
import CheckBox from "../../../../components/CheckBox";
import styled from "styled-components";
import {Button} from "@mui/material";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const Line = styled.div`
  display: flex;
  flex-direction: row;

`;
const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

interface ModalPlanCancelProps {
    onClose: () => void;
    cancelSubscription: () => void;
    reasonCancel: string;
    setReasonCancel: (reason: string) => void;
    loading: boolean;
}

const ModalPlanCancel: React.FC<ModalPlanCancelProps> = ({
                                                             onClose,
                                                             cancelSubscription,
                                                             reasonCancel,
                                                             setReasonCancel,
                                                             loading,
                                                         }) => {
    const [cancel, setCancel] = useState<string>("");
    const [SubSectionFormValid, setSubSectionFormValid] = useState(false);
    const [showFirstInputs, setShowFirstInputs] = useState(true);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [checkbox4, setCheckbox4] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const theme = useThemeWhiteLabel();
    const handleNextClick = () => {
        setShowFirstInputs(false);
        setSubSectionFormValid(true);
    };
    const back = () => {
        setShowFirstInputs(true);
        setSubSectionFormValid(false);
    };
    useEffect(() => {
        if (currentIndex !== -1) {
            const onBackPress = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                onClose();
                // Chrome requires returnValue to be set.
                event.returnValue = "";
            };

            window.addEventListener("beforeunload", onBackPress);

            return () => {
                window.removeEventListener("beforeunload", onBackPress);
            };
        }
    }, [currentIndex, onClose]);

    const updateSelectedReason = (reason: number) => {
        setCheckbox1(false);
        setCheckbox2(false);
        setCheckbox3(false);
        setCheckbox4(false);
        switch (reason) {
            case 1:
                setCheckbox1(true);
                setReasonCancel("Serviço não atendeu às expectativas");
                break;
            case 2:
                setCheckbox2(true);
                setReasonCancel("Não consigo me comprometer com o valor da assinatura");
                break;
            case 3:
                setCheckbox3(true);
                setReasonCancel("Falta de atendimento");
                break;
            case 4:
                setCheckbox4(true);
                setReasonCancel("");
                break;
            default:
                break;
        }
    };
    return (
        <Modal title="Cancelar Assinatura" onClose={onClose}>
            <>
                {showFirstInputs ? (
                    <>
                        <Typography variant="filterLabel">
                            Você está prestes a cancelar sua assinatura. Por favor digite
                            “CANCELAR” no campo abaixo para confirmar sua ação.
                        </Typography>

                        <Input
                            fullWidth
                            placeholder="Digite..."
                            value={cancel}
                            onChange={setCancel}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "24px",
                            }}
                        >
                            <Button size="medium" color="secondary" onClick={onClose}>
                                Voltar
                            </Button>
                            <Button
                                size="medium"
                                variant={'contained'}
                                color={
                                    cancel.toUpperCase() === "CANCELAR" ? "error" : "secondary"
                                }
                                disabled={cancel.toUpperCase() !== "CANCELAR"}
                                onClick={handleNextClick}
                            >
                                Próximo
                            </Button>
                        </div>
                    </>
                ) : SubSectionFormValid ? (
                    <>
                        <InnerContainer>
                            <Typography variant="filterLabel">
                                Conte-nos, por que você deseja cancelar sua assinatura?
                            </Typography>
                            <Line>
                                  <CheckBox
                                    primaryColor={theme.primaryColor}
                                    isChecked={checkbox1}
                                    onPress={() => updateSelectedReason(1)}
                                />
                                <Typography variant="filterLabel">
                                    Serviço não atendeu às expectativas
                                </Typography>
                            </Line>
                            <Line>
                                  <CheckBox
                                    primaryColor={theme.primaryColor}
                                    isChecked={checkbox2}
                                    onPress={() => updateSelectedReason(2)}
                                />
                                <Typography variant="filterLabel">
                                    Não consigo me comprometer com o{"\n"}valor da assinatura
                                </Typography>
                            </Line>
                            <Line>
                                  <CheckBox
                                    primaryColor={theme.primaryColor}
                                    isChecked={checkbox3}
                                    onPress={() => updateSelectedReason(3)}
                                />
                                <Typography variant="filterLabel">
                                    Falta de atendimento
                                </Typography>
                            </Line>
                            <Line>
                                  <CheckBox
                                    primaryColor={theme.primaryColor}
                                    isChecked={checkbox4}
                                    onPress={() => updateSelectedReason(4)}
                                />
                                <Typography variant="filterLabel">Outros</Typography>
                            </Line>
                        </InnerContainer>
                        {checkbox4 && (
                            <>
                                <Typography variant="filterLabel">
                                    Estamos tristes em te ver partir. Pedimos, por gentileza que
                                    você nos conte um pouco mais sobre o motivo do cancelamento
                                    para que possamos continuar a melhorar nossos serviços.
                                </Typography>
                                <Input
                                    fullWidth
                                    multiline
                                    isBigger
                                    placeholder="Motivo do cancelamento"
                                    value={reasonCancel}
                                    onChange={setReasonCancel}
                                />
                            </>

                        )}
                        <ButtonContainer>
                            <Button size="medium" color="secondary" onClick={back}>
                                Voltar
                            </Button>
                            <Button
                                size="medium"
                                variant={'contained'}
                                color={
                                    checkbox1 || checkbox2 || checkbox3 || checkbox4
                                        ? "error"
                                        : "secondary"
                                }
                                disabled={!checkbox1 && !checkbox2 && !checkbox3 && !checkbox4}
                                onClick={cancelSubscription}
                            >
                                Cancelar Assinatura
                            </Button>

                        </ButtonContainer>
                    </>
                ) : null}
            </>
        </Modal>
    );
};

export default ModalPlanCancel;
