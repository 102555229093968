import styled from "styled-components";
import {Palette} from "../../../../shared/theme/palette";

export const ResultTimeInfoCardView = styled.div<{ primaryColor: string }>`
  width: 100%;
  min-height: 52px;
  border-radius: 8px;
  background-color: ${Palette.white};
  border-style: solid;
  border-width: 0.8px;
  border-color: ${(props) => props.primaryColor};
  padding: 7px 7px 7px 15px;
`;
export const ResultTimeInfoCardContent = styled.div`
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  top: 6px;
  gap: 16px;
  padding: 8px;
`;
export const ResultTimeInfoCardTextContainer = styled.div`
  min-height: 24px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;
export const ResultTimeInfoCardText = styled.text`
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  color: ${Palette.black};
`;
export const ResultTimeInfoCardBoldText = styled.text`
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  color: ${Palette.black};
  font-weight: 700;
`;
export const ResultTimeInfoCardIconContainer = styled.div`
  width: 40px;
  height: 40px;
`;