import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Typography from "../../Typography";
import useDate from "../../../shared/hooks/useDate";
import { ButtonText, Container, FilterContainer, Row } from "./styles";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
interface FilterHeaderProps {
  dateFrom: Date;
  dateTo: Date;
  onFilterPress: () => void;
  isFilterOpen: boolean; 
}

const FilterHeader = (props: FilterHeaderProps) => {
  const {formatDate} = useDate();
  const theme = useThemeWhiteLabel();
  const dateFrom = formatDate(props.dateFrom);
  const dateTo = formatDate(props.dateTo);
  return (
    <Container>
      <Row>
        <Typography variant='filterSummaryLabel'>
          Data dos resultados
        </Typography>
        <Typography variant='filterSummaryValue'>
          {dateFrom} a {dateTo}
        </Typography>
      </Row>
      <FilterContainer onClick={props.onFilterPress}> 
        <FilterAltIcon htmlColor={theme.primaryColor} />
        <ButtonText primaryColor={theme.primaryColor}>Filtrar</ButtonText>
        {props.isFilterOpen ? <ArrowCircleUpIcon htmlColor={theme.primaryColor}/> : <ArrowCircleDownIcon htmlColor={theme.primaryColor}/>}
      </FilterContainer>
    </Container>
  );
};


export default FilterHeader;
