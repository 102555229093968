import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";


export const PerformanceReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;
export const PerformanceReportHeadCardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  `;
export const PerformanceReportTopCard = styled.div`
  display: flex;
  width: 22.5rem;
  padding: 0.75rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 0.8px solid ${Palette.lightGrey6};
  background: ${Palette.white};
  box-shadow: 0px 1px 10px 0px rgba(220, 220, 220, 0.70);
`;

export const RiskLabelsContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const RiskLabelContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const RiskLabelColorIndicator = styled.div<{color: string}>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${props => props.color};
`;
export const RiskLabelText = styled.span`
  color: var(--Cinza-Claro, ${Palette.darkGrey4});
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;

export const SpentValueCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const SpentValueCardTextContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;
`;
export const SpentValueCardIconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;
export const SpentValueCardLabel = styled.span<{ primaryColor: string }>`
  color: var(--Verde1, ${(props) => props.primaryColor});
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
`;
export const SpentValueCardValue = styled.span`
  color: var(--Cinza-Escuro, ${Palette.darkGrey2});
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 120% */
`;
export const PerformanceReportInquiriesDataCard = styled.div`
  display: flex;
  width: 22.5rem;
  padding: 0.75rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 0.8px solid ${Palette.lightGrey6};
  background:  ${Palette.white};
  box-shadow: 0px 1px 10px 0px rgba(220, 220, 220, 0.70);
`;
export const PerformanceReportInquiriesDataCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
`;
export const PerformanceReportInquiriesDataCardTitleAndIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  `;
export const PerformanceReportInquiriesDataCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  `;
export const PerformanceReportInquiriesDataCardTitle = styled.span<{ primaryColor: string }>`
  color: var(--Verde1, ${(props) => props.primaryColor});
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
`;
export const PerformanceReportInquiriesDataCardSubtitle = styled.span`
  color: var(--Cinza-Escuro, ${Palette.darkGrey2});
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 109.091% */
`;
export const PerformanceReportInquiriesDataCardBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
`;
export const PerformanceReportInquiriesDataCardBar = styled.div<{color: string, widthPercent: number}>`
  background-color: ${props => props.color};
  height: 1rem;
  width: ${props => ((props.widthPercent ?? 0) * 0.0525)}rem;
`;
export const PerformanceReportInquiriesDataCardText = styled.span`
  display: flex;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  color: var(--Cinza-Escuro, ${Palette.darkGrey2});
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
`;
export const PerformanceReportInquiriesDataBarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  padding-top: 1rem;
`;