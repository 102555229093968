import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../../shared/context/AccountContext";
import {UserProfileType} from "../../../shared/models/user-profile-type";
import MenuGroup from "../components/Item/menuGroup";
import {MenuGroups} from "./menu-groups";

const useMenu = () => {
    const [sections, setSections] = useState<MenuGroup[]>([]);
    const {user} = useContext(AccountContext);
    const getSections = (profile?: UserProfileType) => {
        console.log({profile})
        if (!profile) return;
        const allowedMenus = MenuGroups.map((section) => {
            const options = section.options.filter((option) =>
                !option.roles ? false : option.roles.includes(profile));
            return ({...section, options});
        });
        setSections(allowedMenus);
    }
    useEffect(() => getSections(user?.user?.profile), [user?.user?.profile]);
    return {menuGroups: sections};
}
export default useMenu;