import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import DeviceMobile from "./screens/DeviceMobile/DeviceMobile";
const Main = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
  }, []);

  return isMobileDevice && !isOnboarding ? (
    <>
      <BrowserRouter>
        <DeviceMobile setIsOnboarding={setIsOnboarding} />
      </BrowserRouter>
    </>
  ) : (
    <App />
  );
};

export default Main;
