import Layout from "../../layout";
import SubHeader from "../../components/SubHeader/SubHeader";
import SubSection from "../../components/SubSection";
import {PaginationContainer} from "../InquiryList/styles";
import Typography from "../../components/Typography";
import React, {useEffect, useState} from "react";
import Input from "../../components/Input/Input";
import {SearchBarContainer} from "./styles";
import {Button, Pagination} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useUnitService} from "../../shared/services/useUnitService";
import {ShortUnity} from "../../shared/models/unity";
import {PagedList, PagedListFilter} from "../../shared/models/responses/paged-list";
import UnityCard from "./components/UnityCard";
import {useNavigate} from "react-router-dom";
import Routes from "../../constants/Routes";
import {ListContainer} from "../ListUsers/styles";

const UnitiesListScreen = () => {
    const [pagedList, setPagedList] = useState<PagedList<ShortUnity>>();
    const [filter, setFilter] = useState<PagedListFilter>({page: 0});
    const {getAccountUnities} = useUnitService();
    const navigate = useNavigate();
    useEffect(() => {
        const getUnities = async () =>
            setPagedList(await getAccountUnities(filter))
        getUnities();
    }, [filter]);
    return (
        <Layout>
            <>
                <SubHeader title={"Configurações"} subtitle={"Gerenciar Unidades"}/>
                <SubSection>
                    <Typography variant="caption">
                        Use esta tela para gerenciar e criar unidades.
                    </Typography>
                </SubSection>
                <SearchBarContainer>
                    <></>
                    <Input startAdornment={<SearchIcon/>}
                           placeholder={'Buscar Nome, CNPJ, E-mail, Telefone...'}
                           fullWidth
                           value={filter.search}
                           onChange={(search) => setFilter({...filter, search})}
                    />
                    <Button variant={'contained'}
                            onClick={() => navigate(Routes.Unities.New)}
                    >{'Nova Unidade'}</Button>
                </SearchBarContainer>
                <SubSection>
                    <ListContainer>
                        {pagedList?.itens.map((unity, k) => <UnityCard key={k} unity={unity}></UnityCard>)}
                    </ListContainer>
                </SubSection>
                <SubSection>
                    {pagedList?.itens?.length > 0 && (
                        <PaginationContainer>
                            <Pagination
                                count={pagedList.totalPages}
                                page={filter.page + 1}
                                onChange={(e, p) => setFilter({...filter, page: p - 1})}
                                color="primary"
                                shape="rounded"
                            />
                        </PaginationContainer>
                    )}
                </SubSection>
            </>
        </Layout>
    );
}
export default UnitiesListScreen;