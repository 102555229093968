import Modal from "../../../../components/Modal";
import {SectionText, SectionTextBold, SectionTextLink, SectionTitleText} from "./styles";
import {Button} from "@mui/material";
import useWhitelabel from "../../../../hooks/useWhitelabel";

interface PrivacyPolicyModalProps {
    onClose: (read?: boolean) => void;
}

const PrivacyPolicyModal = ({...props}: PrivacyPolicyModalProps) => {
    const {whitelabelName} = useWhitelabel();
    return <Modal title={'Política de Privacidade'}>
        <>
            <SectionTitleText>{'1. Objetivos'}</SectionTitleText>
            <SectionText>{`A WHO acredita em relações de confiança para todos e confiança pressupõe transparência e segurança, ambos princípios centrais para nós. O objetivo desta Política de Privacidade é esclarecer para Você, os tratamentos que são feitos com os seus dados pessoais no ${whitelabelName}.`}</SectionText>

            <SectionTitleText>{'2. Definições'}</SectionTitleText>
            <SectionText>{'Aqui Você encontrará algumas expressões que utilizamos nesta Política para ' +
                'facilitar a compreensão do conteúdo apresentado'}</SectionText>

            <SectionTextBold>{'Anonimização: '}
                <SectionText>{'Utilização de meios técnicos, razoáveis e disponíveis no momento do ' +
                    'tratamento de dados pessoais, por meio dos quais um dado perde a possibilidade de ' +
                    'associação, direta ou indireta, a um indivíduo. O dado anonimizado não é considerado ' +
                    'dado pessoal para os fins da LGPD.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Autoridade Nacional de Proteção De Dados (“ANPD”): '}
                <SectionText>{'Órgão da administração pública responsável por zelar, implementar e ' +
                    'fiscalizar o cumprimento da LGPD em todo território nacional. A ANPD foi instituída ' +
                    'pela LGPD como órgão da administração pública federal com autonomia técnica, ' +
                    'integrante da Presidência da República, definido a sua natureza como transitória e ' +
                    'passível de transformação pelo Poder Executivo em entidade da administração pública ' +
                    'federal indireta, submetida a regime autárquico especial e vinculada à Presidência da ' +
                    'República.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Clientes: '}
                <SectionText>{'Pessoa Jurídica com as quais a WHO tenha estabelecido relacionamento ' +
                    'comercial amparado por contrato assinado por ambas as partes.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Controlador de Dados Pessoais: '}
                <SectionText>{'Pessoa natural ou jurídica, de direito público ou privado, a quem competem ' +
                    'as decisões referentes ao tratamento de dados pessoais.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Dados Pessoais: '}
                <SectionText>{'Informação relacionada a pessoa natural identificada ou identificável. ' +
                    'Também são considerados dados pessoais aqueles utilizados para formação do perfil ' +
                    'comportamental de determinada pessoa natural.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Dados Pessoais Sensíveis: '}
                <SectionText>{'Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião ' +
                    'política, filiação a sindicato ou a organização de caráter religioso, filosófico ou ' +
                    'político, dado referente à saúde ou à vida sexual, dado genético ou biométrico quando ' +
                    'vinculado a pessoa natural.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Documentoscopia: '}
                <SectionText>{`O serviço de documentoscopia é uma análise de documentos de identificação, como RG e CNH, realizado por um time de especialistas em prevenção a fraude da WHO dedicado a determinar a veracidade destes documentos. Os documentos de identificação (RG e CNH) submetidos no momento de cadastro do ${whitelabelName} serão submetidos ao processo de documentoscopia e caso não se enquadrem no padrão exigido na Política Interna de Aprovação de Cadastros, poderão ser reprovados.`}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Encarregado ou Data Protection Officer (“DPO”): '}
                <SectionText>{'Pessoa natural ou jurídica indicada pelo Agente de Tratamento para atuar ' +
                    'como canal de comunicação entre o Controlador, os titulares de dados e a Autoridade ' +
                    'Nacional de Proteção de Dados.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Fornecedores: '}
                <SectionText>{' São considerados Fornecedores os outros terceiros contratados e ' +
                    'subcontratados, pessoa natural ou jurídica, não enquadrados como parceiros ' +
                    'comerciais.'}</SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Lei Geral de Proteção de Dados Pessoais (“LGPD”): '}
                <SectionText>{'Lei 13.709 de 14 de agosto de 2018 que dispõe acerca do tratamento de ' +
                    'Dados Pessoais, em meios físicos ou digitais, por pessoa natural ou jurídica.'}
                </SectionText>
            </SectionTextBold>

            <SectionTextBold>{`${whitelabelName}: `}
                <SectionText>{`É um produto da WHO. O ${whitelabelName} tem por finalidade facilitar a consulta de serviços de verificação de identidade à pequenas e médias empresas.`}
                </SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Parceiros: '}
                <SectionText>{'Pessoas naturais ou jurídicas com quem a WHO estabelece ' +
                    'relações comerciais, em regime de parceria, para oferecer produtos e serviços.'}
                </SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Titular de Dados Pessoais (“Titular”): '}
                <SectionText>{'Pessoa natural a quem se referem os dados pessoais que são objeto de ' +
                    'tratamento. Titular é apenas pessoa natural, aqui não se encaixam pessoas jurídicas.'}
                </SectionText>
            </SectionTextBold>

            <SectionTextBold>{'Tratamento de Dados Pessoais (“Tratamento”): '}
                <SectionText>{'Toda operação realizada com dados pessoais, como as que se referem a ' +
                    'coleta, produção, recepção, classificação, utilização, acesso, reprodução, ' +
                    'transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, ' +
                    'avaliação, controle da informação, modificação, comunicação, transferência, difusão ' +
                    'ou extração.'}
                </SectionText>
            </SectionTextBold>

            <SectionTextBold>{`Usuários do ${whitelabelName} (“Você”): `}
                <SectionText>{`Pessoas naturais que utilizam o produto ${whitelabelName}.`}
                </SectionText>
            </SectionTextBold>


            <SectionTitleText>{'3. A quem essa Política se aplica?'}</SectionTitleText>
            <SectionText>{`Essa Política se aplica aos usuários ${whitelabelName}. A Política aplicável aos demais casos de tratamentos de dados pessoais que a WHO realiza, como o que diz respeito aos usuários do site, clientes da WHO e usuários de outros produtos da empresa, Você pode acessar a “Política de Privacidade – WHO” disponível `}
                <SectionTextLink
                    onClick={() => window.open('https://whoid.com/politica-de-privacidade', '_blank')}>
                    {'aqui.'}
                </SectionTextLink>
            </SectionText>

            <SectionTitleText>{`3.1. Usuários do ${whitelabelName}`}</SectionTitleText>
            <SectionText>{`São Usuários do ${whitelabelName} todas as pessoas naturais com perfil no ${whitelabelName}. Ao criar sua conta e utilizar o ${whitelabelName} também realizamos o tratamento de seus dados pessoais.`}
            </SectionText>

            <SectionTitleText>{'4. Quais dados são tratados?'}</SectionTitleText>
            <SectionText>{`O ${whitelabelName} é uma solução que facilita a consulta de serviços de verificação de identidade e de crédito financeiro.`}
                <br/>
                {` Todavia, o ${whitelabelName} também oferece outros recursos diversos, facilitando a sua identificação e o acesso aos serviços oferecidos pela WHO ou por terceiros. Ao criar uma conta no ${whitelabelName}, obtemos o seu consentimento para criarmos o seu cadastro e podermos oferecer o ${whitelabelName} à Você. Os demais recursos oferecidos no ${whitelabelName} exigirão consentimento específico para cada atividade que Você desejar realizar, especialmente aquelas onde haverá compartilhamento de dados com parceiros e fornecedores. Vale ressaltar que sempre que houver compartilhamento de dados, informaremos a Você os dados que serão utilizados, para que Você possa decidir se realmente deseja realizar aquela ação.`}
                <br/>
                {'Por fim, eventualmente, a WHO poderá utilizar dados pessoais para finalidades ' +
                    'não expressamente previstas nesta política de privacidade, desde que dentro do escopo ' +
                    'de atividade da WHO e em obediência à legislação de proteção de dados.'}
                <br/>
                {`Você que é Usuário Titular do ${whitelabelName} tem os seguintes dados pessoais tratados pela WHO:`}
                <br/>
                {'● Razão Social;'}
                <br/>
                {'● CNPJ;'}
                <br/>
                {'● Telefone;'}
                <br/>
                {'● Endereço.'}
            </SectionText>

            <SectionTitleText>{'4.1. Biometria comportamental'}</SectionTitleText>
            <SectionText>{'Utilizamos a biometria comportamental para a verificação de endereço com o ' +
                'mínimo de fricção possível com o Usuário. Através de um fornecedor, coletamos a força ' +
                'do sinal de rede, GPS, Wi-fi e Bluetooth, campo magnético, acelerômetro e giroscópio ' +
                'para construir uma identidade privada de alta precisão para cada Usuário, baseado em ' +
                'seu comportamento de localização. Caso seu device já conste na base deste fornecedor ' +
                '(efeito de rede), o tempo de validação pode ser imediato. Caso seu device ainda não ' +
                'conste, será feita a validação da sua biometria comportamental durante um período de ' +
                '48 horas e, a partir daí garantiremos um comprovante de endereço. Não compartilhamos ' +
                'dados de documentos pessoais como nome, RG ou CPF com este fornecedor, sendo as ' +
                'informações utilizadas exclusivamente para fins de entender seu comportamento de ' +
                'localização e, assim podermos prevenir tentativas de fraude, além de verificar seu ' +
                'endereço quando solicitado por Você. [necessário checar se é aplicável à WHO].'}
            </SectionText>

            <SectionTitleText>{'5. Com quem os dados são compartilhados?'}</SectionTitleText>
            <SectionText>{'Os seus dados pessoais poderão ser compartilhados com os seguintes agentes de tratamento, com as finalidades a seguir:'}
                <br/>
                {`[checar modelo do ${whitelabelName} e ver o que é aplicável. Necessário identificar detalhadamente quem são os agentes de tratamento] `}
                <br/>
                {'Importante mencionar que a WHO toma todas as precauções devidas no momento de compartilhamento de dados pessoais para que este seja feito de forma segura e seguindo os requisitos previstos na Lei Geral de Proteção de Dados. Todavia a WHO não se responsabiliza pelo tratamento de dados pessoais efetuado por terceiros (não subcontratados) em razão da utilização de sistemas, aplicativos, sites e plataformas em geral que lhes sejam próprios. Os tratamentos realizados por parceiros da WHO serão regidos por suas respectivas Políticas de Privacidade.'}
            </SectionText>

            <SectionTitleText>{`5.1. Serviços disponibilizados por parceiros no ${whitelabelName}`}</SectionTitleText>
            <SectionText>{`A WHO realiza parcerias com empresas com a finalidade de disponibilizar serviços aos Usuários, através do MarketPlace do ${whitelabelName}. Poderão ser realizadas parcerias com empresas de diferentes segmentos, como por exemplo, [ ]. Seguimos expandindo as parcerias a fim de disponibilizar novos serviços aos Usuários do ${whitelabelName}, logo constantemente o rol de parceiros do MarketPlace é atualizado. `}
                <br/>
                {'Vale ressaltar que os seus Dados Pessoais serão compartilhados com as empresas ' +
                    'parceiras apenas mediante o seu consentimento e que caso você se inscreva para utilizar ' +
                    'um serviço você concorda com os Termos de Uso e Política de Privacidade do Parceiro ' +
                    'responsável pelo serviço escolhido.'}
            </SectionText>

            <SectionTitleText>{'6. Transferência Internacional'}</SectionTitleText>
            <SectionText>{'A WHO concentra a maior parte das suas bases de dados em servidores ' +
                'localizados no Brasil, incluindo todas as operações de tratamentos realizadas para ' +
                'os nossos Clientes que são instituições financeiras, considerando as Resoluções ' +
                '4.893/2021 e 85/2021 do BACEN. Contudo, algumas operações pontuais ainda permanecem ' +
                'com servidores no exterior, estando estes localizados nos EUA.'}
            </SectionText>

            <SectionTitleText>{'7. Fontes de dados públicas e privadas'}</SectionTitleText>
            <SectionText>{`A WHO utiliza fontes de dados públicas e privadas com a finalidade de verificação da identidade e outras soluções fornecidas no produto ${whitelabelName}. São consideradas fontes públicas todas as fontes de informação de acesso público e gratuito, tais como sites de órgãos governamentais do Poder Executivo, Legislativo e Judiciário. São consideradas fontes privadas todas aquelas cujo acesso não é gratuito, sendo necessário realizar a contratação de sua utilização, tais como Birôs privados de dados.`}
            </SectionText>

            <SectionTitleText>{'8. Duração do tratamento dos dados'}</SectionTitleText>
            <SectionText>{'O Tratamento de dados pessoais realizados pela WHO terá a sua duração seguida ' +
                'de acordo com o artigo 15 da LGPD, podendo haver exceções nos termos do artigo 16. Os ' +
                'dados pessoais tratados pela WHO na sua condição de operadora para a prestação de ' +
                'serviços aos Clientes WHO, serão regidos pelas disposições contratuais associadas aos ' +
                'artigos 15 e 16 da LGPD.'}
            </SectionText>


            <SectionTitleText>{'9. Direitos dos Titulares'}</SectionTitleText>
            <SectionText>{`A WHO, no âmbito do ${whitelabelName} atuará como controladora de dados. Conforme os artigos 18 da LGPD são direitos do Titular de dados:`}
            </SectionText>

            <SectionText>{'I. Confirmação da existência de tratamento de dados pessoais: Você poderá ' +
                'questionar à WHO se realizamos qualquer tratamento dos seus dados pessoais. Para isso ' +
                'iremos solicitar a confirmação da sua identidade antes de compartilhar com Você ' +
                'quaisquer informações.'}
            </SectionText>

            <SectionText>{'II. Acesso aos dados tratados: Você tem direito à acessar os seus dados ' +
                'pessoais que tivermos em nossas bases de dados. Para isso iremos solicitar a ' +
                'confirmação da sua identidade antes de compartilhar com Você quaisquer informações.'}
            </SectionText>

            <SectionText>{'III. Correção de dados incompletos, inexatos ou desatualizados: Você ' +
                'poderá solicitar a correção ou atualização dos seus dados através do e-mail ' +
                'disponível no item 12 desta política.'}
            </SectionText>

            <SectionText>{'IV. Anonimização, bloqueio ou eliminação de dados tratados de forma ' +
                'irregular: A qualquer momento, Você poderá requisitar a anonimização, bloqueio ' +
                'ou eliminação de seus dados pessoais, desde que tenham sido reconhecidos por ' +
                'autoridade competente como desnecessários, excessivos ou tratados em desconformidade ' +
                'com o disposto na LGPD.'}
            </SectionText>

            <SectionText>{`V. Eliminação dos dados pessoais: Sempre que seus dados forem tratados com base no seu consentimento, você poderá solicitar a exclusão destes a qualquer tempo entrando em contato agtravés do endereço de e-mail suporte@consultai.app . A WHO poderá vir a mantê-los caso se enquadrem nas exceções do artigo 16 da LGPD. Ressaltamos que caso Você exclua a sua conta e queira depois utilizar novamente o ${whitelabelName}, não será possível resgatar os seus documentos na sua conta antiga, será necessário criar uma nova conta e submeter novamente os seus documentos no ${whitelabelName}.`}
            </SectionText>

            <SectionText>{'VI. Informação sobre compartilhamento: Você poderá solicitar informações ' +
                'sobre as entidades, públicas ou privadas, com as quais seus dados pessoais ' +
                'foram compartilhados.'}
            </SectionText>

            <SectionText>{'VII. Informação sobre a possibilidade de não fornecer consentimento e sobre ' +
                'as consequências da negativa: Você não é obrigado a fornecer seu consentimento para o ' +
                'tratamento de dados pessoais, todavia este pode impactar a forma como conseguimos ' +
                'prestar nossos serviços para Você. Dessa forma, sempre que o consentimento for ' +
                'necessário, deixaremos isto claro, assim como a possibilidade de negá-lo e as ' +
                'consequências disto.'}
            </SectionText>

            <SectionText>{'VIII. Revogação do consentimento, nos termos do § 5º do art. 8º da LGPD: ' +
                'Ainda, sempre que o tratamento dos dados for baseado em consentimento, é um direito ' +
                'seu revogar este a qualquer tempo. Cabe ressaltar que a revogação não afetará qualquer ' +
                'tratamento já realizado e poderá impedir a continuidade da utilização do produto ou ' +
                'serviço em questão, hipótese na qual o Usuário será devidamente informado.'}
            </SectionText>

            <SectionText>{'IX. Revisão de Decisão Automatizada, nos termos do Art. 20º da LGPD: ' +
                'Você pode solicitar a revisão de decisões tomadas unicamente com base em ' +
                'tratamento automatizado de dados pessoais.'}
            </SectionText>


            <SectionTitleText>{'10. Deveres dos Titulares'}</SectionTitleText>
            <SectionText>{'São deveres dos titulares de dados pessoais:'}
            </SectionText>

            <SectionText>{`I. Não fornecer informações inverídicas ou incompletas no momento de cadastro no ${whitelabelName};`}
            </SectionText>

            <SectionText>{'II. Informar à WHO sobre quaisquer modificações em seus dados pessoais ' +
                'para que possamos manter os seus dados sempre atualizados e corretos;'}
            </SectionText>

            <SectionText>{'III. Não compartilhar logins e senhas com terceiros, assim como utilizar ' +
                'senhas fortes em seus cadastros, dessa forma, o titular também faz a sua parte para ' +
                'garantir a segurança de seus dados;'}
            </SectionText>

            <SectionText>{'IV. Analisar esta Política de Privacidade com atenção e, nos casos em que ' +
                'for necessário, apenas dar o seu Consentimento quando concordar com a utilização de ' +
                'seus dados pessoais aqui descritos; e'}
            </SectionText>

            <SectionText>{`V. Respeitar as regras de utilização definidas nos Termos de Uso do ${whitelabelName}.`}
            </SectionText>


            <SectionTitleText>{'11. Segurança da Informação'}</SectionTitleText>
            <SectionText>{'As normas de segurança da informação e prevenção contra incidentes de dados ' +
                'pessoais estão contidas na Política de Segurança da Informação da WHO e nas normativas ' +
                'internas. A WHO se compromete a empregar medidas técnicas e organizacionais adequadas ' +
                'no tratamento de dados pessoais.'}
                <br/>
                {'A WHO tem em vigor medidas de segurança adequadas com vistas à: (i) proteção contra ' +
                    'o acesso não autorizado aos dados armazenados e às cópias de segurança realizadas; e ' +
                    '(ii) impedir alterações, divulgações e/ou destruições não autorizadas de informações ' +
                    'detidas pela WHO. Os dados coletados são armazenados sob as mais rígidas práticas de ' +
                    'segurança da informação no banco de dados da WHO. Nosso banco de dados é rigorosamente' +
                    ' supervisionado e protegido, de modo que apenas funcionários habilitados possuem ' +
                    'acesso, que estão contratualmente obrigados aos deveres de sigilo e confidencialidade.'}
                <br/>
                {'A WHO trabalha continuamente para proteger a sua privacidade e manter a segurança ' +
                    'dos seus dados. Todos os nossos produtos são desenvolvidos com recursos de segurança ' +
                    'sólidos e seguimos as boas práticas de segurança, privacidade, proteção de dados e de ' +
                    'governança para os nossos serviços, incluindo os requisitos de segurança previstos nas ' +
                    'legislações vigentes.'}
                <br/>
                {'Adotamos medidas preventivas e apropriadas para evitar e minimizar os riscos ' +
                    'relacionados ao tratamento de dados pessoais. Apresentamos a seguir as medidas ' +
                    'técnicas e administrativas para proteção de dados adotadas pela WHO:'}
                <br/>
                {'● Normas, políticas e procedimentos internos de segurança da informação e proteção ' +
                    'de dados, que têm como objetivo garantir a segurança da informação e o tratamento ' +
                    'adequado dos dados pessoais.'}
                <br/>
                {'● Treinamento e conscientização de nossos colaboradores.'}
                <br/>
                {'● Restrição ao acesso aos dados pessoais por parte de nossos colaboradores e ' +
                    'terceiros que necessitam dessas informações para o processamento dos dados.'}
                <br/>
                {'● Armazenamento dos dados pessoais em nuvem, seguindo os protocolos de segurança ' +
                    'de nossos provedores de nuvem e melhores práticas internacionais.'}
                <br/>
                {'● Uso de técnicas de criptografia para os dados armazenados em bancos de dados e ' +
                    'para os dados transferidos internamente e externamente.'}
                <br/>
                {'● Uso de ferramentas seguras para transferências das informações.'}
                <br/>
                {' Nossas medidas são continuamente revisadas e monitoradas, de acordo com os recursos ' +
                    'organizacionais e tecnológicos mais atuais. ' +
                    'Apesar de todas as medidas apresentadas acima, a WHO, assim como quaisquer empresas ou ' +
                    'órgão públicos que tratam dados pessoais, não está isenta da ação de terceiros mal ' +
                    'intencionados. Em caso de incidente de segurança da informação que possa acarretar risco ' +
                    'ou dano relevante aos titulares, iremos notificar a Autoridade Nacional de Proteção de ' +
                    'Dados (ANPD), Clientes e demais envolvidos necessários.'}
            </SectionText>


            <SectionTitleText>{'12. Atualização'}</SectionTitleText>
            <SectionText>{'Última atualização em 05/10/2023'}</SectionText>

            <Button size='large'
                    fullWidth
                    color={'secondary'}
                    onClick={() => props.onClose(true)}
            >
                Voltar
            </Button>
        </>
    </Modal>
}
export default PrivacyPolicyModal;