import {useState} from "react";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import DropDownOption from "../DropDown/DropDownOption";

interface TypeFilterProps {
    onOk?: (options: DropDownOption[]) => void;
    options: DropDownOption[];
    onSelect?: (selectedOption: DropDownOption) => void;
    label?: string;
}

const TypeValueFilter = (props: TypeFilterProps) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        const options = props.options;
        const value = event.target.value;
        setSelectedValue(value);
        const selectedOption = options.find(option => option.value === value);
        if (selectedOption) {
            props?.onOk && props?.onOk([selectedOption]);
            props?.onSelect && props?.onSelect(selectedOption);
        }
    };

    return (
        <>
            <Select
                value={selectedValue}
                onChange={handleChange}
                displayEmpty
                sx={{ height: 56}}
            >
                {props?.label && <MenuItem value="" disabled>
                    {props?.label}
                </MenuItem>}
                {props.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export default TypeValueFilter;