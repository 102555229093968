import { useState, useEffect, useRef } from 'react';
import MenuGroup from './menuGroup';

export const useItem = (activeItem: string | null, setActiveItem: React.Dispatch<React.SetStateAction<string | null>>, isExpanded: boolean, setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>, item: MenuGroup) => {
  const [topPosition, setTopPosition] = useState(0);
  const [optionListHeight, setOptionListHeight] = useState(0);
  const itemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (itemRef.current) {
      setTopPosition(itemRef.current.offsetTop);
      setOptionListHeight(itemRef.current.offsetHeight);
    }
  }, [activeItem]);

  const toggleOptions = () => {
    if (item.iconName === "chevron-right") {
      setIsExpanded(!isExpanded);
    }
    setActiveItem((prevActiveItem) =>
      prevActiveItem === item.iconName ? "" : String(item.iconName)
    );
  };
  return { topPosition, optionListHeight, itemRef, toggleOptions };
};