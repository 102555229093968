import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";

interface StyledHeaderProps {
    primaryColor: string;
  }
  
  export const StyledHeader = styled.div<StyledHeaderProps>`
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 64px;
    border-bottom: 1px solid ${props => props.primaryColor};
    background: ${Palette.white};
    z-index: 100; 
  `;
export const IconWrapper = styled.div`
  position: absolute;
  right: 50px;
  justify-content: center;
  cursor: pointer;
`;
export const HeaderInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    cursor: pointer;
`;
export const HeaderBolderText = styled.b`
    font-weight: bold;
    margin-left: 5px;    
`;
export const HeaderInfoOptionsButtonContainer = styled.div`
    height: 100px;
    position: absolute;
    margin-top: 64px;
    cursor: pointer;
`;
export const HeaderInfoOptionContainer = styled.div`
    cursor: pointer;
    width: 350px;
    background-color: ${Palette.lightGrey1};
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Palette.lightGrey2};
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    gap: 10px;
    align-items: center;
`;
export const HeaderInfoOptionIconContainer = styled.div`
    width: 25px;
    height: 25px;
`;