import Typography from '../../components/Typography';
import DetailLineProps from './DetailLineProps';
import {DetailLineDiv} from './styles';


const DetailLine = (props: DetailLineProps) => {
    const valueMaxLength = 35;
    return (
        <DetailLineDiv>
            <Typography variant='cardTitle'>{props.label}</Typography>
            <Typography variant='caption2'>{props.value.length > valueMaxLength ? props.value.slice(0, valueMaxLength) + '...' : props.value}</Typography>
        </DetailLineDiv>
    );
};


export default DetailLine;
