import { Grid } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const ReceiptContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>VII - RECIBO</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Desejo receber a cópia do presente Termo de Adesão, da seguinte forma:
        </ContractText>

        <MailingContractCheckbox label=" Recebimento através da impressão do respectivo Documento, que declaro receber neste ato;" />
        <MailingContractCheckbox label=" Recebimento através de e-mail encaminhado para o endereço eletrônico ______________________;" />
        <MailingContractCheckbox label="Recebimento através de mensagem eletrônica (SMS) encaminhada para o número de telefone (__) _______________;" />
      </Grid>
    </>
  );
};

export default ReceiptContractSection;
