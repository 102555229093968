import { useEffect, useState } from "react";
import useOnBoardingInviteService from "../services/useOnBoardingService";
import { EInquiryScoreColor } from "../models/backend/inquiry/EInquiryScoreColor";
import { FormatUtils } from "../utils/format-utils";
import { LineData, PersonalData, ServiceRiskDetails, TimezoneData } from "../models/backend/inquiry/additional-info-phone-inquiry";

export enum TitleMidia {
    Facematch = 'Facematch',
    Documento = 'Documento',
    Video = 'Palavra Chave',
    Deepfake = 'Deepfake',
    Transcription = 'Transcrição de vídeo',
    PhoneDescriptions = 'Descrição do Resultado',
    PhonePersonalData = 'Dados Pessoais',
    PhoneLineData = 'Dados da Linha',
    PhoneTimezoneData = 'Detalhes do Fuso Horário',
}
interface InquiryData {
    title: TitleMidia;
    description: string;
    videoUrl?: string;
    facematchBestFrameUrl?: string;
    documentFrontImageUrl?: string;
    documentBackImageUrl?: string;
    selfieFrontImageUrl?: string;
    extraTitle?: string[];
    extraDescription?: string[];
    iconType?: "success" | "warning" | "error";
    inquiryId: number;
    extraIcons?: { infoIcon: boolean; tooltipText: string }[]; 
}

export const useInquirySpecifics = (id: number) => {
    const [inquiryData, setInquiryData] = useState<InquiryData[]>([]);
    const { getInquiryV2Return } = useOnBoardingInviteService();

    const getIconTypeForScoreColor = (scoreColor: EInquiryScoreColor) => {
        switch (scoreColor) {
            case EInquiryScoreColor.Green:
                return "success";
            case EInquiryScoreColor.Yellow:
                return "warning";
            case EInquiryScoreColor.Red:
                return "error";
            default:
                return "warning";
        }
    };

    useEffect(() => {
        const fetchInquiryAdditionalInfo = async () => {
                const inquiry = await getInquiryV2Return<any>(id);
                const additionalInfo = inquiry?.additionalInfo;
                if (additionalInfo) {
                    const cards = [];
                    if (additionalInfo.keywordScoreResultDescription) {
                        cards.push({
                            title: TitleMidia.Video,
                            description: additionalInfo.keywordScoreResultDescription,
                            iconType: getIconTypeForScoreColor(additionalInfo.keywordScoreColor),
                            inquiryId: id,
                        });
                    }
                    if (additionalInfo.transcriptionScoreResultDescription) {
                        cards.push({
                            title: TitleMidia.Transcription,
                            description: additionalInfo.transcriptionScoreResultDescription,
                            extraTitle: [
                                "Transcrição de vídeo(s)",
                            ],
                            extraDescription: [
                                `${additionalInfo?.audioTranscription || ""}`,
                            ],
                            inquiryId: id,
                        });
                    }
                    if (additionalInfo?.deepFakeScoreResultDescription) {
                        cards.push({
                            title: TitleMidia.Deepfake,
                            description: additionalInfo?.deepFakeScoreResultDescription,
                            videoUrl: additionalInfo.videoUrl,
                            facematchBestFrameUrl: additionalInfo?.facematchBestFrameUrl,
                            iconType: getIconTypeForScoreColor(additionalInfo?.deepFakeScoreColor),
                            inquiryId: id,
                        });
                    }
                    if (additionalInfo?.faceMatchScoreResultDescription) {
                        cards.push({
                            title: TitleMidia.Facematch,
                            description: additionalInfo?.faceMatchScoreResultDescription,
                            iconType: getIconTypeForScoreColor(additionalInfo?.faceMatchScoreColor),
                            inquiryId: id,
                        });
                    }
                    if (additionalInfo?.documentosCopiaScoreResultDescription || additionalInfo?.Ocr) {
                        cards.push({
                            title: TitleMidia.Documento,
                            description: additionalInfo?.documentosCopiaScoreResultDescription,
                            iconType: getIconTypeForScoreColor(additionalInfo?.documentosCopiaScoreColor),
                            documentFrontImageUrl: additionalInfo?.documentFrontImageUrl,
                            documentBackImageUrl: additionalInfo?.documentBackImageUrl,
                            selfieFrontImageUrl: additionalInfo?.SelfieFrontImageUrl, 
                            inquiryId: id,
                            extraTitle: [
                                "Número do Documento",
                                "Nome",
                                "Nome da Mãe",
                                "Nome do Pai",
                                "Data de Nascimento",
                                "Data de Expedição do Documento",
                                "Data de Primeira Emissão",
                                "Data de Expiração",
                                "Categoria",
                                "RG",
                                "CPF",
                                "Estado do Documento"
                            ],
                            extraDescription: [
                                additionalInfo?.Ocr?.DocumentNumber,
                                additionalInfo?.Ocr?.Name,
                                additionalInfo?.Ocr?.MotherName,
                                additionalInfo?.Ocr?.FatherName,
                                FormatUtils.toPTBRDateFormat((additionalInfo?.Ocr?.BirthDate)),
                                FormatUtils.toPTBRDateFormat((additionalInfo?.Ocr?.DocumentExpeditionDate)),
                                FormatUtils.toPTBRDateFormat((additionalInfo?.Ocr?.FirstIssuanceDate)),
                                FormatUtils.toPTBRDateFormat((additionalInfo?.Ocr?.ExpirationDate)),
                                FormatUtils.toPTBRDateFormat((additionalInfo?.Ocr?.IssueDate)),
                                additionalInfo?.Ocr?.Category,
                                additionalInfo?.Ocr?.Rg,
                                additionalInfo?.Ocr?.Cpf,
                                additionalInfo?.Ocr?.DocumentState,
                                additionalInfo?.Ocr?.State,
                                additionalInfo?.Ocr?.Agency,
                                additionalInfo?.Ocr?.Issuer,
                                additionalInfo?.Ocr?.Record,
                            ],
                        });
                    }
                    if (!additionalInfo?.documentosCopiaScoreResultDescription && additionalInfo?.DocumentType) {
                        cards.push({
                            title: TitleMidia.Documento,
                            extraTitle: ['Tipo', 'Número do CPF'],
                            extraDescription: [additionalInfo?.DocumentType, additionalInfo?.Document],
                            iconType: 'success',
                            documentFrontImageUrl: additionalInfo?.FrontDocumentUrl,
                            documentBackImageUrl: additionalInfo?.BackDocumentUrl,
                            selfieFrontImageUrl: additionalInfo?.SelfieFrontImageUrl ?? additionalInfo?.SelfieUrl,
                            inquiryId: id,
                        });
                    }
                    if (additionalInfo.ServiceRiskDetails) {
                        const serviceRiskDetails: ServiceRiskDetails = additionalInfo.ServiceRiskDetails;
                    
                        const splitSentences = (text: string): string[] => {
                            return text.split('.').map(sentence => sentence.trim()).filter(sentence => sentence.length > 0);
                        };
                    
                        const categoryDescriptions = serviceRiskDetails.CategoryDescriptions ? splitSentences(serviceRiskDetails.CategoryDescriptions.join('. ')) : [];
                        const activityDescriptions = serviceRiskDetails.ActivityDescriptions ? splitSentences(serviceRiskDetails.ActivityDescriptions.join('. ')) : [];
                    
                        cards.push({
                            title: TitleMidia.PhoneDescriptions,
                            inquiryId: id,
                            extraTitle: [
                                "Classificação de todas as atividades recentes",
                                "Movimentações entre empresas",
                            ],
                            extraDescription: [
                                categoryDescriptions,
                                activityDescriptions,
                            ],
                            extraIcons: [
                                { infoIcon: true, tooltipText: "Descreve o nível de confiabilidade do número, baseado nas atividades recentes." },
                                { infoIcon: true, tooltipText: "Descreve interações recentes que o número teve com empresas, como inserção em cadastros, verificações por mensagem ou chamada, entre outros. Também menciona possíveis áreas de risco." },
                            ],
                        });
                        if (additionalInfo.LineData) {
                            const lineData: LineData = additionalInfo.LineData;
                            cards.push({
                                title: TitleMidia.PhoneLineData,
                                inquiryId: id,
                                extraTitle: [
                                    "Tipo",
                                    "Status",
                                    "Data de Ativação",
                                    "Tempo de contrato",
                                    "Operadora Original",
                                    "Operadora Atual",
                                    "Tipo de Contrato",
                                ],
                                extraDescription: [
                                    lineData.PhoneType,
                                    lineData.Status,
                                    FormatUtils.toPTBRDateFormat(lineData.ActivationDate),
                                    lineData.ContractDuration,
                                    lineData.Operator,
                                    lineData.OriginalOperator,
                                    lineData.ContractType,
                                ],
                            });
                        }
                        if (additionalInfo.PersonalData) {
                            const personalData: PersonalData = additionalInfo.PersonalData;
                            cards.push({
                                title: TitleMidia.PhonePersonalData,
                                inquiryId: id,
                                extraTitle: [
                                    "Primeiro Nome",
                                    "Último Nome",
                                    "Rua",
                                    "Bairro",
                                    "Código Postal",
                                    "Estado",
                                    "Cidade",
                                    "País",
                                    "Email",
                                ],
                                extraDescription: [
                                    personalData.FirstName,
                                    personalData.LastName,
                                    personalData.Street,
                                    personalData.Neighborhood,
                                    personalData.PostalCode,
                                    personalData.State,
                                    personalData.City,
                                    personalData.Country,
                                    personalData.Email,
                                ],
                            });
                        }
                        if (additionalInfo.TimezoneData) {
                            const timezoneData: TimezoneData = additionalInfo.TimezoneData;
                            cards.push({
                                title: TitleMidia.PhoneTimezoneData,
                                inquiryId: id,
                                extraTitle: [
                                    "Fuso Horário Padrão",
                                    "Descrição do Fuso Horário",
                                    "Máximo do País",
                                    "Mínimo do País"
                                ],
                                extraDescription: [
                                    timezoneData.TimezoneOffset,
                                    timezoneData.TimezoneDescription,
                                    timezoneData.MaxCountry,
                                    timezoneData.MinCountry
                                ],
                            });
                        }
                    }
                    setInquiryData(cards);
                }
        };
        fetchInquiryAdditionalInfo();
    }, [id]);

    return {
        inquiryData,
        getIconTypeForScoreColor,
    };
};