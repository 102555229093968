import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import Typography from "../../../../../components/Typography";
import Input from "../../../../../components/Input/Input";
import { ButtonContainer } from "./contracts/styles";

interface BankDataProps {
    text: string;
    subtext: string;
    bankNameData: string;
    agency: string;
    account: string;
    setBankNameData: (value: string) => void;
    setAgency: (value: string) => void;
    setAccount: (value: string) => void;
    back : () => void;
    next : () => void;
}
const BankData = ({
    text,
    subtext,
    bankNameData,
    agency,
    account,
    setAccount,
    setBankNameData,
    setAgency,
    back,
    next,
}: BankDataProps) => {
    const [isFormValid, setIsFormValid] = React.useState(false);

    useEffect(() => {
        if (bankNameData && agency && account) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [bankNameData, agency, account]);

    return (
        <> 
         <div>
        <Box
            style={{
                maxWidth: "650px",
            }}
            mt={2}
            mb={2}
        >
            <Typography variant="caption3">{text}</Typography>
            <Typography variant="caption">{subtext}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Banco"
                                value={bankNameData}
                                onChange={setBankNameData}
                                required
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Agencia"
                                value={agency}
                                onChange={setAgency}
                                required
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Conta"
                                value={account}
                                onChange={setAccount}
                                required
                                width={"318px"}
                            />
                        </Grid>
                    </Grid>
                    <ButtonContainer>
                        <Button variant="contained" color="inherit" onClick={back}>
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            color={isFormValid ? "primary" : "inherit"}
                            onClick={next}
                            disabled={!isFormValid}
                        >
                            Próximo
                        </Button>
                    </ButtonContainer>
                </Box>
            </div>
        </>
    );
};
export default BankData;
