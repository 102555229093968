import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import React, {useEffect} from "react";
import {Button} from "@mui/material";
import Routes from "../../../constants/Routes";
import {SearchBarContainer} from "../styles";
import Typography from "../../../components/Typography";
import SubSection from "../../../components/SubSection";
import {useUnitService} from "../../../shared/services/useUnitService";
import {UnityUserDto} from "../../../shared/models/requests/unities/unity-user-dto";
import UnityUserCard from "../components/UnityUserCard";
import {ListContainer} from "../../ListUsers/styles";
import {Unity} from "../../../shared/models/unity";

const UnityLinkedUsersScreen = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [users, setUsers] = React.useState<UnityUserDto[]>([]);
    const [unity, setUnity] = React.useState<Unity>();
    const {getUsersFromUnity, getUnityById} = useUnitService();
    const getUnity = async () => setUnity(await getUnityById(Number(id)));
    const getUsers = async () => setUsers(await getUsersFromUnity(Number(id)));
    useEffect(() => {
        getUsers();
        getUnity()
    }, [id]);
    return (<Layout>
        <SubHeader title={"Gerenciar Unidades"} subtitle={"Vincular Usuários"}/>
        <SearchBarContainer>
            <Typography variant="caption">
                {unity?.name && `Use esta tela vincular e excluir usuários na unidade: ${unity?.name}. 
                Para criar novos usuários você deverá acessar a tela de Gerenciar Usuários.`}
            </Typography>
            <Button variant={'contained'}
                    onClick={() => navigate(Routes.Unities.LinkUsers(id))}
            >{'Vincular Usuários'}</Button>
        </SearchBarContainer>
        <SubSection>
            <ListContainer>
                {users.map((user, k) =>
                    <UnityUserCard key={k}
                                   user={user}
                                   unityId={id}
                                   unityName={unity?.name}
                                   onRefresh={() => getUsers()}/>)}
            </ListContainer>
        </SubSection>
    </Layout>)
}
export default UnityLinkedUsersScreen;