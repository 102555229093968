import Layout from "../../../../layout";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import {InstructionsText, ScreenWithSubHeaderContainer} from "../../../../theme/GlobalStyles";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    SentSuccessfullyButtonsContainer,
    SentSuccessfullyButtonsContainerContent,
    SentSuccessfullyTitleText
} from "../../../Inquiries/components/styles";
import {Button} from "@mui/material";
import {useNavigate} from "react-router";
import Routes from "../../../../constants/Routes";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const InquiriesResultExportRequestSentSuccessfully = () => {
    const navigate = useNavigate();
    const theme = useThemeWhiteLabel();
    return (
        <Layout>
            <SubHeader title="Baixar Relatório de Consulta" subtitle="Solicitar Novo Relatório"/>
            <ScreenWithSubHeaderContainer>
                <div id="success-container">
                    <CheckCircleIcon color="success"/>
                    <SentSuccessfullyTitleText primaryColor={theme.primaryColor}>
                        Solicitação enviada com sucesso!
                    </SentSuccessfullyTitleText>
                </div>
                <InstructionsText>
                    {'Tudo certo! Estamos preparando o link para o seu download.'}
                </InstructionsText>
                <InstructionsText>
                    Não se preocupe! Em breve você receberá o link para baixar os arquivos requisitados em <b>seu
                    e-mail</b>
                </InstructionsText>
                <InstructionsText>
                    Após a geração do relatório, você terá <b>24 horas para baixar os arquivos.</b> Após este tempo o
                    acesso será expirado.
                </InstructionsText>
                <InstructionsText>
                    {'Você também poderá visualizar os relatórios gerados pelo botão abaixo.'}
                </InstructionsText>
                <SentSuccessfullyButtonsContainer>
                    <SentSuccessfullyButtonsContainerContent>
                        <Button
                            variant="outlined"
                            size={'large'}
                            color={'primary'}
                            onClick={() => navigate(Routes.Reports.DownloadInquiriesResults)}
                        >
                            {'Visualizar relatórios gerados'}
                        </Button>
                    </SentSuccessfullyButtonsContainerContent>
                </SentSuccessfullyButtonsContainer>
            </ScreenWithSubHeaderContainer>
        </Layout>)
}
export default InquiriesResultExportRequestSentSuccessfully;