import useWhitelabel from "../../../hooks/useWhitelabel";
import {useEffect, useState} from "react";

interface FaqOption {
    question: string;
    answer: string;
}

export const useFaq = () => {
    const [options, setOptions] = useState<FaqOption[]>([]);
    const {whitelabelName} = useWhitelabel();

    useEffect(() => {
        console.log({whitelabelName})
        if (!whitelabelName) setOptions([]);
        setOptions([
            {
                question: `Para quem é o ${whitelabelName}?`,
                answer:
                    `Para empresas (cadastro exclusivo para CNPJ) que desejam aprimorar a segurança de seus processos e reduzir a ocorrência de fraudes por meio de validações de identidade precisas.`
            },
            {
                question: `Como funciona a contratação?`,
                answer:
                    `Contratar o ${whitelabelName} é uma tarefa simples e rápida. Basta acessar nossa plataforma, escolher o plano que melhor atenda às suas necessidades e começar a utilizar imediatamente.`
            },
            {
                question: `Como os créditos são debitados?`,
                answer:
                    `O funcionamento é semelhante a um celular pré-pago: você utiliza os créditos por meio das consultas (transações) e os valores são debitados até atingir o limite de sua assinatura.`
            },
            {
                question: `Qual o valor das tarifas de consultas dos planos?`,
                answer:
                    `As tarifas por consultas variam de acordo com o plano escolhido, seguindo a tabela abaixo:\n\nTipo de Consulta\n\nLIGHT\n\nPLUS\n\nPREMIUM\n\nULTRA\n\nDocumento\n\n-\n\nR$ 6,47\n\nR$ 6,00\n\nR$ 5,30\n\nDocumento + Selfie\n\n-\n\nR$ 9,25\n\nR$ 8,50\n\nR$ 8,00\n\nTelefone\n\nR$ 1,70\n\nR$ 1,50\n\nR$ 1,40\n\nR$ 1,25\n\nE-mail\n\nR$ 1,85\n\nR$ 1,68\n\nR$ 1,55\n\nR$ 1,40\n\n*As consultas por Documento e Documento + Selfie não estão contempladas no plano Light, apenas a partir do plano Plus.`
            },
            {
                question: `E se eu quiser comprar mais créditos?`,
                answer:
                    `Se necessário, você tem a opção de fazer uma recarga de créditos no valor de sua preferência e continuar desfrutando da assinatura. Nesse caso, os valores das consultas seguem as mesmas tarifas aplicadas à sua assinatura e têm validade de 30 dias a partir da data de recarga.`
            },
            {
                question: `Qual a validade dos créditos?`,
                answer:
                    `Nosso modelo de assinatura é recorrente, o que significa que sua assinatura é renovada mensalmente, garantindo a disponibilidade de créditos para os próximos 30 dias. Se você precisar de mais créditos durante o mês, basta solicitar uma contratação adicional diretamente na plataforma e o valor do crédito adicional terá validade até o final do mês subsequente.`
            },
            {
                question: `Quantas consultas posso realizar?`,
                answer:
                    `Você pode realizar quantas consultas os seus créditos permitirem. E se precisar de crédito extra, basta solicitar a contratação adicional dentro da própria plataforma.`
            },
            {
                question: `Posso cancelar a qualquer momento?`,
                answer:
                    `Se desejar cancelar sua assinatura, o processo é simples: acesse o menu de assinaturas diretamente na plataforma ${whitelabelName} e siga as instruções para solicitar o cancelamento. Vale ressaltar que o cancelamento da assinatura não acarreta em reembolso referente à última cobrança. A partir da data da solicitação de cancelamento, as cobranças recorrentes futuras serão interrompidas. No entanto, durante esse período, você ainda poderá desfrutar de todos os benefícios do ${whitelabelName} até que os créditos do mês atual se esgotem ou atinjam sua data de expiração.`
            },
            {
                question: `E se ainda houver saldo no momento do cancelamento?`,
                answer:
                    `Após solicitar o cancelamento da assinatura, você poderá continuar realizando consultas normalmente até o término do mês vigente, que se completa após 30 dias da renovação, ou até que seus créditos se esgotem, o que ocorrer primeiro.`
            },
            {
                question: `Como alterar meu plano?`,
                answer:
                    `Para alterar o seu plano, basta clicar em “Quero alterar meu plano” dentro da própria plataforma.`
            },
            {
                question: `Após alterar o plano, qual a data de renovação da assinatura?`,
                answer:
                    `Uma vez alterado o plano, a data de cobrança passa a ser 30 dias a contar à partir da data de alteração do plano. Ou seja, se a sua recorrência estava agendada para ser cobrada no dia 23/04 e você alterar o plano no dia 30/04, a nova data de cobrança passa a ser dia 30/05.`
            },
            {
                question: `E se ainda houver saldo no momento da alteração do plano?`,
                answer:
                    `Ao realizar a alteração do plano e efetuar o pagamento correspondente ao novo valor contratado, o saldo anterior permanece creditado em sua conta e é estendido por mais 30 dias. Além disso, as consultas realizadas utilizando créditos pré-existentes serão tarifadas de acordo com as mesmas tarifas aplicadas ao novo plano de assinatura selecionado.`
            },
            {
                question: `Como funciona a consulta de Documento?`,
                answer:
                    `A consulta de documento é realizada para avaliar se o documento é verdadeiro ou falso. Hoje nossa plataforma está pronta para avaliar documentos de RG e CNH. Para realizar essa consulta basta inserir o CPF da pessoal consultada e uma imagem do documento (frente e verso). A imagem pode ser tirada na hora ou você pode realizar o upload de um arquivo do seu dispositivo nos formatos PNG, JPEG e PDF. Lembrando que, para a opção de upload de arquivo, você deve enviar uma imagem ou arquivo que contenham a frente e o verso do documento em um único arquivo.`
            },
            {
                question: `Como funciona a consulta de Documento + Selfie?`,
                answer:
                    `A consulta de Documento + Selfie é realizada para avaliar se o documento é verdadeiro ou falso e se a imagem (foto) anexada ao documento é da mesma pessoa. Hoje nossa plataforma está pronta para avaliar documentos de RG e CNH. Para realizar essa consulta basta inserir o CPF, uma imagem do documento (frente e verso) e uma selfie da pessoal consultada. As imagens documento e/ou selfie podem ser tiradas na hora ou você pode realizar o upload de um arquivo do seu dispositivo nos formatos PNG, JPEG e PDF. Lembrando que, para a opção de upload de arquivo, você deve enviar uma imagem ou arquivo que contenham a frente e o verso do documento em um único arquivo.`
            },
            {
                question: `Qual a diferença da consulta de Documento e Documento + Selfie Manual e Digital Hibrida?`,
                answer:
                    `A avaliação das consultas de Documento e Documento + Selfie Manuais são realizadas por um agente (uma pessoa) especializado em avaliação de documentos, que chamamos de mesa investigativa. Já as consultas de Documento e Documento + Selfie Digitais Hibridas passam por um fluxo de avaliação automática em que, somente na ausência de decisão da aplicação automática, enviamos as informações para a avaliação manual da mesa investigativa.`
            },
            {
                question: `Como funciona a consulta de Telefone?`,
                answer:
                    `A consulta de telefone é utilizada para validar se a linha de telefone informada é válida e se ela já foi marcada em alguma lista de risco. Para realizar este tipo de consulta, basta inserir o número de telefone desejado com o DDD.`
            },
            {
                question: `Como funciona a consulta de E-mail?`,
                answer:
                    `A consulta de E-mail é utilizada para validar se o endereço de e-mail informada é válido e se ele já foi marcada em alguma lista de risco. Para realizar este tipo de consulta, basta inserir o endereço de e-mail que deseja consultar.`
            },
            {
                question: `Como funciona o Onboarding por Vídeo?`,
                answer:
                    `O onboarding de vídeo é muito fácil de utilizar. Basta você selecionar a opção se deseja realizar a consulta por WhatsApp ou presencialmente e, em seguida, inserir os dados indicados na tela. Assim que a consulta for enviada a mesma pode acontecer de duas formas (a depender da opção selecionada):\n\nNa opção pelo WhatsApp o seu cliente receberá um link no WhatsApp informado para que dê continuidade ao processo;\n\nNa opção presencial, abriremos uma nova aba no seu navegador para dar continuidade ao processo. Nesta opção é importante que você tenha os pop-Ups desbloqueados. Uma vez que o link é aberto, o seu cliente deverá repetir as informações que aparecem para ele na tela em um vídeo e enviar o documento dele. Dai pra frente vocês podem deixar com a gente que nós realizaremos a validação das informações do vídeo e dos documentos enviados.`
            },
            {
                question: `Qual a diferença da consulta de Onboarding por Vídeo e Onboarding Consignado?`,
                answer:
                    `O fluxo e devolutiva dos dois tipos de consulta são os mesmos. O que difere é que no Onboarding Consignado é possível adicionar mais informações a serem lidas pelo clinte no vídeo de validação, como informações do representante legal, dados bancários, um ou mais contratos e informações adicionais de seu desejo.`
            }
        ])
    }, [whitelabelName]);

    return {
        options
    }
};