import {Button, Grid} from "@mui/material";
import Input from "../../../../components/Input/Input";
import {HorizontalButtonsContainer} from "../../../../theme/GlobalStyles";
import React, {useEffect, useState} from "react";
import {ValidationUtils} from "../../../../utils/validation-utils";
import useCepSearch from "../../../../hooks/useCepSearch";
import {CompleteAddressData} from "../../../../shared/models/complete-address-data";
import {ENewUnityFormStep} from "../../../../shared/models/enums/e-new-unity-form.step";
import {NewUnityScreenFormStepProps} from "../../NewUnity";
import Typography from "../../../../components/Typography";

const UnityAddressForm = ({...props}: NewUnityScreenFormStepProps) => {
    const [searchCompleteAddressByZipCode] = useCepSearch();
    const [address, setAddress] = useState<CompleteAddressData>()
    const onBlurCep = async (value: string) => await searchCompleteAddressByZipCode(value, setAddress);
    useEffect(() => {
        if (!address) return;
        props.setForm({
            ...props.form,
            addressStreet: address?.addressName,
            addressNeighborhood: address?.neighborhood,
            addressCity: address?.city,
            addressState: address?.state,
            addressComplement: address?.addressComplement,
        });
    }, [address])

    const validate = (showErrors = true) =>
        ValidationUtils.validateCep(props.form?.addressZipCode, showErrors)
        && ValidationUtils.validateCity(props.form?.addressCity, showErrors)
        && ValidationUtils.validateState(props.form?.addressState, showErrors)
        && ValidationUtils.validateAddress(props.form?.addressStreet, showErrors)
        && ValidationUtils.validateAddressNumber(props.form?.addressNumber, showErrors)
        && ValidationUtils.validateNeighborhood(props.form?.addressNeighborhood, showErrors)

    if (props.currentStep !== ENewUnityFormStep.Address) return null;

    return (
        <>
            <Typography variant="caption3">{'Endereço'}</Typography>
            <div id="address-form">
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            id="address-cep"
                            label="CEP *"
                            value={props.form?.addressZipCode}
                            onChange={(addressZipCode) => props.setForm({...props.form, addressZipCode})}
                            onBlur={onBlurCep}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Cidade / Município *"
                            value={props.form?.addressCity}
                            onChange={(addressCity) => props.setForm({...props.form, addressCity})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Estado *"
                            value={props.form?.addressState}
                            onChange={(addressState) => props.setForm({...props.form, addressState})}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Endereço *"
                            value={props.form?.addressStreet}
                            onChange={(addressStreet) => props.setForm({...props.form, addressStreet})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Número *"
                            value={props.form?.addressNumber}
                            onChange={(addressNumber) => props.setForm({...props.form, addressNumber})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Complemento"
                            value={props.form?.addressComplement}
                            onChange={(addressComplement) => props.setForm({...props.form, addressComplement})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Bairro *"
                            value={props.form?.addressNeighborhood}
                            onChange={(addressNeighborhood) => props.setForm({...props.form, addressNeighborhood})}
                        />
                    </Grid>
                </Grid>
            </div>
            <HorizontalButtonsContainer>
                <Button
                    onClick={props.onBack}
                    variant={'text'}>{'Voltar'}</Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => validate() && props.onNext()}>{'Próximo'}</Button>
            </HorizontalButtonsContainer>
        </>
    );
}

export default UnityAddressForm;