import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";


export const SearchBarContainer = styled.div`
    padding: 20px 60px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    max-width: 1128px;
`;

export const FormContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    max-width: 648px;
`;

export const StyledCard = styled.div`
    width: 360px;
    min-height: 168px;
    padding: 10px;
    border-radius: 8px;
    background-color: ${Palette.white};
    box-shadow: 0 4px 20px 0 #0000000f;
`;
export const CardTitleLineDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    height: 1.5rem;
    max-width: 350px;
    margin-bottom: 10px;
    justify-content: space-between;
`;
export const ModalContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    align-self: start;
    flex-direction: column;
    width: 100%;
`;

export const HorizontalButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    padding: 1rem;
`;
export const LinkUserOptionsContainer = styled.div`
    background-color: ${Palette.white};
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1128px;
    margin-top: 40px;
    position: absolute;
    overflow: auto;
    overflow-y: scroll;
    z-index: 100;
`;
export const LinkUserOption = styled.div`
    background-color: ${Palette.white};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    justify-content: start;
    cursor: pointer;
    width: 100%;
    z-index: 100;
`;
export const LinkUserOptionText = styled.span`
    text-align: start;
    font-size: 1rem;
    font-weight: 500;
    color: ${Palette.black};
    cursor: pointer;
    width: 100%;
    margin: 5px;
`;
