import React, {useState} from "react";
import {toast} from "react-toastify";
import PrivacyPolicyModal from "../../../CreateAccount/components/PrivacyPolicyModal";
import ServiceTermsModal from "../../../CreateAccount/components/ServiceTermsModal";
import {Button, Grid} from "@mui/material";
import Input from "../../../../components/Input/Input";
import {TermsModalLink, TermsModalLinkText} from "../../../CreateAccount/components/PrivacyPolicyModal/styles";
import {Palette} from "../../../../shared/theme/palette";
import CheckBox from "../../../../components/CheckBox";
import {HorizontalButtonsContainer} from "../../../../theme/GlobalStyles";
import {ValidationUtils} from "../../../../utils/validation-utils";
import {ENewUnityFormStep} from "../../../../shared/models/enums/e-new-unity-form.step";
import {NewUnityScreenFormStepProps} from "../../NewUnity";
import Typography from "../../../../components/Typography";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";


const UnityRegistrationDataForm = ({...props}: NewUnityScreenFormStepProps) => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [termsRead, setTermsRead] = useState(false);
    const [policyRead, setPolicyRead] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const {whitelabelName} = useWhitelabel();
    function acceptTerms() {
        if (!termsRead) return toast.error('Leia os termos para continuar.');
        if (!policyRead) return toast.error('Leia a política de privacidade para continuar.');
        setTermsAccepted(!termsAccepted)
        setPrivacyPolicyAccepted(!privacyPolicyAccepted);
    }
    const theme = useThemeWhiteLabel();
    const getLinkColor = (read: boolean) => read ? Palette.purple : theme.primaryColor;

    const validate = (showErrors = true) => {
        return ValidationUtils.validateBussinessName(props.form?.name, showErrors)
            && ValidationUtils.validateCnpj(props.form?.cnpj, showErrors)
            && ValidationUtils.validateEmail(props.form?.email, showErrors)
            && ValidationUtils.validatePhone(props.form?.phone, showErrors)
            && termsAccepted
            && privacyPolicyAccepted
    }
    if (props.currentStep !== ENewUnityFormStep.RegistrationData) return null;
    return (
        <>
            {showPrivacyPolicy &&
                <PrivacyPolicyModal onClose={(read) => {
                    setPolicyRead(read)
                    setShowPrivacyPolicy(false)
                }}></PrivacyPolicyModal>}
            {showTerms &&
                <ServiceTermsModal onClose={(read) => {
                    setTermsRead(read)
                    setShowTerms(false)
                }}></ServiceTermsModal>}
            <Typography variant="caption2">{'Digite os dados da nova Unidade. Ao final do processo o usuário receberá um e-mail com as instruções de acesso.'}</Typography>
            <Typography variant="caption3">{'Dados cadastrais'}</Typography>
            <div id="personal-data-form">
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Razão Social *"
                            value={props.form?.name}
                            onChange={(name) => props.setForm({...props.form, name})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="CNPJ *"
                            value={props.form?.cnpj}
                            onChange={(cnpj) => props.setForm({...props.form, cnpj})}
                            mask="99.999.999/9999-99"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="E-mail *"
                            value={props.form?.email}
                            onChange={(email) => props.setForm({...props.form, email})}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Número de telefone *"
                            value={props.form?.phone}
                            onChange={(phone) => props.setForm({...props.form, phone})}
                            mask="(99) 99999-9999"
                        />
                    </Grid>
                </Grid>
            </div>
            <div>
                <TermsModalLinkText>{'Clique aqui ler os '}</TermsModalLinkText>
                <TermsModalLink onClick={() => setShowTerms(true)}
                                color={getLinkColor(termsRead)}>
                    {'termos de uso '}</TermsModalLink>
                <TermsModalLinkText>{'e '}</TermsModalLinkText>
                <TermsModalLink onClick={() => setShowPrivacyPolicy(true)}
                                color={getLinkColor(policyRead)}>
                    {'política de privacidade '}</TermsModalLink>
                <TermsModalLinkText>{`do ${whitelabelName}.`}</TermsModalLinkText>
            </div>
              <CheckBox
                                    primaryColor={theme.primaryColor}
                isChecked={termsAccepted}
                onPress={acceptTerms}
                id="cb-terms-conditions"
                label={`Eu aceito os termos de uso e a política de privacidade do ${whitelabelName}`}
            />
            <HorizontalButtonsContainer>
                <Button
                    onClick={props.onBack}
                    variant={'text'}>{'Voltar'}</Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => validate() && props.onNext()}>{'Próximo'}</Button>
            </HorizontalButtonsContainer>
        </>
    );
}

export default UnityRegistrationDataForm;