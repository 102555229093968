import ConsultaiApiContext from "../consultai-api-context";
import {useContext, useEffect, useState} from "react";
import {ApiContextProviderProps} from "./api-context-provider-props";

export const ConsultaiApiContextProvider = ({
                                                children,
                                                baseUrl,
                                                env,
                                                enableLogs = false,
                                                onError,
                                                onWarn,
                                                onInfo,
                                                onLogout,
                                            }: ApiContextProviderProps) => {
    const context = useContext(ConsultaiApiContext);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    useEffect(() => {
        // enableLogs && console.log('accessTokenChanged:', accessToken?.slice(-3))
    }, [accessToken]);
    useEffect(() => {
        // enableLogs && console.log('refreshTokenChanged:', refreshToken?.slice(-3))
    }, [refreshToken]);
    const initialValue = {
        ...context,
        baseUrl,
        env,
        enableLogs,
        onError,
        onWarn,
        onInfo,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        onLogout
    }
    return (
        <ConsultaiApiContext.Provider value={initialValue}>
            {children}
        </ConsultaiApiContext.Provider>
    );
}