import {
    MoneyCardsContainer,
    MoneyDescriptionCard,
    MoneyDescriptionCardTitleText,
    MoneyDescriptionCardValueText
} from "../styles";
import useUserService from "../../../../shared/services/useUserService";
import {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../../../shared/context/AccountContext";
import useMoney from "../../../../shared/hooks/useMoney";
import {Constants} from "../../../../constants/Constants";

interface InquiryPriceAndBalanceCardsProps {
    inquiryPrice?: number;
}

const InquiryPriceAndBalanceCards = ({...props}: InquiryPriceAndBalanceCardsProps) => {
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(0);
    const {ids, subscription} = useContext(AccountContext);
    const {buscarSaldo} = useUserService();
    const money = useMoney();
    useEffect(() => {
        setLoading(true)
        buscarSaldo(ids.unitId).then((response) => setBalance(response?.saldo))
            .finally(() => setLoading(false))
    }, []);
    return (
        <>
            {!loading &&
                <MoneyCardsContainer>
                    <MoneyDescriptionCard>
                        <MoneyDescriptionCardTitleText>
                            {'Valor da Consulta'}
                        </MoneyDescriptionCardTitleText>
                        <MoneyDescriptionCardValueText>
                            {props?.inquiryPrice ? money.toString(props?.inquiryPrice ?? 0) : '---'}
                        </MoneyDescriptionCardValueText>
                    </MoneyDescriptionCard>
                    {subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                        <MoneyDescriptionCard>
                            <MoneyDescriptionCardTitleText>
                                {'Saldo'}
                            </MoneyDescriptionCardTitleText>
                            <MoneyDescriptionCardValueText>
                                {money.toString(balance)}
                            </MoneyDescriptionCardValueText>
                        </MoneyDescriptionCard>}
                </MoneyCardsContainer>}
        </>
    )
}
export default InquiryPriceAndBalanceCards;