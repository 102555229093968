import { Box, Button } from "@mui/material";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import PropTypes from "prop-types";

interface ModalChangeDataProps {
  onClose: () => void;
  sendData: () => void;
}
const ModalChangeData = ({ onClose, sendData }: ModalChangeDataProps) => {
  return (
    <Modal title="Solicitar alteração de dados" onClose={onClose}>
      <>
        <Typography variant="caption">
          Antes de alterar os dados, a equipe da Who deverá avaliar as novas
          informações e aprovar as mudanças.
        </Typography>
        <Typography variant="caption">
          Após a aprovação, as informações serão atualizadas automaticamente.
          Você receberá a confirmação das alterações em até 48 horas no e-mail
          cadastrado.
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap={3}>
          <Button size="medium" onClick={onClose}>
             Voltar
          </Button>
          <Button size="medium" variant={"contained"} onClick={sendData} >
          Confirmar solicitação 
          </Button>
        </Box>
      </>
    </Modal>
  );
};
ModalChangeData.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendData: PropTypes.func.isRequired,
};

export default ModalChangeData;
