import { Box, Button, Grid } from "@mui/material";
import Typography from "../../../../../components/Typography";
import Input from "../../../../../components/Input/Input";
import { ButtonContainer } from "./contracts/styles";
import Modal from "../../../../../components/Modal";
import CheckBox from "../../../../../components/CheckBox";
import { useThemeWhiteLabel } from "../../../../../hooks/useThemeWhiteLabel";

interface ModalEditcomplementarMessageProps {
    onClose: () => void;
    complementText: string;
    replaceCurrentUnityComplementText: boolean;
    setComplementText: (value: string) => void;
    setReplaceCurrentUnityComplementText: (value: boolean) => void;
}

const ModalEditComplementarMessage = ({
    complementText,
    replaceCurrentUnityComplementText,
    onClose,
    setComplementText,
    setReplaceCurrentUnityComplementText,
}: ModalEditcomplementarMessageProps) => {
    const theme = useThemeWhiteLabel();
    return (
        <Modal onClose={onClose} title="Texto complementar.">
            <Box>
                <Typography variant="caption">
                    Preencha o campo abaixo para incluir um vídeo complementar no final do
                    processo de onboarding. O texto inserido deverá ser lido na íntegra.
                    (Máximo de 200 Caracteres)
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label="digite..."
                            value={complementText}
                            onChange={setComplementText}
                            width={"580px"}
                            maxLength={200}
                        />
                    </Grid>
                </Grid>
                  <CheckBox
                                    primaryColor={theme.primaryColor}
                    label={"Usar este texto em todos os processos de Onboarding de Vídeo."}
                    onPress={() => setReplaceCurrentUnityComplementText(!replaceCurrentUnityComplementText)}
                    isChecked={replaceCurrentUnityComplementText}
                    square
                />
                <ButtonContainer>
                    <Button variant="contained" color="inherit" onClick={onClose}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={onClose}>
                        Salvar
                    </Button>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};

export default ModalEditComplementarMessage;
