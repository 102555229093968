export enum EExportFileJobStatus {
    Pending = 1,
    Processing = 2,
    Completed = 3,
    Error = 4
}
export const getDescription = (status: EExportFileJobStatus) : string=> {
    switch (status) {
        case EExportFileJobStatus.Pending:
            return 'Pendente';
        case EExportFileJobStatus.Processing:
            return 'Processando';
        case EExportFileJobStatus.Completed:
            return 'Concluído';
        case EExportFileJobStatus.Error:
            return 'Erro';
        default:
            return '';
    }
}