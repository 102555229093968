import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import Typography from "../../../../../components/Typography";
import { ValidationUtils } from "../../../../../utils/validation-utils";
import CheckBox from "../../../../../components/CheckBox";
import Input from "../../../../../components/Input/Input";
import { useThemeWhiteLabel } from "../../../../../hooks/useThemeWhiteLabel";

interface FormPayrollLoanOnboardingProps {
    onNext: () => void;
    inquiryPrice: number;
    name: string;
    setName: (value: string) => void;
    cpf: string;
    setCPF: (value: string) => void;
    phone: string;
    setPhone: (value: string) => void;
    birthDate: string;
    setbirthDate: (value: string) => void;
    setIlliterate: (value: boolean) => void;
    setlegalRepresentative: (value: boolean) => void;
    setRecipient: (value: boolean) => void;
    illiterate: boolean;
    legalRepresentative: boolean;
    recipient: boolean;
}

const FormPayrollLoanOnboarding = ({
    onNext,
    inquiryPrice,
    name,
    setName,
    cpf,
    setCPF,
    phone,
    setPhone,
    birthDate,
    setbirthDate,
    setIlliterate,
    setlegalRepresentative,
    setRecipient,
    illiterate,
    legalRepresentative,
    recipient,
}: FormPayrollLoanOnboardingProps) => {
    const [buttonEnabled, setButtonEnabled] = useState(false);

    const theme = useThemeWhiteLabel();
    useEffect(() => {
        if (name && cpf && phone && birthDate) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
    }, [name, cpf, phone, birthDate]);
    return (
        <div>
            <Box style={{ maxWidth: "650px" }}>
                <Typography variant="caption3">Dados do beneficiário</Typography>
                <Typography variant="caption">
                    Preencha os campos abaixo, essas informações serão utilizadas no
                    texto-guia que deverá ser lido durante a gravação do vídeo de
                    onboarding.
                </Typography>
            </Box>
            <Box mt={2} mb={2}>
                <div
                    style={{
                        maxWidth: "650px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Nome Completo do beneficiário*"
                                value={name}
                                onChange={setName}
                                required
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="CPF"
                                value={cpf}
                                onChange={setCPF}
                                required
                                validationCallback={ValidationUtils.validateCpf}
                                mask="999.999.999-99"
                                label={"CPF"}
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="WhatsApp do beneficiário"
                                onChange={setPhone}
                                value={phone}
                                required
                                validationCallback={ValidationUtils.validatePhone}
                                mask="(99) 99999-9999"
                                label={"WhatsApp"}
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Data de nascimento do beneficiário"
                                value={birthDate}
                                onChange={setbirthDate}
                                required
                                width={"318px"}
                                mask="99/99/9999"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <Box mt={4} mb={4}>
                <Box mb={2}>
                    <CheckBox
                        primaryColor={theme.primaryColor}
                        label={"Este beneficiário possui representante legal."}
                        onPress={() => setlegalRepresentative(!legalRepresentative)}
                        isChecked={legalRepresentative}
                        square
                    />
                </Box>
                <Box mb={2}>
                    <CheckBox
                        primaryColor={theme.primaryColor}
                        label={"Incluir Dados Bancários"}
                        onPress={() => setRecipient(!recipient)}
                        isChecked={recipient}
                        square
                    />
                </Box>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={onNext}
                disabled={!buttonEnabled}
            >
                Próximo
            </Button>
        </div>
    );
};
FormPayrollLoanOnboarding.propTypes = {
    onNext: PropTypes.func.isRequired,
    setPresential: PropTypes.func.isRequired,
    inquiryPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    setName: PropTypes.func.isRequired,
    cpf: PropTypes.string.isRequired,
    setCPF: PropTypes.func.isRequired,
    phone: PropTypes.string.isRequired,
    setPhone: PropTypes.func.isRequired,
    birthDate: PropTypes.string.isRequired,
    setbirthDate: PropTypes.func.isRequired,
    setIlliterate: PropTypes.func.isRequired,
    setlegalRepresentative: PropTypes.func.isRequired,
    setRecipient: PropTypes.func.isRequired,
};

export default FormPayrollLoanOnboarding;
