import React, {createContext, useReducer} from 'react';
import {AddressType} from '../models/address';
import {PlanType} from '../models/plan';
import {AccountType} from '../models/account';
import {IdsType} from '../models/ids';
import {accountReducer} from '../reducers/account-reducer';
import {Subscription} from '../models/subscription';
import {SessionUser} from "../models/session-user";

export type AccountContextType = {
    ids: IdsType;
    account: AccountType;
    address: AddressType;
    user: Partial<SessionUser>;
    plan: PlanType;
    subscription: Subscription;
    setAccount: (conta: AccountType) => void;
    setAddress: (endereco: AddressType) => void;
    setUser: (user: Partial<SessionUser>) => void;
    setPlan: (plano: PlanType) => void;
    setSubscription: (subscription: Subscription) => void;
    setIds: (ids: IdsType) => void;
};
const AccountContextInitialState: AccountContextType = {
    ids: {} as IdsType,
    account: {} as AccountType,
    address: {} as AddressType,
    user: {} as Partial<SessionUser>,
    plan: {} as PlanType,
    subscription: {} as Subscription,
    setIds: () => {
    },
    setAccount: () => {
    },
    setAddress: () => {
    },
    setUser: () => {
    },
    setPlan: () => {
    },
    setSubscription: () => {
    },
};

export const AccountContext = createContext<AccountContextType>({...AccountContextInitialState,});
type AccountContextProviderProps = { children: React.ReactNode };

export function AccountContextProvider({children}: AccountContextProviderProps) {
    const [state, dispatch] = useReducer(accountReducer, AccountContextInitialState);
    return (
        <AccountContext.Provider
            value={{
                ids: state.ids,
                account: state.account,
                address: state.address,
                user: state.user,
                plan: state.plan,
                subscription: state.subscription,
                setAccount: (account) => dispatch({type: 'SET_ACCOUNT', payload: account}),
                setAddress: (address) => dispatch({type: 'SET_ADDRESS', payload: address}),
                setUser: (user) => dispatch({type: 'SET_USER', payload: user}),
                setPlan: (plan) => dispatch({type: 'SET_PLAN', payload: plan}),
                setSubscription: (subscription) => dispatch({type: 'SET_SUBSCRIPTION', payload: subscription}),
                setIds: (ids) => dispatch({type: 'SET_IDS', payload: ids}),
            }}
        >
            {children}
        </AccountContext.Provider>
    );
}
