import {NewUnityScreenFormStepProps} from "../../NewUnity";
import {ENewUnityFormStep} from "../../../../shared/models/enums/e-new-unity-form.step";
import {Button, Grid} from "@mui/material";
import Input from "../../../../components/Input/Input";
import Typography from "../../../../components/Typography";
import {HorizontalButtonsContainer} from "../../../../theme/GlobalStyles";
import React from "react";
import {ValidationUtils} from "../../../../utils/validation-utils";

const UnityPasswordForm = ({...props}: NewUnityScreenFormStepProps) => {
    const validate = (showErrors = true) =>
        ValidationUtils.validatePassword(props.form?.password, showErrors, true)
        && ValidationUtils.validatePasswordConfirmation(props.form?.password, props.form?.passwordConfirmation, showErrors)
    if (props.currentStep !== ENewUnityFormStep.Password) return
    return (
        <>
            <Typography variant="caption3">{'Senha'}</Typography>
            <Typography
                variant="caption2">{'A senha deve conter no mínimo 10 dígitos, uma letra maiúscula e um caractere especial.'}</Typography>
            <div id="password-form">
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Senha *"
                            type="password"
                            value={props.form?.password}
                            onChange={(password) => props.setForm({...props.form, password})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            fullWidth
                            label="Confirmação de senha *"
                            type="password"
                            value={props.form?.passwordConfirmation}
                            onChange={(passwordConfirmation) => props.setForm({...props.form, passwordConfirmation})}
                        />
                    </Grid>
                </Grid>
                <HorizontalButtonsContainer>
                    <Button
                        onClick={props.onBack}
                        variant={'text'}>{'Voltar'}</Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => validate() && props.onNext()}>{'Próximo'}</Button>
                </HorizontalButtonsContainer>
            </div>
        </>
    );
}

export default UnityPasswordForm;