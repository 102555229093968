import { PhotoCamera } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import styled from "styled-components";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import { InterTextTypography } from "../styles";

type TakePhotoStepperProps = {
  progress: number;
  maxWidth?: number;
};

export const TakePhotoStepperContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const TakePhotoStepItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TakePhotoStepItem = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const TakePhotoStepperProgress = styled(LinearProgress)`
  && {
    position: absolute;
    width: calc(100% - 64px);
    left: 32px;
    top: calc(50% - 12px);
    z-index: 9;
    background-color: #d9d9d9;
  }
`;

export const TakePhotoStepper = ({
  progress,
  maxWidth,
}: TakePhotoStepperProps) => {
  const theme = useThemeWhiteLabel();

  return (
    <TakePhotoStepperContainer style={{ maxWidth }}>
      <TakePhotoStepperProgress variant="determinate" value={progress} />
      <TakePhotoStepItemContainer>
        <TakePhotoStepItem style={{ backgroundColor: theme.primaryColor }}>
          <PhotoCamera sx={{ color: "white" }} />
        </TakePhotoStepItem>
        <InterTextTypography
          color={theme.primaryColor}
          style={{ fontWeight: 600 }}
        >
          1- Foto Frente
        </InterTextTypography>
      </TakePhotoStepItemContainer>

      <TakePhotoStepItemContainer>
        <TakePhotoStepItem
          style={{
            backgroundColor: progress === 100 ? theme.primaryColor : "#CFCFCF",
          }}
        >
          <PhotoCamera sx={{ color: "white" }} />
        </TakePhotoStepItem>
        <InterTextTypography
          color={progress === 100 ? theme.primaryColor : "#CFCFCF"}
          style={{ fontWeight: progress === 100 ? 600 : 400 }}
        >
          2- Foto Verso
        </InterTextTypography>
      </TakePhotoStepItemContainer>
    </TakePhotoStepperContainer>
  );
};
