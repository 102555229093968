import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import {MenuOption} from '../Section/menuOption';

export const MATERIAL_ICONS: { [key: string]: React.ElementType } = {
    "chevron-right": ChevronRightIcon,
    "search": SearchIcon,
    "chart": BarChartIcon,
    "settings": SettingsIcon,
    "user": AccountCircleIcon,
    "logout": LogoutIcon,
};

export type MaterialIconName = keyof typeof MATERIAL_ICONS;

export type MenuGroup = {
    iconName: MaterialIconName;
    iconColor?: string;
    backgroundColor?: string;
    options?: MenuOption[];
    label: string;
};

export default MenuGroup;