import {PagamentoRecargaRequest} from "../models/requests/pagarme-recharge-credit-request";
import {CloseRechargeResponse, PaymentRechargeResponse} from "../models/responses/pagarme-recharge-response";
import axiosRetry from "axios-retry";
import axios from "axios";
import {CreateCardRequest, EditCardRequest} from "../models/requests/subscription-request";
import {CreateCardResponse} from "../models/responses/cards-response";
import {CreateCardData} from "../models/create-card-data";
import usePagarMeApi from "./context/hooks/usePagarMeApi";

export const usePagarMeService = () => {

    const pagarMeApi = usePagarMeApi();
    const postPaymentRecharge = async (cardPagarMeId: string, orderPagarMeId: string, value: number) => {
        const request: PagamentoRecargaRequest = {
            payment: {
                credit_card: {
                    installments: 1,
                    card_id: cardPagarMeId,
                },
                payment_method: 'credit_card',
            },
            order_id: orderPagarMeId,
            amount: value * 100,
        }
        return (await pagarMeApi.post<PaymentRechargeResponse>('charges', 5, request));
    }
    const postclosePaymentRecharge = (order_id: string, status: string) => {
        axiosRetry(pagarMeApi.instance, {
            retries: 3,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: (error) => {
                return axios.isAxiosError(error) && error.response?.status !== 400;
            },
        });
        return pagarMeApi.patch<CloseRechargeResponse>(`orders/${order_id}/closed`, 5, {status});
    }
    const postCriarCartao = async (customerId: string, card: CreateCardData, address) => {
        const request: CreateCardRequest = {
            number: card.cardNumber?.replaceAll(' ', ''),
            holder_name: card.cardHolderName,
            holder_document: card.cardHolderDocument
                ?.replaceAll('.', '')
                ?.replaceAll('/', '')
                .replaceAll('-', ''),
            exp_month: card.cardExpirationMonth,
            exp_year: card.cardExpirationYear,
            cvv: card.cardCvv,
            billing_address: {
                line_1: address.addressName,
                line_2: address.neighborhood,
                zip_code: address.zipCode?.replaceAll('-', ''),
                city: address.city,
                state: address.state,
                country: 'BR',
            },
        }
        try{

            return (await pagarMeApi.post<CreateCardResponse>(`customers/${customerId}/cards`, 5, request
            ));
        }
        catch (e) {
            console.log(e)
        }
    }

    const patchEditarCartao = (subscription_id: string, request: EditCardRequest) =>
        pagarMeApi.patch(`subscriptions/${subscription_id}/card`, 5, request
        )
    return {
        pagarMeCardsService: {
            postCriarCartao,
            patchEditarCartao
        },
        pagarMeRechargeService: {
            postPaymentRecharge,
            postclosePaymentRecharge
        }
    }
}