export const CARD_IMAGES: { [key: string]: any } = {
    Visa: require('../shared/assets/cards/visa.png'),
    Mastercard: require('../shared/assets/cards/mastercard.jpg'),
    Discover: require('../shared/assets/cards/discover.png'),
    American: require('../shared/assets/cards/american.png'),
    Diners: require('../shared/assets/cards/diners.jpg'),
    Elo: require('../shared/assets/cards/elo.png'),
    Hipercard: require('../shared/assets/cards/hipercard.png'),
    JCB: require('../shared/assets/cards/jcb.png'),
    Aura: require('../shared/assets/cards/aura.png'),
};