import React, {useState} from "react";
import {Container} from "./ListInquiryListStyles";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import InquiryCardsByStatus from "../InquiryCardsByStatus";
import WarningIcon from '@mui/icons-material/Warning';
import Input from "../../../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";

const ListInquiryList = () => {
    const [currentTab, setCurrentTab] = useState<'all' | 'waiting' | 'error'>('all');
    const [filter, setFilter] = useState<string>();
    return (
        <Container>
            <Input startAdornment={<SearchIcon/>}
                   placeholder={'Busca'}
                   width={'40%'}
                   value={filter}
                   onChange={(search) => setFilter(search)}
            />
            <Box sx={{width: '100%'}}>
                <TabContext value={currentTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(event, value) => setCurrentTab(value)}>
                            <Tab label="RESULTADO DAS CONSULTAS" value="all"/>
                            <Tab label="AGUARDANDO RESPOSTA" value="waiting"/>
                            <Tab label="CONSULTAS COM ERRO" value="error" icon={<WarningIcon/>} iconPosition="end"/>
                        </TabList>
                    </Box>
                    <TabPanel value="all">
                        <InquiryCardsByStatus filterText={filter} status={'all'}></InquiryCardsByStatus>
                    </TabPanel>
                    <TabPanel value="waiting">
                        <InquiryCardsByStatus filterText={filter} status={'waiting'}></InquiryCardsByStatus>
                    </TabPanel>
                    <TabPanel value="error">
                        <InquiryCardsByStatus filterText={filter} status={'error'}></InquiryCardsByStatus>
                    </TabPanel>
                </TabContext>
            </Box>
        </Container>
    );
};

export default ListInquiryList;
