const Routes = {
  Root: "/",
  Login: "/login",
  CreateAccount: "/create-account",
  RegisterCompleted: "/register-completed",
  ResetPassword: "/reset-password",
  HomeNavigator: "/",
  Home: "/home",
  Faqs: "/faqs",
  SettingsPlans: "/settings/plans",
  Settings: "/settings",
  OtherPlans: "/settings/plans/other-plans",
  PlanConfirmation: (id: number | string) =>
    `/settings/plans/${id}/confirmation`,
  Recharge: "/recharge",
  Inquiries: {
    Document: "/inquiries/document",
    DocumentWithSelfie: "/inquiries/document-with-selfie",
    DocumentAutomatic: "/inquiries/document-automatic",
    DocumentWithSelfieAutomatic: "/inquiries/document-with-selfie-automatic",
    Phone: "/inquiries/phone",
    Email: "/inquiries/email",
    VideoOnboardingNoDoc: "/inquiries/video-onboarding",
    VideoOnboardingDoc: "/inquiries/video-onboarding/doc",
    PayrollLoanVideoOnboarding: "/inquiries/video-onboarding-payroll-loan",
    Details: (inquiryType: string, id: number | string) =>
      `/inquiries/${inquiryType}/${id}/details`,
    List: "/inquiries/list",
  },
  Account: {
    Profile: "/account/profile",
    ChangePassword: "/account/change-password",
  },
  Reports: {
    Financial: "/reports/financial",
    Performance: "/reports/performance",
    DownloadInquiriesResults: "/reports/download",
    DownloadInquiriesResultsSent: "/reports/download/sent",
  },
  OnBoarding: {
    Video: (id: string) => `/onboarding/invite/${id}`,
  },
  PayRollOnboarding: {
    Video: (id: string) => `/onboarding/payroll-loan/${id}`,
  },
  User: {
    New: "/user/new",
    List: "/user/list",
  },
  Unities: {
    List: "/unities",
    New: "/unities/new",
    LinkedUsers: (id: string) => `/unities/${id}/users`,
    LinkUsers: (id: string) => `/unities/${id}/users/add`,
  },
  MailingOnboarding: {
    Root: (id: string) => `/mailing/${id}`,
  },
};

export default Routes;
