import { Grid } from "@mui/material";
import { MailingContractDataProps } from "..";
import { ContractSectionTitle, ContractText } from "../styles";

const CreditCardTermsContractSection = ({
  userData,
}: MailingContractDataProps) => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          3 - TERMOS E CONDIÇÕES DO CARTÃO DE CRÉDITO{" "}
          {userData?.cardAcquired ? "" : "- NÃO SE APLICA"}
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          {
            "1) Para o cartão de crédito, o limite será de acordo com o perfil do TITULAR DO CARTÃO e critério exclusivo do EMITENTE ADMINISTRADOR. Caso o limite aprovado seja por mim utilizado, reconheço a exigibilidade dos débitos em Conta Corrente e/ou Cartão Magnético eventualmente efetuados, inclusive das tarifas informadas no contrato e na fatura, obrigando-me a pagar ao EMITENTE E ADMINISTRADOR, conforme as condições previstas. Em caso de realização de compras parceladas, os pagamentos terão seus vencimentos nas datas apontadas na fatura. Estou ciente de que, em caso de inadiplemento, serão cobrados pelo EMITENTE E ADMINISTRADOR os encargos previstos no contrato."
          }
        </ContractText>
        <ContractText>
          {
            "2) FATURA: a fatura mensal estará disponível pelo aplicativo e pelo internet banking, de modo que não será enviada fatura impressa para o endereço cadastrado."
          }
        </ContractText>
        <ContractText>
          {
            "3) INADIMPLEMENTO: no atraso do TITULAR DO CARTÃO, todas as obrigações poderão ser consideradas vencidas antecipadamente e exigido o saldo devedor total e os encargos, mediante prévia comunicação do EMITENTE E ADMINISTRADOR."
          }
        </ContractText>
        <ContractText>
          {
            "4) AUTORIZAÇÃO PARA DESCONTO EM CONTA(S)-CORRENTE(S) E/OU CARTÃO(ÕES) MAGNÉTICO(S): o TITULAR DO CARTÃO autoriza o EMITENTE E ADMINISTRADOR a efetuar o débito de eventual saldo devedor (valor total e/ou do valor mínimo e/ou parcelamento da fatura na(s) Conta(s) Corrente(s) e/ou Cartão(ões) Magnético(s) de sua titularidade aqui informadas e/ou demais Contas Correntes e/ou Cartões Magnéticos registrados em outras operações realizadas junto às instituições pertencentes ao conglomerado prudencial do EMITENTE E ADMINISTRADOR. Caso não seja possível o débito do valor devido na(s) Conta(s) Correntes(s) e/ou Cartão(ões) Magnético(s) do TITULAR DO CARTÃO, o desconto poderá ser feito parceladamente na(s) Conta(s) Corrente(s) e/ou Cartão(ões) Magnético(s) do TITULAR DO CARTÃO, a qualquer tempo, de acordo com o saldo existente, até que seja atingido o valor total devido, somados os encargos e multas previstos no CONTRATO DE CARTÃO DE CRÉDITO – PESSOA FÍSICA e nas faturas."
          }
        </ContractText>
      </Grid>
    </>
  );
};

export default CreditCardTermsContractSection;
