import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import Typography from "../../../../../components/Typography";
import Input from "../../../../../components/Input/Input";
import { ButtonContainer } from "./contracts/styles";
import { ValidationUtils } from "../../../../../utils/validation-utils";

interface RepresentantFormProps {
    nameRepresentant: string;
    setNameRepresentant: (value: string) => void;
    cpfRepresentant: string;
    setCPFRepresentant: (value: string) => void;
    phoneRepresentant: string;
    setPhoneRepresentant: (value: string) => void;
    birthDateRepresentant: string;
    setbirthDateRepresentant: (value: string) => void;
    back: () => void;
    next: () => void;
}
const RepresentantForm = ({
    nameRepresentant,
    setNameRepresentant,
    cpfRepresentant,
    setCPFRepresentant,
    phoneRepresentant,
    setPhoneRepresentant,
    birthDateRepresentant,
    setbirthDateRepresentant,
    back,
    next,
}: RepresentantFormProps) => {
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (
            nameRepresentant &&
            cpfRepresentant &&
            phoneRepresentant &&
            birthDateRepresentant
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [
        nameRepresentant,
        cpfRepresentant,
        phoneRepresentant,
        birthDateRepresentant,
    ]);
    return (
        <>
            <div>
                <Box
                    style={{
                        maxWidth: "650px",
                    }}
                    mt={2}
                    mb={2}
                >
                    <Typography variant="caption3">
                        Dados do representante legal
                    </Typography>
                    <Typography variant="caption">
                        Preencha os campos abaixo com os dados do representante legal, essas
                        informações serão utilizadas no texto-guia que deverá ser lido
                        durante a gravação do vídeo de onboarding. É obrigatório que o/a
                        representante legal esteja com registro em dia no INSS.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Nome Completo do representante*"
                                value={nameRepresentant}
                                onChange={setNameRepresentant}
                                required
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="CPF do representante"
                                value={cpfRepresentant}
                                onChange={setCPFRepresentant}
                                required
                                validationCallback={ValidationUtils.validateCpf}
                                mask="999.999.999-99"
                                label={"CPF do representante"}
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="WhatsApp do representante"
                                onChange={setPhoneRepresentant}
                                value={phoneRepresentant}
                                required
                                validationCallback={ValidationUtils.validatePhone}
                                mask="(99) 99999-9999"
                                label={"WhatsApp do representante"}
                                width={"318px"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                placeholder="Data de nascimento do representante"
                                value={birthDateRepresentant}
                                onChange={setbirthDateRepresentant}
                                required
                                width={"318px"}
                                mask="99/99/9999"
                                label="Data de nascimento do representante"
                            />
                        </Grid>
                    </Grid>
                    <ButtonContainer>
                        <Button variant="contained" color="inherit" onClick={back}>
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            color={isFormValid ? "primary" : "inherit"}
                            onClick={next}
                            disabled={!isFormValid}
                        >
                            Próximo
                        </Button>
                    </ButtonContainer>
                </Box>
            </div>
        </>
    );
};
export default RepresentantForm;
