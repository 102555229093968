import {SelectOption} from "../../SelectOption";
import {EnumUtils} from "../../../utils/enum-utils";

enum EInquiryType {
    Documento = 1,
    DocumentoComSelfie = 2,
    Email = 3,
    Telefone = 4,
    VideoOnboarding = 5,
    PayrollLoanVideoOnboarding = 7,
    DocumentoAutomatic= 8,
    DocumentoComSelfieAutomatic = 9,
    NoDocVideoOnboarding = 10
}

export const InquiryTypeOptions = [
    {label: 'Documento - Análise Manual', value: EInquiryType.Documento},
    {label: 'Documento com selfie - Análise Manual', value: EInquiryType.DocumentoComSelfie},
    {label: 'E-mail', value: EInquiryType.Email},
    {label: 'Telefone', value: EInquiryType.Telefone},
    {label: 'Onboarding de vídeo', value: EInquiryType.VideoOnboarding},
    {label: 'Onboarding de vídeo - Consignado', value: EInquiryType.PayrollLoanVideoOnboarding},
    {label: 'Documento - Digital Híbrida', value: EInquiryType.DocumentoAutomatic},
    {label: 'Documento com selfie - Digital Híbrida', value: EInquiryType.DocumentoComSelfieAutomatic},
    {label: 'Onboarding de vídeo - Sem documento', value: EInquiryType.NoDocVideoOnboarding},
]
const getInquiryTypeAsText = (type: EInquiryType) => {
    switch (type) {
        case EInquiryType.DocumentoComSelfie:
            return 'Documento com selfie - Análise Manual';
        case EInquiryType.Documento:
            return 'Documento - Análise Manual';
        case EInquiryType.Email:
            return 'E-mail';
        case EInquiryType.VideoOnboarding:
            return 'Onboarding de vídeo';
        case EInquiryType.PayrollLoanVideoOnboarding:
            return 'Onboarding de vídeo - Consignado';
        case EInquiryType.DocumentoAutomatic:
            return 'Documento - Digital Híbrida';
        case EInquiryType.DocumentoComSelfieAutomatic:
            return 'Documento com selfie - Digital Híbrida';
        case EInquiryType.NoDocVideoOnboarding:
            return 'Onboarding de vídeo - Sem documento';
        default:
            return EInquiryType[type];
    }
};

export const EInquiryTypeDescriptions: { [key in EInquiryType]: string } = {
    [EInquiryType.Documento]: 'Documento - Análise Manual',
    [EInquiryType.DocumentoComSelfie]: 'Documento com selfie - Análise Manual',
    [EInquiryType.Email]: 'E-mail',
    [EInquiryType.Telefone]: 'Telefone',
    [EInquiryType.VideoOnboarding]: 'Onboarding de vídeo',
    [EInquiryType.PayrollLoanVideoOnboarding]: 'Onboarding de vídeo - Consignado',
    [EInquiryType.DocumentoAutomatic]: 'Documento - Digital Híbrida',
    [EInquiryType.DocumentoComSelfieAutomatic]: 'Documento com selfie - Digital Híbrida',
    [EInquiryType.NoDocVideoOnboarding]: 'Onboarding de vídeo - Sem documento',
};
export const EInquiryTypeOptions: Array<SelectOption> = EnumUtils.enumToArray(EInquiryType, EInquiryTypeDescriptions);

export default EInquiryType;
export {getInquiryTypeAsText};