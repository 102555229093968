import React, { ReactNode } from "react";
import styled from "styled-components";
import Typography from "../Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Palette } from "../../shared/theme/palette";
import { useNavigate } from "react-router-dom";

interface SubHeaderProps {
  title: string;
  subtitle: string;
  children?: ReactNode; 
}

const Container = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #cad7f0;
    background: #fff;
    gap: 0;
    align-items: baseline;
    flex-direction: column;
    position: relative;
    height: 120px;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    padding: 15px 0 15px 40px;
    justify-content: space-between;
`;

const SubTitle = styled.div`
  position: relative;
  left: 75px;
  top: -40px;
`;
const ChildrenContainer = styled.div`
  position: absolute;
  left: 800px;
  top: 20px;
`;
const SubHeader: React.FC<SubHeaderProps> = ({ title, subtitle, children }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <InnerContainer onClick={() => navigate(-1)}>
        <ArrowBackIcon style={{ color: Palette.darkGrey1 }} fontSize="small" />
        <Typography variant="h1">{title}</Typography>
        <ChildrenContainer>
        {children}
        </ChildrenContainer>
      </InnerContainer>
      <SubTitle>
        <Typography variant="caption">{subtitle}</Typography>
      </SubTitle>
    </Container>
  );
};

export default SubHeader;
