import React, {useContext, useState} from "react";
import Input from "../../components/Input/Input";
import "./Login.css";
import {useNavigate} from "react-router-dom";
import Routes from "../../constants/Routes";
import {useAuthenticationService} from "../../shared/services/useAuthenticationService";
import {ValidationUtils} from "../../utils/validation-utils";
import {toast} from "react-toastify";
import {AccountContext} from "../../shared/context/AccountContext";
import {applyMask, removeMask} from "../../shared/utils/remove-mask";
import {Button, Skeleton, Stack} from "@mui/material";
import {Login as LoginIcon} from "@mui/icons-material";
import {AuthenticationRequest} from "../../shared/models/services/requests/AuthenticationRequest";
import useWhitelabel from "../../hooks/useWhitelabel";
import {Constants} from "../../constants/Constants";


const Login: React.FC = () => {
    localStorage.clear();
    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<AuthenticationRequest>({
        email: '',
        password: '',
        originUrl: window.location.href
    });
    const {requestLogin} = useAuthenticationService();
    const accountContext = useContext(AccountContext);
    const {whitelabelLogo, currentWhitelabel} = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;
    const navigate = useNavigate();
    const doLogin = () => {
        if (!ValidationUtils.validateLogin(form.email)
            || !ValidationUtils.validatePassword(form.password, true, false)) return;
        setLoading(true);
        requestLogin(form)
            .then((sessionUser) => {
                const accountId = sessionUser.user.accountId;
                const unitId = sessionUser.user.currentUnityId
                accountContext.setIds({accountId, unitId});
                accountContext.setUser(sessionUser);
                navigate(Routes.Home);
            })
            .catch(() => {
                toast.error("Usuário ou senha inválido(s)")
                toast.clearWaitingQueue()
            })
            .finally(() => setLoading(false));
    };
    const goToCreateNewAccount = () => navigate(Routes.CreateAccount);

    return (
        <div id="login-container">
            <span className="material-symbols-outlined icon-search">search</span>
            <div id="login-inner-container">
                {
                    whitelabelLogo
                        ? <img src={whitelabelLogo} alt="Logo" className="logo-login"/>
                        : <Skeleton variant="rectangular" width={210} height={60}/>
                }
                <h1 className="title-login">Faça o seu login</h1>
                <h2 className="subtitle-login">
                    {isWhitelabel? `Seja bem-vindo! Preencha os campos abaixo e acesse a Plataforma Consultaí ${currentWhitelabel?.name}.` : 'Seja bem-vindo! Preencha os campos abaixo e entre na sua conta.'}
                </h2>
                <Input
                    value={applyMask(
                        form.email,
                        removeMask(form.email).length <= 11
                            ? "999.999.999-99"
                            : "99.999.999/9999-99"
                    )}
                    onChange={(value: string) => setForm({...form, email: removeMask(value)})}
                    placeholder="Usuário"
                    validationCallback={ValidationUtils.validateLogin}
                />
                <Input
                    value={form.password}
                    onChange={(value: string) => setForm({...form, password: value})}
                    placeholder="Senha"
                    isPassword
                />
                <Stack direction="column" spacing={2}>
                    <Button
                        variant={"text"}
                        onClick={() => navigate(Routes.ResetPassword)}>
                        {'Esqueci minha senha'}
                    </Button>
                    <Button
                        color={"primary"}
                        variant="contained"
                        onClick={doLogin}
                        startIcon={<LoginIcon/>}
                        disabled={loading}>
                        {'Entrar'}
                    </Button>
                    <Button onClick={goToCreateNewAccount} disabled={loading} variant="outlined">
                        {'Crie uma conta agora'}
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export default Login;
