import React, {useEffect, useState} from "react";
import SubSection from "../../components/SubSection";
import FAQComponent from "./componentes/Answers";
import ModalQuestion from "./componentes/ModalQuestion/modalQuestion";
import {useHelpService} from "../../shared/services/useHelpService";
import {toast} from "react-toastify";
import Layout from "../../layout";
import SubHeader from "../../components/SubHeader/SubHeader";
import Typography from "../../components/Typography";
import Input from "../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";
import {Button} from "@mui/material";
import {TextsContainer} from "../../theme/global-styles";
import {StyledButton} from "./componentes/Answers/styles";
import {useFaq} from "./consts/faqsConsts";

export default function Help() {
    const [question, setQuestion] = useState("");
    const [filteredFaqs, setFilteredFaqs] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const {requestHelp} = useHelpService();
    const {options} = useFaq();
    useEffect(() => {
        if (!searchText) setFilteredFaqs(options);
    }, [searchText, options]);

    const openModal = () => setModalVisible(true);
    const closeModal = () => {
        setModalVisible(false);
        setQuestion("");
    };
    const sendHelp = () =>
        requestHelp({helpDescription: question})
            .then(() => {
                toast.success("Dúvida enviada com sucesso!");
                closeModal();
            }).catch((error) => {
            toast.error(error.message);
        });

    const handleTextChange = (text) => setQuestion(text);

    const handleSearch = (searchText) => {
        setSearchText(searchText);
        const filtered = options.filter((faq) =>
            faq.question.toLowerCase().includes(searchText.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredFaqs(filtered.length ? filtered : options);
    };

    return (
        <Layout>
            <SubHeader title="Ajuda" subtitle="Perguntas frequentes"/>
            <SubSection>
                <></>
                <TextsContainer>
                    <Typography variant="caption">
                        Clique nas perguntas abaixo para visualizar a resposta. Caso queira
                        entrar em contato conosco utilize o botão ao final para enviar uma
                        dúvida.
                    </Typography>
                    <Input
                        placeholder={"Busque uma palavra chave..."}
                        onChange={handleSearch}
                        value={searchText}
                        startAdornment={<SearchIcon/>}
                        width="100%"
                    />
                    <div>
                        {filteredFaqs?.length > 0 ? (
                            <FAQComponent faqs={filteredFaqs}/>
                        ) : (
                            <Typography variant="caption">
                                A palavra chave digitada não foi encontrada. Tente digitar outra
                                palavra ou clique no botão abaixo para enviar sua dúvida.
                            </Typography>
                        )}
                    </div>
                    <StyledButton>
                        <Button variant={"contained"} onClick={openModal}>
                            Envie sua dúvida aqui
                        </Button>
                    </StyledButton>
                </TextsContainer>
            </SubSection>
            {modalVisible && (
                <ModalQuestion
                    onClose={closeModal}
                    question={question}
                    handleTextChange={handleTextChange}
                    sendHelp={sendHelp}
                />
            )}
        </Layout>
    );
}

export {Help};
