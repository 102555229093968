enum EOnboardingStep {
    Introduction = 0,
    VideoRecorder = 1,
    VideoDisplay = 2,
    DocumentType = 3,
    Instructions = 4,
    DocumentPhotoFront = 5,
    DocumentPhotoBack = 6,
    Result = 7,
    SetDocumentFileSource = 8,
    UploadDocument = 9,
}

export default EOnboardingStep;