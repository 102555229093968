import { Button, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import Typography from "../../../../components/Typography";
import Passaport from "../../../../shared/assets/passaporte-example.png";
import { StyledDivButton } from "../../onBoardingVideo/styles";
import { ImageHorizontalContainer } from "./styles";

interface InstructionsPassaporteProps {
  navigateToNext: () => void;
  title?: string;
}

const InstructionsOnBoardingVideoPassaporte: React.FC<
  InstructionsPassaporteProps
> = ({ navigateToNext, title }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h1" color="green">
        {title || "Instruções para a foto do documento"}
      </Typography>
      <Typography variant="caption">
        Utilize um documento de Passaporte físico (documentos digitais não são
        permitidos). Você deverá fotografar a página que possui sua foto.{" "}
      </Typography>
      <Typography variant="caption">
        Confira abaixo um exemplo da página do Passaporte que deverá ser
        fotografada.
      </Typography>
      <ImageHorizontalContainer isSmallScreen={isSmallScreen} src={Passaport} />
      <Typography variant="caption">
        Clique no botão “Próximo” abaixo para prosseguir.
      </Typography>
      <StyledDivButton>
        <Button variant={"contained"} color="primary" onClick={navigateToNext}>
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

InstructionsOnBoardingVideoPassaporte.propTypes = {
  navigateToNext: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default InstructionsOnBoardingVideoPassaporte;
