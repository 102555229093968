import { FlexDiv } from "./styles";
import PropTypes from "prop-types";
import { TextSmall, TextSmallBold } from "../../theme/GlobalStyles";
import { useEffect, useState } from "react";
import { FormatUtils } from "../../shared/utils/format-utils";
import { format } from "date-fns";
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

interface InquiryCardRowProps {
    label: string;
    value?: string | Date | number | (string | Date | number)[];
    mask?: 'document' | 'phone' | 'currency' | 'date' | 'datetime';
    hidden?: boolean;
    infoIcon?: boolean;
    tooltipText?: string;
}

const InquiryCardRow = ({ label, value, mask, hidden, infoIcon = false, tooltipText }: InquiryCardRowProps) => {
    const [maskedValues, setMaskedValues] = useState<string[]>([]);

    useEffect(() => {
        const formatValue = (val: string | Date | number): string => {
            if (val === null || val === undefined) return '';
            const stringValue = val.toString();
            if (!mask) return stringValue;

            switch (mask) {
                case 'document':
                    return FormatUtils.maskCpfOrCnpj(stringValue);
                case 'phone':
                    return FormatUtils.formatPhoneNumber(stringValue);
                case 'currency':
                    return FormatUtils.toBRLCurrency(stringValue);
                case 'date':
                    return format(new Date(stringValue), 'dd/MM/yyyy');
                case 'datetime':
                    return `${format(new Date(stringValue), 'dd/MM/yyyy')} às ${format(new Date(stringValue), 'HH:mm')}`;
                default:
                    return stringValue;
            }
        };

        if (Array.isArray(value)) {
            setMaskedValues(value.map(formatValue).filter(val => val !== ''));
        } else if (value !== undefined && value !== null) {
            const formattedValue = formatValue(value);
            if (formattedValue !== '') {
                setMaskedValues([formattedValue]);
            } else {
                setMaskedValues([]);
            }
        } else {
            setMaskedValues([]);
        }
    }, [value, mask]);

    const styles = { maxWidth: '100%' };
    return maskedValues.length > 0 && !hidden ? (
        <FlexDiv>
            <TextSmallBold>
                {label}
                {infoIcon && tooltipText && (
                    <Tooltip
                        title={tooltipText}
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid #ccc',
                            },
                        }}
                    >
                        <InfoIcon style={{ marginLeft: 7, fontSize: 16, top: 2 }} />
                    </Tooltip>
                )}
            </TextSmallBold>
            {maskedValues.map((maskedValue, index) => (
                <TextSmall style={{ ...styles, marginTop: index < maskedValues.length - 1 ? '8px' : '0' }} key={index}>
                    {maskedValues.length > 1 ? `- ${maskedValue}` : maskedValue}
                </TextSmall>
            ))}
        </FlexDiv>
    ) : null;
};

InquiryCardRow.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.instanceOf(Date)
        ]))
    ]),
    infoIcon: PropTypes.bool,
    tooltipText: PropTypes.string,
};

export default InquiryCardRow;