import styled from "styled-components";
import {Palette} from "../../../shared/theme/palette";

export const NoCameraAlertContainer = styled.div`
  display: flex;
  width: 1212px;
  align-items: flex-start;
  gap: 8px;
`;
export const NoCameraAlertText = styled.text`
  width: 494px;
  flex-shrink: 0;
  color: ${Palette.red};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
export const NoCameraAlertRecommendationText = styled.div`
  color: ${Palette.black};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.5px;
  width: 648px;
`;
export const MoneyCardsContainer = styled.div`
  display: flex;
  width: 288px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
`;
export const MoneyDescriptionCard = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  background-color: ${Palette.white};
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.07);
`;
export const MoneyDescriptionCardTitleText = styled.text`
  color: ${Palette.darkGrey4};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  align-self: stretch;
`;
export const MoneyDescriptionCardValueText = styled.text`
  color: ${Palette.darkGrey1};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`;

export const DocumentInquiryFirstStepChooseTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;
export const DocumentInquiryFirstStepChooseTypeOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
export const DocumentInquiryTakePhotoButtonContainer = styled.div`
  display: flex;
  width: 40.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const DocumentInquiryFirstStepInstructionsTitleText = styled.text`
  color: var(--Cinza-Escuro, ${Palette.darkGrey2});
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
`;

export const DocumentInquiryRgInstructionsImage = styled.img`
  width: 456px;
  height: 297px;
  flex-shrink: 0;
  background-repeat: no-repeat;
`;
export const DocumentInquiryRgInstructionsImageContainer = styled.div`
  display: flex;
  width: 39.5rem;
  height: 18.5625rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const DocumentInquiryCnhInstructionsImageContainer = styled.div`
  display: flex;
  width: 39.5rem;
  height: 44.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const DocumentInquirySelfieInstructionsImage = styled.img`
    width: 39.5rem;
    height: 18.6875rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
`;
export const DocumentInquiryCnhInstructionsImage = styled.img`
  width: 504px;
  height: 712px;
  flex-shrink: 0;
`;
export const SentSuccessfullyTitleContainer = styled.div`
  display: flex;
  width: 50.5rem;
  align-items: center;
  gap: 1rem;
`;
export const SentSuccessfullyTitleText = styled.text<{ primaryColor: string }>`
  width: 618px;
  flex-shrink: 0;
  color: var(--Verde1, ${(props) => props.primaryColor};);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
`;
export const SentSuccessfullyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
`;
export const SentSuccessfullyButtonsContainer = styled.div`
  display: flex;
  width: 40.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;
export const SentSuccessfullyButtonsContainerContent = styled.div`
  display: flex;
  width: 22.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
`;
export const DocumentInquiryTakePhotoContainer = styled.div`
  display: flex;
  width: 85rem;
  height: 55.5625rem;
  padding: 2.5rem 4.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  flex-shrink: 0;
`;
export const DocumentInquiryPhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  width: 39.5rem;
`;
export const DocumentImagePhoto = styled.img`
    object-fit: contain;
`;
export const FilePickerInput = styled.input`
    display: none;
`;