import { Typography } from "@mui/material";

export const useInquiryHint = ({
  withSelfie,
  automatic,
}: {
  withSelfie: boolean;
  automatic: boolean;
}) => {
  const docOcrMessage = "+ OCR do documento (extração dos dados da imagem)";
  const scoreMessage = `Um score de probabilidade de 0 a 100 do documento ser ou não fraude.
        Quanto mais próximo de ZERO é fraude, quanto mais próximo de CEM é um
        bom documento. O score é organizado por faixas de classificação, onde
        recomendamos fraude na parte vermelha, bom documento na parte verde e
        inconclusivo na parte amarela.`;
  const expectedResultMessage = "O que esperar como resultado?";
  const waterMarkMessage =
    "+ Possibilidade de consulta das imagens do documento e selfie. Sempre com hash de identificação e marca d`água.";
  const manualIntroMessage =
    "Análise realizada por uma mesa especializada em documentoscopia. Os documentos de identificação analisados são RG e CNH físicos. (documentos digitais não são permitidos).";
  const digitalIntroMessage =
    "Análise digital realizada por um software. Nos casos de dúvida do software, direcionamento para uma mesa especializada em documentoscopia. Os documentos de identificação analisados são RG e CNH físicos. (documentos digitais não são permitidos).";
  const endMessage =
    "Para realizar a consulta por documento, insira o CPF vinculado ao documento, antes de incluir a imagem.";

  if (withSelfie && automatic)
    return (
      <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
        {digitalIntroMessage}
        <br />
        {docOcrMessage}
        <br />
        + comparativo da foto do documento com a foto da selfie, através de
        nosso facematch.
        <br /> <br />
        {expectedResultMessage}
        <br />
        {scoreMessage}
        <br />
        {docOcrMessage}
        <br />
        {waterMarkMessage}
        <br /> <br />
        Por mais que o documento seja verdadeiro, caso de facematch não encontre
        similaridade, o resultado do score será baixo.
        <br /> <br />
        {endMessage}
      </Typography>
    );

  if (withSelfie && !automatic)
    return (
      <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
        {manualIntroMessage}
        <br />
        {docOcrMessage}
        <br />
        + comparativo da foto do documento com a foto da selfie, através de
        nosso facematch.
        <br /> <br />
        {expectedResultMessage}
        <br />
        {scoreMessage}
        <br />
        {docOcrMessage}
        <br />
        {waterMarkMessage}
        <br /> <br />
        Por mais que o documento seja verdadeiro, caso de facematch não encontre
        similaridade, o resultado do score será baixo.
        <br /> <br />
        {endMessage}
      </Typography>
    );

  if (!withSelfie && automatic)
    return (
      <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
        {digitalIntroMessage} <br />
        {docOcrMessage}
        <br />
        {waterMarkMessage}
        <br /> <br />
        {expectedResultMessage}
        <br />
        {scoreMessage}
        <br /> <br />
        {endMessage}
      </Typography>
    );

  if (!withSelfie && !automatic)
    return (
      <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
        {manualIntroMessage}
        <br />
        {docOcrMessage}
        <br /> <br />
        {expectedResultMessage}
        <br />
        {scoreMessage}
        <br />
        + Extração dos dados do documento
        <br />
        {waterMarkMessage}
        <br /> <br />
        {endMessage}
      </Typography>
    );

  return "";
};
