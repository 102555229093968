import { Grid, Stack } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import MailingContractCheckbox from "../../ContractCheckbox";
import {
  BoldContractText,
  ContractDivider,
  ContractSectionTitle,
  ContractText,
  SmallContractText,
} from "../styles";

const DateAndSignContractSection = () => {
  const formattedDate = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  });

  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>VIII - DATA E ASSINATURA</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>SAO PAULO, {formattedDate}.</ContractText>

        <Stack width="100%" alignItems="center" mt="60px" mb="24px">
          <ContractDivider sx={{ maxWidth: "676px" }} />
          <Stack direction="row" gap="16px" alignItems="center" mt="8px">
            <MailingContractCheckbox label="Titular" checked />
            <MailingContractCheckbox label="Reprentante Legal" />
            <MailingContractCheckbox label="Procurador" />
            <MailingContractCheckbox label="Terceiro" />
          </Stack>
        </Stack>

        <ContractText>Testemunhas:</ContractText>

        <Stack direction="row" width="100%" mt="48px" mb="24px">
          <Stack flex={1} gap="4px">
            <Stack direction="row">
              <ContractText>{"1)"}</ContractText>
              <ContractDivider sx={{ maxWidth: "430px" }} />
            </Stack>
            <ContractText>Nome:</ContractText>
            <ContractText>CPF:</ContractText>
          </Stack>

          <Stack flex={1} gap="4px">
            <Stack direction="row">
              <ContractText>{"2)"}</ContractText>
              <ContractDivider sx={{ maxWidth: "430px" }} />
            </Stack>
            <ContractText>Nome:</ContractText>
            <ContractText>CPF:</ContractText>
          </Stack>
        </Stack>

        <SmallContractText>
          “Sendo o(a) Contratante analfabeto ou portador(a) de necessidades
          especiais, as testemunhas que firmam o presente instrumento declaram
          que os termos deste documento foram lidos em voz alta e, sendo o(a)
          Contratante questionado(a) sobre sua compreensão, declarou sua
          concordância.”
        </SmallContractText>

        <BoldContractText
          sx={{ textAlign: "center", marginTop: "48px", marginBottom: "24px" }}
        >
          SAC 0800 727 4884 / OUVIDORIA 0800 703 8891 / DEFICIENTES AUDITIVOS E
          DE FALA 0800 273 3374
        </BoldContractText>
      </Grid>
    </>
  );
};

export default DateAndSignContractSection;
