import {DeleteCardRequest,} from '../models/requests/subscription-request';
import {UserCard} from "../models/user-card";
import {SaveCardRequest} from "../models/requests/save-card-request";
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useCardsService = () => {
    const consultaiApi = useConsultaiApi();
    const getListarCartao = async (id: number): Promise<UserCard[]> => {
        return (await consultaiApi.get<UserCard[]>(`/financeiro/formapagamento/unity/${id}/credit-cards`, 1)) ?? [];
    }
    const deleteCard = (request: DeleteCardRequest) => consultaiApi.delete(`/financeiro/formapagamento/unity/${request.unityId}/credit-cards/${request.cardId}`, 1)
    const requestSaveCard = (request: SaveCardRequest) => consultaiApi.post(`/financeiro/formapagamento/unity/${request.unityId}/credit-cards`, 1, request)

    return {
        getListarCartao,
        deleteCard,
        requestSaveCard
    }
}
