import styled from "styled-components";
import { Palette } from "../../shared/theme/palette";

export const StyledDiv = styled.div<{ isActive: boolean }>`
    position: relative;
    width: 360px;
    min-height: 168px;
    padding: 10px;
    border-radius: 8px;
    background-color: ${props => props.isActive ? Palette.white : Palette.lightGrey5};
    box-shadow: 0 4px 20px 0 #0000000f;
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 24px;
    height: 38px;
    margin-bottom: 24px;
`;
export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
`;
export const Container = styled.div`
    max-width: 1128px;
`;
export const SwitchContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
`;
export const DetailLineDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    height: 1.5rem;
    max-width: 350px;
    cursor: pointer;
`;
export const PaginationContainer = styled.div`
    display: flex;
  justify-content: center;
  padding-top: 35px;
  max-width: 1128px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;