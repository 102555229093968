import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PaymentCardProps from "./PaymentCardProps";
import Routes from "../../constants/Routes";
import "./PaymentCard.css";
import {AccountContext} from "../../shared/context/AccountContext";
import {useContext} from "react";
import {Constants} from "../../constants/Constants";

const PaymentCard = (props: PaymentCardProps) => {
  const { plan, showChooseButton, children } = props;

  const navigate = useNavigate();
  const accountContext = useContext(AccountContext);

  return (
    <div className="payment-plan-container">
      <div className="payment-plan-header">
        <div className="payment-plan-header">
          <p className="payment-plan-name">{plan.name}</p>
          <p className="payment-plan-value">
            {
                accountContext?.subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                plan.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </p>
        </div>
      </div>
      <div className="payment-plan-body">
        <p className="payment-plan-observation">{plan.observation}</p>
        {plan.planProducts.map((planProduct) => (
          <>
            <div key={planProduct.produtoId} className="product-item-container">
              <p>
                {planProduct.produto.nome}&nbsp;
                  {
                      accountContext?.subscription?.planoId !== Constants.ENTERPRISE_PLAN_ID &&
                      <b>
                      (
                      {planProduct.valor.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                      })}
                      );
                  </b>}
              </p>
            </div>
            <div className="product-item-separator" />
          </>
        ))}
          {
              !accountContext?.subscription?.planoId &&
              <p className="subscribe-message">Assine agora e comece a validar!</p>}
        {showChooseButton && (
          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              className="confirm-button"
              onClick={() => navigate(Routes.PlanConfirmation(plan.id))}
            >
              Escolher plano
            </Button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default PaymentCard;
