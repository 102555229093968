import { Button } from "@mui/material";
import Typography from "../../../../components/Typography";
import { StyledDivButton } from "../../onBoardingVideo/styles";
import { ImageVerticalContainer } from "./styles";
import Cnh from "../../../../shared/assets/cnh-example.png";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface InstructionsCnhProps {
  navigateToNext: () => void;
  title?: string;
}

const InstructionsOnBoardingVideoCnh: React.FC<InstructionsCnhProps> = ({
  navigateToNext,
  title,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
       <Typography variant="h1" color="green">
        {title || 'Instruções para a foto do documento'}
      </Typography>
      <Typography variant="caption">
        Utilize um documento de CNH físico (documentos digitais não são
        permitidos). Você deverá tirar a foto do documento aberto, conforme
        exemplo abaixo.
      </Typography>
      <Typography variant="caption">
        A frente do documento contém a foto do portador.
      </Typography>
      <ImageVerticalContainer isSmallScreen={isSmallScreen} src={Cnh} />
      <Typography variant="caption">
        Clique no botão “Próximo” abaixo para prosseguir.
      </Typography>
      <StyledDivButton>
        <Button variant={"contained"} color="primary" onClick={navigateToNext}>
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

InstructionsOnBoardingVideoCnh.propTypes = {
  navigateToNext: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default InstructionsOnBoardingVideoCnh;
