import styled from "styled-components";
import {Palette} from "../../../../shared/theme/palette";

export const StyledDiv = styled.button<{ backgroundColor: string }>`
  width: 79px;
  height: 79px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border-top: 1px solid ${Palette.white};
  border-bottom: 1px solid ${Palette.white};
  border-left: none;
  border-right: none;
  cursor: pointer;
`;

export const InnerContainer = styled.div`
  width: 100%; 
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
`;

export const Container = styled.div`
  gap: 5px;
`;
export const OptionListContainer = styled.div<{ top: number, offset: number }>`
  position: absolute;
  top: ${props => `${props.top + props.offset}px`};
  left: 110%;
  z-index: 10000;
  cursor: pointer;
`;

