export const ApiConfig = {
    Environment: process.env.REACT_APP_ENV,
    LogsEnabled: process.env.NODE_ENV === 'development',
    Consultai : {
        baseURL: process.env.REACT_APP_ENV === 'LOCAL' ? process.env.REACT_APP_CONSULTAI_URL_LOCAL
            : process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_CONSULTAI_URL_DEV
                : process.env.REACT_APP_ENV === 'HML' ? process.env.REACT_APP_CONSULTAI_URL_HML
                    : process.env.REACT_APP_CONSULTAI_URL_PROD
    },
    ConsultaiWebApp: {
        baseURL: process.env.REACT_APP_ENV === 'LOCAL' ? process.env.REACT_APP_CONSULTAI_WEBAPP_URL_LOCAL
            : process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_CONSULTAI_WEBAPP_URL_DEV
                : process.env.REACT_APP_ENV === 'HML' ? process.env.REACT_APP_CONSULTAI_WEBAPP_URL_HML
                    : process.env.REACT_APP_CONSULTAI_WEBAPP_URL_PROD
    },
    Pagarme:{
        baseURL: process.env.REACT_APP_PAGARME_API_URL,
        basicToken: process.env.REACT_APP_ENV === 'PRD'
            ? process.env.REACT_APP_PAGARME_API_BASIC_TOKEN_PROD
            : process.env.REACT_APP_PAGARME_API_BASIC_TOKEN_TEST,
        publicToken: process.env.REACT_APP_ENV === 'PRD'
            ? process.env.REACT_APP_PAGARME_API_PUBLIC_TOKEN_PROD
            : process.env.REACT_APP_PAGARME_API_PUBLIC_TOKEN_TEST,
    }
}