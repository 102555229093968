import React from "react";
import "./Steps.css";
import StepItemProps from "./StepItemProps";

const StepItem = (props: StepItemProps) => {
  const getIconName = () => {
    switch (props.type) {
      case "dados-pessoais":
        return "group";
      case "endereco":
        return "location_on";
      case "senha":
        return "lock";
    }
  };

  const getLabel = () => {
    switch (props.type) {
      case "dados-pessoais":
        return "1 - Dados Cadastrais";
      case "endereco":
        return "2 - Endereço        ";
      case "senha":
        return "3 - Senha";
    }
  };

  const iconName = getIconName();

  const label = getLabel();

  const itemClassName = props.active ? "step-item active" : "step-item";

  const nameClassName = props.active ? "step-name active" : "step-name";

  return (
    <div>
      <div className="step-item-container">
        <div className={itemClassName}>
          <span className="step-icon material-symbols-outlined fill">
            {iconName}
          </span>
        </div>
        <span className={nameClassName}>{label}</span>
      </div>
    </div>
  );
};

export default StepItem;
