import {
    PerformanceReportTopCard,
    SpentValueCardContainer,
    SpentValueCardIconContainer,
    SpentValueCardLabel,
    SpentValueCardTextContainer,
    SpentValueCardValue
} from "../../styles";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import useMoney from "../../../../shared/hooks/useMoney";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface SpentValueCardProps {
    spentValue: number;
}

const SpentValueCard = ({spentValue}: SpentValueCardProps) => {
    const {toString} = useMoney();
    const theme = useThemeWhiteLabel();

    return (
        <PerformanceReportTopCard>
            <SpentValueCardContainer>
                <SpentValueCardTextContainer>
                    <SpentValueCardLabel primaryColor={theme.primaryColor}
                    >{'Valor gasto'}</SpentValueCardLabel>
                    <SpentValueCardValue>{toString(spentValue)}</SpentValueCardValue>
                </SpentValueCardTextContainer>
                <SpentValueCardIconContainer>
                    <MonetizationOnRoundedIcon color={'primary'}></MonetizationOnRoundedIcon>
                </SpentValueCardIconContainer>
            </SpentValueCardContainer>
        </PerformanceReportTopCard>
    );
}
export default SpentValueCard;