import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";
import CheckBoxProps from "./CheckBoxProps";

export const CheckBoxContainer = styled.div<CheckBoxProps>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const CheckBoxInput = styled.div<CheckBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid ${props => !props.disabled ? Palette.darkGrey1 : Palette.lightGrey5 };
  border-radius: ${props => props.square ? "0px" : "50%"};
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
  position: relative;

  &:after {
    content: "";
    display: ${props => props.isChecked ? "block" : "none"};
    width: 7px;
    height: 7px;
    background-color: ${props => props.primaryColor};
    border-radius: ${props => props.square ? "0px" : "50%"};
  }
`;

export const CheckBoxLabel = styled.label<CheckBoxProps>`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${props => !props.disabled ? Palette.darkGrey1 : Palette.lightGrey5 };
`;