import React from 'react'
import {HomeCard, HomeCardContainer, HomeCardImage, HomeCardText, HomeCardTextContainer, HomeCardTitle} from './styles'
import homeImage from '../../../../shared/assets/home-banner-image.png';
import BlogBanner from "../../../../components/BlogBanner";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import {Constants} from "../../../../constants/Constants";
import { useThemeWhiteLabel } from '../../../../hooks/useThemeWhiteLabel';

const HomeContentCard = () => {
    const theme = useThemeWhiteLabel();
    const {whitelabelName, currentWhitelabel} = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;
    return (
        <HomeCardContainer>
            <HomeCard>
                <HomeCardTextContainer>
                    <HomeCardTitle primaryColor={theme.primaryColor}
                    >{`O Consultaí ${whitelabelName} é uma plataforma powered by Who.`}</HomeCardTitle>
                    <HomeCardText>{isWhitelabel ?
                        "A Who é uma empresa antifraude que combina tecnologia e expertise de mais de 25 anos de mercado para proteger sua empresa contra fraudes com soluções customizadas. \n" +
                        "Plataforma para validar quem é quem, com melhor experiência e segurança para sua empresa"
                        : `A Who é uma empresa de soluções antifraude que combina seu legado com mais de 25 anos de mercado com a mente aberta para o amanhã, com novas tecnologias, oportunidades e soluções.
Acreditamos que a tecnologia, quando aplicada de forma inteligente para gerar operações seguras, contribui para um mundo mais justo.
Com o Consultaí não poderia ser diferente!`}
                    </HomeCardText>
                </HomeCardTextContainer>
                <HomeCardImage src={homeImage}></HomeCardImage>
                {!isWhitelabel && <BlogBanner/>}
            </HomeCard>
        </HomeCardContainer>
    )
}
export default HomeContentCard