import {AuthenticationRequest} from "../models/services/requests/AuthenticationRequest";
import {
    ChangePasswordRequest,
    PasswordByCodeRequest,
    RecoveryPasswordRequest
} from "../models/requests/autenticacao-request";
import useConsultaiApi from "./context/hooks/useConsultaiApi";
import {AuthenticationWithBiometryRequest} from "../models/services/requests/AuthenticationWithBiometryRequest";
import {SessionUser} from "../models/session-user";

export const useAuthenticationService = () => {
    const consultaiApi = useConsultaiApi();
    const requestLogin = async (request: AuthenticationRequest): Promise<SessionUser> => {
        return sendAuthRequest('/auth', request)
    }
    const requestLoginUnity = async (unityId: number) => {
        return await consultaiApi.post<SessionUser>(`/auth/unity/${unityId}`, 2)
    }
    const requestLoginWithBiometry = async (request: AuthenticationWithBiometryRequest) => {
        return sendAuthRequest('/auth/biometry', request)
    }
    const sendAuthRequest = async (url: string, request: AuthenticationRequest | AuthenticationWithBiometryRequest): Promise<SessionUser> => {
        const response = await consultaiApi.post<SessionUser>(url, 2, request);
        response.accessToken && consultaiApi?.setAccessToken(response.accessToken);
        response.refreshToken && consultaiApi?.setRefreshToken(response.refreshToken);
        return response;
    }
    const requestPasswordRecovery = async (request: RecoveryPasswordRequest): Promise<string> => {
        return await consultaiApi.post<string>(`/autenticacao/password/recovery-request`, 1, request);
    }
    const requestPasswordResetByCode = async (request: PasswordByCodeRequest): Promise<void> => {
        return await consultaiApi.post(`/autenticacao/password/reset-by-code`, 1, request);
    }
    const requestChangePassword = async (request: ChangePasswordRequest): Promise<void> => {
        return await consultaiApi.patch(`/autenticacao/password/change`, 1, request);
    }
    const getAuthorizedDeviceAsync = (cnpj: string) => consultaiApi.get<string>(`/autenticacao/authorized-device/${cnpj}`)
    const setAuthorizedDeviceAsync = (deviceId: string) => consultaiApi.post(`/autenticacao/authorized-device/${deviceId}`)

    return {
        requestLogin,
        requestLoginWithBiometry,
        requestLoginUnity,
        requestPasswordRecovery,
        requestPasswordResetByCode,
        requestChangePassword,
        getAuthorizedDeviceAsync,
        setAuthorizedDeviceAsync
    };
}
