export const cameraConfig: MediaTrackConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export const isImageExtension = (filename: string) =>
  filename.endsWith(".png") ||
  filename.endsWith(".jpeg") ||
  filename.endsWith(".jpg");
