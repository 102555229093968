
import styled from 'styled-components';
import {Palette} from "../../shared/theme/palette";
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
export const StepperContainer = styled.div`
  display: flex;
  width: 39.5rem;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.625rem;
  border: 1px solid ${Palette.lightBlue};
  background: ${Palette.white};
  box-shadow: 0 2px 10px 0 rgba(185, 178, 178, 0.20);
`;

export const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.primaryColor,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.primaryColor,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: Palette.lightBlue,
        borderRadius: 1,
    },
}));


export const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
    backgroundColor: Palette.darkGrey5,
    zIndex: 1,
    color: Palette.white,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.primaryColor,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.primaryColor,
    }),
}));