import { Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../components/Input/Input";
import { TooltipHint } from "../../../../components/TooltipHint";
import { AccountContext } from "../../../../shared/context/AccountContext";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import { usePlanService } from "../../../../shared/services/usePlanService";
import { useSubscriptionService } from "../../../../shared/services/useSubscriptionService";
import {
  FormContainer,
  HorizontalButtonsContainer,
  InstructionsText,
  ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";
import { ValidationUtils } from "../../../../utils/validation-utils";
import InquiryPriceAndBalanceCards from "../InquiryPriceAndBalanceCards";

interface EmailInquiryFormProps {
  onNext: () => void;
  email: string;
  setEmail: (cpf: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}
const EmailInquiryForm = ({ ...props }: EmailInquiryFormProps) => {
  const [inquiryPrice, setInquiryPrice] = useState<number>();
  const { ids } = useContext(AccountContext);
  const { buscarAssinaturaAtiva } = useSubscriptionService();
  const { getProductPrices } = usePlanService();
  useEffect(() => {
    props.setLoading(true);
    buscarAssinaturaAtiva(ids.unitId)
      .then((subscription) => {
        getProductPrices(subscription.planoId).then((prices) => {
          const price = prices?.find(
            (price) => price.type == EInquiryType.Email
          )?.price;
          setInquiryPrice(price);
          if (!price)
            toast.error("Seu plano atual não permite esse tipo de consulta.", {
              autoClose: false,
              closeButton: true,
              toastId: "no-price-error",
            });
        });
      })
      .finally(() => props.setLoading(false));
  }, [ids]);
  return (
    <>
      <ScreenWithSubHeaderContainer>
        <>
          <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice} />
          <InstructionsText>
            {
              "Para realizar uma consulta por e-mail, basta preencher o campo abaixo."
            }

            <TooltipHint
              hint={
                <Typography sx={{ fontFamily: "Inter", fontSize: "14px" }}>
                  Análise de risco do e-mail, baseado nos dados da solução
                  emailage de nosso parceito LexisNexis.
                  <br /> <br />
                  O que esperar como resultado?
                  <br />
                  Um score de probabilidade de 0 a 100 do e-mail está ou não
                  envolvido em uma fraude. Quanto mais próximo de ZERO é risco
                  de envolvimento em fraude, quanto mais próximo de CEM é um bom
                  e-mail. O score é organizado por faixas de classificação, onde
                  recomendamos fraude na parte vermelha, bom e-mail na parte
                  verde e inconclusivo na parte amarela. Para os casos de pouca
                  informação na base de dados, o resulta será inconclusivo.
                  <br /> <br />
                  Para fazer uma consulta por e-mail, basta preencher o campo
                  abaixo.
                </Typography>
              }
            />
          </InstructionsText>
          <FormContainer>
            <Input
              placeholder="Preencha o e-mail"
              onChange={props.setEmail}
              value={props.email}
              required
              validationCallback={ValidationUtils.validateEmail}
              error={"E-mail inválido"}
              label={"E-mail"}
            />
            <HorizontalButtonsContainer>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={props.onNext}
                disabled={!inquiryPrice || props.loading || !props.email}
              >
                {"Solicitar Consulta"}
              </Button>
            </HorizontalButtonsContainer>
          </FormContainer>
        </>
      </ScreenWithSubHeaderContainer>
    </>
  );
};
export default EmailInquiryForm;
