import React from "react";
import {
    RiskLabelColorIndicator,
    RiskLabelContent,
    PerformanceReportTopCard,
    RiskLabelsContainer,
    RiskLabelText
} from "../../styles";
import {Palette} from "../../../../shared/theme/palette";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const RiskLabels = () => {
    const theme = useThemeWhiteLabel();
    return (
        <PerformanceReportTopCard>
            <RiskLabelsContainer>
                <RiskLabelContent>
                    <RiskLabelColorIndicator color={theme.primaryColor}></RiskLabelColorIndicator>
                    <RiskLabelText>{'Sem Risco'}</RiskLabelText>
                </RiskLabelContent>
                <RiskLabelContent>
                    <RiskLabelColorIndicator color={theme.alternativeColor}></RiskLabelColorIndicator>
                    <RiskLabelText>{'Risco Indefinido'}</RiskLabelText>
                </RiskLabelContent>
                <RiskLabelContent>
                    <RiskLabelColorIndicator color={Palette.red}></RiskLabelColorIndicator>
                    <RiskLabelText>{'Com Risco'}</RiskLabelText>
                </RiskLabelContent>
            </RiskLabelsContainer>
        </PerformanceReportTopCard>
    );
}
export default RiskLabels;