import { styled } from "styled-components";
import { Palette } from "../../shared/theme/palette";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 15px;
`;
export const ContainerList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
`;
export const VelocimeterImage = styled.img`
    margin-bottom: -1px;
`;
export const StyledDiv = styled.div`
    position: relative;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 0.8px solid #e1e1e1;
    background: ${Palette.white};
    box-shadow: 0 1px 10px 0 rgba(220, 220, 220, 0.7);
    max-width: 648px;
`;
export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    padding-bottom: 10px;
`;
export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    padding: 5px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 0.8px solid #e1e1e1;
    background: ${Palette.white};
    box-shadow: 0 1px 10px 0 rgba(220, 220, 220, 0.7);
    max-width: 648px;
`;
export const IconContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
`;
export const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;
export const RowDivSpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    width: 100%;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: ${Palette.lightGrey3};
    margin-bottom: 15px;
    margin-top: -3rem;
`;
export const RowDivider = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    width: 80%;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: ${Palette.lightGrey2};
    margin-bottom: 10px;
`;
export const IconWithMargin = styled.div`
    margin-top: 5px;
`;
export const StyledTypography = styled.div`
    margin-bottom: -18px;
`;
export const FlexDiv = styled.div`
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding-bottom: 5px;
    min-width: 150px;
    text-wrap: wrap;
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    gap: 10px;
    `

export const StyledVideo = styled.video`
    max-width: 270px;
    height: auto;
    padding-bottom: 10px;
`;

export const StyledImage = styled.img`
    max-width: 270px;
    height: auto;
`;

export const IconRow = styled.div`
    flex-direction: row;
    justify-content: space-between;
`;

export const ScoreText = styled.span`
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 1rem;
    padding-bottom: -10px;
`
export const RowDivDetailsSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 600px;
`;