import styled from "styled-components";
import { Palette } from "../../../shared/theme/palette";
import background from "../../../assets/background.jpg";
import Webcam from 'react-webcam';

type PhotoProps = {
  isCaptured?: boolean;
  isSmallScreen: boolean;
};
export const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-bottom: 2px solid #cad7f0;
  background-size: 100% auto;
  box-shadow: 0 4px 4px 0 #00000040;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const StyledDivWithBackgroud = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 32px 40px;
  border-radius: 10px;
  border-bottom: 2px solid #cad7f0;
  background: ${Palette.white} url(${background}) no-repeat center bottom;
  background-size: 100% auto;
  box-shadow: 0 4px 4px 0 #00000040;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
export const StyledDivText = styled.div`
  display: flex;
  flez-direction: row;
  align-items: center;
  gap: 8px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledVideo = styled.video`
  width: 100%;
  max-height: 520px;
  object-fit: cover;
`;
export const CircleDiv = styled.div<{ isSmallScreen: boolean }>`
  borderRadius: 50%;
  width: ${props => props.isSmallScreen ? "70vw" : "30vw"};
  height: ${props => props.isSmallScreen ? "60vw" : "30vw"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledWebcam = styled(Webcam)`
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 6px solid #006241;
`;
export const StyledDivButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  right: 20px;
  margin-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 25px;
    margin-right: 0;
  }
`;
export const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
`;
export const InnerContainerVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Video = styled.video<PhotoProps>`
  display: ${(props) => (props.isCaptured ? "none" : "block")};
  object-fit: contain;
  border-radius: 5px;
  max-height: 50%;
`;

export const Canvas = styled.canvas<PhotoProps>`
  display: ${(props) => (props.isCaptured ? "block" : "none")};
  object-fit: contain;
  border-radius: 5px;
  max-height: 50%;
`;
export const InnerContainer = styled.div<PhotoProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isSmallScreen ? "15px" : "30px 5%")};
`;
export const StyledDivCenterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 20px;
`;
