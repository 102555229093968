import { ContractText } from "../Contract/styles";
import { CheckboxLabel, CheckboxMark } from "./styles";

const MailingContractCheckbox = ({
  label,
  description,
  checked = false,
}: {
  label: string;
  description?: string;
  checked?: boolean;
}) => {
  return (
    <>
      <CheckboxLabel>
        <input type="checkbox" readOnly checked={checked} />
        <CheckboxMark />
        <ContractText className="checkbox-label">{label}</ContractText>
      </CheckboxLabel>

      {description && <ContractText>{description}</ContractText>}
    </>
  );
};

export default MailingContractCheckbox;
