import {InstructionsText} from "../../../../theme/GlobalStyles";
import Typography from "../../../../components/Typography";
import {Button} from "@mui/material";
import DragAndDropFilePicker from "../../../../components/DragAndDropFilePicker";
import useInquiryService from "../../../../shared/services/useInquiryService";
import FileInput from "../../../../shared/models/fileInput";
import {useState} from "react";
import {toast} from "react-toastify";
import {Container} from "../Instructions/styles";
import {StyledDivButton} from "../../onBoardingVideo/styles";

interface OnboardingDocumentFileUpload {
    setFrontDocumentFile: (fileIdentifier: string) => void;
    setBackDocumentFile: (fileIdentifier: string) => void;
    documentFile: string;
    docOwnerInfoText: string;
    nextStep: () => void;
    previousStep: () => void;
}

const OnboardingDocumentFileUpload = ({...props}: OnboardingDocumentFileUpload) => {
    const [files, setFiles] = useState<FileInput[]>([]);
    const { saveInquiryFileAsync } = useInquiryService();

    const sendFileAndGoToNextStep = async () => {
        if (files.length === 0) return toast.error('Selecione um arquivo antes de prosseguir.');
        const fileIds = await Promise.all(files.map(file => saveInquiryFileAsync(file)));
        if (fileIds.length > 0) props.setFrontDocumentFile(fileIds[0]);
        if (fileIds.length > 1) props.setBackDocumentFile(fileIds[1]);
        props.nextStep();
    };

    return (
        <Container>
            <Typography variant="h1">
                Envio de Arquivos
            </Typography>
            <InstructionsText>
                Clique no botão abaixo para selecionar um arquivo no seu computador.
                <p></p>
                Certifique-se de que informações estão legíveis.
            </InstructionsText>
            <InstructionsText>
                {props.docOwnerInfoText}
            </InstructionsText>
            <DragAndDropFilePicker onFileChange={setFiles}></DragAndDropFilePicker>
            <InstructionsText>
                Após selecionar um arquivo, <b>clique no botão abaixo para prosseguir.</b>
            </InstructionsText>
            <StyledDivButton>
                <Button
                    onClick={props.previousStep}
                    variant={'text'}
                >
                    {'Voltar'}
                </Button>
                <Button variant={"contained"}
                        disabled={files.length === 0}
                        color="primary"
                        onClick={sendFileAndGoToNextStep}>
                    {"Próximo"}
                </Button>
            </StyledDivButton>
        </Container>
    );
};

export default OnboardingDocumentFileUpload;