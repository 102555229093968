import { Button, Pagination } from "@mui/material";
import SubHeader from "../../components/SubHeader/SubHeader";
import Typography from "../../components/Typography";
import Routes from "../../constants/Routes";
import Layout from "../../layout";
import List from "./List";
import SubSection from "../../components/SubSection";
import { TextsContainer } from "../../theme/global-styles";
import { useNavigate } from "react-router-dom";
import { ButtonContainer, Container, ListContainer, PaginationContainer } from "./styles";
import Input from "../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { PagedList, PagedListFilter } from "../../shared/models/responses/paged-list";
import { UserResponseDto } from "../../shared/models/backend/user/user-response-dto";
import useUserService from "../../shared/services/useUserService";

const ListingUser = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<PagedListFilter>({ page: 0 });
  const [usersData, setusersData] = useState<PagedList<UserResponseDto>>();

  const { getUsersPagedList } = useUserService();

  useEffect(() => {
    const getusersData = async () => {
      const res = await getUsersPagedList(filter);
      setusersData(res);
    };
    getusersData();
  }, [filter]);

  if (!usersData) return null;
  return (
    <Layout>
      <SubHeader title="Configurações" subtitle="Gerenciar usuários" />
      <SubSection>
        <TextsContainer>
          <Typography variant="caption">
            Use esta tela para gerenciar e criar usuários que poderão utilizar
            todos os recursos disponíveis na sua conta principal.
          </Typography>
        </TextsContainer>
        <Container>
          <ButtonContainer>
            <Input
              placeholder={"Busca"}
              value={filter.search}
              onChange={(search) => setFilter({ ...filter, search })}
              startAdornment={<SearchIcon />}
              width="100%"
            />
            <Button
              size="small"
              variant={"contained"}
              onClick={() => navigate(Routes.User.New)}
            >
              Criar Usuário
            </Button>
          </ButtonContainer>
          <ListContainer>
            <List data={usersData} />
          </ListContainer>
          <PaginationContainer>
            <Pagination
              count={usersData.totalPages}
              page={filter.page + 1}
              onChange={(e, p) => setFilter({ ...filter, page: p - 1 })}
              color="primary"
              shape="rounded"
            />
          </PaginationContainer>
        </Container>
      </SubSection>
    </Layout>
  );
};

export default ListingUser;
