import React, {useContext, useEffect, useState} from "react";
import Typography from "./Typography";
import CreateCreditCardModal from "./ModalPaymentCard/ModalPaymentCard";
import CardOption from "./CardOption/CardOption";
import {AccountContext} from "../shared/context/AccountContext";
import {useSubscriptionService} from "../shared/services/useSubscriptionService";
import {useCardsService} from "../shared/services/useCardsService";
import {UserCardOption} from "../shared/models/user-card";
import {Button} from "@mui/material";
import {DivGap} from "./styles";

interface UserCardsPickerProps {
    onChangeSelected: (card?: UserCardOption) => void;
    footer?: React.ReactNode;
}

const

    UserCardsPicker = ({
                           onChangeSelected,
                           footer,
                       }: UserCardsPickerProps) => {
        const [userCards, setUserCards] = useState<UserCardOption[]>([]);
        const [loading, setLoading] = useState(false);
        const [showCreateCreditCardModal, setShowCreateCreditCardModal] = useState(false);
        const accountContext = useContext(AccountContext);
        const {buscarAssinaturaAtiva} = useSubscriptionService();
        const {getListarCartao} = useCardsService();

        const SearchPlan = async () => {
            if (
                !accountContext.ids ||
                accountContext.ids.unitId === null ||
                accountContext.ids.unitId === undefined
            ) return;
            const unitId = accountContext.ids.unitId;
            try {
                setLoading(true);
                const subscription = await buscarAssinaturaAtiva(unitId);
                accountContext.setSubscription(subscription);
                if (subscription.plano !== null) accountContext.setPlan(subscription.plano);
            } finally {
                setLoading(false);
            }
        };
        const getUserCards = () => {
            if (
                !accountContext.ids ||
                accountContext.ids.unitId === null ||
                accountContext.ids.unitId === undefined
            ) return;
            const unitId = accountContext.ids.unitId;
            setLoading(true);
            getListarCartao(unitId)
                .then((cards) => setUserCards(cards as UserCardOption[]))
                .finally(() => setLoading(false));
        };
        useEffect(() => {
            SearchPlan().then(() => getUserCards());
        }, []);

        useEffect(() => {
            const selectedCard = userCards.find((card) => card.isSelected);
            onChangeSelected(selectedCard);
        }, [userCards]);

        return (
            <>
                <Typography variant="h4">Selecionar cartão de crédito</Typography>
                <Typography variant="paymentDescription">
                    {!loading && !userCards
                        ? "Nenhum cartão registrado."
                        : "Clique no botão abaixo para cadastrar um cartão de crédito ou selecione um cartão já salvo."}
                </Typography>
                {loading && (
                    <Typography variant="caption">Carregando cartões...</Typography>
                )}
                <React.Fragment>
                    {userCards.map((card) => (
                        <CardOption
                            key={card.id}
                            card={card}
                            onChange={() => {
                                setUserCards(
                                    userCards.map((c) => ({
                                        ...c,
                                        isSelected: c.id == card.id ? !c.isSelected : false,
                                    }))
                                );
                            }}
                            onDelete={getUserCards}
                            canDelete={
                                accountContext.subscription
                                    ? card.id != accountContext.subscription.formaPagamentoId
                                    : true
                            }
                        ></CardOption>
                    ))}
                </React.Fragment>
                <DivGap></DivGap>
                <DivGap style={{paddingLeft: 220}}>
                    <Button
                        size="medium"
                        variant="outlined"
                        onClick={() => setShowCreateCreditCardModal(true)}
                    >
                        {"+ Inserir cartão de crédito"}
                    </Button>
                </DivGap>
                <DivGap></DivGap>
                {footer}
                {showCreateCreditCardModal && (
                    <CreateCreditCardModal
                        onClose={() => setShowCreateCreditCardModal(false)}
                        onSave={getUserCards}
                        loading={loading}
                        setLoading={setLoading}
                        onCancel={() => setShowCreateCreditCardModal(false)}
                    ></CreateCreditCardModal>
                )}
            </>
        );
    };
export default UserCardsPicker;
