import {
  Card,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  RadioGroup,
  Typography,
} from "@mui/material";
import Webcam from "react-webcam";
import styled, { keyframes } from "styled-components";

export const OnboardingContainer = styled.div`
  height: calc(100% - 64px);
  width: 100%;
  background-color: #eceded;
  overflow-y: auto;
  padding-inline: 42px;

  @media (max-width: 600px) {
    padding-inline: 16px;
  }
`;

export const OnboardingHeader = styled.header`
  height: 64px;
  width: 100%;
  background-color: #fff;
  padding: 8px 32px;
  display: flex;
  align-items: center;

  img {
    width: 120px;
    height: auto;
  }
`;

export const OnboardingCard = styled(Card)`
  && {
    padding-block: 42px;
    padding-inline: 74px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-bottom: 1px solid #cad7f0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      background-color: transparent;
      box-shadow: unset;
      border: 0px;
      padding-block: 0px;
      padding-inline: 0px;
      border-radius: 0px;
      transition: 0s;
    }
  }
`;

export const OnboardingCardLowRadius = styled(Card)`
  && {
    padding-block: 42px;
    padding-inline: 74px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-bottom: 1px solid #cad7f0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 600px) {
      padding: 24px;
    }
  }
`;

export const TitleTypography = styled(Typography)`
  && {
    font-family: "Poppins Regular";
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 32px;
  }
`;

export const TitleExtraBoldTypography = styled(Typography)`
  && {
    font-family: "Poppins Bold";
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 32px;
  }
`;

export const SubTitleTypography = styled(Typography)`
  && {
    font-family: "Poppins Regular";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;

export const SubTitleInterTypography = styled(Typography)`
  && {
    font-family: "Inter";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export const InterTextTypography = styled(Typography)`
  && {
    font-family: "Inter Regular";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;

export const SubTitle500Typography = styled(Typography)`
  && {
    font-family: "Poppins Regular";
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #212121;
  }
`;

export const SubTitleLargeTypography = styled(Typography)`
  && {
    font-family: "Poppins Regular";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 24px;
  }
`;

export const MailingTextTypography = styled(Typography)`
  && {
    font-family: "Inter";
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0.5px;
    white-space: nowrap;

    @media (max-width: 1064px) {
      white-space: normal;
      line-height: 20px;
    }
  }
`;

export const RadioLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const BoldTypography = styled.span`
  && {
    font-family: "Inter Bold";
    font-size: inherit;
    line-height: inherit;
    font-weight: 800;
  }
`;

export const BoldPoppinsTypography = styled.span`
  && {
    font-family: "Poppins Bold";
    font-size: inherit;
    line-height: inherit;
    font-weight: 800;
  }
`;

export const Bold500Typography = styled.span`
  && {
    font-family: "Inter Bold";
    font-size: inherit;
    line-height: inherit;
    font-weight: 500;
  }
`;

export const BoldTitleTypography = styled(Typography)`
  && {
    font-family: "Inter Bold";
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

export const DecoratedTypography = styled.span`
  && {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
  }
`;

export const DocPreviewImg = styled.img`
  && {
    max-width: 456px;
    width: 100%;
    height: auto;

    @media (max-width: 600px) {
      max-width: 196px;
    }
  }
`;

export const SingleFileDocPreviewImg = styled.img`
  && {
    max-width: 355px;
    width: 100%;
    height: auto;

    @media (max-width: 600px) {
      max-width: 305px;
    }
  }
`;

export const CenterBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

export const CircleBackground = styled.div`
  position: absolute;
  inset: 0px;

  &::after {
    content: "";
    background-color: #00548036;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    display: block;
    aspect-ratio: 1 / 1;
    width: 200%;
    border-radius: 50%;
    position: relative;
    top: 35%;
    left: -50%;
  }
`;

export const PhotoWebcamContainer = styled(Webcam)`
  && {
    width: 100%;
    height: auto;

    @media (max-width: 600px) {
      margin-inline: -24px;
      width: calc(100% + 48px);
    }
  }
`;

export const VideoWebcamContainer = styled.div`
  z-index: 2;
  max-width: 487px;
  max-height: 487px;
  margin-bottom: 32px;
  width: 100%;
  aspect-ratio: 1;
`;

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const RecordingLabel = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #00548036;
  border-radius: 6px;
  padding: 6px;

  &::before {
    content: "";
    display: block;
    background-color: red;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    animation: ${blink} 2s infinite;
  }
`;

export const VideoWebcam = styled(Webcam)`
  && {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 6px solid;
  }
`;

export const MailingList = styled(List)`
  && {
    list-style-type: disc;
    margin-bottom: 32px;
    padding-left: 16px;
  }
`;

export const MailingListItem = styled(ListItem)`
  && {
    display: list-item;
    font-family: "Inter Regular";
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    padding: 0px;
  }
`;

export const LineDecorator = styled.div`
  position: absolute;
  height: 100%;
  width: 6px;
  top: 0px;
  left: 0px;
`;

export const AlignEndGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const CardLowRadiusTitleContainer = styled.div`
  && {
    margin-inline: -32px;
    width: calc(100% + 64px);
    padding: 14px 32px;
    color: white;
    margin-top: 24px;
    margin-bottom: 32px;

    p {
      margin-bottom: 0px;
    }
  }
`;

export const PoppinsSmallBoldTypography = styled(Typography)`
  && {
    font-family: "Poppins Bold";
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
`;

export const InlineRadioGroup = styled(RadioGroup)`
  && {
    flex-direction: row;
    align-items: center;
    gap: 32px;

    label {
      margin-bottom: 0px;
    }
  }
`;

export const EditDocumentContainer = styled.div`
  margin-top: 42px;
  width: 40%;
  max-width: 670px;
  position: relative;

  @media (max-width: 600px) {
    width: 80%;
  }

  img {
    width: 100%;
    height: auto;
  }

  button {
    position: absolute;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 16px;
    left: calc(50% - 21px);
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    outline: 0px;
    transition: outline 0.1s;

    &:hover {
      outline: 2px solid #ffffff;
    }
  }
`;
