import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";
import { Palette } from "../../../../shared/theme/palette";
import Typography from "../../../Typography";
import { MATERIAL_ICONS, MenuOption } from "./menuOption";

const StyledButton = styled.div`
  position: relative;
  width: 300px;
  height: 77px;
  display: flex;
  z-index: 1000;
  padding: 16px 12px;
  gap: 10px;
  align-self: stretch;
  background: ${Palette.lightGrey1};
  align-items: center;
  border-top: 0.1px solid ${Palette.lightGrey2};
  border-right: 0.1px solid ${Palette.lightGrey2};
  border-left: 0.1px solid ${Palette.lightGrey2};

  &:last-child {
    border-bottom: 0.1px solid ${Palette.lightGrey2};
  }

  &:hover {
    background: ${Palette.lightGreen};
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
const Option: React.FC<MenuOption> = ({
  iconName,
  text,
  hint,
  onClick,
  multiOption = [],
  navigate,
  isOpen,
  onToggle,
}) => {
  const [showSecondButton, setShowSecondButton] = useState(false);
  const IconComponent = MATERIAL_ICONS[iconName];
  const theme = useThemeWhiteLabel();
  const handleClick = () => {
    if (multiOption.length > 0) {
      setShowSecondButton(!showSecondButton);
      onToggle();
    } else {
      onClick();
    }
  };

  if (multiOption.length > 0) {
    return (
      <StyledDiv>
        <StyledButton onClick={handleClick}>
          <IconComponent style={{ color: theme.primaryColor, fontSize: 26 }} />
          <Typography variant="paymentDescription">{text}</Typography>
          {isOpen ? (
            <ChevronLeftIcon
              style={{ color: theme.primaryColor, fontSize: 26 }}
            />
          ) : (
            <ChevronRightIcon
              style={{ color: theme.primaryColor, fontSize: 26 }}
            />
          )}
        </StyledButton>
        {isOpen && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: "100%",
            }}
          >
            {multiOption.map((option, index) => (
              <Tooltip
                key={index}
                title={option.hint}
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#eee3af",
                      fontSize: "14px",
                      color: "black",
                    },
                  },
                }}
              >
                <StyledButton
                  onClick={() => option.route && navigate(option.route)}
                >
                  <IconComponent
                    style={{ color: theme.primaryColor, fontSize: 26 }}
                  />
                  <Typography variant="paymentDescription">
                    {option.text}
                  </Typography>
                </StyledButton>
              </Tooltip>
            ))}
          </div>
        )}
      </StyledDiv>
    );
  } else {
    return (
      <Tooltip
        title={hint}
        placement="right"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#eee3af",
              fontSize: "14px",
              color: "black",
            },
          },
        }}
      >
        <StyledButton onClick={onClick}>
          <IconComponent style={{ color: theme.primaryColor, fontSize: 26 }} />
          <Typography variant="paymentDescription">{text}</Typography>
        </StyledButton>
      </Tooltip>
    );
  }
};

export default Option;
