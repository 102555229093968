import { Button } from "@mui/material";
import {
  InnerContainerVideo,
  StyledDivButton,
  StyledDivCenterButton,
  StyledVideo,
} from "../../onBoardingVideo/styles";
import Typography from "../../../../components/Typography";

interface VideoDisplayProps {
  videoSize: number;
  data: any;
  videoUrl: string;
  handleRerecord: () => void;
  sendVideo?: () => Promise<unknown>;
  navigateToNext: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  textPayroll?: string;
}

function VideoDisplay({
  videoSize,
  data,
  videoUrl,
  handleRerecord,
  sendVideo,
  navigateToNext,
  videoRef,
  textPayroll,
}: VideoDisplayProps) {
  return (
    <>
      <Typography variant="h1">Conferir gravação</Typography>
      <Typography variant="caption">
        Por favor verifique se o vídeo gravado contém todas as palavras do
        roteiro abaixo:
      </Typography>
      <Typography variant="caption">
        {`Tamanho do vídeo: ${videoSize} MB`}
      </Typography>
      <Typography variant="caption" align="center">
        {textPayroll || data?.telepromptText}
      </Typography>
      <InnerContainerVideo>
        <StyledVideo ref={videoRef} src={videoUrl} controls />
      </InnerContainerVideo>
      <Typography variant="caption">
        Caso queira substituir o vídeo, clique no botão abaixo.
      </Typography>
      <StyledDivCenterButton>
        <Button onClick={handleRerecord} variant="outlined">
          Trocar Vídeo
        </Button>
      </StyledDivCenterButton>
      <Typography variant="caption">
        Para prosseguir, clique no botão abaixo.
      </Typography>
      <StyledDivButton>
        <Button
          variant={"contained"}
          color="primary"
          onClick={async () => {
            if (sendVideo && typeof sendVideo === 'function') {
              await sendVideo();
            }
            navigateToNext();
          }}
        >
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
}

export default VideoDisplay;
