import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import usePayrollLoanOnboardingService from "../../../../shared/services/usePayrollLoanOnboardingService";
import {AccountContext} from "../../../../shared/context/AccountContext";
import {useSubscriptionService} from "../../../../shared/services/useSubscriptionService";
import {usePlanService} from "../../../../shared/services/usePlanService";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import {toast} from "react-toastify";
import Routes from "../../../../constants/Routes";
import {
    BankData,
    Contract,
    PayrollLoanOnboardingRequest,
    Person,
} from "../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";
import {DateUtils} from "../../../../shared/utils/date-utils";
import {format} from 'date-fns';

export const usePayrollLoanOnboarding = () => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [inquiryPrice, setInquiryPrice] = useState<number>();
    const [presential, setPresential] = useState<boolean>();
    const [whatsapp, setWhatsapp] = useState();
    const navigate = useNavigate();
    const [name, setName] = useState<string>("");
    const [cpf, setCPF] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [birthDate, setbirthDate] = useState("");
    const [illiterate, setIlliterate] = useState(false);
    const [legalRepresentative, setlegalRepresentative] = useState(false);
    const [recipient, setRecipient] = useState(false);
    const {createInvitePayRollVideoOnBoarding} =
        usePayrollLoanOnboardingService();
    const [bankName, setBankName] = useState<string>("");
    const [value, setValue] = useState<number>();
    const [installmentsValue, setInstallmentsValue] = useState<number>();
    const [installments, setInstallments] = useState<number>();
    const [contractNumber, setContractNumber] = useState<string>("");
    const [contractDate, setContractDate] = useState(() => {
        const today = new Date();
        return format(today, 'dd/MM/yyyy');
    });
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [agency, setAgency] = useState<string>("");
    const [account, setAccount] = useState<string>("");
    const [bankNameData, setBankNameData] = useState<string>("");
    const [nameRepresentant, setNameRepresentant] = useState<string>("");
    const [cpfRepresentant, setCPFRepresentant] = useState<string>("");
    const [phoneRepresentant, setPhoneRepresentant] = useState<string>("");
    const [birthDateRepresentant, setbirthDateRepresentant] = useState("");
    const [complementText, setcomplementText] = useState<string>("");
    const [
        replaceCurrentUnityComplementText,
        setReplaceCurrentUnityComplementText,
    ] = useState(false);

    const isEmpty = (obj: Person) => {
        return ["name", "cpf", "whatsapp", "birthDate"].every(
            (key) => obj[key] === null || obj[key] === ""
        );
    };
    const isEmptyBankData = (obj: BankData) => {
        return ["bankName", "agency", "account"].every(
            (key) => obj[key] === null || obj[key] === ""
        );
    };
    const onSendLink = async () => {
        setLoading(true);

        const stringToDateISOString = (date) => {
            if (!date) return null;
            const formattedDate = DateUtils.stringToFormattedDate(date, "dd/mm/yyyy");
            return formattedDate.toISOString();
        };
        try {
            const beneficiary: Person = {
                name: name,
                cpf: cpf,
                whatsapp: phone,
                birthDate: stringToDateISOString(birthDate),
                illiterate: illiterate,
            };

            let legalRepresentative: Person | null = {
                name: nameRepresentant,
                cpf: cpfRepresentant,
                whatsapp: phoneRepresentant,
                birthDate: stringToDateISOString(birthDateRepresentant),
                illiterate: illiterate,
            };

            let bankData: BankData | null = {
                bankName: bankNameData,
                agency: agency,
                account: account,
            };

            if (isEmpty(legalRepresentative)) legalRepresentative = null;
            if (isEmptyBankData(bankData)) bankData = null;

            const contractsFormatted = contracts.map((contract) => ({
                ...contract,
                contractDate: stringToDateISOString(contract.contractDate),
            }));
            const request: PayrollLoanOnboardingRequest = {
                presential,
                whatsapp: phone,
                bankname: bankName,
                complementText: complementText,
                replaceCurrentUnityComplementText: replaceCurrentUnityComplementText,
                beneficiary,
                bankData,
                contracts: contractsFormatted,
            };

            if (legalRepresentative) {
                request.legalRepresentative = legalRepresentative;
            }

            const response = await createInvitePayRollVideoOnBoarding(request);

            if (presential) {
                const url = `${window.location.origin}/onboarding/payroll-loan/${response.publicId}`;
                window.open(url, '_blank');
            }
            setStep(5);
        } finally {
            setLoading(false);
        }
    };

    const [showForm, setShowForm] = useState(false);
    const {ids} = useContext(AccountContext);

    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {getProductPrices} = usePlanService();

    useEffect(() => {
        const getInquiryPrice = async () => {
            const subscription = await buscarAssinaturaAtiva(ids.unitId);
            const productPrices = await getProductPrices(subscription.planoId);
            const price = productPrices?.find(
                (price) => price.type == EInquiryType.PayrollLoanVideoOnboarding
            )?.price;
            setInquiryPrice(price);
            if (!price)
                toast.error("Seu plano atual não permite esse tipo de consulta.", {
                    autoClose: false,
                    closeButton: true,
                    toastId: "no-price-error",
                });
        };

        getInquiryPrice();
    }, [ids]);

    const onReturnMenu = () => navigate(Routes.Home);
    const onNewOnboarding = () => {
        const clearAllData = () => {
            setName("");
            setCPF("");
            setPhone("");
            setbirthDate("");
            setIlliterate(false);
            setlegalRepresentative(false);
            setRecipient(false);
            setBankName("");
            setValue(undefined);
            setInstallmentsValue(undefined);
            setInstallments(undefined);
            setContractNumber("");
            setContractDate("");
            setContracts([]);
            setAgency("");
            setAccount("");
            setBankNameData("");
            setNameRepresentant("");
            setCPFRepresentant("");
            setPhoneRepresentant("");
            setbirthDateRepresentant("");
            setWhatsapp(undefined);
            setReplaceCurrentUnityComplementText(false);
            setShowForm(false);
            setPresential(undefined);
        }
        clearAllData();
        setStep(0);
    };
    const {getComplementarMessageVideoOnBoarding} =
        usePayrollLoanOnboardingService();
    useEffect(() => {
        const getMessage = async () => {
            const complementarMessage = await getComplementarMessageVideoOnBoarding();
            setcomplementText(complementarMessage);
        };
        getMessage()
    }, []);

    return {
        step,
        setStep,
        loading,
        setLoading,
        inquiryPrice,
        setInquiryPrice,
        presential,
        setPresential,
        onSendLink,
        name,
        setName,
        cpf,
        setCPF,
        phone,
        setPhone,
        birthDate,
        setbirthDate,
        illiterate,
        setIlliterate,
        legalRepresentative,
        setlegalRepresentative,
        recipient,
        setRecipient,
        showForm,
        setShowForm,
        onReturnMenu,
        onNewOnboarding,
        bankName,
        setBankName,
        value,
        setValue,
        installmentsValue,
        setInstallmentsValue,
        installments,
        setInstallments,
        contractNumber,
        setContractNumber,
        contractDate,
        setContractDate,
        whatsapp,
        setWhatsapp,
        contracts,
        setContracts,
        agency,
        setAgency,
        account,
        setAccount,
        bankNameData,
        setBankNameData,
        nameRepresentant,
        setNameRepresentant,
        cpfRepresentant,
        setCPFRepresentant,
        phoneRepresentant,
        setPhoneRepresentant,
        birthDateRepresentant,
        setbirthDateRepresentant,
        complementText,
        setcomplementText,
        replaceCurrentUnityComplementText,
        setReplaceCurrentUnityComplementText,
    };
};
