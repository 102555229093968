import styled from 'styled-components'

export const InnerContainer = styled.div`
max-width: 525px;
padding-bottom: 15px;
`
export const InputContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding-top: 16px;
padding-bottom: 16px;
width: 100%; 
`
