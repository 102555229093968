import { useMemo } from 'react';
import useWhitelabel from './useWhitelabel';
import { Palette } from '../shared/theme/palette';

export const useThemeWhiteLabel = () => {
  const { currentWhitelabel } = useWhitelabel();

  const theme = useMemo(() => ({
    ...Palette,
    primaryColor: currentWhitelabel?.primaryColor || Palette.green,
    secondaryColor: currentWhitelabel?.secondaryColor || Palette.green2,
    tertiaryColor: currentWhitelabel?.tertiaryColor || Palette.green3,
    alternativeColor: currentWhitelabel?.alternativeColor || Palette.yellow,
  }), [currentWhitelabel]);

  return theme;
};