import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { FadeIn } from "../../../../components/FadeIn/FadeIn";

type ContainerContentProps = {
  centered?: boolean;
  id?: string;
  minWidth?: string;
} & PropsWithChildren;

const ContainerContent = ({
  centered,
  id,
  children,
  minWidth,
}: ContainerContentProps) => {
  return (
    <FadeIn fullWidth fullHeight>
      <Box
        id={id}
        maxWidth="1200px"
        minWidth={minWidth}
        width="100%"
        height={centered ? "100%" : "auto"}
        display={centered ? "flex" : "block"}
        alignItems="center"
        justifyContent="center"
        paddingBlock={{ xs: "24px", sm: "32px" }}
        margin={"0 auto"}
      >
        {children}
      </Box>
    </FadeIn>
  );
};

export default ContainerContent;
