import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";

export const StyledHeader = styled.div<{ primaryColor: string }>`
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0; 
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center; 
  border-bottom: 1px solid ${(props) => props.primaryColor};
  background: ${Palette.white};
  z-index: 100; 
`;

