import {InquiryFinanceRequest} from '../models/requests/inquiry-finance-request';
import {InquiryFinanceResponse} from '../models/responses/inquiry-finances-response';
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useInquiryFinancialService = () => {

  const consultaiApi = useConsultaiApi();
  const getInquiryReport = async (request: InquiryFinanceRequest) => {
    let url = `/InquiryReport/unity/${request.id}/financial?DateFrom=${request.DateFrom}&DateTo=${request.DateTo}&Page=${request.Page}&PerPage=${request.PerPage}`;

    if (Array.isArray(request.TransactionTypes)) {
      for (const type of request.TransactionTypes) {
        if (type !== undefined) {
          url += `&TransactionTypes=${type}`;
        }
      }
    }

    return await consultaiApi.get<InquiryFinanceResponse>(url, 1);
  }
  return {getInquiryReport};
}