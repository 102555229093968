import { Box, Button, Grid } from "@mui/material";
import Input from "../Input/Input";
import AddressFormProps from "./AddressFormProps";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../shared/context/AccountContext";
import { useUnitService } from "../../shared/services/useUnitService";
import CheckBox from "../CheckBox";
import Typography from "../Typography";
import { removeMask } from "../../shared/utils/remove-mask";
import useCepSearch from "../../hooks/useCepSearch";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

const AddressForm = (props: AddressFormProps) => {
  const { address, setAddress } = props;
  const { getAccountData } = useUnitService();
  const [useProfileAddress, setUseProfileAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accountContext = useContext(AccountContext);
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
  const [searchCompleteAddressByZipCode] = useCepSearch();
  useEffect(() => {
    if (!useProfileAddress) {
      setAddress({
        zipCode: "",
        country: "",
        state: "",
        city: "",
        addressName: "",
        addressNumber: "",
        neighborhood: "",
        addressComplement: "",
      });
      return;
    }
    setIsLoading(true);
    const accountId = accountContext.ids?.accountId;
    getAccountData(accountId).then((response) => {
      setAddress({
        zipCode: response.cep,
        country: response.pais,
        state: response.uf,
        city: response.municipio,
        addressName: response.logradouro,
        addressNumber: response.numero,
        neighborhood: response.bairro,
        addressComplement: response.complemento,
      });
      setIsLoading(false);
    });
  }, [useProfileAddress]);

  useEffect(() => {
    !useProfileAddress &&
      !!address?.zipCode &&
      searchCompleteAddressByZipCode(removeMask(address?.zipCode), setAddress);
  }, [address?.zipCode]);

  const theme = useThemeWhiteLabel();

  const saveButtonEnabled =
    address &&
    address.zipCode &&
    address.state &&
    address.city &&
    address.addressName &&
    address.addressNumber &&
    address.neighborhood;
  return (
    <>
      <p>Insira abaixo o endereço de cobrança do cartão.</p>
      <p>Endereço de cobrança</p>
      <Grid container alignItems="center" paddingTop={1} paddingBottom={1}>
        <Grid item>
          <CheckBox
            primaryColor={theme.primaryColor}
            square
            isChecked={useProfileAddress}
            onPress={() => {
              setIsCheckboxClicked(true);
              setUseProfileAddress(!useProfileAddress);
            }}
          />
        </Grid>
        <Grid item paddingBottom={1}>
          <Typography variant="caption3">
            Desejo usar o mesmo endereço do cadastro
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.zipCode || ""}
              onChange={(value) => {
                setAddress({ ...address, zipCode: value });
              }}
              placeholder="CEP *"
              maxLength={9}
              mask="99999-999"
              isNormal
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.state || ""}
              onChange={(value) => {
                setAddress({ ...address, state: value });
              }}
              placeholder="Estado *"
              isNormal
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.city || ""}
              onChange={(value) => {
                setAddress({ ...address, city: value });
              }}
              placeholder="Cidade / Município *"
              isNormal
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.addressName || ""}
              onChange={(value) => {
                setAddress({ ...address, addressName: value });
              }}
              placeholder="Logradouro *"
              isNormal
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.addressNumber}
              onChange={(value) => {
                setAddress({ ...address, addressNumber: value });
              }}
              placeholder="Número *"
              isNormal
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={address?.addressComplement}
              onChange={(value) => {
                setAddress({ ...address, addressComplement: value });
              }}
              placeholder="Complemento"
              isNormal
            />
          </Grid>
        </Grid>
        <Input
          fullWidth
          value={address?.neighborhood || ""}
          onChange={(value) => {
            setAddress({ ...address, neighborhood: value });
          }}
          placeholder="Bairro *"
          isNormal
        />
      </div>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            size="medium"
            variant="text"
            onClick={props.onBack}
            disabled={props.loading}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            onClick={props.onSaveCard}
            disabled={!saveButtonEnabled}
          >
            Cadastrar cartão
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddressForm;
