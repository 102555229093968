import React, {useContext, useEffect, useState} from "react";
import Input from "../../../../components/Input/Input";
import "./Address.css";
import CreateAccountContext from "../../context/CreateAccountContext";
import useCepSearch from "../../../../hooks/useCepSearch";
import {CompleteAddressData} from "../../../../shared/models/complete-address-data";
import {Grid} from "@mui/material";

const Address = () => {
  const [ searchCompleteAddressByZipCode ] = useCepSearch();
  const [address, setAddress] = useState<CompleteAddressData>()

  const context = useContext(CreateAccountContext);

  const onBlurCep = async (value: string) => {
    await searchCompleteAddressByZipCode(value, setAddress);
  };

  useEffect(() => {
    if (!address) return;

    context.setAddress(address?.addressName ?? "");
    context.setNeighborhood(address?.neighborhood ?? "");
    context.setCity(address?.city ?? "");
    context.setState(address?.state ?? "");
    context.setComplement(address?.addressComplement ?? "");
  },[address])

  return (
    <div>
      <label className="register-title">Preencha os dados do endereço.</label>
      <div id="address-form">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={4}>
            <Input
                width={'19rem'}
                id="address-cep"
                label="CEP *"
                value={context.cep ?? ""}
                onChange={context.setCep}
                onBlur={onBlurCep}
            />
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={4}>
            <Input
                width={'19rem'}
                label="Cidade / Município *"
                value={context.city ?? ""}
                onChange={context.setCity}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
                width={'19rem'}
                label="Estado *"
                value={context.state ?? ""}
                onChange={context.setState}
            />

          </Grid>
          <Grid item xs={4}>
            <Input
                width={'19rem'}
                label="Endereço *"
                value={context.address ?? ""}
                onChange={context.setAddress}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
                width={'19rem'}
                label="Número *"
                value={context.number ?? ""}
                onChange={context.setNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
                width={'19rem'}
                label="Complemento"
                value={context.complement ?? ""}
                onChange={context.setComplement}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
                width={'19rem'}
                label="Bairro *"
                value={context.neighborhood ?? ""}
                onChange={context.setNeighborhood}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Address;
