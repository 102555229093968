import Layout from "../../../layout";
import SubHeader from "../../../components/SubHeader/SubHeader";
import React, {useState} from "react";
import PhoneInquiryForm from "../components/PhoneInquiryForm";
import {ValidationUtils} from "../../../utils/validation-utils";
import useInquiryService from "../../../shared/services/useInquiryService";
import {removeMask} from "../../../shared/utils/remove-mask";
import InquirySentSuccessfully from "../components/InquirySentSuccessfully";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";

const PhoneInquiryScreen = () => {
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [inquiryId, setInquiryId] = useState<number>();
    const {createAndSendPhoneInquiryAsync} = useInquiryService();

    function onConfirmPhoneInquiry() {
        if (!ValidationUtils.validatePhone(phone, true)) return
        try {
            setLoading(true);
            createAndSendPhoneInquiryAsync({phone: removeMask(phone),})
                .then((sentInquiry) => {
                    setInquiryId(sentInquiry?.id)
                    resetForm()
                })
        } finally {
            setLoading(false);
        }
    }

    const resetForm = (clearId?: boolean) => {
        setPhone('')
        if (clearId) setInquiryId(undefined)
    }

    return (
        <Layout>
            <SubHeader
                title={'Consulta'}
                subtitle={'Telefone'}/>
            {!inquiryId &&
                <PhoneInquiryForm
                    loading={loading}
                    setLoading={setLoading}
                    phone={phone}
                    setPhone={setPhone}
                    onNext={onConfirmPhoneInquiry}
                ></PhoneInquiryForm>
            }
            {inquiryId && !phone &&
                <InquirySentSuccessfully
                    inquiryType={EInquiryType.Telefone}
                    onNewInquiry={() => resetForm(true)}
                />}
        </Layout>
    )
}
export default PhoneInquiryScreen;