import { Box, Button, Grid } from "@mui/material";
import Modal from "../../../../../components/Modal";
import Typography from "../../../../../components/Typography";
import Input from "../../../../../components/Input/Input";
import { ButtonContainer } from "./contracts/styles";
import { useState } from "react";

interface ModalEditBankDataProps {
    onClose: () => void;
    bankName: string;
    agency: string;
    account: string;
    setBankName: (value: string) => void;
    setAgency: (value: string) => void;
    setAccount: (value: string) => void;
}

const ModalEditBankData = ({
    onClose,
    bankName: initialBankName,
    agency: initialAgency,
    account: initialAccount,
    setBankName,
    setAgency,
    setAccount,
    
}: ModalEditBankDataProps) => {
    const [bankName, setBankNameLocal] = useState(initialBankName);
    const [agency, setAgencyLocal] = useState(initialAgency);
    const [account, setAccountLocal] = useState(initialAccount);

    const handleSave = () => {
        setBankName(bankName);
        setAgency(agency);
        setAccount(account);
        onClose();
    };

    const handleClose = () => {
        setBankNameLocal(initialBankName);
        setAgencyLocal(initialAgency);
        setAccountLocal(initialAccount);
        onClose();
    };

    return (
        <Modal onClose={onClose} title="Editar dados bancários">
            <Box>
                <Typography variant="caption3">Dados bancários</Typography>
                <Typography variant="caption">
                    Preencha os campos abaixo com os dados bancários
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label="Nome do banco"
                            value={bankName}
                            onChange={setBankNameLocal}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label="Agência"
                            value={agency}
                            onChange={setAgencyLocal}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label="Conta"
                            value={account}
                            onChange={setAccountLocal}
                        />
                    </Grid>
                </Grid>
                <ButtonContainer>
                    <Button variant="contained" color="inherit" onClick={handleClose}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Salvar
                    </Button>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};

export default ModalEditBankData;

