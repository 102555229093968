import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import {
  default as InternalRoutes,
  default as Routes,
} from "../constants/Routes";
import ChangePasswordScreen from "../screens/Account/ChangePassword";
import { ProfileData } from "../screens/Account/Profile/Profile";
import CreateAccount from "../screens/CreateAccount";
import DownloadInquiriesResultReport from "../screens/DownloadInquiriesResultReport";
import InquiriesResultExportRequestSentSuccessfully from "../screens/DownloadInquiriesResultReport/components/InquiriesResultExportRequestSentSuccessfully";
import Help from "../screens/Faqs";
import { FinancialReport } from "../screens/FinancialReport";
import Home from "../screens/Home/Index";
import DocumentInquiryScreen from "../screens/Inquiries/DocumentInquiry";
import EmailInquiry from "../screens/Inquiries/EmailInquiry";
import PhoneInquiry from "../screens/Inquiries/PhoneInquiry";
import VideoOnboarding from "../screens/Inquiries/VideoOnboarding";
import PayrollLoanVideoOnboarding from "../screens/Inquiries/VideoOnboarding/PayrollLoanOnboarding/PayrollLoanOnboarding";
import InquiryDetails from "../screens/InquiryDetails/InquiryDetails";
import InquiryList from "../screens/InquiryList";
import ListingUser from "../screens/ListUsers/ListUsers";
import Login from "../screens/Login";
import NewUser from "../screens/NewUser/NewUser";
import OnBoardingVideos from "../screens/onBoardingVideo/onBoardingVideo/onBoardingVideo";
import PayRollLoanOnboardingOnBoardingVideo from "../screens/PayRollLoanOnboarding/onBoardingVideo/PayRollLoanOnboardingVideo";
import PerformanceReportScreen from "../screens/PerformanceReport";
import PublicOnboarding from "../screens/PublicOnboarding";
import Recharge from "../screens/Recharge";
import RegisterCompleted from "../screens/RegisterCompleted";
import ResetPasswordScreen from "../screens/ResetPassword";
import OtherPlans from "../screens/Settings/otherPlans/otherPlans";
import PlanConfirmation, {
  loadPlanFromParams,
} from "../screens/Settings/planConfirm/planConfirm";
import PlansPayment from "../screens/Settings/plansPayment/plansPayment";
import UnitiesListScreen from "../screens/Unities";
import LinkUsersToUnityScreen from "../screens/Unities/LinkUsersToUnity";
import NewUnityScreen from "../screens/Unities/NewUnity";
import UnityLinkedUsersScreen from "../screens/Unities/UnityLinkedUsers";

const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={InternalRoutes.Root} element={<Login />} />
      <Route path={InternalRoutes.Login} element={<Login />} />
      <Route path={InternalRoutes.CreateAccount} element={<CreateAccount />} />
      <Route
        path={InternalRoutes.RegisterCompleted}
        element={<RegisterCompleted />}
      />
      <Route
        path={InternalRoutes.ResetPassword}
        element={<ResetPasswordScreen />}
      />
      <Route path={InternalRoutes.Home} element={<Home />} />
      <Route path={InternalRoutes.SettingsPlans} element={<PlansPayment />} />
      <Route
        path={InternalRoutes.Inquiries.Document}
        element={<DocumentInquiryScreen />}
      />
      <Route
        path={InternalRoutes.Inquiries.DocumentWithSelfie}
        element={<DocumentInquiryScreen withSelfie />}
      />
      <Route
        path={InternalRoutes.Inquiries.DocumentAutomatic}
        element={<DocumentInquiryScreen automatic />}
      />
      <Route
        path={InternalRoutes.Inquiries.DocumentWithSelfieAutomatic}
        element={<DocumentInquiryScreen withSelfie automatic />}
      />
      <Route path={InternalRoutes.Inquiries.Phone} element={<PhoneInquiry />} />
      <Route path={InternalRoutes.Inquiries.Email} element={<EmailInquiry />} />
      <Route
        path={InternalRoutes.Inquiries.VideoOnboardingNoDoc}
        element={<VideoOnboarding isNoDocOnboarding={true} />}
      />
      <Route
        path={InternalRoutes.Inquiries.VideoOnboardingDoc}
        element={<VideoOnboarding isNoDocOnboarding={false} />}
      />
      <Route path={InternalRoutes.OtherPlans} element={<OtherPlans />} />
      <Route path={InternalRoutes.Inquiries.List} element={<InquiryList />} />
      <Route
        path={InternalRoutes.PlanConfirmation(":id")}
        element={<PlanConfirmation />}
        loader={loadPlanFromParams}
      />
      <Route
        path={InternalRoutes.Account.ChangePassword}
        element={<ChangePasswordScreen />}
      />
      <Route path={InternalRoutes.Account.Profile} element={<ProfileData />} />
      <Route
        path={InternalRoutes.Inquiries.Details(":inquiryType", ":id")}
        element={<InquiryDetails />}
      />
      <Route
        path={InternalRoutes.Reports.Performance}
        element={<PerformanceReportScreen />}
      />
      <Route
        path={InternalRoutes.Reports.Financial}
        element={<FinancialReport />}
      />
      <Route
        path={InternalRoutes.Reports.DownloadInquiriesResults}
        element={<DownloadInquiriesResultReport />}
      />
      <Route
        path={InternalRoutes.Reports.DownloadInquiriesResultsSent}
        element={<InquiriesResultExportRequestSentSuccessfully />}
      />
      <Route path={InternalRoutes.Recharge} element={<Recharge />} />
      <Route
        path={InternalRoutes.OnBoarding.Video(":id")}
        element={<OnBoardingVideos />}
      />
      <Route
        path={InternalRoutes.PayRollOnboarding.Video(":id")}
        element={<PayRollLoanOnboardingOnBoardingVideo />}
      />
      <Route path={InternalRoutes.Faqs} element={<Help />} />
      <Route path={InternalRoutes.User.List} element={<ListingUser />} />
      <Route path={InternalRoutes.User.New} element={<NewUser />} />
      <Route
        path={InternalRoutes.Inquiries.PayrollLoanVideoOnboarding}
        element={<PayrollLoanVideoOnboarding />}
      />
      <Route path={Routes.Unities.List} element={<UnitiesListScreen />} />
      <Route path={Routes.Unities.New} element={<NewUnityScreen />} />
      <Route
        path={Routes.Unities.LinkedUsers(":id")}
        element={<UnityLinkedUsersScreen />}
      />
      <Route
        path={Routes.Unities.LinkUsers(":id")}
        element={<LinkUsersToUnityScreen />}
      />
      <Route
        path={Routes.MailingOnboarding.Root(":id")}
        element={<PublicOnboarding />}
      />
    </>
  )
);
export default AppRoutes;
