import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";

export const BlogBannerContainer = styled.div`
  display: flex;
  padding: 13px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
`;
export const BlogBannerContent = styled.div`
  display: flex;
  height: 60px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 20px;
  background: ${Palette.lightGrey11};
`;
export const BlogBannerTextContainer = styled.div`
  display: flex;
  padding-left: 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;
export const BlogBannerText = styled.text`
  flex: 1 0 0;
  color: ${Palette.white};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 83.333% */
`;
export const BlogBannerOrangeText = styled.text`
  color: ${Palette.orange};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
export const BlogBannerImage = styled.img`
  width: 111px;
  height: 100px;
`;