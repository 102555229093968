import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import ResponsiveContainer from "../../../components/ResponsiveSection/ResponsiveContainer";
import EPhotoSourceType from "../../../shared/models/backend/inquiry/EPhotoSourceType";
import {
  EInquiryFileType,
  EInquiryFileTypeDescriptions,
} from "../../../shared/models/enums/EInquiryFileType";
import { FileTypeEnum } from "../../../shared/models/enums/files.enum";
import ChoosePhotoSource from "../../Inquiries/components/ChoosePhotoSource";
import DocumentPhoto from "../../onBoardingVideo/components/DocumentPhoto/DocumentPhoto";
import DocumentType from "../../onBoardingVideo/components/DocumentType/DocumentType";
import InstructionsOnBoardingVideoCnh from "../../onBoardingVideo/components/Instructions/InstructionsCnh";
import InstructionsOnBoardingVideoCTPS from "../../onBoardingVideo/components/Instructions/InstructionsCTPS";
import InstructionsOnBoardingVideoPassaporte from "../../onBoardingVideo/components/Instructions/InstructionsPassaporte";
import InstructionsOnBoardingVideoRg from "../../onBoardingVideo/components/Instructions/InstructionsRg";
import InstructionsUploadFile from "../../onBoardingVideo/components/Instructions/InstructionsUploadFile";
import OnBoardingFailed from "../../onBoardingVideo/components/onBoardingVideoFailed/onBoardingVideoFailed";
import ResultOnBoardingVideo from "../../onBoardingVideo/components/onBoardingVideoResult/onBoardingVideoResult";
import VideoDisplay from "../../onBoardingVideo/components/VideoDisplay/VideoDisplay";
import VideoRecorder from "../../onBoardingVideo/components/VideoRecorder/VideoRecorder";
import {
  InnerContainer,
  StyledDiv,
  StyledDivWithBackgroud,
} from "../../onBoardingVideo/onBoardingVideo/styles";
import IntroductionPayRoll from "../components/introductionPayRoll";
import PayrollOnboardingDocumentFileUpload from "../components/PayrollOnboardingDocumentFileUpload";
import usePayRollLoanOnboardingOnBoardingVideo from "./hooks/usePayRollLoanOnboardingOnBoardingVideoOnBoardingVideo";

const PayRollLoanOnboardingOnBoardingVideo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    mediaRecorder,
    recording,
    videoSize,
    videoUrl,
    isCaptured,
    selectedDocument,
    data,
    step,
    canvasRef,
    videoRef,
    previewRef,
    chunksRef,
    documentIds,
    filesSource,
    setSelectedDocumentId,
    setSelectedDocument,
    setIsCaptured,
    nextStep,
    handleNext,
    handleRerecord,
    handleCapture,
    handleStart,
    handleStop,
    badRequest,
    onBoardingExpired,
    setStep,
    setFilesSource,
  } = usePayRollLoanOnboardingOnBoardingVideo();

  const [showVideoDisplay, setShowVideoDisplay] = useState(false);
  const [currentContractIndex, setCurrentContractIndex] = useState(0);
  const [
    showVideoDisplayComplementarText,
    setShowVideoDisplayComplementarText,
  ] = useState(false);

  const nextStepContracts = (contractIndex) => {
    if (contractIndex < data?.inviteDTO.contracts.length - 1) {
      setCurrentContractIndex(contractIndex + 1);
      setShowVideoDisplay(false);
      handleNext(
        videoUrl,
        FileTypeEnum.ContractVideo,
        false,
        data?.inviteDTO.contracts[contractIndex].id
      );
    } else {
      handleNext(
        videoUrl,
        FileTypeEnum.ContractVideo,
        true,
        data?.inviteDTO.contracts[contractIndex].id
      );
    }
  };

  useEffect(() => {
    renderStep();
  }, []);

  interface StepContainerProps {
    children: React.ReactNode;
  }

  const StepContainer = ({ children }: StepContainerProps) => {
    return (
      <StyledDiv>
        <InnerContainer isSmallScreen={isSmallScreen}>
          {children}
        </InnerContainer>
      </StyledDiv>
    );
  };
  const renderStep = () => {
    if (onBoardingExpired) {
      return (
        <StyledDiv>
          <InnerContainer isSmallScreen={isSmallScreen}>
            <OnBoardingFailed />
          </InnerContainer>
        </StyledDiv>
      );
    } else if (badRequest) {
      return (
        <StyledDiv>
          <InnerContainer isSmallScreen={isSmallScreen}>
            <OnBoardingFailed isBadRequest />
          </InnerContainer>
        </StyledDiv>
      );
    }
    const title = data?.inviteDTO.legalRepresentative?.name
      ? "Instruções para a foto do documento do Representante Legal"
      : "Instruções para a foto do documento do beneficiário";
    let InstructionComponent;
    switch (step) {
      case 0:
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              <IntroductionPayRoll data={data} navigateToNext={nextStep} />
            </InnerContainer>
          </StyledDiv>
        );
      case 1:
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              <DocumentType
                documentIds={documentIds}
                handleDocumentSelection={(documentId: number) => {
                  setSelectedDocumentId(documentId);
                }}
                setSelectedDocumentType={setSelectedDocument}
                selectDocument={selectedDocument}
                navigateToNext={() => setStep(10)}
              />
            </InnerContainer>
          </StyledDiv>
        );
      case 10:
        return (
          <StepContainer>
            <ChoosePhotoSource
              source={filesSource}
              setSource={setFilesSource}
              onBack={() => setStep(1)}
              onNext={() => setStep(2)}
            />
          </StepContainer>
        );
      case 2:
        if (filesSource === EPhotoSourceType.File)
          return (
            <StepContainer>
              <InstructionsUploadFile
                previousStep={() => setStep(10)}
                nextStep={() => setStep(11)}
                docType={selectedDocument}
              />
            </StepContainer>
          );
        switch (selectedDocument) {
          case "RG":
            InstructionComponent = (
              <InstructionsOnBoardingVideoRg
                navigateToNext={nextStep}
                title={title}
              />
            );
            break;
          case "CNH":
            InstructionComponent = (
              <InstructionsOnBoardingVideoCnh
                navigateToNext={nextStep}
                title={title}
              />
            );
            break;
          case "Passaporte":
            InstructionComponent = (
              <InstructionsOnBoardingVideoPassaporte
                navigateToNext={nextStep}
                title={title}
              />
            );
            break;
          case "Carteira de Trabalho":
            InstructionComponent = (
              <InstructionsOnBoardingVideoCTPS
                navigateToNext={nextStep}
                title={title}
              />
            );
            break;
          default:
            InstructionComponent = null;
        }
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              {InstructionComponent}
            </InnerContainer>
          </StyledDiv>
        );
      case 11:
        return (
          <StepContainer>
            <PayrollOnboardingDocumentFileUpload
              docOwnerInfoText={`O documento enviado deve estar no nome de 
                            ${
                              data?.inviteDTO.legalRepresentative?.name ??
                              data?.inviteDTO.beneficiary.name
                            }`}
              documentType={selectedDocument.toString()}
              publicId={data?.inviteDTO?.publicId}
              previousStep={() => setStep(2)}
              nextStep={() => setStep(5)}
            ></PayrollOnboardingDocumentFileUpload>
          </StepContainer>
        );
      case 3:
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              <DocumentPhoto
                key="front"
                handleCapture={handleCapture}
                navigateToNext={() =>
                  handleNext(
                    EInquiryFileTypeDescriptions[FileTypeEnum.DocumentFront],
                    FileTypeEnum.DocumentFront
                  )
                }
                isCaptured={isCaptured}
                selectedDocument={selectedDocument}
                side="front"
                canvasRef={canvasRef}
                videoRef={videoRef}
                setIsCaptured={setIsCaptured}
              />
            </InnerContainer>
          </StyledDiv>
        );
      case 4:
        if (selectedDocument === "CNH" || selectedDocument === "Passaporte") {
          nextStep();
          return null;
        } else {
          return (
            <StyledDiv>
              <InnerContainer isSmallScreen={isSmallScreen}>
                <DocumentPhoto
                  key="back"
                  handleCapture={handleCapture}
                  navigateToNext={() =>
                    handleNext(
                      EInquiryFileTypeDescriptions[
                        EInquiryFileType.DocumentBack
                      ],
                      FileTypeEnum.DocumentBack
                    )
                  }
                  isCaptured={isCaptured}
                  selectedDocument={selectedDocument}
                  side="back"
                  canvasRef={canvasRef}
                  videoRef={videoRef}
                  setIsCaptured={setIsCaptured}
                />
              </InnerContainer>
            </StyledDiv>
          );
        }
      case 5:
        return (
          <StyledDivWithBackgroud>
            <VideoRecorder
              handleStart={handleStart}
              handleStop={handleStop}
              navigateToNext={nextStep}
              recording={recording}
              videoRef={videoRef}
              previewRef={previewRef}
              chunksRef={chunksRef}
              mediaRecorder={mediaRecorder}
              textPayroll={data?.inviteDTO.personalDataVideoScriptText}
            />
          </StyledDivWithBackgroud>
        );
      case 6:
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              <VideoDisplay
                videoSize={parseFloat(videoSize)}
                data={data}
                videoUrl={videoUrl}
                handleRerecord={handleRerecord}
                navigateToNext={() =>
                  handleNext(videoUrl, FileTypeEnum.PersonalDataVideo)
                }
                videoRef={videoRef}
                textPayroll={data?.inviteDTO.personalDataVideoScriptText}
              />
            </InnerContainer>
          </StyledDiv>
        );
      case 7:
        return (
          data?.inviteDTO.contracts[currentContractIndex] && (
            <React.Fragment key={currentContractIndex}>
              {showVideoDisplay ? (
                <StyledDiv>
                  <InnerContainer isSmallScreen={isSmallScreen}>
                    <VideoDisplay
                      videoSize={parseFloat(videoSize)}
                      data={data}
                      videoUrl={videoUrl}
                      handleRerecord={handleRerecord}
                      navigateToNext={() =>
                        nextStepContracts(currentContractIndex)
                      }
                      videoRef={videoRef}
                      textPayroll={
                        data?.inviteDTO.contracts[currentContractIndex]
                          .videoScriptText
                      }
                    />
                  </InnerContainer>
                </StyledDiv>
              ) : (
                <StyledDivWithBackgroud>
                  <VideoRecorder
                    handleStart={handleStart}
                    handleStop={handleStop}
                    navigateToNext={() => setShowVideoDisplay(true)}
                    recording={recording}
                    videoRef={videoRef}
                    previewRef={previewRef}
                    chunksRef={chunksRef}
                    mediaRecorder={mediaRecorder}
                    textPayroll={
                      data?.inviteDTO.contracts[currentContractIndex]
                        .videoScriptText
                    }
                  />
                </StyledDivWithBackgroud>
              )}
            </React.Fragment>
          )
        );
      case 8:
        if (data?.inviteDTO.complementText) {
          return showVideoDisplayComplementarText ? (
            <StyledDiv>
              <InnerContainer isSmallScreen={isSmallScreen}>
                <VideoDisplay
                  videoSize={parseFloat(videoSize)}
                  data={data}
                  videoUrl={videoUrl}
                  handleRerecord={handleRerecord}
                  navigateToNext={() =>
                    handleNext(videoUrl, FileTypeEnum.PersonalDataVideo)
                  }
                  videoRef={videoRef}
                  textPayroll={data?.inviteDTO.complementText}
                />
              </InnerContainer>
            </StyledDiv>
          ) : (
            <StyledDivWithBackgroud>
              <VideoRecorder
                handleStart={handleStart}
                handleStop={handleStop}
                navigateToNext={() => setShowVideoDisplayComplementarText(true)}
                recording={recording}
                videoRef={videoRef}
                previewRef={previewRef}
                chunksRef={chunksRef}
                mediaRecorder={mediaRecorder}
                textPayroll={data?.inviteDTO.complementText}
              />
            </StyledDivWithBackgroud>
          );
        } else {
          nextStep();
          return null;
        }
      case 9:
        return (
          <StyledDiv>
            <InnerContainer isSmallScreen={isSmallScreen}>
              <ResultOnBoardingVideo />
            </InnerContainer>
          </StyledDiv>
        );
      default:
        return null;
    }
  };

  return (
    <ResponsiveContainer>
      <Header />
      {renderStep()}
    </ResponsiveContainer>
  );
};

export default PayRollLoanOnboardingOnBoardingVideo;
