import React, {ReactElement} from "react";
import styled from "styled-components";
import Typography from "./Typography";
import {Palette} from "../shared/theme/palette";
import CloseIcon from '@mui/icons-material/Close';

const modalWidth = "40%";

const ModalHeader = styled.div<{ width?: string }>`
    width: ${(props) => props.width || modalWidth};
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid #c2c2c2;
    background: #fff;
`;

const ModalContainer = styled.div`
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    align-self: center;
    flex-direction: column;
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const ModalContent = styled.div<{ width?: string }>`
    width: ${(props) => props.width || modalWidth};
    max-height: 80%;
    display: flex;
    padding: 16px 52px 40px 52px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 0 0 10px 10px;
    background: ${Palette.lightGrey10};
    overflow: auto;
`;

type ModalProps = {
    title: string;
    onClose?: () => void;
    children: ReactElement<any, any>;
    width?: string;
};

const Modal: React.FC<ModalProps> = ({title, onClose, children, width}) => {
    return (
        <ModalContainer>
            <ModalHeader width={width}>
                <Typography variant="h1">{title}</Typography>
                {onClose && <CloseIcon onClick={onClose} style={{color: Palette.darkGrey1, cursor: 'pointer'}}
                                       fontSize="small"/>}
            </ModalHeader>
            <ModalContent width={width}>{children}</ModalContent>
        </ModalContainer>
    );
};

export default Modal;
