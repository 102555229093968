import { Button } from "@mui/material";
import Typography from "../../../../components/Typography";
import CnhSample from "../../../../shared/assets/upload-cnh-file-sample.png";
import CtpsSample from "../../../../shared/assets/upload-ctps-file-sample.png";
import PassportSample from "../../../../shared/assets/upload-passport-file-sample.png";
import RgSample from "../../../../shared/assets/upload-rg-file-sample.png";
import { InstructionsText } from "../../../../theme/GlobalStyles";
import { OnboardingVideoDocType } from "../../onBoardingVideo/hooks/useOnBoardingVideo";
import { StyledDivButton } from "../../onBoardingVideo/styles";
import {
  Container,
  DocumentInquiryFileUploadInstructionsImage,
} from "./styles";

interface InstructionsUploadFileProps {
  docType: OnboardingVideoDocType;
  nextStep: () => void;
  previousStep: () => void;
}

const InstructionsUploadFile = ({ ...props }: InstructionsUploadFileProps) => {
  const GetInstructionText = () => {
    const instructionText =
      "Envie os arquivos referentes ao documento, incluindo a frente e, se houver, o verso. ";
    switch (props.docType) {
      case "Passaporte":
        return `${instructionText}Inclua a página com a foto de seu passaporte, conforme o exemplo abaixo. `;
      case "CNH":
        return `${instructionText}Inclua a CNH aberta, conforme o exemplo abaixo. `;
      case "Carteira de Trabalho":
        return `${instructionText}Inclua a página com a foto e a página com os dados (Qualificação Civil), conforme o exemplo abaixo. `;
      default:
        return `${instructionText}A frente e o verso do documento podem ser enviados em arquivos separados. `;
    }
  };

  const GetInstructionImageSample = () => {
    switch (props.docType) {
      case "Passaporte":
        return PassportSample;
      case "CNH":
        return CnhSample;
      case "Carteira de Trabalho":
        return CtpsSample;
      default:
        return RgSample;
    }
  };

  return (
    <Container>
      <Typography variant="h1">
        Instruções para envio de arquivos do computador
      </Typography>
      <InstructionsText>
        {GetInstructionText()}Cada arquivo não deve ultrapassar <b>10 MB</b>.
        <br />
        Formatos suportados: JPG, PNG ou PDF.
      </InstructionsText>
      <InstructionsText>Exemplo:</InstructionsText>
      <DocumentInquiryFileUploadInstructionsImage
        src={GetInstructionImageSample()}
      />
      <InstructionsText>
        Clique no botão <b>“Próximo”</b> abaixo para prosseguir.
      </InstructionsText>
      <StyledDivButton>
        <Button variant={"contained"} color="primary" onClick={props.nextStep}>
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </Container>
  );
};

export default InstructionsUploadFile;
