import React from "react";
import {Button} from "@mui/material";
import Typography from "../../../../components/Typography";
import {ReceiveOnBoardingInviteResponse} from "../../../../shared/models/requests/receive-invite-onboarding";
import {StyledDivButton, StyledDivText} from "../../onBoardingVideo/styles";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import WhitelabelVideoOnboardingInstructions from "../../../../components/WhitelabelVideoOnboardingInstructions";


interface IntroductionOnBoardingVideoProps {
    data: ReceiveOnBoardingInviteResponse | null;
    navigateToNext: () => void;
}

const IntroductionOnBoardingVideo: React.FC<IntroductionOnBoardingVideoProps> = ({data, navigateToNext}) => {
    const {whitelabelName, isDefaultWhitelabel} = useWhitelabel();
    return (
        <>
            <Typography variant="h1" color="green">
                Boas vindas ao {whitelabelName}!
            </Typography>
            {isDefaultWhitelabel && <>
                <Typography variant="caption">Acesso de visitante.</Typography>
                <StyledDivText>
                    <Typography variant="caption">
                        Olá! Você recebeu este vídeo pois contratou algum serviço do banco{" "}
                        {data?.inviteDTO.bankName}
                    </Typography>
                </StyledDivText>
            </>}
            <Typography variant="caption5" align="center">
                LEIA ATENTAMENTE AS INSTRUÇÕES ABAIXO ANTES DE INICIAR!
            </Typography>
            <Typography variant="caption">
                {!data.inviteDTO.isNoDocOnboarding ? '1. ' : ''}
                Neste momento, você deverá gravar um vídeo lendo o texto apresentado em sua tela para validação da
                sua proposta de Empréstimo.
                Clique no botão “Iniciar gravação”, leia o texto com calma, e em seguida clique em “finalizar gravação”
                para concluir o seu vídeo.
                Após finalizar o vídeo, terá a oportunidade de assistí-lo e, caso prefira gravar novamente, poderá
                realizar a gravação de um novo vídeo.
            </Typography>
            {!data.inviteDTO.isNoDocOnboarding && <Typography variant="caption">
                2. O segundo passo consiste no envio das fotos do seu documento. No
                caso de RG você deverá fotografar primeiro a frente do documento,
                seguido do verso.
            </Typography>}
            <WhitelabelVideoOnboardingInstructions/>
            <StyledDivButton>
                <Button
                    variant={"contained"}
                    color="primary"
                    onClick={navigateToNext}
                >
                    {"Próximo"}
                </Button>
            </StyledDivButton>
        </>
    );
};


export default IntroductionOnBoardingVideo;
