import Typography from '../../../../components/Typography';
import DetailLine from '../../../ListUsers/DetailLine';
import {applyMask} from '../../../../shared/utils/remove-mask';
import React, {useState} from 'react';
import {CardTitleLineDiv, HorizontalButtonsContainer, ModalContainer, StyledCard} from "../../styles";
import CloseIcon from "@mui/icons-material/Close";
import {Button, IconButton} from "@mui/material";
import {UnityUserDto} from "../../../../shared/models/requests/unities/unity-user-dto";
import {useUnitService} from "../../../../shared/services/useUnitService";
import {toast} from "react-toastify";
import Modal from "../../../../components/Modal";

interface UnityUserCardProps {
    user: UnityUserDto
    unityId: string
    unityName: string
    onRefresh: () => void
}

const UnityUserCard = ({...props}: UnityUserCardProps) => {
    const maxTitleLength = 30;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const {unlinkUserFromUnity} = useUnitService();
    const userInfoComponents = () => {
        return {
            name: <DetailLine label={'Nome: '} value={props.user.name}/>,
            cnpj: <DetailLine label={'CPF/CNPJ: '}
                              value={applyMask(props.user.cpf, props.user.cpf.length > 11
                                  ? '99.999.999/9999-99'
                                  : '999.999.999-99')}/>,
            email: <DetailLine label={'E-mail: '} value={props.user.email}/>,
            phone: <DetailLine label={'Telefone: '} value={applyMask(props.user.phone, '+55 (99) 99999-9999')}/>,
            users: <DetailLine label={'Unidades vinculadas: '} value={props.user.unitiesQty.toString()}/>
        }
    }

    const unlinkUser = async () => {
        try{
            setLoading(true)
            await unlinkUserFromUnity(props.unityId, props.user.id)
            props.onRefresh()
            toast.success('Usuário desvinculado com sucesso!')
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setLoading(false)
            setShowConfirmation(false)
        }
    }

    return (
        <>
            {showConfirmation && <Modal
                title={'Desvincular usuário'}
                onClose={() => setShowConfirmation(false)}>
                <ModalContainer>
                    <Typography variant='caption'>
                        {`Tem certeza que deseja desvincular o usuário ${props.user.name} da unidade ${props.unityName}?`}
                    </Typography>
                    <HorizontalButtonsContainer>
                        <Button
                            onClick={() => setShowConfirmation(false)}
                            variant={'text'}
                        >{'Voltar'}</Button>
                        <Button
                            variant={'outlined'}
                            color={'error'}
                            onClick={() => unlinkUser()}
                            disabled={loading}
                        >{`Desvincular Usuário`}</Button>
                    </HorizontalButtonsContainer>
                </ModalContainer>
            </Modal>}
            <StyledCard>
                <CardTitleLineDiv>
                    <Typography
                        variant='caption3'>{props.user.name.length > maxTitleLength
                        ? props.user.name.slice(0, maxTitleLength) + '...'
                        : props.user.name}</Typography>
                    <IconButton onClick={() => setShowConfirmation(true)}><CloseIcon/></IconButton>
                </CardTitleLineDiv>
                {userInfoComponents().cnpj}
                {userInfoComponents().email}
                {userInfoComponents().phone}
                {userInfoComponents().users}
            </StyledCard>
        </>
    );
}
export default UnityUserCard;