import ItemLineProps from "./ItemInquiryListLineProps";
import Typography from "../../../../components/Typography";
import styled from "styled-components";
import { Container } from "./ItemInquiryListStyles";


const ItemLine = (props: ItemLineProps) => {
  return (
    <Container>
      <Typography variant={"filterSummaryLabel"}>{props.label}</Typography>
      <Typography variant={"filterSummaryValue"}>{props.value}</Typography>
    </Container>
  );
};

export default ItemLine;
