import PagarMeApiContext from "../pagarme-api-context";
import {ApiContextProviderProps} from "./api-context-provider-props";
import {useContext} from "react";

interface PagarMeApiContextProviderProps extends ApiContextProviderProps {
    basicToken: string
}

export const PagarMeApiContextProvider = ({
                                              children,
                                              baseUrl,
                                              env,
                                              enableLogs = false,
                                              onError,
                                              onWarn,
                                              onInfo,
                                              basicToken
                                          }: PagarMeApiContextProviderProps) => {
    const context = useContext(PagarMeApiContext);
    const initialValue = {
        ...context,
        baseUrl,
        env,
        enableLogs,
        onError,
        onWarn,
        onInfo,
        basicToken
    }
    return (
        <PagarMeApiContext.Provider value={initialValue}>
            {children}
        </PagarMeApiContext.Provider>
    );
}