import { useContext, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import SubHeader from "../../../components/SubHeader/SubHeader";
import SubSection from "../../../components/SubSection";
import Typography from "../../../components/Typography";
import { ButtonContainer } from "./styles";
import UserCardsPicker from "../../../components/UserCardsPicker";
import { AccountContext } from "../../../shared/context/AccountContext";
import { usePlanService } from "../../../shared/services/usePlanService";
import { useSubscriptionService } from "../../../shared/services/useSubscriptionService";
import Layout from "../../../layout";
import { UserCardOption } from "../../../shared/models/user-card";
import { toast } from "react-toastify";
import { Button, Stack } from "@mui/material";
import Routes from "../../../constants/Routes";
import PlanCard from "./components/PlanCard";
import PlanoDTO from "../../../shared/models/backend/plan/PlanoDTO";

export async function loadPlanFromParams({ params }) {
  return params?.id;
}

export default function PlanConfirmation() {
  const { ids } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanoDTO>();
  const [selectedCard, setSelectedCard] = useState<UserCardOption>();
  const planId = useLoaderData() as number;
  const { buscarTodosPlanosAtivos } = usePlanService();
  const { postSubscription } = useSubscriptionService();
  const navigate = useNavigate();

  useEffect(() => {
    const getPlans = async () => {
      const availablePlans = (await buscarTodosPlanosAtivos())
        ?.list as PlanoDTO[];
      console.log("plans", availablePlans);
      setSelectedPlan(
        availablePlans?.find((plan: PlanoDTO) => plan.id == planId)
      );
    };
    setLoading(true);
    getPlans().finally(() => setLoading(false));
  }, [planId]);

  const PaySubscription = () => {
    if (!selectedCard || !ids || !ids.unitId || !planId) {
      return;
    }
    const subscriptionData = {
      planId: planId,
      unityId: ids.unitId,
      pagarMeCardId: selectedCard.pagarMeCardId,
      cardName: selectedCard.name,
    };
    setLoading(true);

    postSubscription(subscriptionData)
      .then(() => {
        toast.success("Assinatura contratada com sucesso!");
        navigate(Routes.SettingsPlans);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <Layout iconHelp>
      <SubHeader title="Planos/Pagamentos" subtitle="Seleção de planos" />
      <SubSection>
        <Typography variant="h1">Você Selecionou:</Typography>
        {selectedPlan && <PlanCard plan={selectedPlan} />}
        <UserCardsPicker
          onChangeSelected={setSelectedCard}
          footer={
            <ButtonContainer>
              <Stack direction="row" spacing={2}>
                <Button size="medium" variant={"text"}>
                  Voltar
                </Button>
                <Button
                  size="medium"
                  variant={"contained"}
                  color={"primary"}
                  onClick={PaySubscription}
                  disabled={!selectedCard || loading}
                >
                  Contratar plano
                </Button>
              </Stack>
            </ButtonContainer>
          }
        ></UserCardsPicker>
      </SubSection>
    </Layout>
  );
}

export { PlanConfirmation };
