import { useState } from "react";
import ICreateAccountContext from "./context/ICreateAccountContext";

const useCreateAccountContext = (): ICreateAccountContext => {
  const [name, setName] = useState<string>();
  const [cpfCnpj, setCpfCnpj] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [cep, setCep] = useState<string>();
  const [state, setState] = useState<string>();
  const [city, setCity] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [number, setNumber] = useState<string>();
  const [complement, setComplement] = useState<string>();
  const [neighborhood, setNeighborhood] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);

  return {
    name,
    cpfCnpj,
    email,
    phone,
    cep,
    state,
    city,
    address,
    number,
    complement,
    neighborhood,
    password,
    passwordConfirmation,
    termsAccepted,
    privacyPolicyAccepted,
    setName,
    setCpfCnpj,
    setEmail,
    setPhone,
    setCep,
    setState,
    setCity,
    setAddress,
    setNumber,
    setComplement,
    setNeighborhood,
    setPassword,
    setPasswordConfirmation,
    setTermsAccepted,
    setPrivacyPolicyAccepted
  };
};

export default useCreateAccountContext;
