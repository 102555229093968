import {
    ResultTimeInfoCardBoldText,
    ResultTimeInfoCardContent,
    ResultTimeInfoCardText,
    ResultTimeInfoCardTextContainer,
    ResultTimeInfoCardView
} from "./styles";

import ClockIcon from '@mui/icons-material/WatchLater';
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import {InquiryUtils} from "../../../../shared/utils/inquiry-utils";
import useWhitelabel from "../../../../hooks/useWhitelabel";
import {Constants} from "../../../../constants/Constants";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

interface ResultTimeInfoCardProps {
    inquiryType: EInquiryType
}

const ResultTimeInfoCard = ({inquiryType}: ResultTimeInfoCardProps) => {
    const {currentWhitelabel} = useWhitelabel();
    const isWhitelabel = currentWhitelabel?.id != Constants.CONSULTAI_WHITELABEL_ID;
    const theme = useThemeWhiteLabel();
    return (
        <ResultTimeInfoCardView primaryColor={theme.primaryColor}>
            <ResultTimeInfoCardContent>
                {isWhitelabel ?
                    <ResultTimeInfoCardTextContainer>
                        <ResultTimeInfoCardText>
                            <>
                                {`Você receberá um retorno do time ${currentWhitelabel?.name} o mais breve possível`}
                            </>
                        </ResultTimeInfoCardText>
                        <ClockIcon color={'primary'}/>
                    </ResultTimeInfoCardTextContainer>
                    :
                    <ResultTimeInfoCardTextContainer>
                        <ResultTimeInfoCardText>
                            <>
                                {'O tempo estimado para o resultado dessa consulta é de '}
                                <ResultTimeInfoCardBoldText>{InquiryUtils.getEstimatedTime(inquiryType)}</ResultTimeInfoCardBoldText>
                            </>
                        </ResultTimeInfoCardText>
                        <ClockIcon color={'primary'}/>
                    </ResultTimeInfoCardTextContainer>}
            </ResultTimeInfoCardContent>
        </ResultTimeInfoCardView>
    )
}
export default ResultTimeInfoCard;