import { useRef, useState } from "react";

const useCamera = () => {
  const previewRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const chunksRef = useRef<BlobPart[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [isCaptured, setIsCaptured] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [recording, setRecording] = useState(false);
  const [videoSize, setVideoSize] = useState("");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const handleStart = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    const options = {
      videoBitsPerSecond: 1000000,
    };
    const mediaRecorder = new MediaRecorder(stream, options);
    setMediaRecorder(mediaRecorder);

    if (previewRef.current) {
      previewRef.current.srcObject = stream;
      previewRef.current.play();
    }

    mediaRecorder.start();

    mediaRecorder.ondataavailable = (e) => {
      chunksRef.current.push(e.data);
    };

    setRecording(true);
  };
  const handleStop = () => {
    if (mediaRecorder) {
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "video/mp4" });
        const sizeInMB = blob.size / (1024 * 1024);
        setVideoSize(sizeInMB.toFixed(2));
        const videoUrl = URL.createObjectURL(blob);
        setVideoUrl(videoUrl);
        chunksRef.current = [];

        if (videoRef.current) {
          videoRef.current.load();
        }
      };
      mediaRecorder.stop();
    }
    if (previewRef.current && previewRef.current.srcObject) {
      const tracks = (previewRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      previewRef.current.srcObject = null;
    }

    setRecording(false);
  };
  const handleCapture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      const aspectRatio = 4 / 3;
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      // Ajusta as dimensões
      let width = videoWidth;
      let height = width / aspectRatio;
      if (height > videoHeight) {
        height = videoHeight;
        width = height * aspectRatio;
      }
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      context?.drawImage(videoRef.current, 0, 0, width, height);

      setIsCaptured(true);
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  };
  return {
    previewRef,
    videoRef,
    chunksRef,
    mediaRecorder,
    videoUrl,
    recording,
    videoSize,
    canvasRef,
    isCaptured,
    setIsCaptured,
    handleCapture,
    handleStart,
    handleStop,
    setVideoUrl,
  };
};

export default useCamera;
