import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SubHeader from "../../../components/SubHeader/SubHeader";
import Routes from "../../../constants/Routes";
import Layout from "../../../layout";
import { AccountContext } from "../../../shared/context/AccountContext";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";
import CreateOnboardingInviteCommand from "../../../shared/models/requests/CreateOnboardingInviteCommand";
import useInquiryService from "../../../shared/services/useInquiryService";
import { usePlanService } from "../../../shared/services/usePlanService";
import { useSubscriptionService } from "../../../shared/services/useSubscriptionService";
import { removeMask } from "../../../shared/utils/remove-mask";
import { ScreenWithSubHeaderContainer } from "../../../theme/GlobalStyles";
import DataValidation from "./components/DataValidation";
import Form from "./components/Form";
import LinkSent from "./components/LinkSent";
import OnBoardingType from "./components/OnboardingType";
import { useVideoOnboardingHint } from "./hooks/useVideoOnboardingHint";
import "./VideoOnboarding.css";

interface VideoOnboardingProps {
  isNoDocOnboarding: boolean;
}

const VideoOnboarding: React.FC<VideoOnboardingProps> = ({
  isNoDocOnboarding,
}) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inquiryPrice, setInquiryPrice] = useState<number>();
  const [presential, setPresential] = useState(false);
  const [whatsapp, setWhatsapp] = useState();
  const inviteCommand = useRef<CreateOnboardingInviteCommand>();
  const navigate = useNavigate();
  const { createOnboardingInvite } = useInquiryService();
  const hint = useVideoOnboardingHint({ isNoDocOnboarding });

  const onFormNext = (pInviteCommand: CreateOnboardingInviteCommand) => {
    inviteCommand.current = pInviteCommand;
    setStep(2);
  };
  const { ids } = useContext(AccountContext);
  const { buscarAssinaturaAtiva } = useSubscriptionService();
  const { getProductPrices } = usePlanService();
  const location = useLocation();
  const inquiryType = isNoDocOnboarding
    ? EInquiryType.NoDocVideoOnboarding
    : EInquiryType.VideoOnboarding;

  useEffect(() => {
    const getInquiryPrice = async () => {
      const subscription = await buscarAssinaturaAtiva(ids.unitId);
      const productPrices = await getProductPrices(subscription.planoId);
      const price = productPrices?.find(
        (price) => price.type == inquiryType
      )?.price;
      setInquiryPrice(price);
      if (!price)
        toast.error("Seu plano atual não permite esse tipo de consulta.", {
          autoClose: false,
          closeButton: true,
          toastId: "no-price-error",
        });
    };

    getInquiryPrice();
  }, [ids, location]);
  const onSendLink = async () => {
    setLoading(true);

    try {
      const onboardingData = inviteCommand.current;

      const response = await createOnboardingInvite({
        name: onboardingData.name,
        cpf: removeMask(onboardingData.cpf),
        loanValue: onboardingData.loanValue,
        installmentsValue: onboardingData.installmentsValue,
        installmentsQuantity: onboardingData.installmentsQuantity,
        contractDate: onboardingData.contractDate,
        bankName: onboardingData.bankName,
        phone: onboardingData.phone,
        unityId: onboardingData.unityId,
        companyName: "",
        presential: presential,
        isNoDocOnboarding: isNoDocOnboarding,
        loanType: onboardingData.loanType,
        loanModality: onboardingData.loanModality,
      });

      if (presential) {
        const url = `${window.location.origin}/onboarding/invite/${response.publicId}`;
        window.open(url, "_blank");
      }
      setStep(3);
    } finally {
      setLoading(false);
    }
  };
  const onNewOnboarding = () => {
    inviteCommand.current = undefined;
    setStep(0);
  };

  const onReturnMenu = () => navigate(Routes.Home);

  let component;
  switch (step) {
    case 0:
      component = (
        <OnBoardingType
          onNext={() => setStep(1)}
          setPresential={setPresential}
          inquiryPrice={inquiryPrice}
          presential={presential}
          whatsapp={whatsapp}
          hint={hint}
        />
      );
      break;
    case 1:
      component = (
        <Form
          onNext={onFormNext}
          inviteCommand={inviteCommand.current}
          inquiryPrice={inquiryPrice}
          presential={presential}
        />
      );
      break;
    case 2:
      component = (
        <DataValidation
          inviteCommand={inviteCommand.current}
          isLoading={loading}
          onEdit={() => setStep(1)}
          onSendLink={onSendLink}
          buttonText={
            presential ? "Iniciar vídeo de Onboarding" : "Enviar Link de Acesso"
          }
        />
      );
      break;
    case 3:
      component = (
        <LinkSent
          onNewOnboarding={onNewOnboarding}
          onReturnMenu={onReturnMenu}
          inquiryType={inquiryType}
        />
      );
      break;
    default:
      component = null;
  }
  const title = isNoDocOnboarding
    ? "Onboarding (Vídeo)"
    : "Onboarding (Vídeo + Doc)";

  return (
    <Layout>
      <SubHeader title="Consulta" subtitle={title} />
      <ScreenWithSubHeaderContainer>{component}</ScreenWithSubHeaderContainer>
    </Layout>
  );
};

VideoOnboarding.propTypes = {
  isNoDocOnboarding: PropTypes.bool.isRequired,
};
export default VideoOnboarding;
