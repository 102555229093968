import { CircularProgress } from "@mui/material";
import styled, { keyframes } from "styled-components";


export const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledCircularProgress = styled(CircularProgress)`
    margin-right: 10px;
    animation: ${spin} 1s linear infinite;
`;