import {InquiryReturnDetailsDTO} from "../models/backend/inquiry/inquiry-details-onboarding-dto";
import {OnBoardingRequest} from "../models/requests/onboarding-request";
import {ReceiveOnBoardingInviteResponse} from "../models/requests/receive-invite-onboarding";
import useConsultaiApi from "./context/hooks/useConsultaiApi";

export const useOnBoardingInviteService = () => {
    const consultaiApi = useConsultaiApi();

    const receiveOnBoardingInvite = (id) => {
        return consultaiApi.get<ReceiveOnBoardingInviteResponse>(`/inquiries/onboarding/invite/${id}`);
    };
    const sendVideoOnBoarding = async (fileContent: string) => {
        const requestBody = {
            fileContent: fileContent
        };
        return await consultaiApi.post<string>('/inquiries/video', 2, requestBody);
    };
    const sendInquiryOnBoarding = async (request: OnBoardingRequest) => {
        return await consultaiApi.post<string>('/inquiries/video-onboarding/send', 2, request);
    };
    const getInquiryV2Return = async <T>(id: number): Promise<InquiryReturnDetailsDTO<T>> => {
        return await consultaiApi.get<InquiryReturnDetailsDTO<T>>(`/inquiries/${id}/return`, 2);
    };
    const getPdf = async <T>(id: number): Promise<string> => {
        return await consultaiApi.get<string>(`/inquiries/${id}/pdf`, 1);
    };
    return {
        receiveOnBoardingInvite,
        sendVideoOnBoarding,
        sendInquiryOnBoarding,
        getInquiryV2Return,
        getPdf
    };
};
export default useOnBoardingInviteService;