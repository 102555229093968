import {BuscarSaldoResponse} from "../models/responses/dados-login-response";
import useConsultaiApi from "./context/hooks/useConsultaiApi";
import UserInsertInput from "../models/backend/user/user-insert-input";
import { UserResponseDto } from "../models/backend/user/user-response-dto";
import { PagedList, PagedListFilter } from "../models/responses/paged-list";

const useUserService = () => {
    const consultaiApi = useConsultaiApi();
    const getUsersPagedList = (params: PagedListFilter) =>
        consultaiApi.get<PagedList<UserResponseDto>>(`/users`, 2, params);
    
    const enableUser = async (id: number) => await consultaiApi.post(`/cadastros/usuario/enable/${id}`, 1);
    const disableUser = async (id: number) => await consultaiApi.post(`/cadastros/usuario/disable/${id}`, 1);
    const insertUserAsync = async (input: UserInsertInput) => await consultaiApi.post('/cadastros/usuario/inserir', 1, input);
    const buscarUsuariosAtivos = (id: number) => consultaiApi.post(`/cadastros/usuario/listar/${id}`, 1)
    const buscarSaldo = async (unidadeId: number): Promise<BuscarSaldoResponse> => {
        return (await consultaiApi.get<BuscarSaldoResponse>(`/cadastros/unidade/${unidadeId}/wallet`, 1));
    }
    const getUnities = async (): Promise<any> => {
        return (await consultaiApi.get<any>(`/users/unities`,2,{
            params: {
                pageSize: 1000
            }
        }));
    }

    return {getUsersPagedList, insertUserAsync, buscarUsuariosAtivos, buscarSaldo, getUnities, enableUser, disableUser};
};

export default useUserService;

